import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button/Button';

import _ from 'lodash';

import * as api from '../api';

class InfoCategory extends React.Component {
  state = {
    categories: [],
  };
  render() {
    const { categories } = this.state;

    if (Object.keys(categories).length > 0) {
      let items = categories.map((category, idx) => {
        return (
          <div className="row">
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                value={category.first}
                disabled={category.second && category.second.length > 0}
                onChange={evt => {
                  let first = category.first;
                  this.state.categories.forEach(cat => {
                    if (cat.first === first && first.length > 0) {
                      cat.first = evt.target.value;
                    }
                  });
                  category.first = evt.target.value;
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                value={category.second}
                disabled={category.third && category.third.length > 0}
                onChange={evt => {
                  let first = category.first;
                  let second = category.second;
                  this.state.categories.forEach(cat => {
                    if (cat.first === first && cat.second === second && first.length > 0 && second.length > 0) {
                      cat.second = evt.target.value;
                    }
                  });
                  category.second = evt.target.value;
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                value={category.third}
                onChange={evt => {
                  let first = category.first;
                  let second = category.second;
                  let third = category.third;
                  this.state.categories.forEach(cat => {
                    if (
                      cat.first === first &&
                      cat.second === second &&
                      cat.third === third &&
                      first.length > 0 &&
                      second.length > 0 &&
                      third.length > 0
                    ) {
                      cat.third = evt.target.value;
                    }
                  });
                  category.third = evt.target.value;
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ flex: 0.3 }}>
              <TextField
                fullWidth
                value={category.order}
                onChange={evt => {
                  category.order = parseInt(evt.target.value);
                  this.forceUpdate();
                }}
              />
            </div>
            <div style={{ flex: 1.2 }}>
              <Button
                color="secondary"
                onClick={() => {
                  this.handleSave(idx);
                }}>
                저장
              </Button>
              <Button
                color="secondary"
                disabled
                onClick={() => {
                  this.handleDel(idx);
                }}>
                삭제
              </Button>
            </div>
          </div>
        );
      });

      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>카테고리 수정</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>대분류</label>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>중분류</label>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>소분류</label>
                  </div>
                  <div style={{ flex: 0.4 }}>
                    <label>순서</label>
                  </div>
                  <div style={{ flex: 1.2 }}></div>
                </div>
                {items}
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const catobj = await api.getOptions('type=category');
    const result = await api.getCategory();
    result.categories.forEach(category => {
      if (category.second && category.third) {
        category.firstOrder = catobj[category.first].order;
        category.secondOrder = catobj[category.first].sub[category.second].order;
        category.thirdOrder = category.order;
      } else if (category.second) {
        category.firstOrder = catobj[category.first].order;
        category.secondOrder = category.order;
        category.thirdOrder = -1;
      } else {
        category.firstOrder = category.order;
        category.secondOrder = -1;
        category.thirdOrder = -1;
      }
    });
    result.categories = _.sortBy(result.categories, ['firstOrder', 'secondOrder', 'thirdOrder']);
    result.categories.push({ first: '', second: '', third: '' });

    this.setState({ categories: result.categories });
  }

  handleSave = idx => {
    if (this.state.categories[idx]) {
      if (this.state.categories[idx].id) {
        api.updateCategory(this.state.categories[idx].id, this.state.categories[idx]).then(() => {
          window.location.reload();
        });
      } else {
        console.log(this.state.categories[idx]);
        api.createCategory(this.state.categories[idx]).then(() => {
          window.location.reload();
        });
      }
    }
  };

  handleDel = idx => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      if (this.state.paycards[idx]) {
        api.deleteCategory(this.state.categories[idx].id).then(() => {
          window.location.reload();
        });
      }
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoCategory);
