import { useEffect, useState } from 'react';
import { getAdsGroupByCompanyId } from '../api/ads';

const useAdsGroups = companyId => {
  const [adsGroups, setAdsGroups] = useState([]);
  const [notGroupedAds, setNotGroupedAds] = useState([]);
  const [adsGroupIds, setAdsGroupIds] = useState([]);

  const getAdsGroupList = async () => {
    const response = await getAdsGroupByCompanyId(companyId);

    if (response.data.success) {
      const groupedAds = Object.entries(response.data.data.adsGroups)
        .filter(([key]) => key !== 'none')
        .reduce((acc, [key, value]) => {
          acc.push(value);
          return acc;
        }, []);

      console.log(response.data);
      setAdsGroups(groupedAds);
      setNotGroupedAds(response.data.data.adsGroups['none'] || []);
      setAdsGroupIds(Object.keys(response.data.data.adsGroups).filter(key => key !== 'none'));
    }
  };

  useEffect(() => {
    getAdsGroupList();
  }, [companyId]);

  return { adsGroups, adsGroupIds, notGroupedAds };
};

export default useAdsGroups;
