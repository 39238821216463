import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as api from '../api';
import { Button } from '@material-ui/core';

class EditorBasic extends React.Component {
  state = {
    pkg: {},
  };
  render() {
    let { pkg } = this.state;

    if (Object.keys(pkg).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>{pkg.company.name}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>{pkg.adId ? '사용' : '충전'}인원</label>
                  <TextField
                    fullWidth
                    value={pkg.number}
                    onChange={evt => this.handleChange('number', evt.target.value)}
                  />
                  <label>기간</label>
                  <TextField
                    fullWidth
                    value={pkg.period}
                    onChange={evt => this.handleChange('period', evt.target.value)}
                    disabled={pkg.adId}
                  />
                  <label>가격</label>
                  <TextField
                    fullWidth
                    value={pkg.price}
                    onChange={evt => this.handleChange('price', evt.target.value)}
                    disabled={pkg.adId}
                  />
                  <label>누적충전인원</label>
                  <TextField
                    fullWidth
                    value={pkg.total}
                    onChange={evt => this.handleChange('total', evt.target.value)}
                  />
                  <label>모집가능인원</label>
                  <TextField
                    fullWidth
                    value={pkg.balance}
                    onChange={evt => this.handleChange('balance', evt.target.value)}
                  />
                </section>
              </div>
            </ExpansionPanelDetails>
            <div className="row" style={{ justifyContent: 'flex-end' }}>
              <Button color="secondary" onClick={this.handleDelete}>
                삭제
              </Button>
              <Button color="secondary" onClick={this.handleSave}>
                저장
              </Button>
            </div>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.setState({ pkg: this.props.pkg });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ pkg: newProps.pkg });
  }

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({ pkg: { ...this.state.pkg, [name]: value } });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const { pkg } = this.state;
      api
        .deletePackage(pkg.id)
        .then(() => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
          this.props.push('/packages');
        })
        .catch(err => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
  handleSave = json => {
    this.props.activateLoading();
    const { id, pkg } = this.state;

    api
      .updatePackage(id, pkg)
      .then(result => {
        this.setState({ schema: json, snackOpen: true });
        this.props.deactivateLoading();
      })
      .catch(err => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorBasic);
