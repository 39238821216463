import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import { useEffect } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

const Help = ({ currentList, type, onFilterHandler, filterName, onCsvHandler, onAddHandler }) => {
  const [statisticsMap, setStatisticsMap] = useState({});

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    const saturation = 60;
    const lightness = 85;
    const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    return color;
  };

  useEffect(() => {
    setStatisticsMap({});
  }, [type]);

  useEffect(() => {
    const listCountMap = currentList.reduce((acc, cur) => {
      const name = (() => {
        if (!!cur.Contracts) {
          return cur.Contracts.companyName;
        } else {
          return cur.Propositions.cname;
        }
      })();

      acc[name] = (acc[name] || 0) + 1;
      return acc;
    }, {});

    const clone = { ...statisticsMap };

    Object.entries(listCountMap).forEach(([k, v]) => {
      if (!clone[k]) {
        clone[k] = {};
      }

      if (v === 0) {
        delete clone[k];
      } else {
        clone[k].count = v;

        if (!clone[k].color) {
          clone[k].color = generatePastelColor();
        }
      }
    });

    setStatisticsMap(clone);
  }, [currentList]);

  return (
    <div
      style={{
        width: '400px',
        borderRadius: 16,
        padding: 16,
        position: 'fixed',
        border: '1px solid #e8ebf2',
        backgroundColor: '#fff',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        right: 56,
        top: 124,
        cursor: 'pointer',
      }}>
      {/* Contents */}
      <div>
        {/* Top */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            borderTop: '1px solid rgba(255, 255, 255, 0.53)',
            paddingBottom: '24px',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <p style={{ fontSize: '18px', fontWeight: 600 }}>{currentList.length}</p>
            <p style={{ fontSize: '16px', fontWeight: 500 }}>row</p>
          </div>
          <div
            onClick={onCsvHandler}
            style={{
              color: '#2a5bd7',
              border: '1px solid #2a5bd7',
              padding: '0px 8px',
              height: '36px',
              backgroundColor: '#fff',
              display: 'flex',
              alignItems: 'center',
            }}>
            CSV Download <GetAppIcon />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '4px',
            maxHeight: '200px',
            overflowY: 'scroll',
          }}>
          {Object.entries(statisticsMap).map(([k, v]) => (
            <div
              style={{
                display: 'flex',
                width: `calc(50% - 8px)`,
                cursor: 'pointer',
              }}
              key={k}
              onClick={() => {
                onFilterHandler(k);
              }}>
              <div
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: v.color,
                  marginRight: 4,
                }}></div>
              <p
                style={{
                  fontSize: 12,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  flex: 1,
                  fontWeight: filterName === k ? 700 : 400,
                }}>
                {k}
              </p>
              <p style={{ fontSize: 12, fontWeight: 600 }}>{v.count}</p>
            </div>
          ))}
        </div>
      </div>
      {/* Footer */}
      <div
        onClick={onAddHandler}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTop: '1px solid rgba(255, 255, 255, 0.53)',
          paddingTop: '24px',
        }}>
        <p
          style={{
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '18px',
            marginRight: '4px',
          }}>
          추가
        </p>
        <AddIcon />
      </div>
    </div>
  );
};

export default Help;
