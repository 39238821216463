import React, { PureComponent } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import _ from 'lodash';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Receipt from '@material-ui/icons/Receipt';
import IconButton from '@material-ui/core/IconButton/IconButton';
import momentLib from 'moment-timezone';

class InfoPay extends PureComponent {
  render() {
    const { companyUser, companies } = this.props;

    const items = companies.map((company, idx) => {
      let channels = ['naver', 'insta'];
      return channels.map((channel, idx2) => {
        let paycards = company[channel + 'PayCards'];
        if (!_.isEmpty(paycards)) {
          return paycards.map(paycard => {
            let payState = '결제 예정';
            if (paycard.payState === 0) {
              payState = '결제 예정';
            } else if (paycard.payState === 1) {
              payState = '결제 완료';
            } else if (paycard.payState === 2) {
              payState = '결제 요청';
            } else if (paycard.payState === 3) {
              payState = '결제 중지';
            } else {
              payState = '중지 후 재시작';
            }
            return (
              <div
                key={idx + paycard.channel + paycard.order}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 12,
                  borderBottom: '1px solid #dbdbdb',
                }}>
                <div>
                  <span>{paycard.order}차</span>
                </div>
                <div>
                  <span>{company.name}</span>
                </div>
                <div>
                  <span>{paycard.payMethod || (!!company[channel] && company[channel].payInfo_payMethod)}</span>
                </div>
                <div>
                  <span>{paycard.card_name}</span>
                </div>
                <div>
                  <span>{paycard.amount}</span>
                </div>
                <div>
                  <span>{payState}</span>
                </div>
                <div>
                  <span>{momentLib(paycard.paidAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</span>
                </div>
                <div>
                  <IconButton
                    style={{ padding: 0 }}
                    onClick={() => {
                      window.open(paycard.receipt);
                    }}>
                    <Receipt />
                  </IconButton>
                </div>
              </div>
            );
          });
        } else {
          return <div key={idx + ' ' + idx2}></div>;
        }
      });
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name}님의 결제내역`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>{items}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {}
  async componentWillReceiveProps(nextProps) {}
}

export default InfoPay;
