import axios from 'axios';

/**
 * Contracts 요청
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @param {string} query.category ?category (AD | CONTRACT)
 * @returns {Handbook[]} Contract 목로
 */

export const getHandbooks = (query = {}) => {
  const payloadString = Object.entries(query)
    .map(e => e.join('='))
    .join('&');

  let baseURL = `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/handbooks`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const addHandbook = body => {
  return axios.post(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/handbook`, {
    ...body,
  });
};

export const deleteHandbook = id => {
  return axios.delete(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/handbook/${id}`);
};
