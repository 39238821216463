import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import axios from 'axios';
import AWS from 'aws-sdk';

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
};

firebase.initializeApp(config);
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.timeout = 36000000;

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
function onUploadProgress(ev) {
  console.log(ev);
  // do your thing here
}

// axios.interceptors.request.use(config => {
//   ...config, onUploadProgress: config.onUploadProgress || onUploadProgress
// });

export function isSignedIn() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        resolve(user);
      } else {
        reject();
      }
    });
  });
}

export async function signIn(email, password) {
  try {
    // NOTE: 로그아웃하거나 캐시를 지우지 않는 이상 세션 유지되도록 설정
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    await firebase.auth().signInWithEmailAndPassword(email, password);
    return;
  } catch (err) {
    throw err;
  }
}

export function getToken() {
  return new Promise((resolve, reject) => {
    let user = firebase.auth().currentUser;
    user
      .getIdToken()
      .then(token => {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        let profile = {
          name: user.displayName,
          email: user.email,
          photoUrl: user.photoURL,
          emailVerified: user.emailVerified,
          uid: user.uid,
        };
        resolve(profile);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function signOut() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
}

export function get(routePath) {
  return new Promise(resolve => {
    firebase
      .database()
      .ref(routePath)
      .once('value', snapshot => {
        resolve(snapshot.val());
      });
  });
}

export function fetchData(method, url, query, body, newApiServer = false) {
  // if(process.env.NODE_ENV === 'development')
  console.log("@@@@@ 'fetchData'", method, url, query, body);

  return getToken().then(user => {
    return new Promise((resolve, reject) => {
      let fetchURL = '';

      // NOTE: 새로운 API 서버를 이용할 때 임시로 baseURL 변경. 구 API 서버에서 신 API 서버로 모두 이관되면 제거.
      if (newApiServer) {
        fetchURL = process.env.REACT_APP_NEW_API_BASE_URL;
      }

      fetchURL += url;

      if (query) {
        fetchURL += '?';
        let keys = Object.keys(query);
        for (let i = 0; i < keys.length; i++) {
          if (Array.isArray(query[keys[i]])) {
            query[keys[i]].forEach(item => {
              fetchURL = fetchURL + keys[i] + '[]=' + item + '&';
            });
          } else {
            fetchURL = fetchURL + keys[i] + '=' + query[keys[i]] + '&';
          }
        }
      }
      axios({
        method: method,
        url: fetchURL,
        data: body,
      })
        .then(res => {
          if (newApiServer) {
            resolve(res.data.data);
          } else {
            resolve(res.data);
          }

          // if(process.env.NODE_ENV === 'development')
          console.log("##### 'fetchData'", method, fetchURL, body, '=>', res.data);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  });
}

export function getUser(userID) {
  return new Promise((resolve, reject) => {
    axios
      .get('/user/console?uid=' + userID)
      .then(result => {
        resolve(result.data.users[0]);
      })
      .catch(err => {
        reject(err.response ? err.response.data : err);
      });
  });
}

export async function uploadFile(filePath, fileData, progressCallback) {
  return new Promise((resolve, reject) => {
    let uploadTask = firebase.app().storage('gs://phoz-7f0de').ref().child(filePath).put(fileData);

    uploadTask.on(
      'state_changed',
      snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        progressCallback({ progress: progress, state: snapshot.state });
      },
      err => {
        reject(err);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => resolve(downloadURL));
      }
    );
  });
}

export async function uploadFileToS3(filePath, fileData, progressCallback) {
  let token = await firebase.auth().currentUser.getIdToken(true);
  AWS.config.credentials = new AWS.WebIdentityCredentials({
    RoleArn: 'arn:aws:iam::557258438147:role/FirebaseS3Role',
    WebIdentityToken: token, // Access token from identity provider
  });
  AWS.config.region = 'ap-northeast-2';

  let upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: 'supermembers.upload',
      Key: filePath,
      // ACL : 'public-read',
      Body: fileData,
    },
  });

  let url = '';
  let res = await upload.promise();
  if ((res || {}).Location) {
    url = 'https://d21ebri204ww32.cloudfront.net/' + filePath.replace('org', 'resize');
    url = url.replace(/\.[^/.]+$/, '.webp');
    url = url + '?f=jpeg';
  }
  return url;
}

export function onDataChanged(collectionName, docName, callback) {
  let ref = firebase.firestore().collection(collectionName);
  if (docName) {
    ref = ref.doc(docName);
  }
  const remover = ref.onSnapshot(snapshot => {
    if (docName) {
      callback(snapshot.data(), remover);
    } else {
      let docs = [];
      snapshot.forEach(doc => {
        docs.push(doc.data());
      });
      callback(docs, remover);
    }
  });
}

export function updateDirectly(collectionName, docName, data) {
  return firebase.firestore().collection(collectionName).doc(docName).update(data);
}
