import React, { Component } from 'react';
import { Link } from 'react-router';

import momentLib from 'moment-timezone';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Pageview from '@material-ui/icons/Pageview';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import _ from 'lodash';

import { getUser, fetchData } from '../../app/api';
import './cards.css';
import { Button } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';

class CardEdit extends Component {
  state = {
    ...this.props.proposition,
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  handleSave = () => {
    const { close, handleSaved } = this.props;
    let proposition = this.state;

    if (proposition.link && proposition.currentState < 1) {
      proposition.currentState = 1;
      proposition.date1 = new Date();
    }
    if (proposition.mark && proposition.currentState < 2) proposition.currentState = 2;
    proposition.date2 = new Date();

    fetchData('put', '/proposition/console', { id: proposition.id }, proposition)
      .then(result => {
        handleSaved(proposition);
        alert('저장 완료');
        close();
      })
      .catch(err => {
        alert('저장 실패');
      });
  };
  render() {
    const { close, collapsed } = this.props;
    const { link, mark } = this.state;
    return (
      <div className={collapsed ? 'forms collapsed' : 'forms'}>
        <TextField
          label={'링크'}
          name="link"
          fullWidth
          style={{ marginRight: 20 }}
          value={link ? link : ''}
          onChange={this.handleChange}
        />
        <TextField label={'평가'} name="mark" fullWidth value={mark ? mark : ''} onChange={this.handleChange} />
        <div className="buttons">
          <IconButton onClick={this.handleSave}>
            <Save />
          </IconButton>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </div>
      </div>
    );
  }
}
class Card extends Component {
  state = {
    email: '',
    editable: false,
    proposition: this.props.proposition,
    manuscript: this.props.manuscript,
  };
  componentDidMount() {
    const { proposition, manuscript } = this.state;
    if (proposition.userEmail === undefined) {
      if (proposition.user) {
        this.setState({ email: proposition.user.email, username: proposition.user.displayName });
      } else {
        getUser(proposition.uid).then(user => {
          this.setState({ email: user.email, username: user.displayName });
        });
      }
    } else {
      this.setState({ email: proposition.userEmail });
    }
  }
  componentWillReceiveProps(newProps) {
    this.setState({ manuscript: newProps.manuscript });
  }
  openLink = () => {
    const { proposition, email } = this.state;
    if (proposition.type === 'naver' || proposition.type === undefined) {
      if (email === undefined || email === null) {
        alert('유저정보 조회 실패\n탈퇴한 회원일 수 있습니다.');
        return;
      }
      window.open(`http://blog.naver.com/${email.split('@')[0]}/${proposition.link}`);
    } else {
      window.open(proposition.link);
    }
  };
  render() {
    const { forWho, companies } = this.props;
    const { editable, proposition, email, username, manuscript } = this.state;
    const stateNameMap = ['이용완료', '리뷰등록', '평가완료'];

    let status = '';
    let manuStatus = '-';
    if (manuscript && manuscript[0].status === 3) {
      status = '등록';
    } else if (manuscript && (manuscript[0].status === 1 || manuscript[0].status === 2)) {
      if (manuscript[0].step === 0) {
        status = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            초본
          </a>
        );
      } else {
        status = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            {manuscript[0].step}차 수정본
          </a>
        );
      }
      if (manuscript[0].status === 1) {
        manuStatus = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            수정요청
          </a>
        );
      } else {
        manuStatus = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            업로드요청
          </a>
        );
      }
    } else if (manuscript) {
      if (manuscript[0].step) {
        status = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            {manuscript[0].step}차 수정본
          </a>
        );
      } else {
        status = (
          <a href="#" onClick={() => window.open(`${manuscript[0].manuscript}`)}>
            초본 등록
          </a>
        );
      }
    } else if (proposition.status === 0) {
      status = '목록';
    } else if (proposition.status === 1) {
      status = '제안';
    } else if (proposition.status === 2) {
      status = '수락';
    } else if (proposition.status === 3) {
      status = '거절';
    } else if (proposition.status === 4) {
      status = '무응답';
    } else if (proposition.status === 10) {
      status = '발송대기';
    } else if (proposition.status === 12) {
      status = '미방문';
    } else if (proposition.status === 20) {
      status = '이용완료';
    } else if (proposition.status === 22) {
      status = '원고수정요청';
    } else if (proposition.status === 24) {
      status = '원고확인중';
    } else if (proposition.status === 30) {
      status = '리뷰대기중';
    } else if (proposition.status === 32) {
      status = '리뷰완료';
    } else if (proposition.status === 40) {
      status = '평가완료';
    } else if (proposition.status === 50) {
      status = '이용취소';
    }

    return (
      <div className={'card ' + proposition.level}>
        <div className="infos">
          <div className="date" style={{ display: 'block' }}>
            <div>{momentLib(proposition.proposedAt).tz('Asia/Seoul').format('YY/MM/DD')}</div>
            <div>{momentLib(proposition.proposedAt).tz('Asia/Seoul').format('HH:mm')}</div>
          </div>
          {forWho === 'campaign' ? (
            <div className="info" style={{ display: 'block' }}>
              {/*<h4 className={proposition.level}>{proposition.level}</h4>*/}
              <div style={{ display: 'block' }}>
                <Link to={'/user?uid=' + proposition.uid} target="_blank">
                  {username}
                </Link>
              </div>
              <div>
                {email ? (
                  <a href={'https://blog.naver.com/' + (email || '').split('@')[0]} target="_blank">
                    {' (' + email.split('@')[0] + ')'}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div className="info" style={{ display: 'block' }}>
              <Link to={'/campaigns?id=' + proposition.campaign} target="_blank">
                {proposition.campaignName}
              </Link>
            </div>
          )}
          <div className="info">{status}</div>
          <div className="info" style={{ display: 'block' }}>
            <div>{manuscript && _.orderBy(manuscript, ['step'], ['desc'])[0].status === 0 ? '요청' : '남은수정'}</div>
            <div>
              {manuscript ? proposition.maxEditCount - manuscript[0].step + '/' + proposition.maxEditCount + '회' : ''}
            </div>
          </div>
          <div className="info">{manuStatus}</div>
          <div className="info" style={{ display: 'block' }}>
            <div>
              {proposition.review ? (
                <a href="#" onClick={() => window.open(`${proposition.review}`)}>
                  리뷰
                </a>
              ) : (
                '-'
              )}
            </div>
            <div>
              {proposition.reviewRegisteredAt
                ? momentLib(proposition.reviewRegisteredAt).tz('Asia/Seoul').format('YY/MM/DD')
                : ''}
            </div>
          </div>
        </div>
        <CardEdit
          collapsed={!editable}
          proposition={proposition}
          handleSaved={proposition => this.setState({ proposition: proposition })}
          close={() => this.setState({ editable: false })}
        />
      </div>
    );
  }
}

export default Card;
