import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AWS from 'aws-sdk';

class CampaignInfo extends React.Component {
  state = {
    limitation: (this.props.campaign.unavailable || '').length > 0,
    file: null,
  };
  render() {
    const { campaign } = this.props;

    if (Object.keys(campaign).length > 0) {
      const deliveryLabels = {
        address: '주소',
        name: '실명',
        id: '자사몰 아이디(일반 아이디 형식)',
        email: '자사몰 아이디(이메일 형식)',
        contact: '연락처',
      };
      campaign.delivery = campaign.delivery || [];
      const deliveryMenus = Object.keys(deliveryLabels).map(key => {
        return (
          <MenuItem key={key} value={key}>
            <Checkbox checked={campaign.delivery.includes(key)} />
            <ListItemText primary={deliveryLabels[key]} />
          </MenuItem>
        );
      });
      const bottomContents =
        campaign.category.firstName === '제품' ? (
          <div>
            {/*제품*/}
            <label>제품 배송 방식</label>
            <Select
              fullWidth
              value={campaign.deliveryMethod ? campaign.deliveryMethod : ''}
              onChange={evt => this.props.handleChange('deliveryMethod', evt.target.value)}>
              <MenuItem value="C">자사몰에서 쿠폰/적립금 지급</MenuItem>
              <MenuItem value="A">회원 주소로 직접 배송</MenuItem>
            </Select>
            <label>배송 정보</label>
            <Select
              multiple
              fullWidth
              value={campaign.delivery ? campaign.delivery : []}
              renderValue={selected => selected.map(key => deliveryLabels[key]).join(', ')}
              onChange={evt => this.handleDeliveryChange(evt.target.value)}>
              {deliveryMenus}
            </Select>
            {/*<label>추가 질문 텍스트</label>*/}
            {/*<TextField*/}
            {/*  fullWidth*/}
            {/*  value={campaign.additionalInfo}*/}
            {/*  onChange={(evt) => this.props.handleChange('additionalInfo', evt.target.value)}*/}
            {/*/>*/}
            <label>제품 발송 요일</label>
            <TextField
              fullWidth
              value={campaign.shipping}
              onChange={evt => this.props.handleChange('shipping', evt.target.value)}
            />
          </div>
        ) : (
          <div>
            {/*매장*/}
            <label>예약 필수 여부</label>
            <Switch
              checked={campaign.isReservationRequired}
              onClick={() => this.props.handleChange('isReservationRequired', !campaign.isReservationRequired)}
            />
            <label>예약 안내</label>
            <TextField
              fullWidth
              value={campaign.reservationComment}
              onChange={evt => this.props.handleChange('reservationComment', evt.target.value)}
            />
            <label>방문 마감 기한(일)</label>
            <TextField
              fullWidth
              value={campaign.usingPeriod}
              onChange={evt => this.props.handleChange('usingPeriod', evt.target.value)}
            />
          </div>
        );
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>광고정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <label>한줄소개</label>
              <TextField
                fullWidth
                value={campaign.abstraction}
                onChange={evt => this.props.handleChange('abstraction', evt.target.value)}
              />
              <label>상세소개</label>
              <TextField
                multiline
                fullWidth
                value={campaign.introduction}
                onChange={evt => this.props.handleChange('introduction', evt.target.value)}
              />
              <label>인스타그램 계정</label>
              <TextField
                fullWidth
                value={campaign.instaOfficial}
                onChange={evt => this.props.handleChange('instaOfficial', evt.target.value)}
              />
              <div className="row">
                <div style={{ flex: 1 }}>
                  <label>공정위 배너</label>
                  <TextField
                    fullWidth
                    value={campaign.bannerImg}
                    onChange={evt => this.props.handleChange('bannerImg', evt.target.value)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <label>이미지 업로드</label>
                  <div>
                    <input accept="image/*" onChange={this.handleLoad} type="file" style={{ display: 'inline' }} />
                    <IconButton component="span" onClick={this.handleUpload}>
                      <CloudUploadIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
              {bottomContents}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  handleLoad = event => {
    this.setState({ file: event.target.files[0] });
  };
  handleUpload = async () => {
    if (this.state.file) {
      AWS.config.update({
        region: 'ap-northeast-2',
        accessKeyId: 'AKIAJOTDRH27VFET7VIA',
        secretAccessKey: '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',
      });

      const s3 = new AWS.S3();
      const params = {
        Bucket: 'supermembers.app',
        Key: 'frontend/cam/' + this.props.campaign.id + '/' + this.state.file.name,
        Body: this.state.file,
        ACL: 'public-read',
      };

      try {
        await s3.upload(params).promise();
        this.props.handleChange('bannerImg', this.state.file.name);
        alert('업로드 성공!');
      } catch (error) {
        alert('업로드 중 오류 발생:', error.message);
      }
    }
  };

  async componentDidMount() {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CampaignInfo);
