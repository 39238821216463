import React, { PureComponent } from 'react';
import momentLib from 'moment-timezone';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getAnswers } from '../api';

class InfoNotice extends PureComponent {
  render() {
    const { companyUser, notices } = this.props;

    const items = notices.map((notice, idx) => {
      return (
        <div
          key={idx}
          style={{ display: 'flex', justifyContent: 'space-between', padding: 12, borderBottom: '1px solid #dbdbdb' }}>
          <h4>{momentLib(notice.date).tz('Asia/Seoul').format('YY/MM/DD')}</h4>
          <div>
            <span style={notice.icon.indexOf('naver') > -1 ? { color: '#1bb200' } : { color: '#ef0b50' }}>
              {notice.icon}
            </span>
          </div>
          <div>
            <span>{notice.company}</span>
          </div>
          <div>
            <span className="red">{notice.content}</span>
          </div>
        </div>
      );
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name} 알림현황`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <h4 style={{ paddingBottom: 8, borderBottom: '1px solid #757575' }}>
              <span style={{ fontWeight: 400 }}>알림현황</span>
              {}
            </h4>
            {items}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {}
}

export default InfoNotice;
