import React from 'react';

import MenuBookIcon from '@material-ui/icons/MenuBook';
import Close from '@material-ui/icons/Close';
import { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect } from 'react';
import { getHandbooks } from '../api/handbook';

const HandBook = props => {
  const [openHandbook, setOpenHandbook] = useState(false);
  const [encyclopedia, setEncyclopedia] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getHandbooks({ category: props.category });
      setEncyclopedia(result.data.data.handbooks);
    };

    fetch();
  }, []);

  return (
    <>
      <div
        style={{
          width: 56,
          height: 56,
          borderRadius: 56,
          backgroundColor: '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          bottom: 48,
          right: 48,
          cursor: 'pointer',
          zIndex: 5,
        }}
        onClick={() => {
          setOpenHandbook(true);
        }}>
        <MenuBookIcon style={{ color: '#fff', width: 36, height: 36 }} />
      </div>
      {openHandbook && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}>
          <div
            style={{
              backgroundColor: '#fff',
              padding: 24,
              maxHeight: '80%',
              overflowY: 'scroll',
              width: '60%',
              borderRadius: 16,
            }}>
            <div style={{ textAlign: 'right' }}>
              <Close
                style={{ color: '#000', width: 36, height: 36 }}
                onClick={() => {
                  setOpenHandbook(false);
                }}
              />
            </div>
            <div>
              {encyclopedia.map(handbook => (
                <ExpansionPanel defaultExpanded={false}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>{handbook.title}</h3>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div style={{ width: '100%' }}>
                      <pre style={{ fontSize: 14, fontWeight: 500 }}>{handbook.content}</pre>
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HandBook;
