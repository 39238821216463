import * as api from '../api';

export function filterKeywordRequest(query) {
  return api
    .fetchData('get', '/keyword/matching/request', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function deleteKeywordRequest(id) {
  return api
    .fetchData('delete', '/keyword/matching/request', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
