import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableRow from '@material-ui/core/TableRow';

export default class SortableTableHead extends React.Component {
  handleRequestSort = property => event => {
    const orderBy = property;
    let order = 'desc';

    if (this.props.orderBy === property && this.props.order === 'desc') {
      order = 'asc';
    }
    this.props.handleSort(orderBy, order);
  };
  render() {
    const { order, orderBy } = this.props;
    let columns = this.props.columns.map(column => {
      if (column.component) {
        return (
          <TableCell
            key={column.id}
            style={{
              backgroundColor: '#fff',
              position: 'sticky',
              top: 0,
              zIndex: 1,
            }}>
            {column.component}
          </TableCell>
        );
      }
      return column.sortable ? (
        <TableCell
          key={column.id}
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}>
          <TableSortLabel
            active={orderBy === column.id}
            direction={order}
            onClick={this.handleRequestSort(column.id)}
            hideSortIcon={true}>
            {column.label}
          </TableSortLabel>
        </TableCell>
      ) : (
        <TableCell
          key={column.id}
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}>
          <TableSortLabel active={orderBy === column.id} direction={order} hideSortIcon={true}>
            {column.label}
          </TableSortLabel>
        </TableCell>
      );
    });
    return (
      <TableHead>
        <TableRow>{columns}</TableRow>
      </TableHead>
    );
  }
}
