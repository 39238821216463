import React, { Component, PureComponent } from 'react';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import { getQuestions } from '../api';

class QuestionItem extends PureComponent {
  render() {
    const { options, question, idx, handleChange, handleDelete } = this.props;
    const optionElements = options.map(item => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.question}
        </MenuItem>
      );
    });
    return (
      <div className="row" style={{ alignItems: 'center' }}>
        <Select value={question} onChange={evt => handleChange('question', idx, evt.target.value)} fullWidth>
          {optionElements}
        </Select>
        <IconButton onClick={() => handleDelete(idx)}>
          <Remove />
        </IconButton>
      </div>
    );
  }
}

class AdsQuestion extends Component {
  state = {
    newQuestion: 0,
    newPlaceholder: '',
    options: [],
  };
  render() {
    const { question, handlePropChange } = this.props;
    const { newQuestion, options } = this.state;
    const questions = question
      ? question.map((item, idx) => {
          return (
            <QuestionItem
              options={options}
              question={item}
              key={idx}
              idx={idx}
              handleChange={handlePropChange}
              handleDelete={this.handleDelete}
            />
          );
        })
      : '';
    const optionElements = options.map(item => {
      return (
        <MenuItem key={item.id} value={item.rKey}>
          {item.question}
        </MenuItem>
      );
    });
    return (
      <section>
        <label>한줄평 질문</label>
        {questions}
        <div className="row" style={{ alignItems: 'center' }}>
          <Select value={newQuestion} onChange={evt => this.setState({ newQuestion: evt.target.value })} fullWidth>
            {optionElements}
          </Select>
          <IconButton onClick={() => this.handleAdd()}>
            <Add />
          </IconButton>
        </div>
      </section>
    );
  }
  componentDidMount() {
    getQuestions().then(qs => {
      this.setState({ options: qs.questions });
    });
  }
  handleAdd = () => {
    const { question, handleChange } = this.props;
    const { newQuestion } = this.state;

    if (newQuestion === 0) {
      return;
    }
    const newQuestions = question ? [...question] : [];
    newQuestions.push(String(newQuestion));
    this.setState({ newQuestion: 0 });

    handleChange('question', newQuestions);
  };
  handleDelete = idx => {
    const { question, handleChange } = this.props;

    const newQuestion = [...question];
    newQuestion.splice(idx, 1);

    handleChange('question', newQuestion);
  };
}

export default AdsQuestion;
