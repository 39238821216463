import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import _ from 'lodash';

import * as api from '../api';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '../../../components/contract-cards/card';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import CreateIcon from '@material-ui/icons/Create';
import momentLib from 'moment-timezone';
import { Link } from 'react-router';

class EditorChargeHistory extends React.Component {
  state = {
    premium: {},
    premiums: [],
    filter: 0,
  };

  render() {
    let { premium, premiums } = this.state;
    // let cards = charges.map(charge => {
    //   return (
    //     <Card
    //       key={contract.id || contract.rKey}
    //       contract={contract}
    //       forWho={forWho}
    //       companies={companies}
    //     />
    //   );
    // });

    if ((premiums || []).length > 0 && Object.keys(premium).length > 0) {
      const elements =
        premiums && premiums.length
          ? premiums.map(item => {
              return (
                <TableRow key={item.id}>
                  <TableCell>{momentLib(item.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
                  <TableCell>{item.adId ? '사용' : '충전'}</TableCell>
                  <TableCell>{item.chargeId && item.number > 0 ? item.number : '-'}</TableCell>
                  <TableCell>{item.adId ? item.number : '-'}</TableCell>
                  <TableCell>{item.chargeId && item.number < 0 ? item.number : '-'}</TableCell>
                  <TableCell>{item.balance}</TableCell>
                  <TableCell>
                    <Link to={'/premium?id=' + (item.id || item.rKey)} target="_blank">
                      <CreateIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })
          : null;
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>{premium.company.name} 충전금액 내역</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <div>
                  <Tabs value={0} onChange={this.handleFilterChange}>
                    <Tab label={'전체(' + premiums.length + ')'} />
                    {/*<Tab label={"충전"} />*/}
                    {/*<Tab label={"환불"} />*/}
                  </Tabs>
                </div>
                <div className="count">
                  <div className="table-container">
                    <Table style={{ minWidth: 500 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>일자</TableCell>
                          <TableCell>유형</TableCell>
                          <TableCell>충전</TableCell>
                          <TableCell>소진</TableCell>
                          <TableCell>환불</TableCell>
                          <TableCell>잔액</TableCell>
                          <TableCell>상세</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{elements}</TableBody>
                    </Table>
                  </div>
                </div>
                {/*{cards}*/}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }

  async componentDidMount() {
    this.setState({ premium: this.props.premium });
  }

  async componentWillReceiveProps(newProps) {
    this.setState({ premium: newProps.premium });
    let result = await api.filterPremium({
      companyId: newProps.premium.companyId,
      orderBy: 'createdAt',
      direction: 'desc',
    });
    let premiums = result.packages;
    this.setState({ premiums: premiums });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorChargeHistory);
