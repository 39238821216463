import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import CompanyField from '../../../components/editor/company';

const Editor = ({ contract, handleChange }) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div>
            <CompanyField
              field={{ name: 'company', value: contract.company }}
              handleChange={value => handleChange('company', value)}
            />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>사용자명</label>
              <TextField
                fullWidth
                value={contract.username}
                onChange={evt => handleChange('username', evt.target.value)}
              />
            </div>
            {contract.type === 'insta' ? (
              <div style={{ flex: 1 }}>
                <label>인스타 아이디</label>
                <TextField
                  fullWidth
                  value={contract.userOuterId}
                  onChange={evt => handleChange('outerId', evt.target.value)}
                />
              </div>
            ) : (
              <div style={{ flex: 1 }}>
                <label>블로그 아이디</label>
                <TextField
                  fullWidth
                  value={contract.userEmail}
                  onChange={evt => handleChange('userEmail', evt.target.value)}
                />
              </div>
            )}
            <div style={{ flex: 1 }}>
              <label>등급</label>
              <Select fullWidth value={contract.level} onChange={evt => handleChange('level', evt.target.value)}>
                <MenuItem value={undefined}>미지정</MenuItem>
                <MenuItem value={'black'}>black</MenuItem>
                <MenuItem value={'red'}>red</MenuItem>
                <MenuItem value={'yellow'}>yellow</MenuItem>
              </Select>
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>uid</label>
              <TextField fullWidth value={contract.user} onChange={evt => handleChange('user', evt.target.value)} />
            </div>
            <div style={{ flex: 1 }}>
              <label>
                outerId{' '}
                <span
                  style={{
                    fontSize: '10px',
                    color: 'rgba(0, 0, 0, 0.87)',
                  }}>
                  (블로그 글 URL 유저 ID)
                </span>
              </label>
              <TextField
                fullWidth
                value={contract.outerId}
                onChange={evt => handleChange('outerId', evt.target.value)}
              />
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>유저 타입</label>
              <Select fullWidth value={contract.type} onChange={evt => handleChange('type', evt.target.value)}>
                <MenuItem value={undefined}>미지정</MenuItem>
                <MenuItem value={'insta'}>insta</MenuItem>
                <MenuItem value={'naver'}>naver</MenuItem>
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <label>유저 그룹</label>
              <Select fullWidth value={contract.group} onChange={evt => handleChange('group', evt.target.value)}>
                <MenuItem value={undefined}>미지정</MenuItem>
                <MenuItem value={'newbie'}>newbie</MenuItem>
                <MenuItem value={'active'}>active</MenuItem>
                <MenuItem value={'inactive'}>inactive</MenuItem>
              </Select>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>할인적용금액</label>
              <TextField
                fullWidth
                type="number"
                value={contract.discount}
                onChange={evt => handleChange('discount', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>총 이용금액</label>
              <TextField
                fullWidth
                type="number"
                value={contract.used}
                onChange={evt => handleChange('used', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>결제금액</label>
              <TextField
                fullWidth
                type="number"
                value={contract.payment}
                onChange={evt => handleChange('payment', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>원고료</label>
              <TextField
                fullWidth
                type="number"
                value={contract.point}
                onChange={evt => handleChange('point', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Editor;
