import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';

const header = [
  { sortable: true, id: 'id', label: 'ID' },
  { sortable: true, id: 'category', label: '대분류' },
  { sortable: true, id: 'name', label: '가맹점명' },
  { sortable: false, id: 'available', label: '진행 상품' },
  { sortable: false, id: 'contractTotal', label: '총 이용자 수' },
  { sortable: false, id: 'contractCount-current', label: '최근 차수 이용자 수' },
  { sortable: false, id: 'contractCount-state0', label: '리뷰 대기' },
  { sortable: false, id: 'contractCount-state1', label: '평가 대기' },
  { sortable: false, id: 'keywordCount', label: '검색어 수' },
  { sortable: false, id: 'edit', label: '정보' },
];
const styles = theme => ({
  naver: {
    borderColor: '#1bb200',
    color: '#1bb200',
  },
  insta: {
    borderColor: '#ef0b50',
    color: '#ef0b50',
  },
  appointed: {
    borderColor: '#e2a700',
    color: '#e2a700',
  },
  finished: {
    borderColor: '#BDBDBD',
    color: '#BDBDBD',
  },
  paused: {
    borderColor: '#212121',
    color: '#212121',
  },
  newads: {
    borderColor: '#0D47A1',
    color: '#0D47A1',
  },
  transparent: {
    backgroundColor: 'transparent',
    color: 'transparent',
  },
});

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  companies: [],
  schema: {},
  total: 0,
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
  }
  componentDidMount() {
    this.fetchDatas(null, null, null, null);
  }
  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };
  resetState = () => {
    let state = {};
    Object.keys(state).forEach(key => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };
  handleCompanyFilter = filter => {
    this.resetState();
    if (filter === 'ready') {
      this.fetchDatas(null, null, null, { verified: undefined });
    } else if (filter === 'start') {
      this.fetchDatas(null, null, null, { available: 0 });
    } else if (filter === 'pause') {
      this.fetchDatas(null, null, null, { available: 1 });
    } else if (filter === 'end') {
      this.fetchDatas(null, null, null, { available: 2 });
    } else {
      this.fetchDatas(null, null, null, null);
    }
  };
  handleChangePage(event) {
    this.fetchDatas(
      event.selected,
      this.state.orderBy,
      this.state.direction,
      _.omit(this.state, Object.keys(initialState))
    );
  }
  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      ...options,
    };

    api.filterCompanies(filter).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
  render() {
    const { rowsPerPage, orderBy, direction, companies, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    const { classes } = this.props;
    let bodys = companies.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell style={item.test ? { color: 'LightGray' } : item.is_verified === 99 ? { color: 'red' } : {}}>
            {item.id}
          </TableCell>
          <TableCell>
            {item.type === 'A' ? '고급' : '일반'}
            {(item.category || {}).firstName}
          </TableCell>
          <TableCell>{(item.name || '') + (item.is_verified === 99 ? '(' + item.step + '단계 등록중)' : '')}</TableCell>
          <TableCell>
            {item.naver ? (
              <Chip
                label={item.category.firstName}
                variant={
                  (item.naver.available || item.naver.available === undefined || item.naver.available === null) > 0
                    ? 'default'
                    : 'outlined'
                }
                className={
                  item.naver.available < 0
                    ? classes.appointed
                    : item.naver.available === 2
                      ? classes.finished
                      : item.naver.available === 1
                        ? classes.paused
                        : item.naver.available === undefined || item.naver.available === null
                          ? classes.newads
                          : classes.naver
                }
              />
            ) : (
              <Chip label="없음" className={classes.transparent} />
            )}
            &nbsp;
            {item.insta ? (
              <Chip
                label={item.category.firstName}
                variant={
                  item.insta.available > 0 || item.insta.available === undefined || item.insta.available === null
                    ? 'default'
                    : 'outlined'
                }
                className={
                  item.insta.available < 0
                    ? classes.appointed
                    : item.insta.available === 2
                      ? classes.finished
                      : item.insta.available === 1
                        ? classes.paused
                        : item.insta.available === undefined || item.insta.available === null
                          ? classes.newads
                          : classes.insta
                }
              />
            ) : (
              <Chip label="없음" className={classes.transparent} />
            )}
          </TableCell>
          <TableCell>{item.contractTotal}</TableCell>
          <TableCell>{item.contractCount.naver + '/' + item.contractCount.insta}</TableCell>
          <TableCell>{item.contractCount.state0}</TableCell>
          <TableCell>{item.contractCount.state1}</TableCell>
          <TableCell>{item.keywordCount}</TableCell>
          <TableCell>
            <Link to={'/company3?id=' + item.id} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} handleCompanyFilter={this.handleCompanyFilter} />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(List));
