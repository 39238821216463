import React, { PureComponent } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

class Editor extends PureComponent {
  state = {
    newMemo: '',
  };
  render() {
    const { proposition } = this.props;
    const { newMemo } = this.state;
    const memo = proposition.memo === null || proposition.memo === undefined ? [] : proposition.memo;
    const memoElements = memo.map((item, idx) => {
      return (
        <div className="row" key={idx}>
          <TextField value={item} onChange={evt => this.handleChange(evt.target.value, idx)} fullWidth />
          <IconButton onClick={() => this.handleDelete(idx)}>
            <Remove />
          </IconButton>
        </div>
      );
    });
    if (Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>메모</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                {memoElements}
                <div className="row">
                  <TextField value={newMemo} onChange={evt => this.setState({ newMemo: evt.target.value })} fullWidth />
                  <IconButton onClick={() => this.handleAdd()}>
                    <Add />
                  </IconButton>
                </div>
              </section>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button color="secondary" onClick={this.props.handleDelete}>
                  삭제
                </Button>
                <Button color="secondary" onClick={this.props.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  handleChange = (value, idx) => {
    const { proposition, handleChange } = this.props;
    const memo = proposition.memo === null || proposition.memo === undefined ? [] : [...proposition.memo];

    memo[idx] = value;

    handleChange('memo', memo);
  };
  handleDelete = idx => {
    const { proposition, handleChange } = this.props;
    const memo = proposition.memo === null || proposition.memo === undefined ? [] : [...proposition.memo];

    memo.splice(idx, 1);

    handleChange('memo', memo);
  };
  handleAdd = () => {
    const { proposition, handleChange } = this.props;
    const { newMemo } = this.state;

    const memo = proposition.memo === null || proposition.memo === undefined ? [] : [...proposition.memo];

    if (newMemo.length === 0) {
      return;
    }
    memo.push(newMemo);
    this.setState({ newMemo: '' });

    handleChange('memo', memo);
  };
}
export default Editor;
