import React from 'react';
import momentLib from 'moment-timezone';
// import { calculateRecruitmentPeriod } from '../../../utils';

const DateInfo = ({ startDate, deadlineRecruitment, deadlineAnnouncement, deadlineReview, type }) => {
  const formatDate = date => date.format('MM.DD');

  const calculateRecruitmentPeriod = (startDate, deadlineRecruitment, deadlineAnnouncement) => {
    // 모집 시작일: startDate로부터 시작
    const startRecruitment = momentLib(startDate);

    // 모집 종료일: 시작일에서 deadlineRecruitment - 1
    const endRecruitment = startRecruitment.add(deadlineRecruitment - 1, 'day');

    // 발표 시작일: 모집 종료일 다음날
    const startAnnouncement = endRecruitment.add(1, 'day');

    // 발표 종료일: 발표 시작일에서 deadlineAnnouncement - 1
    const endAnnouncement = startAnnouncement.add(deadlineAnnouncement - 1, 'day');

    return {
      startRecruitment,
      endRecruitment,
      startAnnouncement,
      endAnnouncement,
    };
  };

  const { startRecruitment, endRecruitment, startAnnouncement, endAnnouncement } = calculateRecruitmentPeriod(
    startDate,
    deadlineRecruitment,
    deadlineAnnouncement
  );

  const data = [
    {
      header: 'Deadline',
      body: `${deadlineReview} days from the selected date`,
    },
  ];

  if (type === 'STANDARD') {
    data.unshift({
      header: 'Announcement',
      body: `${formatDate(startAnnouncement)} ~ ${formatDate(endAnnouncement)}`,
    });

    data.unshift({
      header: 'Recruitment',
      body: `${formatDate(startRecruitment)} ~ ${formatDate(endRecruitment)}`,
    });
  }

  return (
    <div
      style={{
        margin: '20px 16px',
      }}>
      <ul
        style={{
          listStyle: 'none',
          paddingLeft: '0px',
        }}>
        {data.map(d => (
          <li>
            {d.header} : {d.body}
          </li>
        ))}
      </ul>
    </div>
    // <Container>
    //   <List defaultCount={3} data={data} />
    // </Container>
  );
};

export default DateInfo;
