import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import { Group } from '@vx/group';
import { Tree } from '@vx/hierarchy';
import { LinearGradient } from '@vx/gradient';
import { hierarchy } from 'd3-hierarchy';

import { LinkHorizontalStep } from '@vx/shape';

import * as api from '../api';
import ExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';

import _ from 'lodash';

class InfoCategory extends React.Component {
  render() {
    const {
      categories,
      margin = {
        top: 40,
        left: 0,
        right: 0,
        bottom: 40,
      },
    } = this.props;

    const width = 470;
    const height = 470;
    const sizeWidth = width - margin.left - margin.right;
    const sizeHeight = height - margin.top - margin.bottom;
    let origin = { x: 20, y: 0 };

    let data = { sub: categories, key: 'category' };
    function convert(dat) {
      if (dat.sub) {
        dat.children = Object.keys(dat.sub).map(key => {
          dat.sub[key].key = key;
          convert(dat.sub[key]);
          return dat.sub[key];
        });
      }
    }

    if (!_.isEmpty(categories)) {
      convert(data);
    }

    if (Object.keys(categories).length > 0) {
      let details = data.children.map(child => {
        return (
          <ExpansionPanelDetails>
            <svg width={width} height={height}>
              <LinearGradient id="lg" from="#fd9b93" to="#fe6e9e" />
              <rect width={width} height={height} rx={14} fill="#ffffff" />
              <Tree
                top={margin.top}
                left={margin.left}
                root={hierarchy(child, d => {
                  return d.isExpanded ? null : d.children;
                })}
                size={[sizeWidth, sizeHeight]}
                separation={(a, b) => (a.parent == b.parent ? 1 : 0.9) / a.depth}>
                {tree => {
                  return (
                    <Group top={origin.y} left={origin.x}>
                      {tree.links().map((link, i) => {
                        let LinkComponent = LinkHorizontalStep;

                        return (
                          <LinkComponent
                            data={link}
                            percent={0.5}
                            stroke="#374469"
                            strokeWidth="1"
                            fill="none"
                            key={i}
                          />
                        );
                      })}

                      {tree.descendants().map((node, key) => {
                        const width = 60;
                        const height = 20;

                        let top = node.x;
                        let left = node.y;

                        return (
                          <Group top={top} left={left} key={key}>
                            {node.depth === 0 && (
                              <circle
                                r={20}
                                fill="url('#lg')"
                                onClick={() => {
                                  node.data.isExpanded = !node.data.isExpanded;
                                  this.forceUpdate();
                                }}
                              />
                            )}
                            {node.depth !== 0 && (
                              <rect
                                height={height}
                                width={width}
                                y={-height / 2}
                                x={-width / 2}
                                fill={'#ffffff'}
                                stroke={node.data.children ? '#03c0dc' : '#260eb0'}
                                strokeWidth={1}
                                strokeDasharray={!node.data.children ? '2,2' : '0'}
                                strokeOpacity={!node.data.children ? 0.6 : 1}
                                rx={!node.data.children ? 10 : 0}
                                onClick={() => {
                                  node.data.isExpanded = !node.data.isExpanded;
                                  this.forceUpdate();
                                }}
                              />
                            )}
                            <text
                              dy={'.33em'}
                              fontSize={10}
                              fontFamily="Arial"
                              textAnchor={'middle'}
                              style={{ pointerEvents: 'none' }}
                              fill={node.depth === 0 ? '#71248e' : node.children ? 'black' : '#260eb0'}>
                              {node.data.key + ':' + node.data.naverCount + ':' + node.data.instaCount}
                            </text>
                          </Group>
                        );
                      })}
                    </Group>
                  );
                }}
              </Tree>
            </svg>
          </ExpansionPanelDetails>
        );
      });

      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>카테고리 정보</h3>
          </ExpansionPanelSummary>
          {details}
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const categories = await api.getOptions('type=category');
    this.setState({ categories });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoCategory);
