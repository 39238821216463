import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';

import registerServiceWorker from './registerServiceWorker';
import AppRoute from './app/router';
import reducer from './app/reducer';
import { startUnleash } from './unleash';

console.log(process.env.REACT_APP_API_URL);

const rMiddleware = routerMiddleware(browserHistory);

const middlewares = [thunk, rMiddleware];
// if (process.env.NODE_ENV === 'development') {
//     const {logger} = require('redux-logger')
//     middlewares.push(logger);
// }

function configureStore(initialState) {
  const enhancer = compose(applyMiddleware(...middlewares));
  return createStore(reducer, initialState, enhancer);
}

const store = configureStore({});

const history = syncHistoryWithStore(browserHistory, store);

// console = window.console || {};
// console.warn = function no_console() {}; // console warning 막기
// console.error = function () {}; // console error 막기

startUnleash()
  .then(() => {
    console.log(`Feature Toggle is Ready (env:${process.env.NODE_ENV})`);
  })
  .catch(error => {
    console.error('Error starting Unleash:', error);
  })
  .finally(() => {
    ReactDOM.render(
      <Provider store={store}>
        <AppRoute history={history} />
      </Provider>,
      document.getElementById('root')
    );
    registerServiceWorker();
  });
