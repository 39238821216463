import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Switch from '@material-ui/core/Switch/Switch';
import Select from '@material-ui/core/Select/Select';
import Button from '@material-ui/core/Button/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import _ from 'lodash';
import momentLib from 'moment-timezone';

import * as api from '../api';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';

class InfoPaycard extends React.Component {
  state = {
    paycards: [],
    newMemo: [],
  };

  render() {
    const { company, paycards, cards } = this.props;
    const { newMemo } = this.state;

    let items = <div />;
    if (!_.isEmpty(paycards)) {
      items = paycards.map((paycard, idx) => {
        paycard.paidAtTemp = paycard.paidAt
          ? momentLib(paycard.paidAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm')
          : ' ';

        if (!_.isEmpty(paycard)) {
          let memoElements = '';
          let memo = (paycard || {}).memo || [];
          memoElements = memo.map((item, memoidx) => {
            return (
              <div className="row" key={idx}>
                <TextField
                  value={item}
                  onChange={evt => {
                    item = evt.target.value;
                    paycards[idx].memo = item;
                    this.setState({ paycards: paycards });
                  }}
                  fullWidth
                />
                <IconButton onClick={() => this.handleMemoDel(idx, memoidx)}>
                  <Remove />
                </IconButton>
              </div>
            );
          });

          return (
            <div>
              <ExpansionPanel defaultExpanded style={{ marginBottom: 0 }}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <h2>
                    {paycard.channel + ' ' + company.name + ' ' + paycard.order}차{' '}
                    {paycard.payState === 0 ? '(예정)' : ''}
                  </h2>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div style={{ width: '100%' }}>
                    <section>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>광고 상품</label>
                          <TextField
                            fullWidth
                            value={paycard.type === 'N' ? '제품-실속형' : paycard.type === 'S' ? '제품-표준형' : '매장'}
                            disabled
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>결제플랜</label>
                          <TextField fullWidth value={(paycard.plan || 1) + '개월'} disabled />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>선결제수</label>
                          <TextField fullWidth value={paycard.prePayCnt || 0} disabled />
                        </div>
                      </div>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>결제 수단</label>
                          <Select
                            value={paycard.payMethod}
                            onChange={evt => this.handleChange(idx, 'payMethod', evt.target.value)}>
                            <MenuItem value={'card'}>card</MenuItem>
                            <MenuItem value={'cash'}>cash</MenuItem>
                            <MenuItem value={'free'}>free</MenuItem>
                          </Select>
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>결제 상태</label>
                          <Select
                            value={paycard.payState}
                            onChange={evt => this.handleChange(idx, 'payState', parseInt(evt.target.value))}>
                            <MenuItem value={0}>{'결제 예정'}</MenuItem>
                            <MenuItem value={1}>
                              {paycard.installmentAmount && paycard.installmentTotal < paycard.amount
                                ? '할부 중'
                                : '결제 완료'}
                            </MenuItem>
                            <MenuItem value={2}>{'결제 요청'}</MenuItem>
                            <MenuItem value={3}>{'결제 중지'}</MenuItem>
                            <MenuItem value={4}>{'중지 후 재시작'}</MenuItem>
                          </Select>
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>연장 여부</label>
                          <Switch
                            checked={
                              paycard.extended ||
                              momentLib(paycard.adsStart).add(30, 'day').isBefore(momentLib(paycard.nextPaidAt))
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>결제 금액</label>
                          <TextField
                            fullWidth
                            value={paycard.amount}
                            onChange={evt => this.handleChange(idx, 'amount', Number(evt.target.value))}
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>종료 사유</label>
                          <Select
                            fullWidth
                            value={paycard.endReason}
                            onChange={evt => this.handleChange(idx, 'endReason', Number(evt.target.value))}>
                            <MenuItem value={1}>1 폐업</MenuItem>
                            <MenuItem value={2}>2 기타</MenuItem>
                            <MenuItem value={3}>3 플랫폼 서비스 불만족</MenuItem>
                            <MenuItem value={4}>4 이용률 저조</MenuItem>
                            <MenuItem value={5}>5 나중에 다시함</MenuItem>
                            <MenuItem value={6}>6 연락 두절</MenuItem>
                            <MenuItem value={7}>7 비용 부담</MenuItem>
                            <MenuItem value={8}>8 블로거 콘텐츠 품질 불만족</MenuItem>
                            <MenuItem value={9}>9 타 서비스 이용</MenuItem>
                            <MenuItem value={10}>10 충분히 이용</MenuItem>
                            <MenuItem value={11}>11 블로거 태도 불만족</MenuItem>
                            <MenuItem value={12}>12 광고 성과 부족</MenuItem>
                          </Select>
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>이용자 수</label>
                          <TextField fullWidth value={paycard.contractCount} disabled />
                        </div>
                        {/*<div style={{flex: 1, minWidth: 0}}>*/}
                        {/*  <label>dis(nth:3>10,6>20,12>30,24>40)</label>*/}
                        {/*  <TextField*/}
                        {/*    fullWidth*/}
                        {/*    value={paycard.discount || 0}*/}
                        {/*    onChange={(evt) => this.handleChange(idx, 'discount', Number(evt.target.value))}*/}
                        {/*  />*/}
                        {/*</div>*/}
                        {/*<div style={{flex: 1, minWidth: 0}}>*/}
                        {/*  <label>dis(cnt:4>30,2>20)</label>*/}
                        {/*  <TextField*/}
                        {/*    fullWidth*/}
                        {/*    value={paycard.qtyDiscount || 0}*/}
                        {/*    disabled*/}
                        {/*  />*/}
                        {/*</div>*/}
                      </div>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>실결제액</label>
                          <TextField
                            fullWidth
                            value={
                              paycard.amount *
                              (((100 - (paycard.discount || 0)) * (100 - (paycard.qtyDiscount || 0))) / 10000)
                            }
                            disabled
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>최소인원</label>
                          <TextField
                            fullWidth
                            value={paycard.checkBloggerCnt}
                            onChange={evt => this.handleChange(idx, 'checkBloggerCnt', Number(evt.target.value))}
                          />
                        </div>
                        {company.type === 'A' || company.category.firstName === '제품' ? (
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <label>최대인원</label>
                            <TextField
                              fullWidth
                              value={paycard.number}
                              onChange={evt => this.handleChange(idx, 'number', Number(evt.target.value))}
                            />
                          </div>
                        ) : (
                          <div style={{ flex: 1, minWidth: 0 }} />
                        )}
                      </div>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>총할부납입액</label>
                          <TextField
                            fullWidth
                            value={paycard.installmentTotal}
                            onChange={evt => this.handleChange(idx, 'installmentTotal', Number(evt.target.value))}
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>할부 금액</label>
                          <TextField
                            fullWidth
                            value={paycard.installmentAmount}
                            onChange={evt => this.handleChange(idx, 'installmentAmount', Number(evt.target.value))}
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>할부 차수</label>
                          <TextField
                            fullWidth
                            value={paycard.installmentNumber}
                            onChange={evt => this.handleChange(idx, 'installmentNumber', Number(evt.target.value))}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>광고 시작일</label>
                          <TextField
                            fullWidth
                            value={paycard.adsStart}
                            onChange={evt => this.handleChange(idx, 'adsStart', evt.target.value)}
                          />
                          {/*<DateEditor {...childProps} />;*/}
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>광고 종료 예정일</label>
                          <TextField
                            fullWidth
                            value={momentLib(paycard.adsStart)
                              .tz('Asia/Seoul')
                              .add((paycard.plan || 1) * 30, 'day')
                              .format('YYYY-MM-DD')}
                            disabled
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>{paycard.payState === 4 ? '광고 재시작일' : '광고 종료일'}</label>
                          <TextField
                            fullWidth
                            value={paycard.nextPaidAt}
                            onChange={evt => this.handleChange(idx, 'nextPaidAt', evt.target.value)}
                          />
                        </div>
                      </div>

                      {company.type === 'A' ? (
                        <div className="row">
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <label>프리미엄 체험단 신청 마감</label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '0px',
                                marginTop: '-15px',
                              }}>
                              <div>
                                <label>마감기한(일)</label>
                                <TextField
                                  value={paycard.periodApply ? paycard.periodApply : null}
                                  onChange={evt => this.handleChange(idx, 'periodApply', Number(evt.target.value))}
                                />
                              </div>
                              <div>
                                <label>마감 예정일</label>
                                <TextField
                                  value={
                                    paycard.periodApply
                                      ? momentLib(paycard.adsStart)
                                          .tz('Asia/Seoul')
                                          .add(paycard.periodApply, 'day')
                                          .format('YYYY-MM-DD')
                                      : null
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <label>프리미엄 체험단 선정 마감</label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '0px',
                                marginTop: '-15px',
                              }}>
                              <div>
                                <label>마감기한(일)</label>
                                <TextField
                                  value={paycard.periodAccept ? paycard.periodAccept : null}
                                  onChange={evt => this.handleChange(idx, 'periodAccept', Number(evt.target.value))}
                                />
                              </div>
                              <div>
                                <label>마감 예정일</label>
                                <TextField
                                  value={
                                    paycard.periodApply && paycard.periodAccept
                                      ? momentLib(paycard.adsStart)
                                          .tz('Asia/Seoul')
                                          .add(paycard.periodApply + paycard.periodAccept, 'day')
                                          .format('YYYY-MM-DD')
                                      : null
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <label>프리미엄 체험단 리뷰등록 마감</label>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '0px',
                                marginTop: '-15px',
                              }}>
                              <div>
                                <label>마감기한(일)</label>
                                <TextField
                                  value={paycard.periodReview ? paycard.periodReview : null}
                                  onChange={evt => this.handleChange(idx, 'periodReview', Number(evt.target.value))}
                                />
                              </div>
                              <div>
                                <label>마감 예정일</label>
                                <TextField
                                  value={
                                    paycard.periodApply && paycard.periodAccept && paycard.periodReview
                                      ? momentLib(paycard.adsStart)
                                          .tz('Asia/Seoul')
                                          .add(paycard.periodApply + paycard.periodAccept + paycard.periodReview, 'day')
                                          .format('YYYY-MM-DD')
                                      : null
                                  }
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>결제 완료일</label>
                          <TextField
                            fullWidth
                            defaultValue={paycard.paidAtTemp}
                            onChange={evt => {
                              // this.setState({paidAtTemp: evt.target.value});
                              if (momentLib(evt.target.value, 'YYYY-MM-DD HH:mm').isValid()) {
                                this.handleChange(
                                  idx,
                                  'paidAt',
                                  momentLib(evt.target.value, 'YYYY-MM-DD HH:mm').toISOString()
                                );
                              } else if (evt.target.value.length === 0) {
                                this.handleChange(idx, 'paidAt', '');
                              }
                            }}
                          />
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>1차 알림</label>
                          {paycard.alarm1st !== undefined ? (
                            <Checkbox
                              checked={paycard.alarm1st}
                              onChange={evt => {
                                this.handleChange(idx, 'alarm1st', evt.target.checked);
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>2차 알림</label>
                          {paycard.alarm2nd !== undefined ? (
                            <Checkbox
                              checked={paycard.alarm2nd}
                              onChange={evt => {
                                this.handleChange(idx, 'alarm2nd', evt.target.checked);
                              }}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div style={{ flex: 1, minWidth: 0 }}>
                          <label>60일 제한 해제</label>
                          <Checkbox
                            checked={paycard.checkPeriod || false}
                            onChange={evt => {
                              this.handleChange(idx, 'checkPeriod', evt.target.checked);
                            }}
                          />
                        </div>
                        {company.type === 'A' ? (
                          <div style={{ flex: 1, minWidth: 0 }}>
                            <label>자동선정 진행여부</label>
                            <Checkbox checked={paycard.disableAutoSelection} disabled />
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div style={{ flex: 1, minWidth: 0 }}>
                          {memoElements}
                          <div className="row">
                            <TextField
                              value={newMemo[idx]}
                              onChange={evt => {
                                newMemo[idx] = evt.target.value;
                                this.setState({ newMemo: newMemo });
                              }}
                              fullWidth
                            />
                            <IconButton onClick={() => this.handleMemoAdd(idx)}>
                              <Add />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="row" style={{ justifyContent: 'flex-end' }}>
                      {paycard.payState === 2 ? (
                        <Button
                          color="secondary"
                          onClick={() => this.handlePay(idx, company[paycard.channel].payInfo_card)}>
                          신용카드결제
                        </Button>
                      ) : (
                        ''
                      )}
                      {paycard.payState === 2 ? (
                        <Button color="secondary" onClick={() => this.handlePay(idx)}>
                          패키지결제
                        </Button>
                      ) : (
                        ''
                      )}
                      {idx === 0 && paycard.payState === 0 ? (
                        <Button color="secondary" onClick={() => this.handleInfoUpdate(idx)}>
                          정보업데이트
                        </Button>
                      ) : (
                        ''
                      )}
                      {idx === 0 && paycard.payState === 0 ? (
                        <Button color="secondary" onClick={() => this.handleDel(idx)}>
                          삭제
                        </Button>
                      ) : (
                        ''
                      )}
                      {idx === 1 ? (
                        <Button color="secondary" onClick={() => this.handleTerminate(idx)}>
                          광고종료
                        </Button>
                      ) : (
                        ''
                      )}
                      <Button color="secondary" onClick={() => this.handleSave(idx)}>
                        저장
                      </Button>
                    </div>
                  </div>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </div>
          );
        } else {
          return <div />;
        }
      });
    }

    if (Object.keys(company).length > 0) {
      return items;
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.setState({ paycards: this.props.paycards });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ paycards: newProps.paycards });
  }

  handleSave = idx => {
    console.log('@@ save');
    if (this.state.paycards[idx]) {
      console.log('@@ save 1');

      delete this.state.paycards[idx].paidAtTemp;
      api
        .updateCompanyPayCard(this.props.company.id, this.state.paycards[idx].channel, this.state.paycards[idx])
        .then(() => {
          console.log('@@ save 2');

          if (idx > 0 && this.state.paycards[idx - 1]) {
            api
              .updateCompanyPayCard(
                this.props.company.id,
                this.state.paycards[idx - 1].channel,
                this.state.paycards[idx - 1]
              )
              .then(() => {
                window.setTimeout(() => {
                  console.log('@@ save3');

                  window.location.reload();
                }, 1000);
              });
          } else {
            window.setTimeout(() => {
              console.log('@@ save4');

              window.location.reload();
            }, 1000);
          }
        });
    }
  };

  handlePay = (idx, payInfo_card) => {
    if (this.state.paycards[idx]) {
      api
        .createCompanyPayCardPay(
          this.props.company.id,
          this.state.paycards[idx].channel,
          this.state.paycards[idx].id,
          payInfo_card
        )
        .then(() => {
          window.setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  handleDelCurrent = idx => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      if (this.state.paycards[idx]) {
        api.deleteCompanyPayCardCurrent(this.props.company.id, this.state.paycards[idx].channel).then(() => {
          window.setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      }
    }
  };

  handleInfoUpdate = idx => {
    if (this.state.paycards[idx]) {
      delete this.state.paycards[idx].paidAtTemp;
      this.state.paycards[idx].amount = this.props.company[this.state.paycards[idx].channel].payInfo_amount;
      this.state.paycards[idx].checkBlogger = this.props.company[this.state.paycards[idx].channel].payInfo_checkBlogger;
      this.state.paycards[idx].checkBloggerCnt =
        this.props.company[this.state.paycards[idx].channel].payInfo_checkBloggerCnt;
      this.state.paycards[idx].dayOfMonth = this.props.company[this.state.paycards[idx].channel].payInfo_dayOfMonth;
      this.state.paycards[idx].extened = this.props.company[this.state.paycards[idx].channel].payInfo_extended;
      this.state.paycards[idx].payMethod = this.props.company[this.state.paycards[idx].channel].payInfo_payMethod;
      this.state.paycards[idx].checkPeriod = this.props.company[this.state.paycards[idx].channel].payInfo_checkPeriod;
      this.state.paycards[idx].installmentAmount =
        this.props.company[this.state.paycards[idx].channel].payInfo_installmentAmount;
      this.state.paycards[idx].plan = this.props.company[this.state.paycards[idx].channel].payInfo_plan;
      this.state.paycards[idx].prePayCnt = this.props.company[this.state.paycards[idx].channel].payInfo_prePayCnt;
      this.state.paycards[idx].number = this.props.company[this.state.paycards[idx].channel].number;
      api
        .updateCompanyPayCard(this.props.company.id, this.state.paycards[idx].channel, this.state.paycards[idx])
        .then(() => {
          window.setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
    }
  };

  handleDel = idx => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      if (this.state.paycards[idx]) {
        api
          .deleteCompanyPayCard(this.props.company.id, this.state.paycards[idx].channel, this.state.paycards[idx].id)
          .then(() => {
            window.setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    }
  };

  handleChange = (idx, name, value) => {
    if (this.state.paycards[idx]) {
      this.state.paycards[idx][name] = value;
    }
    if (idx > 0 && this.state.paycards[idx - 1] && name === 'nextPaidAt') {
      this.state.paycards[idx - 1]['adsStart'] = value;
    }

    this.setState({ paycards: this.state.paycards });
  };

  handleTerminate = idx => {
    if (this.state.paycards[0] && this.state.paycards[1]) {
      if (this.state.paycards[idx - 1].payState == 0) {
        this.state.paycards[idx].nextPaidAt = this.state.paycards[idx - 1].adsStart = momentLib(new Date())
          .tz('Asia/Seoul')
          .format('YYYY-MM-DD');
        this.state.paycards[idx - 1].payState = 3;
        let company = this.props.company;
        company[this.state.paycards[idx].channel].available = 2;
        api
          .updateCompanyPayCard(this.props.company.id, this.state.paycards[idx].channel, this.state.paycards[idx])
          .then(() => {
            return api.updateCompanyPayCard(
              this.props.company.id,
              this.state.paycards[idx - 1].channel,
              this.state.paycards[idx - 1]
            );
          })
          .then(() => {
            return api.updateCompany(this.props.company.id, company);
          })
          .then(() => {
            window.setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
      }
    }
  };

  handleMemoAdd = idx => {
    const { paycards, newMemo } = this.state;

    const memo = (paycards[idx] || {}).memo || [];

    if (newMemo[idx].length === 0) {
      return;
    }
    memo.push(newMemo[idx]);
    paycards[idx].memo = memo;
    newMemo[idx] = '';
    this.setState({ newMemo: newMemo, paycards: paycards });
  };

  handleMemoDel = (idx, memoidx) => {
    const { paycards } = this.state;

    const memo = (paycards[idx] || {}).memo || [];
    memo.splice(memoidx, 1);
    paycards[idx].memo = memo;

    this.setState({ paycards: paycards });
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoPaycard);
