import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import * as api from '../api';
import InfoCategoryTree from './info-category-tree';
import InfoCategory from './info-category';
import LoadInfluencer from './load-influencer';
import UpdateUser from './update-user';
import LoadQNA from './load-qna';

class CompanyEtc extends React.Component {
  state = {
    categories: {},
    locals: {},
    snackOpen: false,
  };
  render() {
    const { categories, locals } = this.state;

    return (
      <div className="contents">
        <div className="editor-container contents-editor">
          <LoadInfluencer />
          <UpdateUser />
          <LoadQNA />
          <InfoCategoryTree categories={categories} handleChange={this.handleChange} />
        </div>
        <div className="additional-container contents-editor">
          <InfoCategory />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => this.setState({ snackOpen: false })}>
                <i className="material-icons">close</i>
              </IconButton>,
            ]}
          />
        </div>
      </div>
    );
  }

  async componentDidMount() {
    this.props.activateLoading();
    const categories = await api.getOptions('type=category');
    this.setState({ categories });
    const locals = await api.getOptions('type=local');
    this.setState({ locals });
    this.props.deactivateLoading();
  }

  handleChange = (name, value) => {
    console.log(name, value);
  };
  handleSave = () => {};
  handleCancel = () => {};
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CompanyEtc);
