import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../app/action';
import { findDOMNode } from 'react-dom';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import { withStyles } from '@material-ui/core/styles';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import VirtualizedSelect from 'react-virtualized-select';
import momentLib from 'moment-timezone';
import { Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import _ from 'lodash';

import Photo from '@material-ui/icons//Photo';
import Pageview from '@material-ui/icons//Pageview';
import OpenInNew from '@material-ui/icons//OpenInNew';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '30px',
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  category: {
    position: 'relative',
  },
  button: {
    width: 40,
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconButton: {
    width: 32,
    height: 32,
  },
  '@global': {
    '.Select': {
      width: '30%',
    },
    '.Select-control': {
      alignItems: 'center',
      height: 'auto',
      background: 'transparent',
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: 'absolute',
      left: 0,
    },
  },
});

const today = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');

class Card extends React.Component {
  state = {
    single: null,
    multi: null,
    campaignKeyword: {},
    open: false,
    anchorEl: null,
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'center',
    positionTop: 0, // Just so the popover can be spotted more easily
    positionLeft: 0, // Same as above
    anchorReference: 'anchorEl',
  };

  componentDidMount() {
    this.setState({
      campaignKeyword: this.props.campaignKeyword,
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      campaignKeyword: newProps.campaignKeyword,
    });
  }

  render() {
    const { classes, index, isLast, handlePlus, handleMinus, handleSave } = this.props;

    const {
      open,
      anchorEl,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
      campaignKeyword,
    } = this.state;

    const buttons = isLast ? (
      <IconButton className={classes.iconButton} onClick={handlePlus}>
        <Add />
      </IconButton>
    ) : (
      <IconButton className={classes.iconButton} onClick={() => handleMinus(index)}>
        <Remove />
      </IconButton>
    );

    return (
      <div className={classes.root}>
        <TextField
          halfWidth
          value={campaignKeyword.name}
          onChange={evt => {
            campaignKeyword.name = evt.target.value;
          }}
        />
        <span className={classes.category}>
          {(((campaignKeyword.ranks || {})[this.props.date || today] || {})[0]
            ? campaignKeyword.ranks[this.props.date || today][0].rank
            : '무') + '순위'}
        </span>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            if (campaignKeyword.name) {
              handleSave(campaignKeyword, true);
            } else {
              alert('키워드를 선택해 주세요.');
            }
          }}>
          저장
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            if (campaignKeyword.name) {
              handleSave(campaignKeyword, false);
            } else {
              alert('키워드를 선택해 주세요.');
            }
          }}>
          재검색
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          ref={node => (this.button = node)}
          onClick={() => {
            if ((campaignKeyword.ranks || {})[this.props.date || today]) {
              this.setState({
                open: true,
                anchorEl: findDOMNode(this.button),
              });
            } else {
              alert('내역이 없습니다.');
            }
          }}>
          내역
        </Button>
        {buttons}
        {open ? (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorReference={anchorReference}
            anchorPosition={{ top: positionTop, left: positionLeft }}
            onClose={() => {
              this.setState({
                open: false,
              });
            }}
            anchorOrigin={{
              vertical: anchorOriginVertical,
              horizontal: anchorOriginHorizontal,
            }}
            transformOrigin={{
              vertical: transformOriginVertical,
              horizontal: transformOriginHorizontal,
            }}>
            <List component="nav">
              {(campaignKeyword.ranks || {})[this.props.date || today]
                ? campaignKeyword.ranks[this.props.date || today].map((rank, i) => {
                    return (
                      <ListItem key={i} button>
                        <ListItemText secondary={rank.username} />
                        <ListItemText secondary={'순위: ' + rank.rank} />
                        <ListItemIcon
                          onClick={() => {
                            window.open(`/proposition?id=${rank.propositionId}`);
                          }}>
                          <Pageview />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })
                : ''}
            </List>
          </Popover>
        ) : null}
      </div>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Card));
