import React, { Component } from 'react';

import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { Popover } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { findDOMNode } from 'react-dom';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  formContainer: {
    display: 'flex',
    minWidth: 800,
    marginBottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  selectMenu: {
    height: 33,
  },
});

class SearchInput extends Component {
  state = {
    findText: '',
    selectItem: 'search',
    companyFilter: 'all',
    userPointFilter: '',
    propositionFilter: '',
    sel: [],
    startDate: '',
    endDate: '',
    account: '',
    selectedTemplate: '',
  };
  handleChangeFind = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChangeText = event => {
    this.setState({ findText: event.target.value });
  };
  handleSearch = () => {
    const { selectItem, findText } = this.state;
    this.props.handleSearch(selectItem, findText);
  };

  handleMessageFilter = event => {
    this.setState({ selectedTemplate: event.target.value });
    this.props.handleMessageFilter(event.target.value);
  };

  render() {
    const { classes, header } = this.props;
    const { selectItem, findText, companyFilter, userPointFilter, propositionFilter, open, anchorEl } = this.state;

    let finds = header.map((item, index) => {
      return item.id === 'edit' ? (
        ''
      ) : (
        <MenuItem key={index} value={item.id}>
          {item.label || item.id}
        </MenuItem>
      );
    });
    let toggles = header.map((item, index) => {
      if (item.filter) {
        return (
          <div>
            <div>{item.label}</div>
            <ToggleButtonGroup
              size="small"
              value={this.state.sel[index]}
              onChange={(event, value) => {
                if (this.props.handlePropositionFilter) {
                  this.state.sel = [];
                  this.state.sel[index] = [value[value.length - 1]];
                } else {
                  if (value[value.length - 1] === '전체') {
                    this.state.sel[index] = ['전체'];
                  } else {
                    this.state.sel[index] = _.without(value, '전체');
                  }
                }
                this.setState({ sel: this.state.sel });
              }}>
              {item.filter.map((subitem, idx) => {
                return <ToggleButton value={subitem}>{subitem}</ToggleButton>;
              })}
            </ToggleButtonGroup>
          </div>
        );
      }
      return '';
    });
    return (
      <div className={classes.formContainer}>
        <FormControl className={classes.formControl}>
          <Select
            classes={{ selectMenu: classes.selectMenu }}
            value={selectItem}
            onChange={this.handleChangeFind}
            input={<Input name="selectItem" id="selectItem" />}>
            <MenuItem value="search">전체검색</MenuItem>
            {finds}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Input
            placeholder={'Text to find'}
            value={findText}
            onKeyDown={evt => {
              if (evt.key === 'Enter') {
                evt.preventDefault();
                this.handleSearch();
              }
            }}
            onChange={this.handleChangeText}
          />
        </FormControl>
        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSearch}>
          검색
        </Button>

        {this.props.customComponents &&
          this.props.customComponents.map(item => {
            return item;
          })}
        {this.props.handleFilter ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            ref={node => (this.button = node)}
            onClick={() => {
              this.setState({
                open: true,
                anchorEl: findDOMNode(this.button),
              });
            }}>
            필터선택
          </Button>
        ) : (
          ''
        )}
        {this.props.handleFilter ? (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorPosition={{ top: 0, left: 0 }}
            onClose={() => {
              this.setState({
                open: false,
              });
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Card className={classes.root}>
              <CardContent>{toggles}</CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      open: false,
                    });
                  }}>
                  취소
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.props.handleFilter(this.state.sel);
                    this.setState({
                      open: false,
                    });
                  }}>
                  확인
                </Button>
              </CardActions>
            </Card>
          </Popover>
        ) : (
          ''
        )}
        {this.props.handleReview ? (
          <Button variant="contained" color="primary" className={classes.button} onClick={this.props.handleReview}>
            재심사 대상
          </Button>
        ) : (
          ''
        )}
        {this.props.handleUpdateVistor ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={this.props.handleUpdateVistor}>
            방문자 업데이트
          </Button>
        ) : (
          ''
        )}
        {this.props.handlePrivate ? (
          <Button variant="contained" color="primary" className={classes.button} onClick={this.props.handlePrivate}>
            비공개
          </Button>
        ) : (
          ''
        )}
        {this.props.handleExceed ? (
          <Button variant="contained" color="primary" className={classes.button} onClick={this.props.handleExceed}>
            미등록
          </Button>
        ) : (
          ''
        )}
        {this.props.handleCompanyFilter ? (
          <ToggleButtonGroup
            value={companyFilter}
            exclusive
            size="small"
            onChange={(event, value) => {
              this.props.handleCompanyFilter(value);
              this.setState({ companyFilter: value });
            }}>
            <ToggleButton value="all">전체</ToggleButton>
            <ToggleButton value="ready">작성검토중</ToggleButton>
            <ToggleButton value="start">게시중</ToggleButton>
            <ToggleButton value="pause">일시중지</ToggleButton>
            <ToggleButton value="end">광고종료</ToggleButton>
          </ToggleButtonGroup>
        ) : (
          ''
        )}
        {this.props.handleDataInRange ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              padding: '0 12px 0 12px',
              justifyContent: 'space-between',
            }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={this.state.startDate}
                  onChange={event => {
                    const startDate = event.target.value;
                    this.setState({ startDate });
                    this.props.handleDateOnChange && this.props.handleDateOnChange(startDate, this.state.endDate);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
              <span>-</span>
              <FormControl className={classes.formControl}>
                <TextField
                  id="date"
                  type="date"
                  defaultValue={this.state.endDate}
                  onChange={event => {
                    const endDate = event.target.value;
                    this.setState({ endDate });
                    this.props.handleDateOnChange && this.props.handleDateOnChange(this.state.startDate, endDate);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </span>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.props.handleDataInRange(this.state.startDate, this.state.endDate)}>
              {this.props.handleDataInRangeLabel || ''}
            </Button>
          </div>
        ) : (
          ''
        )}
        {this.props.handleUserPointFilter ? (
          <ToggleButtonGroup
            value={userPointFilter}
            exclusive
            size="small"
            onChange={(event, value) => {
              this.props.handleUserPointFilter(value);
              this.setState({ userPointFilter: value });
            }}>
            <ToggleButton value="">전체</ToggleButton>
            <ToggleButton value="0">캠페인진행중</ToggleButton>
            <ToggleButton value="1">적립대기</ToggleButton>
            <ToggleButton value="2">적립보류</ToggleButton>
            <ToggleButton value="5">취소</ToggleButton>
            <ToggleButton value="10">적립완료</ToggleButton>
            <ToggleButton value="101">인출대기</ToggleButton>
            <ToggleButton value="110">인출완료</ToggleButton>
          </ToggleButtonGroup>
        ) : (
          ''
        )}
        {this.props.handlePropositionFilter ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            ref={node => (this.button = node)}
            onClick={() => {
              this.setState({
                open: true,
                anchorEl: findDOMNode(this.button),
              });
            }}>
            필터선택
          </Button>
        ) : (
          ''
        )}
        {this.props.handlePropositionFilter ? (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorPosition={{ top: 0, left: 0 }}
            onClose={() => {
              this.setState({
                open: false,
              });
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Card className={classes.root}>
              <CardContent>{toggles}</CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      open: false,
                    });
                  }}>
                  취소
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.props.handlePropositionFilter(this.state.sel);
                    this.setState({
                      open: false,
                    });
                  }}>
                  확인
                </Button>
              </CardActions>
            </Card>
          </Popover>
        ) : (
          ''
        )}
        {this.props.handleCreateAccount ? (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            ref={node => (this.button = node)}
            onClick={() => {
              this.setState({
                open: true,
                anchorEl: findDOMNode(this.button),
              });
            }}>
            계정생성
          </Button>
        ) : (
          ''
        )}
        {this.props.handleCreateAccount ? (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorPosition={{ top: 0, left: 0 }}
            onClose={() => {
              this.setState({
                open: false,
              });
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}>
            <Card className={classes.root}>
              <CardContent>
                이메일
                <TextField fullWidth onChange={evt => this.setState({ account: evt.target.value })} />
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  onClick={() => {
                    this.setState({
                      open: false,
                    });
                  }}>
                  취소
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => {
                    this.props.handleCreateAccount(this.state.account);
                    this.setState({
                      open: false,
                    });
                  }}>
                  확인
                </Button>
              </CardActions>
            </Card>
          </Popover>
        ) : (
          ''
        )}
        {this.props.handleMessageFilter ? (
          <span>
            <span>템플릿 필터</span>
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              value={this.state.selectedTemplate}
              onChange={this.handleMessageFilter}>
              {this.props.templates.map((item, index) => {
                return (
                  <MenuItem key={index} value={item.templateId}>
                    {item.name || item.content}
                  </MenuItem>
                );
              })}
            </Select>
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withStyles(styles)(SearchInput);
