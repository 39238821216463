import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { getCompany } from '../../api/companies';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CustomTextField from '../../../../components/CustomTextField';
import { getAdsAll } from '../../api/ads';
import CompanyCard from '../../components/CompanyCard';

const Company = props => {
  const [company, setCompany] = useState(null);
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const [companyResult, adsResult] = await Promise.all([
        getCompany(props.location.query.id),
        getAdsAll({
          companyId: props.location.query.id,
        }),
      ]);

      setCompany(companyResult.data.data.company);
      setAds(adsResult.data.data.ads);
    };

    fetch();
  }, []);

  if (!company) {
    return null;
  }

  const { openAds, closeAds } = ads.reduce(
    (acc, cur) => {
      if (cur.ad.status === 'OPEN') {
        acc.openAds.push(cur);
      } else {
        acc.closeAds.push(cur);
      }
      return acc;
    },
    { openAds: [], closeAds: [] }
  );

  console.log(openAds);
  console.log(closeAds);

  return (
    <div>
      <div className="container">
        <div className="contents">
          <div className="editor-container">
            <div className="editor">
              <div style={{ width: '100%' }}>
                <h2>업체 정보</h2>
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField value={company.companyName} label="companyName" layoutStyle={{ flex: 1 }} />
                  <CustomTextField value={company.contact} label="contact" layoutStyle={{ flex: 1 }} />
                  <CustomTextField value={company.email} label="email" layoutStyle={{ flex: 1 }} />
                </div>
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    value={company.registrationNumber}
                    label="registrationNumber"
                    layoutStyle={{ flex: 1 }}
                  />
                  <CustomTextField value={company.lastLoggedIn} label="lastLoggedIn" layoutStyle={{ flex: 1 }} />
                  <CustomTextField value={company.updatedAt} label="updatedAt" layoutStyle={{ flex: 1 }} />
                </div>
              </div>
            </div>

            <ExpansionPanel expanded={true}>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <h2 style={{ marginBottom: 16 }}>매니저 정보</h2>
                  <div>
                    {company.managers.map(m => (
                      <div
                        style={{
                          padding: '10px',
                          borderRadius: '4px',
                          border: '1px solid #ddd',
                          marginBottom: '10px',
                        }}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                          }}>
                          <span
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                            }}>
                            {m.name}
                          </span>
                          <span
                            style={{
                              fontSize: '14px',
                              color: '#888',
                            }}>
                            {m.email}
                          </span>
                        </div>
                        <p
                          style={{
                            fontSize: '14px',
                            color: '#555',
                            fontWeight: '500',
                          }}>
                          {m.contact}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
          <div className="additional-container">
            <ExpansionPanel expanded={true}>
              <ExpansionPanelDetails>
                <div style={{ width: '100%' }}>
                  <h2 style={{ marginBottom: 16 }}>등록한 광고</h2>
                  <div>
                    <h3>OPEN</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                      {openAds.map(adsWithDetails => (
                        <CompanyCard
                          adsWithDetails={adsWithDetails}
                          onClickHandler={() => {
                            window.open(`/cosduck/ad?id=${adsWithDetails.ad.id}`, '_blank');
                          }}
                        />
                      ))}
                    </div>
                  </div>
                  <div>
                    <h3>CLOSE</h3>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
                      {closeAds.map(adsWithDetails => (
                        <CompanyCard
                          adsWithDetails={adsWithDetails}
                          onClickHandler={() => {
                            window.open(`/cosduck/ad?id=${adsWithDetails.ad.id}`, '_blank');
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Company);
