import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormatColorFill from '@material-ui/icons/FormatColorFill';
import ColorSelector from './color-selector';

class ButtonForm extends PureComponent {
  static propTypes = {
    initialStyle: PropTypes.object,
    initialType: PropTypes.string,
    initialName: PropTypes.string,
    initialParams: PropTypes.object,
  };
  static defaultProps = {
    initialStyle: {
      backgroundColor: '#525252',
      height: 48,
    },
  };
  state = {
    backgroundColor: '#525252',
    height: 48,
    ...this.props.initialStyle,
    routeType: this.props.initialType,
    routeName: this.props.initialName,
    routeParams: this.props.initialParams ? this.props.initialParams : {},
  };
  handleChange = (name, value) => {
    this.setState({ [name]: value });
    const state = { ...this.state, [name]: value };
    const data = {
      buttonStyle: { backgroundColor: state.backgroundColor, height: state.height },
      routeType: state.routeType,
    };
    if (state.routeType === 'app') {
      data.routeName = state.routeName;
      data.routeParams = state.routeParams;
    } else {
      data.routeURL = state.routeName;
    }
    this.props.handleChange(data);
  };
  render() {
    const { height, backgroundColor, routeType, routeName, routeParams } = this.state;
    return (
      <div>
        <section>
          <label>버튼 컬러</label>
          <ColorSelector
            label={<FormatColorFill />}
            initialColor={backgroundColor}
            handleChange={colorCode => this.handleChange('backgroundColor', colorCode)}
          />
          <label>버튼 높이</label>
          <TextField
            type="number"
            value={height}
            onChange={evt => this.handleChange('height', evt.target.value)}
            margin="normal"
          />
        </section>
        <section>
          <label>버튼 타입</label>
          <Select value={routeType ? routeType : ''} onChange={evt => this.handleChange('routeType', evt.target.value)}>
            <MenuItem value={''} disabled>
              버튼 타입
            </MenuItem>
            <MenuItem value={'app'}>앱 내부 이동</MenuItem>
            <MenuItem value={'web'}>앱 외부 링크</MenuItem>
          </Select>
          {routeType === 'web' ? (
            <div>
              <label>링크</label>
              <TextField
                fullWidth={true}
                value={routeName}
                onChange={evt => this.handleChange('routeName', evt.target.value)}
                margin="normal"
              />
            </div>
          ) : (
            <div>
              <label>가맹점 번호</label>
              <TextField
                style={{ width: 60 }}
                fullWidth={true}
                value={routeParams.companyNumber}
                onChange={evt => this.handleChange('routeParams', { companyNumber: evt.target.value })}
                margin="normal"
              />
            </div>
          )}
        </section>
      </div>
    );
  }
}

export default ButtonForm;
