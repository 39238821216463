import React, { useMemo } from 'react';

const Tag = ({ label, mode, color }) => {
  const [fontColor, backgroundColor, borderColor] = useMemo(() => {
    if (mode === 'FILL') {
      return ['#FFFFFF', color, color];
    } else {
      return [color, '#FFFFFF', color];
    }
  }, [mode, color]);

  return (
    <div
      style={{
        padding: '6px 8px',
        borderWidth: '1px',
        borderStyle: 'solid',
        display: 'inline-flex',
        alignItems: 'center',
        borderRadius: '8px',
        backgroundColor: backgroundColor,
        borderColor: borderColor,
      }}>
      <span style={{ color: fontColor, fontWeight: 500, fontSize: '12px' }}>{label}</span>
    </div>
  );
};

export default Tag;
