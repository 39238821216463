import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { UserCard } from '../../components';
import * as api from './api';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import Checkbox from '@material-ui/core/Checkbox';

class Account extends React.Component {
  state = {
    uid: this.props.location.query.uid,
    account: {},
    snackOpen: false,
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getAccount(this.state.uid).then(result => {
      let account = result.accounts[0];
      this.setState({ account: account });
    });
    this.props.deactivateLoading();
  }
  handleCancel = () => {
    this.props.push('/accounts');
  };

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({ account: { ...this.state.account, [name]: value } });
  };
  handleSave = json => {
    this.props.activateLoading();

    const { uid, account } = this.state;

    api
      .updateAccount(uid, account)
      .then(() => {
        this.setState({ snackOpen: true });
        this.props.deactivateLoading();
      })
      .catch(err => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        }
      });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const { uid, account } = this.state;
      api
        .deleteAccount(uid)
        .then(() => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
          this.props.push('/accounts');
        })
        .catch(err => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
  render() {
    const { account } = this.state;
    return (
      <div className="contents">
        <div className="editor-container contents-editor">
          <ExpansionPanel defaultExpanded={true}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>{`${account.email}님의 계정`}</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>이메일</label>
                  <TextField
                    fullWidth
                    value={account.email}
                    onChange={evt => this.handleChange('email', evt.target.value)}
                  />
                  <label>이메일검증여부</label>
                  <div className="row">
                    {account.emailVerified !== undefined ? (
                      <Checkbox
                        checked={account.emailVerified}
                        onChange={evt => {
                          this.handleChange('emailVerified', evt.target.value);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <label>이름</label>
                  <TextField
                    fullWidth
                    value={account.displayName}
                    onChange={evt => this.handleChange('displayName', evt.target.value)}
                  />
                  <label>사진</label>
                  <TextField
                    fullWidth
                    value={account.photoURL}
                    onChange={evt => this.handleChange('photoURL', evt.target.value)}
                  />
                  <label>연락처</label>
                  <TextField
                    fullWidth
                    value={account.phoneNumber}
                    onChange={evt => this.handleChange('phoneNumber', evt.target.value)}
                  />
                  <label>차단</label>
                  <div className="row">
                    {account.disabled !== undefined ? (
                      <Checkbox
                        checked={account.disabled}
                        onChange={evt => {
                          this.handleChange('disabled', evt.target.value);
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <label>비밀번호</label>
                  <TextField
                    fullWidth
                    value={account.password}
                    onChange={evt => this.handleChange('password', evt.target.value)}
                  />
                </section>
                <div className="row" style={{ justifyContent: 'flex-end' }}>
                  <Button color="secondary" onClick={this.handleDelete}>
                    삭제
                  </Button>
                  <Button color="secondary" onClick={this.handleSave}>
                    저장
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
        {/*<div className="additional-container">*/}
        {/*    <UserCard uid={account.uid}/>*/}
        {/*</div>*/}
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.snackOpen}
          autoHideDuration={4000}
          onClose={() => this.setState({ snackOpen: false })}
          message={<span>업데이트 성공</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.setState({ snackOpen: false })}>
              <Close />
            </IconButton>,
          ]}
        />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Account);
