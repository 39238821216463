import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import DateField from '../../../components/editor/date';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import momentLib from 'moment-timezone';

const Editor = ({ contract, handleChange, handleLoad, handleUpload }) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>대분류</label>
              <TextField fullWidth disabled value={contract.first} />
            </div>
            <div style={{ flex: 1 }}>
              <label>중분류</label>
              <TextField fullWidth disabled value={contract.second} />
            </div>
            <div style={{ flex: 1 }}>
              <label>소분류</label>
              <TextField fullWidth disabled value={contract.third} />
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>이용상태</label>
              <Select
                fullWidth
                value={contract.currentState}
                onChange={evt => handleChange('currentState', Number(evt.target.value))}>
                <MenuItem value={undefined}>미지정</MenuItem>
                <MenuItem value={0}>이용완료</MenuItem>
                <MenuItem value={1}>리뷰완료</MenuItem>
                <MenuItem value={2}>평가완료</MenuItem>
                <MenuItem value={-1}>이용취소</MenuItem>
                <MenuItem value={-2}>신청완료</MenuItem>
                <MenuItem value={-3}>선정완료</MenuItem>
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <label>작성기한</label>
              <TextField
                fullWidth
                type="number"
                value={contract.deadline ? contract.deadline : ''}
                onChange={evt => handleChange('deadline', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>유저 적립 포인트</label>
              <TextField fullWidth value={contract.settlementAmount || 0} />
            </div>
            <div style={{ flex: 1 }}>
              <label>포인트 상태</label>
              <TextField
                fullWidth
                value={
                  {
                    0: '캠페인진행중',
                    1: '적립대기',
                    2: '적립보류',
                    5: '취소',
                    10: '적립완료',
                    101: '인출대기',
                    110: '인출완료',
                  }[contract.pointStatus]
                }
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>유저 수수료율 (%)</label>
              <TextField fullWidth value={contract.fees} />
            </div>
          </div>
          <DateField
            field={{ name: 'appliedAt', value: contract.appliedAt, format: 'date-time' }}
            handleChange={value => handleChange('appliedAt', value)}
            description="프리미엄 체험단 신청일"
          />
          <DateField
            field={{ name: 'selectedAt', value: contract.selectedAt, format: 'date-time' }}
            handleChange={value => handleChange('selectedAt', value)}
            description="프리미엄 체험단 선정일 (프리미엄 체험단 작성기한 조정 시 참고)"
          />
          <DateField
            field={{ name: 'date0', value: contract.date0, format: 'date-time' }}
            handleChange={value => handleChange('date0', value)}
          />
          <DateField
            field={{ name: 'date1', value: contract.date1, format: 'date-time' }}
            handleChange={value => handleChange('date1', value)}
          />
          <DateField
            field={{ name: 'date2', value: contract.date2, format: 'date-time' }}
            handleChange={value => handleChange('date2', value)}
          />
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>포스트링크</label>
              <TextField fullWidth value={contract.link} onChange={evt => handleChange('link', evt.target.value)} />
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>구매인증링크</label>
              <TextField
                fullWidth
                value={contract.purchaseThumbnail}
                onChange={evt => handleChange('purchaseThumbnail', evt.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>구매인증링크 재업로드</label>
              <div>
                <input
                  accept="image/*"
                  onChange={event => handleLoad(event, 'purchaseThumbnail')}
                  type="file"
                  style={{ display: 'inline' }}
                />
                <IconButton component="span" onClick={() => handleUpload('purchaseThumbnail')}>
                  <CloudUploadIcon />
                </IconButton>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>영수증 리뷰 링크</label>
              <TextField
                fullWidth
                value={contract.receiptReview}
                onChange={evt => handleChange('receiptReview', evt.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>영수증 리뷰링크 재업로드</label>
              <div>
                <input
                  accept="image/*"
                  onChange={event => handleLoad(event, 'receiptReview')}
                  type="file"
                  style={{ display: 'inline' }}
                />
                <IconButton component="span" onClick={() => handleUpload('receiptReview')}>
                  <CloudUploadIcon />
                </IconButton>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>영수증 리뷰</label>
              <Switch
                checked={contract.receiptOnly}
                onChange={() => handleChange('receiptOnly', !contract.receiptOnly)}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>한줄평ID</label>
              <TextField fullWidth value={contract.review} onChange={evt => handleChange('review', evt.target.value)} />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>제품 배송 방식</label>
              <Select
                fullWidth
                value={contract.deliveryMethod ? contract.deliveryMethod : ''}
                onChange={evt => handleChange('deliveryMethod', evt.target.value)}>
                <MenuItem value="C">자사몰에서 쿠폰/적립금 지급</MenuItem>
                <MenuItem value="A">회원 주소로 직접 배송</MenuItem>
                <MenuItem value="PR">구매지원금</MenuItem>
              </Select>
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div className="row">
            <div style={{ flex: 2 }}>
              <label>가맹점 평가</label>
              <TextField
                fullWidth
                type="number"
                value={contract.mark}
                onChange={evt => handleChange('mark', Number(evt.target.value))}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
          {/* row */}
          <div>
            <label>연장</label>
            <Switch checked={contract.extended} onChange={() => handleChange('extended', !contract.extended)} />
          </div>
          <div>
            <label>피드백</label>
            <Switch checked={contract.feedback} onChange={() => handleChange('feedback', !contract.feedback)} />
          </div>
          <div>
            <label>푸시</label>
            <Switch checked={contract.push} onChange={() => handleChange('push', !contract.push)} />
          </div>
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>포인트 지급 여부</label>
              <Checkbox checked={!!contract.rewardStatus} disabled />
            </div>
            <div style={{ flex: 2 }}>
              <label>지급일시</label>
              <TextField
                fullWidth
                type="text"
                value={
                  contract.rewardCompletedAt
                    ? momentLib(contract.rewardCompletedAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
                    : ''
                }
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <label>계좌번호</label>
              <TextField
                fullWidth
                type="text"
                value={contract.accountNumber}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>

            <div style={{ flex: 2 }}>
              <label>은행명</label>
              <TextField
                fullWidth
                type="text"
                value={contract.bankName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div style={{ flex: 2 }}>
              <label>계좌 소유주 성함</label>
              <TextField
                fullWidth
                type="text"
                value={contract.accountHolder}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
            <div style={{ flex: 1 }} />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Editor;
