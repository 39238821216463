import * as api from '../api';

export function getSchema() {
  return api
    .fetchData('get', '/contract/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function filterContracts(query) {
  return api
    .fetchData('get', '/contract/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getContract(id) {
  return api
    .fetchData('get', '/contract/console', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateContract(id, json) {
  return api
    .fetchData('put', '/contract/console', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function deleteContract(id) {
  return api
    .fetchData('delete', '/contract/console', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getLog(id) {
  let query = {
    bool: {
      must: [
        {
          terms: {
            baseUrl: ['contract'],
          },
        },
        {
          multi_match: {
            query: id,
            type: 'phrase',
            fields: ['mergeParams.contractId', 'mergeParams.id'],
          },
        },
      ],
    },
  };
  let params = new URLSearchParams({
    query: JSON.stringify(query).replace(/\\n/g, ' '),
  });
  return api
    .fetchData('get', '/log/auto?' + params.toString())
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
