import React, { useState, useCallback, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ReactPaginate from 'react-paginate';
import { SearchInput, SortableTableHead } from '../../../../components';
import momentLib from 'moment-timezone';
import { getReport, updateReportIssue } from '../../api/report';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router';

const header = [
  { sortable: true, id: 'createdAt', label: '생성일' },
  { sortable: false, id: 'uid', label: 'uid' },
  { sortable: true, id: 'category', label: 'category' },
  { sortable: true, id: 'content', label: 'content' },
  { sortable: true, id: 'manufacturer', label: 'manufacturer' },
  { sortable: true, id: 'modelName', label: 'modelName' },
  { sortable: true, id: 'osName', label: 'osName' },
  { sortable: true, id: 'osVersion', label: 'osVersion' },
  { sortable: true, id: 'appVersion', label: 'appVersion' },
];

const ROWS_PER_PAGE = 20;

const initialState = {
  page: 1,
  rowsPerPage: ROWS_PER_PAGE,
  // orderBy: 'createdAt',
  direction: 'desc',
  issues: [],
  total: 0,
};

const ReportIssue = props => {
  const searchOptions = useRef({
    selectItem: '',
    findText: '',
  });

  const [state, setState] = useState(initialState);

  const fetch = async page => {
    const result = await getReport({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),
    });

    setState({
      ...state,
      issues: [...result.data.data.reportIssues],
      page: page,
      total: result.data.data.totalRows,
    });
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleSearch = (selectItem, findText) => {
    searchOptions.current = {
      selectItem,
      findText,
    };

    fetch(1);
  };

  const body = state.issues.map((issue, index) => {
    return (
      <TableRow key={index}>
        {console.log(issue)}
        <TableCell>{momentLib(issue.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
        <TableCell>
          <Link to={`/cosduck/user?id=${issue.uid}`} target="_blank">
            {issue.uid}
          </Link>
        </TableCell>
        <TableCell>{issue.category}</TableCell>
        <TableCell>{issue.content}</TableCell>
        <TableCell>{issue.manufacturer}</TableCell>
        <TableCell>{issue.modelName}</TableCell>
        <TableCell>{issue.osName}</TableCell>
        <TableCell>{issue.osVersion}</TableCell>
        <TableCell>{issue.appVersion}</TableCell>
        <TableCell>
          <Checkbox
            checked={issue.completed}
            onChange={async () => {
              const result = await updateReportIssue(issue.id, !issue.completed);
              const clone = [...state.issues];
              clone[index] = result.data.data.updated;

              setState({
                ...state,
                issues: clone,
              });
            }}
          />
        </TableCell>
      </TableRow>
    );
  });

  const pageCount = parseInt(state.total / state.rowsPerPage, 10) + (state.total % state.rowsPerPage === 0 ? 0 : 1);

  const handleChangePage = event => {
    fetch(event.selected + 1);
  };

  return (
    <div className="table-container">
      <SearchInput header={header} handleSearch={handleSearch} />
      <Table>
        <SortableTableHead order={state.direction} columns={header} />
        <TableBody>{body}</TableBody>
      </Table>
      <div className="table-footer">
        <div />
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={handleChangePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportIssue);
