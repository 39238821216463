import React from 'react';

import { Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import useAdsGroup from '../../hooks/useAdsGroup';
import AdCompactList from '../../components/ads/AdCompactList';
import Typo from '../../../../components/Typo';

const AdsGroupList = props => {
  const { adId, companyId } = props;
  const { adsGroup, adsGroupInfo } = useAdsGroup(adId);

  return (
    <div style={{ width: '100%' }}>
      {adsGroup.length === 0 ? (
        <Typo>등록된 광고 그룹이 없습니다.</Typo>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            <Typo>광고 그룹 정보</Typo>
            <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typo
                style={{
                  fontSize: 10,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                }}>
                이용 제한 횟수 : {adsGroupInfo.availableCount}
              </Typo>
              <Tooltip
                title={`한 유저가 같은 그룹의 제품을 이용할 수 있는 최대 횟수를 의미합니다. \n  이용 제한 횟수에 도달하면, 같은 그룹의 광고가 유저에게 보이지 않습니다.`}
                placement="bottom">
                <HelpOutline style={{ fontSize: 12, color: '#999' }} />
              </Tooltip>
            </div>
          </div>
          <AdCompactList ads={adsGroup} />
        </div>
      )}
    </div>
  );
};

export default AdsGroupList;
