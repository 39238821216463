import * as api from '../api';

export function filterCompanies(query) {
  return api
    .fetchData('get', '/company3/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getSchema() {
  return api
    .fetchData('get', '/company3/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getCompany(id) {
  if (id) {
    return api
      .fetchData('get', '/company3', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getAdsGroup(adsGroupId) {
  if (adsGroupId) {
    return api
      .fetchData('get', '/company3/group', { id: adsGroupId })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}
export function addAdsToAdsGroup(adsGroupId, adId) {
  if (adsGroupId && adId) {
    return api
      .fetchData('patch', '/company3/group', { id: adsGroupId, adId })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function upsertAdsGroup(adsGroupData) {
  if (adsGroupData) {
    return api
      .fetchData('put', '/company3/group', adsGroupData)
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}
export function deleteAdsGroup(adsGroupId) {
  if (adsGroupId) {
    return api
      .fetchData('delete', '/company3/group', { id: adsGroupId })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function deleteCompany(id) {
  return api
    .fetchData('delete', '/company3', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateCompany(id, json) {
  return api
    .fetchData('put', '/company3', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getQuestions() {
  return api
    .fetchData('get', '/question')
    .then(qs => {
      return Promise.resolve(qs);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export async function getAnswers(questionId, companyId) {
  return await Promise.all([
    api.fetchData('get', '/question?id=' + questionId),
    api.fetchData('get', `/question/answer?questionId=${questionId}&company=${companyId}`),
  ]);
}

export async function deleteAnswer(questionId, answerId) {
  return api
    .fetchData('delete', 'question/answer', { questionId: questionId, answerId: answerId })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export async function getOptions(query) {
  const url = '/company/option?' + query;
  return await api.fetchData('get', url);
}

export function getHistory(id) {
  if (id) {
    return api
      .fetchData('get', '/company3/history', { companyId: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateHistory(comId, id, json) {
  return api
    .fetchData('put', '/company3/history', { companyId: comId, id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function deleteHistory(comId, id) {
  return api
    .fetchData('delete', '/company3/history', { companyId: comId, id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getPush(id) {
  if (id) {
    return api
      .fetchData('get', '/push', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updatePush(id, json) {
  return api
    .fetchData('put', '/push', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function deletePush(id) {
  return api
    .fetchData('delete', '/push', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function sendSms(json) {
  return api
    .fetchData('post', '/utils/sendsms', null, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getLog(id) {
  let query = {
    bool: {
      must: [
        {
          terms: {
            baseUrl: ['ad', 'company3'],
          },
        },
        {
          multi_match: {
            query: id,
            fields: ['mergeParams.companyId', 'mergeParams.id', 'mergeParams.adId'],
          },
        },
      ],
    },
  };
  let params = new URLSearchParams({
    query: JSON.stringify(query).replace(/\\n/g, ' '),
  });
  return api
    .fetchData('get', '/log/auto?' + params.toString())
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getCompanyUser(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function getMessages(phone) {
  return api
    .fetchData('get', '/utils/sendsms', { to: phone })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
