import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import Dropzone from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { uploadFile } from '../../app/api';

export default class Image extends React.Component {
  state = {
    oldURL: this.props.field.value,
    file: null,
    filename: '',
    cropStep: 0,
    preview: null,
    progress: null,
  };
  onDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({ file: acceptedFiles[0], filename: acceptedFiles[0].name, cropStep: 1 });
  };
  handleClose = () => {
    this.setState({ cropStep: 0, progress: null });
  };
  handleCrop = () => {
    const { field } = this.props;
    let croppedCanvas = this.cropper.getCroppedCanvas({ width: field.width, height: field.height });
    croppedCanvas.toBlob(blob => {
      this.setState({ preview: croppedCanvas.toDataURL(), file: blob, cropStep: 2 });
    });
  };
  handleUpload = () => {
    const { field } = this.props;
    const { file, filename } = this.state;
    let fileRef = field.filepath + '/';
    if (field.filename) {
      fileRef += field.filename;
    } else {
      fileRef += filename;
    }
    uploadFile(fileRef, file, progressObj => {
      this.setState({ progress: progressObj.progress });
    })
      .then(downloadURL => {
        this.props.handleChange(downloadURL);
        this.setState({ cropStep: 3 });
      })
      .catch(err => {
        console.log(err);
        alert('파일 업로드에 실패했습니다.');
      });
  };
  render() {
    const { field } = this.props;
    let { file, cropStep, preview, progress } = this.state;

    let dialogTitle = '';
    let dialogBody = '';
    let dialogActions = '';

    if (cropStep === 1) {
      dialogTitle = <DialogTitle>이미지 자르기</DialogTitle>;
      dialogBody = (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">이미지를 규격에 맞게 잘라주세요.</DialogContentText>
          <Cropper
            ref={component => (this.cropper = component)}
            src={file.preview}
            aspectRatio={field.width / field.height}
            style={{ width: '100%', height: 400 }}
          />
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleClose}>취소</Button>
          <Button onClick={this.handleCrop} color="secondary" autoFocus>
            자르기
          </Button>
        </DialogActions>
      );
    } else if (cropStep === 2) {
      dialogTitle = <DialogTitle>이미지 업로드</DialogTitle>;
      dialogBody = (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">아래 이미지를 업로드하시겠습니까?</DialogContentText>
          <div className="preview">
            <img src={preview} width="100%" alt="이미지 프리뷰" />
            {progress !== null ? (
              <div className="overlay">
                <div className="progress">{progress}%</div>
              </div>
            ) : (
              ''
            )}
          </div>
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleClose}>취소</Button>
          <Button onClick={this.handleUpload} color="secondary" autoFocus>
            업로드하기
          </Button>
        </DialogActions>
      );
    } else if (cropStep === 3) {
      dialogTitle = <DialogTitle>이미지 업로드 완료</DialogTitle>;
      dialogBody = (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            파일 업로드가 완료되었습니다.
            <br />
            저장 버튼을 누르셔야 URL이 DB에 저장됩니다.
          </DialogContentText>
          <div className="preview">
            <img src={preview} width="100%" alt="이미지 프리뷰" />
          </div>
        </DialogContent>
      );
      dialogActions = (
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary" autoFocus>
            닫기
          </Button>
        </DialogActions>
      );
    }

    return (
      <div className="row">
        <label>{field.name}</label>
        <div className="flex-row">
          <TextField
            fullWidth
            multiline={field.multiple ? true : false}
            rows={6}
            value={field.value === null ? '' : field.value}
            onChange={evt => this.props.handleChange(evt.target.value)}
          />
          <button className="icon-button" onClick={() => document.getElementById('dropper').click()}>
            <i className="material-icons">file_upload</i>
          </button>
          {field.value ? (
            <button className="icon-button" onClick={() => window.open(field.value)}>
              <i className="material-icons">open_in_new</i>
            </button>
          ) : (
            ''
          )}
        </div>
        <Dropzone
          onDrop={this.onDrop}
          id={'dropper'}
          style={{ display: 'none' }}
          accept={field.filetype === undefined ? '*' : field.filetype}>
          <p>Click or drag and drop</p>
        </Dropzone>
        <Dialog
          open={cropStep > 0}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          {dialogTitle}
          {dialogBody}
          {dialogActions}
        </Dialog>
      </div>
    );
  }
}
