import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatAlignLeft from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenter from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRight from '@material-ui/icons/FormatAlignRight';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ColorSelector from './color-selector';

class TextForm extends Component {
  static propTypes = {
    initialStyle: PropTypes.object,
    initialValue: PropTypes.string,
    multiline: PropTypes.bool,
  };
  static defaultProps = {
    initialStyle: {
      fontSize: 14,
      color: '#000',
      textAlign: 'center',
      fontWeight: 'normal',
    },
    initialValue: '',
  };
  state = {
    fontSize: 14,
    color: '#000',
    textAlign: 'center',
    fontWeight: 'normal',
    ...this.props.initialStyle,
    text: this.props.initialValue,
  };
  handleChange = (name, value) => {
    this.setState({ [name]: value });
    const textStyle = {
      ...this.state,
      [name]: value,
    };
    const text = textStyle.text;
    const textKey = this.props.multiline ? 'text' : 'label';
    delete textStyle.text;

    const textStyleKey = this.props.multiline ? 'textStyle' : 'labelStyle';

    this.props.handleChange({ [textKey]: text, [textStyleKey]: textStyle });
  };
  render() {
    const { fontSize, textAlign, fontWeight, text, color } = this.state;
    return (
      <section>
        <label>텍스트</label>
        <div className="toolbar">
          <Select
            value={fontSize}
            style={{ flex: 1 }}
            onChange={evt => this.handleChange('fontSize', evt.target.value)}>
            <MenuItem value={18}>Xlarge</MenuItem>
            <MenuItem value={16}>Large</MenuItem>
            <MenuItem value={14}>Medium</MenuItem>
            <MenuItem value={13}>Small</MenuItem>
            <MenuItem value={10}>Xsmall</MenuItem>
          </Select>
          <Divider />
          <ColorSelector
            label="TEXT COLOR"
            initialColor={color}
            handleChange={colorCode => this.handleChange('color', colorCode)}
          />
          <Divider />
          <IconButton onClick={() => this.handleChange('fontWeight', fontWeight === 'bold' ? 'normal' : 'bold')}>
            <FormatBold color={fontWeight === 'bold' ? 'secondary' : 'inherit'} />
          </IconButton>
          <Divider />
          <IconButton onClick={() => this.handleChange('textAlign', 'left')}>
            <FormatAlignLeft color={textAlign === 'left' ? 'secondary' : 'inherit'} />
          </IconButton>
          <IconButton onClick={() => this.handleChange('textAlign', 'center')}>
            <FormatAlignCenter color={textAlign === 'center' ? 'secondary' : 'inherit'} />
          </IconButton>
          <IconButton onClick={() => this.handleChange('textAlign', 'right')}>
            <FormatAlignRight color={textAlign === 'right' ? 'secondary' : 'inherit'} />
          </IconButton>
        </div>
        <TextField
          value={text}
          onChange={evt => this.handleChange('text', evt.target.value)}
          margin="normal"
          multiline={this.props.multiline}
          rows={this.props.multiline ? 10 : 1}
          fullWidth
        />
      </section>
    );
  }
}

export default TextForm;
