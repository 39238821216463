import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import EmptySpace from './component/EmptySpace';
import Card from './component/Card';
import TabButton from './component/TabButton';
import QuickScrollTopButton from './component/QuickScrollTopButton';
import DetailModal from './component/DetailModal';
import AddModal from './component/AddModal';
import Help from './component/Help';
import CustomInput from './component/CustomInput';
import useKeyword from './hooks/useKeyword';
import KeywordChip from './component/KeywordChip';

const purchaseLink = props => {
  const [selected, setSelected] = useState('chart');
  const [list, setList] = useState([]);
  const LIMIT = 30;
  const offset = useRef(0);
  const [selectedId, setSelectedId] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const selectRef = useRef(null);
  const inputRef = useRef(null);
  const { keywords, addKeyword, removeKeyword } = useKeyword();

  const filteredList = (() => {
    if (!!filterName) {
      return list.filter(item => {
        if (!!item.Contracts) {
          return item.Contracts.companyName === filterName;
        } else {
          return item.Propositions.cname === filterName;
        }
      });
    }

    return list;
  })();

  const onCsvHandler = () => {
    alert('필요하시면 문의해주세요! :D');
  };

  const onFilterHandler = name => {
    if (name === filterName) {
      setFilterName('');
    } else {
      setFilterName(name);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const requestPurchaseLink = async () => {
    let URL = `https://hashble.com/list?type=${selected}&limit=${LIMIT}&offset=${offset.current}`;

    const value = inputRef.current.value;

    if (!!value) {
      const key = selectRef.current.value;
      URL += `&${key}=${value}`;

      addKeyword({ category: selected, subCategory: key, keyword: value });
    }

    try {
      const result = await fetch(URL);
      const parse = await result.json();

      if (offset.current === 0) {
        setList([...parse]);
      } else {
        setList([...list, ...parse]);
      }

      offset.current += LIMIT;
    } catch (error) {
      console.error('request error', error);
    }
  };

  const requestSearch = () => {
    offset.current = 0;
    requestPurchaseLink();
  };

  useEffect(() => {
    offset.current = 0;
    scrollToTop();
    requestPurchaseLink();
  }, [selected]);

  const handleScroll = async () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      requestPurchaseLink();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [list]);

  const initSelectedId = () => {
    setSelectedId(null);
  };

  const modifyHandler = (id, newLongURL, name) => {
    const clone = [...list];
    const findIndex = clone.findIndex(item => item[selected === 'members' ? 'contractId' : 'propositionId'] === id);
    clone[findIndex].longUrl = newLongURL;
    clone[findIndex][selected === 'members' ? 'Contracts' : 'Propositions'][
      selected === 'members' ? 'companyName' : 'cname'
    ] = name;

    setList(clone);
    setSelectedId(null);
  };

  const onDeleteHandler = async item => {
    if (window.confirm(`정말 삭제하시겠습니까?\nID : ${item.id}`)) {
      try {
        let URL = `https://hashble.com/del?${
          !!item.propositionId ? `propositionId=${item.propositionId}` : `contractId=${item.contractId}`
        }`;

        await fetch(`${URL}`, {
          method: 'DELETE',
          body: JSON.stringify({
            password: 'phozphoz1!',
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const clone = [...list].filter(l => l.id !== item.id);
        setList(clone);
      } catch (error) {
        alert(`fail to delete!!\n${error}`);
      }
    }
  };

  const settingPrevKeyword = id => {
    const findData = keywords.find(item => item.id === id);
    selectRef.current.value = findData.subCategory;
    inputRef.current.value = findData.keyword;
    setSelected(findData.category);

    if (selected === findData.category) {
      offset.current = 0;
      requestPurchaseLink();
    }
  };

  return (
    <>
      {selectedId && (
        <DetailModal
          selectedId={selectedId}
          type={selected}
          onCloseHandler={() => {
            initSelectedId();
          }}
          modifyHandler={modifyHandler}
        />
      )}
      {isOpenAddModal && (
        <AddModal
          type={selected}
          onCloseHandler={() => {
            setIsOpenAddModal(false);
          }}
        />
      )}

      <div style={{ margin: '0px 32px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '24px',
            maxWidth: '824px',
            justifyContent: 'space-between',
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <TabButton
              label={'멤버스'}
              iconName={'M'}
              isSelected={selected === 'members'}
              onClickHandler={() => {
                inputRef.current.value = '';
                setSelected('members');
              }}
            />
            <TabButton
              label={'차트'}
              iconName={'C'}
              isSelected={selected === 'chart'}
              onClickHandler={() => {
                inputRef.current.value = '';
                setSelected('chart');
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <select
              style={{
                height: '44px',
                backgroundColor: '#fff',
                border: '1px solid #dbe0eb',
                boxSizing: 'border-box',
                borderRadius: 4,
                padding: '0px 16px',
              }}
              ref={selectRef}
              defaultValue={'cname'}>
              <option value="id">id 🔴</option>
              <option value="longUrl">longUrl 🟠</option>
              <option value="shortUrl">shortUrl 🟡</option>
              <option value="naverUrl">naverUrl 🟢</option>
              <option value="contractId">contractId 🔵</option>
              <option value="propositionId">propositionId 🟣</option>
              <option value="cname">cname(차트) ⚪️</option>
              <option value="companyName">companyName(멤버스) ⚫️</option>
            </select>
            <CustomInput
              ref={inputRef}
              inputStyle={{ width: '220px', padding: '0px 16px' }}
              onKeyDown={e => {
                if (e.keyCode === 13) {
                  requestSearch();
                }
              }}
            />
          </div>
        </div>
        <div
          style={{
            maxWidth: '824px',
            display: 'flex',
            gap: '8px',
            flexWrap: 'wrap',
            marginBottom: '24px',
          }}>
          {keywords.map(k => (
            <KeywordChip
              category={k.category}
              subCategory={k.subCategory}
              label={k.keyword}
              onClickHandler={() => {
                settingPrevKeyword(k.id);
              }}
              onDeleteHandler={() => {
                removeKeyword(k.id);
              }}
            />
          ))}
        </div>

        {filteredList.map(item => {
          return (
            <React.Fragment key={item.id}>
              <Card
                {...item}
                onEditHandler={() => {
                  setSelectedId(!!item.Contracts ? item.contractId : item.propositionId);
                }}
                onDeleteHandler={() => {
                  onDeleteHandler(item);
                }}
              />
              <EmptySpace />
            </React.Fragment>
          );
        })}
        <QuickScrollTopButton
          onClickHandler={() => {
            scrollToTop();
          }}
        />
        <Help
          currentList={list}
          type={selected}
          onFilterHandler={onFilterHandler}
          filterName={filterName}
          onCsvHandler={onCsvHandler}
          onAddHandler={() => {
            setIsOpenAddModal(true);
          }}
        />
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(purchaseLink);
