import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import { mapSeries } from 'p-iteration';

import * as api from '../api';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import axios from 'axios';

class UpdateUser extends React.Component {
  state = {
    users: [],
    progress: -1,
  };
  render() {
    const { progress } = this.state;
    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <h3>유저 업데이트</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <section>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <input
                    accept="text/tab-separated-values, .csv"
                    id="tsv-button-file"
                    onChange={this.handleLoad}
                    type="file"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  {progress == -1 ? (
                    <IconButton onClick={this.handleUpdate}>
                      <CloudUploadIcon />
                    </IconButton>
                  ) : (
                    <CircularProgress variant="static" value={progress} color="secondary" />
                  )}
                </div>
                <div style={{ flex: 1 }}>{progress > -1 ? progress + '%' : ''}</div>
              </div>
            </section>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
  async componentDidMount() {}

  handleLoad = event => {
    const fileReader = new FileReader();

    // fileReader.readAsDataURL(event.target.files[0]);
    fileReader.readAsText(event.target.files[0]);
    fileReader.onload = e => {
      let columns;
      let items = e.target.result.split('\n');
      items = items.filter(item => {
        return item.length > 0;
      });
      items = items.map((item, idx) => {
        item = item.trim().split(/[\t,\r\n\f\x0B]/);
        if (idx === 0) {
          columns = item;
        }
        let row = {};
        columns.forEach((column, idx) => {
          row[columns[idx]] = item[idx];
        });

        return row;
      });
      items.shift();
      console.log(items);

      this.setState({
        users: items,
      });
    };
  };

  handleUpdate = async () => {
    console.log('@@ this.state.users', this.state.users);
    const result = await axios.post('https://push-server-fg5dmxiesa-du.a.run.app/post-level', {
      postLevels: this.state.users,
    });
    console.log('@@ result', result);
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(UpdateUser);
