import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import { getUnleashValue } from '../../../unleash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import unleash from '../../../unleash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import { StyledBackdrop, StyledAlert } from './components/StyledComponents';

import _ from 'lodash';

import * as apiV2 from '../api-v2';
import PaymentStepper from './components/Steps';
import PaymentAdsOrderDetails from './components/AdsOrderDetails';
import { AdsProgressStepEnum, PaymentStatusEnum } from '../paycard.enum';
import momentLib from 'moment-timezone';

// 클래스형 컴포넌트 import
import ClassCompanyPaycard from './index-backup';
import { AdCategoryEnum, AdTypeEnum } from '../paycard.enum';
import PaymentAdsDetail from './components/AdsDetail';
import { PendingPaymentRule, ReviewingRule, ruleSets } from './rules';

const FunctionalCompanyPaycard = props => {
  const adId = props.location.query.id;
  const [adCategory, setAdCategory] = useState(null);
  const [ad, setAd] = useState(null);
  const [channel, setChannel] = useState(null);
  const [paymentCards, setPaymentCards] = useState(null);
  const [adPayments, setAdPayments] = useState(null);

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [activeStep, setActiveStep] = useState(null);
  const [errors, setErrors] = useState(JSON.parse(JSON.stringify(ruleSets)));
  const [childMethods, setChildMethods] = useState(null);

  // NOTE: 매장 표준형은 5단계 (종료예정 포함), 나머지는 4단계 (종료예정 제외)
  useEffect(() => {
    if (!ad || !channel || !adPayments || !adCategory) return;
    let step;
    if (adPayments.length === 0) {
      // NOTE: 검토 중 - adPayments가 없는 경우
      step = AdsProgressStepEnum.REVIEWING;
      const result = ReviewingRule.schema.validate({
        adCategory: adCategory,
        amount: channel.payInfo_amount,
        plan: channel.payInfo_plan,
        payMethod: channel.payInfo_payMethod,
        paymentCardIndex: channel.payInfo_card,
        checkBloggerCnt: channel.payInfo_checkBloggerCnt,
        number: channel.number,
      });
      if (result.error) {
        const errorDetails = result.error.details.reduce((acc, detail) => {
          const key = detail.context.key;
          const error = {
            type: detail.type,
            message: detail.message,
          };
          if (key in acc) {
            acc[key].push(error);
          } else {
            acc[key] = [error];
          }
          return acc;
        }, {});

        setErrors(prev => {
          const newErrors = _.cloneDeep(prev);
          Object.keys(errorDetails).forEach(key => (newErrors.REVIEWING[key] = errorDetails[key]));
          return newErrors;
        });
      }
    } else if (adPayments.length === 1) {
      // NOTE: 결제 예정 - adPayments가 1개인 경우
      step = AdsProgressStepEnum.PENDING_PAYMENT;

      // TODO:
      // 결제금액, 결제수단, 결제플랜 체크
      // 카드일 경우 채널에 등록된 카드가 있는지
      // 광고 시작일이 세팅 됐는지
      // 모집인원, 최소인원이 세팅됐는지
      // --
      // 결제할때 셋업:
      // 광고 종료일이 세팅 됐는지
      // 광고 시작일이 오늘 이후 인지
      const result = PendingPaymentRule.schema.validate({
        adCategory: adCategory,
        amount: adPayments[0].amount,
        plan: adPayments[0].plan,
        payMethod: adPayments[0].payMethod,
        paymentCardIndex: channel.payInfo_card,
        checkBloggerCnt: adPayments[0].checkBloggerCnt,
        number: adPayments[0].number,
      });
      if (result.error) {
        const errorDetails = result.error.details.reduce((acc, detail) => {
          const key = detail.context.key;
          const error = {
            type: detail.type,
            message: detail.message,
          };
          if (key in acc) {
            acc[key].push(error);
          } else {
            acc[key] = [error];
          }
          return acc;
        }, {});

        setErrors(prev => {
          const newErrors = _.cloneDeep(prev);
          Object.keys(errorDetails).forEach(key => (newErrors.PENDING_PAYMENT[key] = errorDetails[key]));
          return newErrors;
        });
      }
    } else {
      if (adPayments[0].status === PaymentStatusEnum.SCHEDULED && adPayments[1].status === PaymentStatusEnum.COMPLETE) {
        // NOTE: 진행중 - 최근 차수가 결제 예정이고 그 이전 차수가 결제완료인 건
        step = AdsProgressStepEnum.ACTIVE;
      } else if (
        adPayments[0].status === PaymentStatusEnum.STOPPED &&
        adPayments[1].status === PaymentStatusEnum.COMPLETE
      ) {
        // NOTE: [광고 종료, 종료 예정] - 최근 차수가 결제 중지고 그 이전 차수가 결제완료인 건
        const nextPaidAt =
          adPayments[1].nextPaidAt && /\d{4}-\d{2}-\d{2}/.test(adPayments[1].nextPaidAt)
            ? momentLib(adPayments[1].nextPaidAt).tz('Asia/Seoul')
            : null;
        if (!nextPaidAt) {
          // ERROR: 광고 종료일이 없거나 날짜 형식이 아닌 경우
          step = AdsProgressStepEnum.ERROR;
        } else {
          const today = momentLib().tz('Asia/Seoul');
          if (nextPaidAt.isBefore(today)) {
            // NOTE: 광고 종료 - 최근 진행했던 광고의 광고 종료일이 오늘 이전인 경우
            step = AdsProgressStepEnum.TERMINATED;
          } else {
            // NOTE: 종료 예정 - 현재 진행중인 광고의 광고 종료일이 당일이거나 오늘 이후인 경우
            step = AdsProgressStepEnum.PENDING_TERMINATION;
          }
        }
      } else {
        // ERROR: 차수 상태 오류
        step = AdsProgressStepEnum.ERROR;
      }
      // CHECK: 결제 이력이 2개 이상인 경우 최근, 최근-1 제외 나머지는 모두 결제완료로 되어있어야 함.
    }

    setActiveStep(step);
  }, [ad, channel, adPayments, adCategory]);

  const handleStepAction = action => {
    console.log('@@ handleStepAction', action);

    switch (action) {
      case 'createNewAdPayment':
        if (childMethods && childMethods.handleCreateNewAdsPayment) {
          childMethods.handleCreateNewAdsPayment();
        }
        break;
      case 'changePaymentInfo':
        break;
      case 'changeRecruitmentInfo':
        break;
      case 'executePayment':
        setLoading(true);
        // TODO: 결제로직 수행
        // IDEA: 패키지 결제할때 패키지 내역을 보여주자!!!
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    apiV2
      .getAdsPaymentDetail({ adId })
      .then(result => {
        console.log('@@ result', result);
        const { ad, channel, paymentCards, adPayments } = result.data;
        handleChange({ ad, channel, paymentCards, adPayments });

        let category = null;
        const categoryObj = typeof ad.category === 'string' ? JSON.parse(ad.category) : ad.category;
        if (categoryObj.firstName === '제품') {
          if (ad.type === AdTypeEnum.STANDARD) {
            category = AdCategoryEnum.PRODUCT_STANDARD;
          } else if (ad.type === AdTypeEnum.PREMIUM) {
            category = AdCategoryEnum.PRODUCT_PREMIUM;
          }
        } else if (categoryObj.firstName === '매장') {
          if (ad.type === AdTypeEnum.STANDARD) {
            category = AdCategoryEnum.STORE_STANDARD;
          } else if (ad.type === AdTypeEnum.PREMIUM) {
            category = AdCategoryEnum.STORE_PREMIUM;
          }
        }
        setAdCategory(category);
      })
      .catch(err => {
        console.log('@@ fail', err);
      });
  }, []);

  const handleChange = data => {
    for (const key in data) {
      if (key === 'ad') {
        setAd(data.ad);
      } else if (key === 'channel') {
        setChannel(data.channel);
      } else if (key === 'paymentCards') {
        setPaymentCards(data.paymentCards);
      } else if (key === 'adPayments') {
        let tobeSelectedOrder;
        setAdPayments(prev => {
          const prevLength = (prev || []).length;
          const currentLength = (data.adPayments || []).length;

          // NOTE: adPayments의 개수가 변경된 경우 selectedOrder를 변경
          if (prevLength !== currentLength) {
            if (currentLength === 1) tobeSelectedOrder = 1;
            else if (currentLength > 1) tobeSelectedOrder = currentLength - 1;
          }
          return data.adPayments.map(payment => ({
            ...payment,
            ...(!!payment.memo && { memo: JSON.parse(payment.memo) }),
          }));
        });
        if (!!tobeSelectedOrder && childMethods && childMethods.setSelectedOrder) {
          childMethods.setSelectedOrder(tobeSelectedOrder);
        }
      } else if (key === 'adPayment') {
        setAdPayments(prev => {
          return prev.map(payment => {
            if (payment.id === data.adPayment.id) {
              return {
                ...data.adPayment,
                ...(!!data.adPayment.memo && {
                  memo: JSON.parse(data.adPayment.memo),
                }),
              };
            } else return payment;
          });
        });
      }
    }
  };

  if (!!ad && !!channel && !!paymentCards) {
    return (
      <div className="contents">
        <div className="editor-container contents-editor">
          <PaymentAdsDetail
            ad={ad}
            channel={channel}
            paymentCards={paymentCards}
            adCategory={adCategory}
            onUpdate={handleChange}
            setLoading={setLoading}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            onExpose={setChildMethods}
          />
        </div>
        <div className="additional-container contents-editor">
          <PaymentStepper
            ad={ad}
            channel={channel}
            paymentCards={paymentCards}
            adCategory={adCategory}
            activeStep={activeStep}
            errors={errors}
            onAction={handleStepAction}
            onUpdate={handleChange}
            setLoading={setLoading}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
          />
          <PaymentAdsOrderDetails
            ad={ad}
            channel={channel}
            paymentCards={paymentCards}
            adPayments={adPayments}
            adCategory={adCategory}
            errors={errors}
            onUpdate={handleChange}
            onExpose={setChildMethods}
            setLoading={setLoading}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
          />
        </div>
        <StyledBackdrop open={loading}>
          <CircularProgress color="inherit" />
        </StyledBackdrop>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <StyledAlert onClose={handleCloseSnackbar} severity="success">
            {snackbarMessage}
          </StyledAlert>
        </Snackbar>
      </div>
    );
  }
  return <div />;
};

// 새로운 컨테이너 컴포넌트
const CompanyPaycard = props => {
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const TEST_ID = 11994; // FIXME: 테스트 후 삭제
  const adId = Number(props.location.query.id);
  const useNewComponent = adId === TEST_ID || getUnleashValue('console-paycard-renewal');

  useEffect(() => {
    const handleUpdate = () => {
      setShowUpdateDialog(true);
    };

    unleash.on('update', handleUpdate);
    return () => {
      unleash.off('update', handleUpdate);
    };
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <ClassCompanyPaycard {...props} />

      <Dialog open={showUpdateDialog} onClose={() => setShowUpdateDialog(false)}>
        <DialogTitle>업데이트 알림</DialogTitle>
        <DialogContent>콘솔 대시보드 코드가 업데이트 됐습니다. 지금 새로고침 하시겠습니까?</DialogContent>
        <DialogActions>
          <Button onClick={() => setShowUpdateDialog(false)} color="default">
            아니오
          </Button>
          <Button onClick={handleRefresh} color="secondary" autoFocus>
            예
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(CompanyPaycard);
