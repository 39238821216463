import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CustomTextField from '../../../../../components/CustomTextField';
import CustomSelect from '../../../../../components/CustomSelect';
import { LEVEL } from '../../constants';

const UserInformation = props => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomSelect
              label="userLevel"
              value={props.contract.userLevel}
              menus={LEVEL.map(lv => ({
                name: lv,
                value: lv,
              }))}
              onChange={event => {
                props.onChangeContract('userLevel', event.target.value);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'uid'}
              value={props.contract.uid}
              onChange={event => {
                props.onChangeContract('uid', event.target.value);
              }}
            />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default UserInformation;
