import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import InfoBasic from './info-basic';

import * as api from '../api';
import { UserCard } from '../../../components';
import EditorDelivery from '../../contract/editor/editor-delivery';
import EditorDeliveryHistory from '../../contract/editor/editor-delivery-history';

class UserPay extends React.Component {
  state = {
    id: this.props.location.query.id,
    settlement: {},
    contract: {},
    snackOpen: false,
  };
  render() {
    const { settlement, contract } = this.state;

    if (Object.keys(settlement).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container contents-editor">
            <InfoBasic
              settlement={settlement}
              contract={contract}
              handleChange={this.handleChange}
              handleSave={this.handleSave}
              handleRefund={this.handleRefund}
              handleDelete={this.handleDelete}
            />
          </div>
          <div className="additional-container contents-editor">
            <UserCard uid={settlement.uid} />
            <EditorDelivery contract={contract} handleChange={this.handleContractChange} />
            <EditorDeliveryHistory
              contract={contract}
              handleChange={this.handleContractChange}
              handleSave={this.handleContractSave}
            />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={4000}
              onClose={() => this.setState({ snackOpen: false })}
              message={<span>업데이트 성공</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => this.setState({ snackOpen: false })}>
                  <i className="material-icons">close</i>
                </IconButton>,
              ]}
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.props.activateLoading();

    api.filterUserPay({ id: this.state.id }).then(result => {
      this.setState({ settlement: result.settlements[0] });

      if (result.settlements[0]) {
        api.getContract(result.settlements[0].contract).then(result => {
          let contract = result.contracts[0];
          this.setState({ contract });
          this.props.deactivateLoading();
        });
      } else {
        this.props.deactivateLoading();
      }
    });
  }

  handleContractChange = (name, value) => {
    const contract = { ...this.state.contract };
    if (name === 'extended' && value && !contract[name]) {
      contract['deadline'] += 5;
    } else if (name === 'extended' && !value && contract[name]) {
      contract['deadline'] -= 5;
    }
    contract[name] = value;
    this.setState({ contract });
  };

  handleContractSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .updateContract(id, this.state.contract)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch(err => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };

  handleChange = (name, value) => {
    const settlement = { ...this.state.settlement };
    settlement[name] = value;
    this.setState({ settlement });
  };

  handleSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .updateSettlement(id, this.state.settlement)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch(err => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };

  handleRefund = () => {
    if (window.confirm('정말 환불 하시겠습니까')) {
      this.props.activateLoading();
      // api.deleteInicis(null, settlement.P_TID, settlement.P_MID).then(() => {
      api
        .deleteInicis(this.state.id)
        .then(() => {
          this.props.deactivateLoading();
          alert('환불 성공');
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('환불에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };

  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteSettlement(this.state.id)
        .then(() => {
          this.props.deactivateLoading();
          alert('삭제 성공');
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(UserPay);
