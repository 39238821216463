// TODO : 추후 실제 API로 교체 필요
import axios from 'axios';

/**
 * Users 요청
 * @param {string} query.level ?User level
 * @param {number} query.email ?email
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @param {string} query.name ?이름
 * @param {string} query.phoneNumber ?번호
 * @returns {User[]} User 목록
 */

export const getUsers = (query = {}) => {
  const payloadString = Object.entries(query)
    .map(e => e.join('='))
    .join('&');

  let baseURL = `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/users`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const getUser = id => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/user/${id}`);
};

export const getUserAddress = id => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/user/${id}/addresses`);
};

export const updateUser = (id, body) => {
  console.log(id, body);
  delete body.id;
  delete body.createdAt;
  delete body.updatedAt;

  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/user/${id}`, {
    ...body,
  });
};

export const updateUsersLevel = (uids, level) => {
  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/users/level`, {
    uids,
    level,
  });
};
