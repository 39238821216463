import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';

export default class ObjectEditor extends React.Component {
  state = {
    obj: this.props.field.value || {},
  };
  handleChange = (key, value) => {
    let obj = this.state.obj;
    obj[key] = value;
    this.setState({ obj: obj });
    this.props.handleChange(obj);
  };
  render() {
    const { field } = this.props;
    const { obj } = this.state;
    let fields = [];
    if (field.properties) {
      fields = Object.keys(field.properties).map((key, index) => {
        return (
          <div key={index} className="flex-row">
            <label>{key}</label>
            <Switch checked={obj[key] || false} onChange={(evt, checked) => this.handleChange(key, checked)} />
          </div>
        );
      });
    } else {
      fields = Object.keys(obj).map((key, index) => {
        if (typeof obj[key] === 'boolean') {
          return (
            <div key={index} className="flex-row">
              <label>{key}</label>
              <Switch
                checked={obj[key] === null ? false : obj[key]}
                onChange={(evt, checked) => this.handleChange(key, checked)}
              />
            </div>
          );
        } else {
          return (
            <div key={index} className="flex-row">
              <label>{key}</label>
              <TextField
                rows={3}
                style={{ flex: 1 }}
                value={obj[key]}
                onChange={evt => this.handleChange(key, evt.target.value)}
              />
            </div>
          );
        }
      });
    }
    return (
      <div className="row array">
        <FormLabel>{field.name}</FormLabel>
        {fields}
      </div>
    );
  }
}
