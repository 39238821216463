import { useEffect, useState } from 'react';

// NOTE: checkList에 checked가 있다는 가정하에 진행된 코드. 이후에 수정 필요

const useCheckList = () => {
  const [checkList, setCheckList] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    const allChecked = checkList.reduce((acc, cur) => {
      return acc && cur.checked;
    }, true);

    setIsAllChecked(allChecked);
  }, [checkList]);

  return { checkList, setCheckList, isAllChecked, setIsAllChecked };
};

export default useCheckList;
