import React, { useEffect, useState } from 'react';
import Information from './components/Information';
import { getProductReviewConfig, updateProductReviewConfig } from '../../api/productReviewConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { Button } from '@material-ui/core';

const ProductReviewConfig = props => {
  const [productReviewConfig, setProductReviewConfig] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await getProductReviewConfig(props.location.query.id);

      setProductReviewConfig(result.data.data.config);
    };

    fetch();
  }, []);

  const onSaveHandler = async () => {
    const { name, sourceUrl, target, targetId, ...rest } = productReviewConfig;

    try {
      const result = await updateProductReviewConfig(props.location.query.id, {
        name,
        sourceUrl,
        target,
        targetId,
      });

      if (!result.data.success) {
        console.log(result.data);
      } else {
        alert('저장되었습니다.');
        window.location.reload();
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };

  if (!productReviewConfig) {
    return null;
  }

  return (
    <div className="contents">
      <div className="editor-container">
        <div className="editor">
          <Information productReviewConfig={productReviewConfig} setProductReviewConfig={setProductReviewConfig} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: 10,
            }}>
            <Button
              style={{ margin: 12 }}
              variant="contained"
              color="secondary"
              onClick={() => {
                onSaveHandler();
              }}>
              저장
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewConfig);
