import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';

const Editor = ({ proposition, handleChange, handleSave }) => {
  if (proposition) {
    const deliveryInfo = Object.keys(proposition.delivery).map(key => {
      if (proposition.delivery) {
        let additionalInfo = null;
        if (key === 'additionalInfo') {
          let infos = JSON.parse(proposition.delivery.additionalInfo);
          additionalInfo = Object.keys(infos).map(key => {
            return (
              <div className="row">
                <div style={{ flex: 1 }}>
                  <TextField fullWidth value={key || ''} />
                </div>
                <div style={{ flex: 1 }}>
                  <TextField fullWidth value={infos[key] || ''} />
                </div>
              </div>
            );
          });
        }
        return (
          <div key={key}>
            <label>{key}</label>
            {key === 'additionalInfo' ? (
              additionalInfo
            ) : (
              <TextField
                fullWidth
                value={proposition.delivery[key] || ''}
                onChange={evt => {
                  proposition.delivery[key] = evt.target.value;
                  handleChange('delivery', proposition.delivery);
                }}
              />
            )}
          </div>
        );
      } else {
        return '';
      }
    });
    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <h3>배송정보</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            {/* row */}
            <div className="row">
              <div style={{ flex: 2 }}>
                <label>배송방법</label>
                <Select
                  fullWidth
                  value={proposition.deliveryMethod}
                  onChange={evt => handleChange('deliveryMethod', evt.target.value)}>
                  <MenuItem value={undefined}>미지정</MenuItem>
                  <MenuItem value={'A'}>회원 주소로 직접 배송</MenuItem>
                  <MenuItem value={'C'}>자사몰에서 쿠폰 / 적립금 지급</MenuItem>
                </Select>
              </div>
              <div style={{ flex: 1 }} />
            </div>
            <div className="row">
              <div style={{ flex: 2 }}>{deliveryInfo}</div>
            </div>
            <div className="row" style={{ justifyContent: 'flex-end' }}>
              <Button color="secondary" onClick={handleSave}>
                저장
              </Button>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  } else {
    return '';
  }
};

export default Editor;
