import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import InfoBasic from './info-basic';
import InfoNotice from './info-notice';
import InfoAds from './info-ads';
import InfoCard from './info-card';
import InfoAccount from './info-account';
import InfoPay from './info-pay';
import InfoUserPay from './info-userpay';
import InfoBlock from './info-block';
import InfoMemo from './info-memo';
import InfoPackage from './info-package';
import InfoPremiumPackage from './info-premium-package';
import InfoSumemPoint from './info-sumempoint';

import * as api from '../api';
import { InfoLog, InfoMessage } from '../../../components';

class CompanyUser extends React.Component {
  state = {
    id: this.props.location.query.id,
    companyUser: {},
    companies: [],
    notices: [],
    cards: [],
    accounts: [],
    settlements: [],
    packages: [],
    premiums: [],
    sumempoints: [],
    showAccount: false,
    snackOpen: false,
  };
  render() {
    const {
      companyUser,
      companies,
      notices,
      cards,
      accounts,
      settlements,
      showAccount,
      packages,
      premiums,
      sumempoints,
    } = this.state;

    if (Object.keys(companyUser).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container contents-editor">
            <InfoBasic
              companyUser={companyUser}
              handleChange={this.handleChange}
              handleSave={this.handleSave}
              handleDel={this.handleDel}
            />
            <InfoNotice companyUser={companyUser} notices={notices} />
            <InfoBlock companies={companies} />
            <InfoMemo
              companyUser={companyUser}
              handleChange={this.handleChange}
              handlePropChange={this.handlePropChange}
              handleDelete={this.handleDelete}
              handleSave={this.handleSave}
            />
            <InfoMessage type={'company-user'} phone={companyUser.contacts} />
            <InfoLog type={'company-user'} id={companyUser.id} />
          </div>
          <div className="additional-container contents-editor">
            <InfoAds companyUser={companyUser} companies={companies} />
            <InfoCard companyUser={companyUser} cards={cards} />
            <InfoAccount companyUser={companyUser} accounts={accounts} />
            <InfoPay companyUser={companyUser} companies={companies} />
            {showAccount ? (
              <InfoUserPay
                companyUser={companyUser}
                settlements={settlements}
                companies={companies}
                accounts={accounts}
              />
            ) : (
              ''
            )}
            <InfoPackage companyUser={companyUser} companies={companies} packages={packages} />
            <InfoPremiumPackage companyUser={companyUser} companies={companies} premiums={premiums} />
            {/*<InfoSumemPoint companyUser={companyUser} companies={companies} sumempoints={sumempoints}/>*/}
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={4000}
              onClose={() => this.setState({ snackOpen: false })}
              message={<span>업데이트 성공</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => this.setState({ snackOpen: false })}>
                  <i className="material-icons">close</i>
                </IconButton>,
              ]}
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  async componentDidMount() {
    this.props.activateLoading();
    let result = await api.getCompanyUser(this.state.id);
    const companyUser = result.users[0];
    this.setState({ companyUser: companyUser });

    let companies = await api.getCompanies3(companyUser.email);
    companies = companies.companies;
    this.setState({ companies: companies });
    let cards = await api.getCompanyUserCards(companyUser.id);
    this.setState({ cards: cards.cards });
    let accounts = await api.getCompanyUserAccounts(companyUser.id);
    this.setState({ accounts: accounts.accounts });
    let packages = await api.getCompanyUserPackages(companyUser.id);
    this.setState({ packages: packages.packages });
    let premiumPackages = await api.getCompanyUserPremiumPackages(companyUser.id);
    this.setState({ premiums: premiumPackages.packages });
    let sumempoints = await api.getCompanyUserSumempoints(companyUser.id);
    this.setState({ sumempoints: sumempoints.sumempoints });

    let results = await Promise.all(
      companies.map(company => {
        return Promise.all([api.getCompanyPayCards(company.id, 'naver'), api.getCompanyPayCards(company.id, 'insta')]);
      })
    );

    // _.flatten(_.map(results, 'paycards'))
    companies = this.state.companies.map((company, idx) => {
      company.naverPayCards = results[idx][0].paycards;
      company.instaPayCards = results[idx][1].paycards;
      ['naver', 'insta'].forEach(channel => {
        if (company.category.firstName === '제품' && company[channel]) {
          this.setState({ showAccount: true });
        }
      });
      return company;
    });
    this.setState({ companies: companies });

    let notices = await api.getCompanyUserNotices(companyUser.email);
    this.setState({ notices: notices.notices });
    let settlements = await api.getCompanyUserSettlements(companyUser.id);
    this.setState({ settlements: settlements.settlements });
    this.props.deactivateLoading();
  }

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({ companyUser: { ...this.state.companyUser, [name]: value } });
  };
  handlePropChange = (name, key, value) => {
    const companyUser = {
      ...this.state.companyUser,
    };
    companyUser[name][key] = value;
    // this.setState({ company });
    return new Promise(resolve => {
      this.setState({ companyUser }, () => resolve());
    });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const { id, companyUser } = this.state;
      api
        .deleteCompanyUser(id)
        .then(() => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
        })
        .catch(err => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
  handleSave = () => {
    this.props.activateLoading();
    const { id, companyUser } = this.state;
    api
      .updateCompanyUser(id, companyUser)
      .then(() => {
        this.setState({ snackOpen: true });
        this.props.deactivateLoading();
      })
      .catch(err => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        }
      });
  };
  handleCancel = () => {
    this.props.push('/companies3');
  };
  handleDel = () => {
    if (window.confirm('로그인 계정 및 업체에서 생성한 광고도 모두 삭제됩니다. 정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const { id, companies } = this.state;
      api
        .deleteCompanyUser(id)
        .then(() => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
        })
        .catch(err => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(CompanyUser);
