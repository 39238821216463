import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import momentLib from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as api from '../api';
import { Link } from 'react-router';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

class AdsHistory extends React.PureComponent {
  state = {
    history: [],
  };
  render() {
    const { classes } = this.props;
    const { history } = this.state;

    if (history.total > 0) {
      let items = history.history.map((his, idx) => {
        let diffs = '';
        if (his.diff.length > 0) {
          diffs = his.diff.map(diff => {
            if (momentLib(diff.lhs, momentLib.ISO_8601, true).isValid()) {
              diff.lhs = momentLib(diff.lhs).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');
            }
            if (momentLib(diff.rhs, momentLib.ISO_8601, true).isValid()) {
              diff.rhs = momentLib(diff.rhs).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');
            }

            return (
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>{diff.path.join('/')}</div>
                  <div>{diff.lhs ? JSON.stringify(diff.lhs) : '(Empty)'}</div>
                  <div>{diff.rhs ? JSON.stringify(diff.rhs) : '(Empty)'}</div>
                </div>
              </div>
            );
          });
        }
        return (
          <ExpansionPanel style={{ minWidth: '100%' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <h4>{momentLib(his.createdAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</h4>
                <span>
                  <Checkbox
                    checked={his.confirm}
                    onChange={event => {
                      api.updateHistory(this.props.company, his.id, { confirm: event.target.checked });
                      his.confirm = event.target.checked;
                      this.setState({ history });
                    }}
                    style={{ padding: 0 }}
                  />
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={event => {
                      if (window.confirm('정말 삭제 하시겠습니까')) {
                        api.deleteHistory(this.props.company, his.id);
                        history.history.splice(idx, 1);
                        history.total = history.history.length;
                        this.setState({ history });
                        event.stopPropagation();
                        this.forceUpdate();
                      }
                    }}>
                    삭제
                  </Button>
                </span>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'block' }}>{diffs}</ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>광고수정히스토리</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block', paddingLeft: 0, paddingRight: 0 }}>
            {items}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const history = await api.getHistory(this.props.company);
    this.setState({ history });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(AdsHistory));
