import React, { Component } from 'react';
import momentLib from 'moment-timezone';
import './cards.css';

class Card extends Component {
  state = {
    editable: false,
  };

  componentDidMount() {}

  openReceipt = () => {
    const { paid } = this.props;
    window.open(`${paid.receipt_url}`);
  };

  render() {
    const { paid, payment, iamport } = this.props;

    if ((paid || {}).card_name) {
      return (
        <div className="card">
          <div className="infos">
            <div>
              <div className="date">{momentLib.unix(paid.paid_at).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</div>
              <div className="info">{paid.card_name}</div>
              <div className="info">{paid.status}</div>
            </div>
            <div className="buttons">
              {paid.receipt_url ? (
                <button onClick={this.openReceipt}>
                  <i className="material-icons">open_in_new</i>
                  <span>영수증</span>
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      );
    } else if ((payment || {}).card_name) {
      return (
        <div className="card">
          <div className="infos">
            <div className="info">{payment.card_name}</div>
            <div className="info">{payment.customer_name}</div>
            <div className="info">{payment.last4}</div>
          </div>
        </div>
      );
    } else if ((iamport || {}).card_name) {
      return (
        <div className="card">
          <div className="infos">
            <div className="date">{momentLib.unix(iamport.inserted).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}</div>
            <div className="info">{iamport.card_name}</div>
            <div className="info">{iamport.customer_name}</div>
            <button className="pay-btn" onClick={this.props.payAgain}>
              결제하기
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="card">
          <div className="info">내역이 없습니다.</div>
        </div>
      );
    }
  }
}

export default Card;
