import React from 'react';

const styles = {
  defaultButton: {
    color: '#fff',
    backgroundColor: '#525252',
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
};
const Preview = ({ contents }) => {
  const contentElements = contents.map((item, idx) => {
    if (item.type === 'image') {
      return (
        <div key={idx} style={item.backgroundStyle}>
          <img src={item.url} style={{ width: '100%' }} alt="" />
        </div>
      );
    } else if (item.type === 'button') {
      return (
        <div key={idx} style={item.backgroundStyle}>
          <div style={{ ...styles.defaultButton, ...item.buttonStyle }}>
            <span style={{ ...item.textStyle }}>{item.label}</span>
          </div>
        </div>
      );
    } else {
      return (
        <div key={idx} style={item.backgroundStyle}>
          <div style={item.textStyle}>{item.text}</div>
        </div>
      );
    }
  });
  return <div className="preview-container">{contentElements}</div>;
};

export default Preview;
