import React, { PureComponent } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/EditOutlined';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import momentLib from 'moment-timezone';

import DateField from '../../../components/editor/date';

const stateText = [
  '정보수정요청',
  '수정완료',
  '신청구매완료',
  '발송적립완료',
  '기한만료',
  '재적립',
  '발송적립준비중',
  '5',
  '6',
  '7',
  '8',
  '9',
  '이용취소',
];

class Editor extends PureComponent {
  state = {
    selected: null,
  };
  render() {
    const { proposition } = this.props;
    const { selected } = this.state;
    const deliveryHistory =
      !proposition || proposition.deliveryHistory === null || proposition.deliveryHistory === undefined
        ? []
        : proposition.deliveryHistory;
    const deliveryHistoryElements = deliveryHistory.map((item, idx) => {
      return (
        <TableRow key={idx}>
          <TableCell style={{ textAlign: 'center' }}>
            {momentLib(item.updatedAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}
          </TableCell>
          <TableCell style={{ textAlign: 'center' }}>{stateText[item.state + 2]}</TableCell>
          <TableCell>{item.message}</TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleSelect(idx)}>
              <Edit />
            </IconButton>
          </TableCell>
          <TableCell>
            <IconButton onClick={() => this.handleDelete(idx)}>
              <Remove />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    });
    if (proposition && Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>배송 상태</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <div className="table-container">
                  <Table style={{ minWidth: '100px' }}>
                    <TableBody>
                      {deliveryHistoryElements}
                      <TableRow>
                        <TableCell colSpan={4}>&nbsp;</TableCell>
                        <TableCell>
                          <IconButton onClick={() => this.handleAdd()}>
                            <Add />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </section>

              {selected > -1 && deliveryHistory[selected] ? (
                <section>
                  <div className="row">
                    <div style={{ flex: 2 }}>
                      <label>메시지</label>
                      <TextField
                        fullWidth
                        value={proposition.deliveryHistory[selected].message}
                        onChange={evt => this.handleChange('message', evt.target.value)}
                      />
                    </div>
                    <div style={{ flex: 1 }}>
                      <label>배송상태</label>
                      <Select
                        fullWidth
                        value={proposition.deliveryHistory[selected].state}
                        onChange={evt => this.handleChange('state', evt.target.value)}>
                        <MenuItem value={undefined}>미지정</MenuItem>
                        <MenuItem value={-2}>정보수정요청</MenuItem>
                        <MenuItem value={-1}>수정완료</MenuItem>
                        <MenuItem value={0}>신청구매완료</MenuItem>
                        <MenuItem value={1}>발송적립완료</MenuItem>
                        <MenuItem value={2}>기한만료</MenuItem>
                        <MenuItem value={3}>재적립</MenuItem>
                        <MenuItem value={4}>발송적립준비중</MenuItem>
                        <MenuItem value={10}>이용취소</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <DateField
                      field={{
                        name: '배송상태 변경일자',
                        value: proposition.deliveryHistory[selected].updatedAt,
                        format: 'date-time',
                      }}
                      handleChange={evt => this.handleChange('updatedAt', new Date(evt.target.value))}
                    />
                  </div>
                </section>
              ) : null}
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button color="secondary" onClick={this.props.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  handleChange = (key, value) => {
    const { proposition, handleChange } = this.props;
    const { selected } = this.state;
    const deliveryHistory =
      proposition.deliveryHistory === null || proposition.deliveryHistory === undefined
        ? []
        : [...proposition.deliveryHistory];
    deliveryHistory[selected][key] = value;
    handleChange('deliveryHistory', deliveryHistory);
  };
  handleSelect = idx => {
    this.setState({ selected: idx });
  };
  handleDelete = idx => {
    const { proposition, handleChange } = this.props;
    const deliveryHistory =
      proposition.deliveryHistory === null || proposition.deliveryHistory === undefined
        ? []
        : [...proposition.deliveryHistory];

    deliveryHistory.splice(idx, 1);

    handleChange('deliveryHistory', deliveryHistory);
  };
  handleAdd = () => {
    const { proposition, handleChange } = this.props;

    const deliveryHistory =
      proposition.deliveryHistory === null || proposition.deliveryHistory === undefined
        ? []
        : [...proposition.deliveryHistory];

    deliveryHistory.push({ message: '', state: 0, updatedAt: null });

    handleChange('deliveryHistory', deliveryHistory);
    this.setState({ selected: deliveryHistory.length - 1 });
  };
}
export default Editor;
