import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import * as api from '../../campaigns/api';
import { Link } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';

const Editor = ({ proposition, categories, handleChange }) => {
  let firstItems = <MenuItem value={proposition.category.first}>{proposition.category.firstName}</MenuItem>;
  let secondItems = <MenuItem value={proposition.category.second}>{proposition.category.secondName}</MenuItem>;
  let thirdItems = <MenuItem value={proposition.category.third}>{proposition.category.thirdName}</MenuItem>;
  if (Object.keys(categories).length > 0) {
    firstItems = Object.keys(categories).map(item => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
    });
    const second = categories[proposition.category.firstName].sub;
    secondItems = Object.keys(second).map(item => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
    });
    const third = proposition.category.secondName
      ? categories[proposition.category.firstName].sub[proposition.category.secondName].sub
      : {};
    thirdItems = Object.keys(third).map(item => {
      return (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      );
    });
  }

  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <label>propositionId</label>
          <TextField fullWidth value={proposition.id} />
          <label>회원명</label>
          <TextField fullWidth value={(proposition.User || {}).displayName} />
          <label>uid</label>
          <TextField fullWidth value={proposition.uid} onChange={evt => handleChange('uid', evt.target.value)} />
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>인플루언서 타입</label>
              <TextField
                fullWidth
                value={proposition.taggableType}
                onChange={evt => handleChange('taggableType', evt.target.value)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <label>InfluencerId</label>
              <TextField
                fullWidth
                value={proposition.taggableId}
                onChange={evt => handleChange('taggableId', evt.target.value)}
              />
            </div>
          </div>
          <label>체험단/기자단</label>
          <TextField fullWidth value={proposition.Campaign.type === 'P' ? '기자단' : '체험단'} />
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>대분류</label>
              <Select
                fullWidth
                value={proposition.category.firstName}
                onChange={evt => {
                  this.props.handlePropChange('category', 'first', categories[evt.target.value].id);
                  this.props.handlePropChange('category', 'firstName', evt.target.value);
                  this.props.handlePropChange('category', 'second', '');
                  this.props.handlePropChange('category', 'secondName', '');
                  this.props.handlePropChange('category', 'third', '');
                  this.props.handlePropChange('category', 'thirdName', '');
                }}>
                {firstItems}
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <label>중분류</label>
              <Select
                fullWidth
                value={proposition.category.secondName}
                onChange={evt => {
                  this.props.handlePropChange(
                    'category',
                    'second',
                    categories[proposition.category.firstName].sub[evt.target.value].id
                  );
                  this.props.handlePropChange('category', 'secondName', evt.target.value);
                  this.props.handlePropChange('category', 'third', '');
                  this.props.handlePropChange('category', 'thirdName', '');
                }}>
                {secondItems}
              </Select>
            </div>
            <div style={{ flex: 1 }}>
              <label>소분류</label>
              <Select
                fullWidth
                value={proposition.category.thirdName}
                onChange={evt => {
                  this.props.handlePropChange(
                    'category',
                    'third',
                    categories[proposition.category.firstName].sub[proposition.category.secondName].sub[
                      evt.target.value
                    ].id
                  );
                  this.props.handlePropChange('category', 'thirdName', evt.target.value);
                }}>
                {thirdItems}
              </Select>
            </div>
          </div>
          <label>campaign</label>
          <TextField fullWidth value={proposition.cname} />
          <label>캠페인 혜택</label>
          <TextField fullWidth value={proposition.creward} />
          <label>혜택 정가</label>
          <TextField fullWidth value={proposition.cprice} />
          <div className="row">
            <div>
              <label>캠페인</label>
              <Link to={'/campaign?id=' + proposition.campaignId} target="_blank">
                <IconButton>
                  <OpenInNew />
                </IconButton>
              </Link>
            </div>
            <div>
              <label>업체</label>
              <Link to={'/company-user?id=' + proposition.Campaign.companyId} target="_blank">
                <IconButton>
                  <OpenInNew />
                </IconButton>
              </Link>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Editor;
