import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ContractCard from '../../../components/ContractCard';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import { useEffect } from 'react';
import { getContracts } from '../../../api/contract';

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '1em',
    lineHeight: '1.5em',
    maxWidth: 1600,
    whiteSpace: 'pre-wrap', // 줄 바꿈 허용
    fontFamily: 'monospace',
  },
})(Tooltip);

const ContractList = props => {
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getContracts({
        uid: props.uid,
      });

      setContracts(result.data.data.contracts);
    };

    fetch();
  }, []);

  const [onlyPending, setOnlyPending] = useState(false);
  const contractList = (() => {
    if (onlyPending) {
      return contracts.filter(c => c.contract.currentContractStatus === 'SELECTED');
    }

    return [...contracts];
  })();

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3 style={{ display: 'flex', alignItems: 'center' }}>
          유저 이용내역
          <CustomTooltip
            title={
              <span>
                해당 이용내역의 View는 실제 앱 사용자의 화면과 똑같이 구현이 되었습니다.
                <br />
                Benefit이 존재하면 '직접구매' Cashback이 존재하면 '환급형' 입니다.
                <br />
                EXPRESS는 즉시 이용, STANDARD는 선정형입니다.
              </span>
            }>
            <div
              style={{
                width: 24,
                height: 24,
                backgroundColor: '#000',
                color: '#fff',
                borderRadius: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 4,
              }}>
              ?
            </div>
          </CustomTooltip>
        </h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ flexDirection: 'column' }}>
        <div
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          <p
            style={{
              fontSize: '16px',
              fontWeight: 500,
            }}>
            총 건 수 {contractList.length}건
          </p>
          <label>
            <input
              type="checkbox"
              checked={onlyPending}
              onChange={event => {
                setOnlyPending(event.target.checked);
              }}
            />
            리뷰 미등록만 보기
          </label>
        </div>
        <div
          style={{
            width: '100%',
            minWidth: 500,
            gap: 16,
            display: 'flex',
            flexDirection: 'column',
          }}>
          {contractList.map(contractInfo => (
            <ContractCard contract={contractInfo.contract} />
          ))}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ContractList;
