import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreateIcon from '@material-ui/icons/Create';
import React, { useState } from 'react';
import Typo from '../../../components/Typo';
import ImageGallery from '../../../components/ImageList';

import momentLib from 'moment-timezone';
import { Color } from '../../../constants/color';
import IconButton from '../../../components/IconButton';
import ModifyInquiryAnswer from './ModifyInquiryAnswer';

const InquiryAnswerLists = ({ id, inquiryAnswers }) => {
  const [modify, setModify] = useState({ isModifying: false, index: null });

  return (
    <>
      {inquiryAnswers.map((inquiryAnswer, index) => {
        return (
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <Typo typography={'Body1'} fontWeight={600}>
                  답변 내용
                </Typo>
                <IconButton
                  label={'수정'}
                  color={Color.button_icon_icon_modify}
                  onClick={() => {
                    setModify({ isModifying: true, index: index });
                  }}>
                  <CreateIcon size={16} />
                </IconButton>
              </div>
            </ExpansionPanelSummary>

            {modify.isModifying && modify.index === index ? (
              <ModifyInquiryAnswer id={id} inquiryAnswer={inquiryAnswer} setModify={setModify} />
            ) : (
              <ExpansionPanelDetails>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '8px',
                  }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typo typography={'Body1'}>
                      {momentLib.tz(inquiryAnswer.createdAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')}
                    </Typo>

                    {inquiryAnswer.isRead ? (
                      <Typo typography={'Body1'} color={Color.statustext_complete}>
                        {momentLib.tz(inquiryAnswer.readAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')} 읽음
                      </Typo>
                    ) : (
                      <Typo typography={'Body1'} color={Color.statustext_pending}>
                        안읽음
                      </Typo>
                    )}
                  </div>
                  <Typo typography={'Body1'}>{inquiryAnswer.contentText}</Typo>
                  <ImageGallery images={inquiryAnswer.contentImages || []} />
                </div>
              </ExpansionPanelDetails>
            )}
          </ExpansionPanel>
        );
      })}
    </>
  );
};

export default InquiryAnswerLists;
