import React, { useState, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ReactPaginate from 'react-paginate';
import { SearchInput, SortableTableHead } from '../../../../components';
import momentLib from 'moment-timezone';
import { useEffect } from 'react';
import { cancelContracts, getContracts } from '../../api/contract';
import { LOGO_IMAGE } from '../constants';
import { useRef } from 'react';
import { InsertPhoto } from '@material-ui/icons';
import { Button, Checkbox } from '@material-ui/core';
import useCheckList from '../../hooks/useCheckList';
import CustomDialog from '../../../../components/CustomDialog';
import CustomModal from '../../../../components/CustomModal';

const ROWS_PER_PAGE = 20;

const initialState = {
  page: 1,
  rowsPerPage: ROWS_PER_PAGE,
  // orderBy: 'createdAt',
  direction: 'desc',
  contracts: [],
  total: 0,
};

const SEARCH_OPTIONS = [
  { sortable: true, id: 'adsType', label: '타입' },
  { sortable: false, id: 'productName', label: '광고명' },
  { sortable: true, id: 'platform', label: '이용채널' },
];

const Contracts = props => {
  const searchOptions = useRef({
    selectItem: '',
    findText: '',
  });

  const [state, setState] = useState(initialState);
  const [open, setOpen] = useState(false);
  const { checkList, setCheckList } = useCheckList();

  const header = [
    {
      sortable: false,
      id: 'checkbox',
      label: '',
      component: (
        <Checkbox
          onChange={event => {
            onChangeHandler(event);
          }}
        />
      ),
    },
    { sortable: true, id: 'adsType', label: '타입' },
    { sortable: false, id: 'productName', label: '광고명' },
    { sortable: true, id: 'platform', label: '이용채널' },
    { sortable: true, id: 'deliveryMethod', label: '배송 방식' },
    { sortable: true, id: 'currentContractStatus', label: '상태' },
    { sortable: false, id: 'purchaseImgUrl', label: '구매 인증' },
    { sortable: true, id: 'user', label: '유저' },
    { sortable: true, id: 'createdAt', label: 'createdAt' },
  ];

  const fetch = async page => {
    const result = await getContracts({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),
    });

    setState({
      ...state,
      contracts: [...result.data.data.contracts],
      page: page,
      total: result.data.data.totalRows,
    });

    setCheckList(
      result.data.data.contracts.map(item => {
        const { contract } = item;
        return { id: contract.id, checked: false };
      })
    );
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleSearch = (selectItem, findText) => {
    searchOptions.current = {
      selectItem,
      findText,
    };

    fetch(1);
  };

  const onChangeHandler = (event, contract) => {
    const check = event.target.checked;

    if (contract && contract.currentContractStatus !== 'CANCELLED') {
      setCheckList(checkList => checkList.map(item => (item.id == contract.id ? { ...item, checked: check } : item)));
    } else {
      setCheckList(
        state.contracts.reduce((acc, cur) => {
          const { contract } = cur;
          if (contract.currentContractStatus !== 'CANCELLED') {
            acc.push({ id: contract.id, checked: check });
          } else {
            acc.push({ id: contract.id, checked: false });
          }
          return acc;
        }, [])
      );
    }
  };

  const handleConfirm = async () => {
    const selectedContracts = checkList.reduce((acc, cur) => {
      if (cur.checked) acc.push(cur.id);
      return acc;
    }, []);

    if (selectedContracts.length === 0) {
      return;
    }

    const result = await cancelContracts(selectedContracts);

    if (result.data.success) {
      alert('이용내역이 취소되었습니다.');
      window.location.reload();
    }
  };

  const body = state.contracts.map((contractInfo, index) => {
    const image = new Image();
    const { contract, user } = contractInfo;

    image.src = contract.purchaseImgUrl; // 미리 로드할 이미지

    return (
      <TableRow key={index}>
        <TableCell>
          <Checkbox
            checked={(checkList[index] && !!checkList[index].checked) || false}
            onChange={event => onChangeHandler(event, contract)}
            disabled={contract.currentContractStatus === 'CANCELLED'}
          />
        </TableCell>
        <TableCell>{contract.adsType}</TableCell>
        <TableCell>{contract.productName}</TableCell>
        <TableCell>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContents: 'center',
            }}>
            <img
              style={{
                width: 24,
                height: 24,
                marginRight: 4,
              }}
              src={contract.channel === 'TIKTOK' ? LOGO_IMAGE.TIKTOK : LOGO_IMAGE.INSTAGRAM}
            />
            <span>{contract.channel}</span>
          </div>
        </TableCell>
        <TableCell>{contract.deliveryMethod}</TableCell>
        <TableCell>{contract.currentContractStatus}</TableCell>
        <TableCell>
          {contract.purchaseImgUrl && (
            <CustomModal
              title=""
              content={
                <img
                  src={contract.purchaseImgUrl}
                  alt="purchaseLinkImage"
                  style={{ width: '100%', height: '100%' }}
                  loading="lazy"
                />
              }
              buttonIcon={<InsertPhoto />}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            />
          )}
        </TableCell>
        <TableCell>
          <Link to={'/cosduck/user?id=' + contract.uid} target="_blank">
            [{contract.userLevel}]{contract.uid}
            <br />
            {user.firstName} {user.lastName}
            <br />
            {user.email}
          </Link>
        </TableCell>
        <TableCell>{momentLib(contract.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
        <TableCell>
          <Link to={'/cosduck/contract?id=' + contract.id} target="_blank">
            편집
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const pageCount = parseInt(state.total / state.rowsPerPage, 10) + (state.total % state.rowsPerPage === 0 ? 0 : 1);

  const handleChangePage = event => {
    fetch(event.selected + 1);
  };

  return (
    <div className="table-container">
      <SearchInput
        header={SEARCH_OPTIONS}
        handleSearch={handleSearch}
        customComponents={[
          <div
            style={{
              display: 'flex',
              flex: 1,
              margin: 8,
              gap: 8,
            }}>
            <Button key="changeButton" variant="contained" color="secondary" onClick={() => setOpen(true)}>
              이용내역 취소
            </Button>
          </div>,
        ]}
      />
      <CustomDialog
        title="이용내역 취소 확인"
        content="이용내역을 취소하시겠습니까?"
        open={open}
        setOpen={setOpen}
        handleConfirm={handleConfirm}
      />
      <Table>
        <SortableTableHead order={state.direction} columns={header} />
        <TableBody>{body}</TableBody>
      </Table>
      <div className="table-footer">
        <div />
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={handleChangePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
