import React, { Component } from 'react';

import momentLib from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

class InfoLog extends Component {
  state = {
    logs: [],
  };

  render() {
    const { classes } = this.props;
    const { logs } = this.state;

    if (logs.length > 0) {
      let items = logs.map((log, idx) => {
        log.date = momentLib(log.date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');

        return (
          <ExpansionPanel style={{ minWidth: '100%' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <h4>
                  <span
                    style={{
                      color: '#4770bd',
                    }}>{`${(log.authenticatedUser || {}).email || '유저'}(${log.ip.regionName + ' ' + log.ip.city})`}</span>
                  {'가 '}
                  <span style={{ color: '#bd1328' }}>{`${log.headers.origin || '앱'}`}</span>
                  {'에서 '}
                  <span
                    style={{
                      color: '#a0a0a0',
                    }}>{`${momentLib(log.date).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}`}</span>
                  {'에 '}
                  <span
                    style={{ color: '#1ec800' }}>{`${{ POST: '등록', PUT: '수정', DELETE: '삭제' }[log.method]}`}</span>
                  {'하였습니다.'}
                </h4>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'block' }}>
              <div>
                <div>
                  <h4>ip</h4>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>{log.ip.query + ' ' + log.ip.regionName + ' ' + log.ip.city}</div>
                </div>
                <div>
                  <h4>UA</h4>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(log.ua.browser)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(log.ua.engine)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(log.ua.os)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(log.ua.device)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(log.ua.cpu)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
                <div>
                  <h4>내용</h4>
                </div>
                <div
                  style={{
                    wordBreak: 'break-all',
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>{JSON.stringify(log.mergeParams, null, 2)}</div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>수정로그</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block', paddingLeft: 0, paddingRight: 0 }}>
            {items}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  formatParams = params => {
    if (params === null || params === undefined) {
      return '';
    }
    return Object.entries(params)
      .map(([key, value]) => {
        if (typeof value === 'object') {
          return `${key}: ${this.formatParams(value)}`;
        } else {
          return `${key}: ${value}`;
        }
      })
      .join(', ');
  };

  async componentDidMount() {
    let api = require(`../../app/${this.props.type}/api`);

    const logs = await api.getLog(this.props.id);
    console.log(logs);
    this.setState({ logs });
  }
}

export default withStyles(styles)(InfoLog);
