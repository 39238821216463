import React, { useState } from 'react';
import CustomTextField from '../../../components/CustomTextField';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import Typo from '../../../components/Typo';
import { Color } from '../../../constants/color';
import { IconButton } from '@material-ui/core';

/**
 *
 * @param {Array} list 기존에 존재하는 배열
 * @param {Object} layoutStyle 가장 바깥 div의 스타일
 * @param {Boolean} multiline 줄바꿈 허용
 * @param {Number} rows row 수
 * @param {function} onAddHandler 새로운 원소를 추가하는 함수
 * @param {function} onRemoveHandler 기존의 원소를 삭제하는 함수
 */

const ModifiableList = props => {
  const label = props.label;
  const [newElement, setNewElement] = useState('');
  const [newElement2, setNewElement2] = useState('');
  const listElements = props.list.map((item, idx) => {
    console.log(item);
    if (label === 'referenceVideoUrls') {
      return (
        <div
          className="row"
          key={idx}
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Typo typography="Detail_1" fontWeight="500" color={Color.label_type1}>
              {`Reference Video ${idx + 1}`}
            </Typo>
            <IconButton
              style={{ width: '36px', height: '36px' }}
              onClick={() => {
                props.onRemoveHandler(idx);
              }}>
              <RemoveCircleOutline />
            </IconButton>
          </div>
          <CustomTextField
            showModifiedStatus={false}
            value={item.url}
            label="URL"
            fullWidth
            layoutStyle={{ flex: 1 }}
            {...props.textFieldStyle}
            {...(props.multiline && { multiline: props.multiline })}
            {...(props.rows && { rows: props.rows })}
          />
          <CustomTextField
            showModifiedStatus={false}
            value={item.description}
            label="Description"
            fullWidth
            layoutStyle={{ flex: 1 }}
            {...props.textFieldStyle}
            {...(props.multiline && { multiline: props.multiline })}
            {...(props.rows && { rows: props.rows })}
          />
        </div>
      );
    }
    return (
      <div className="row" key={idx} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <CustomTextField
          showModifiedStatus={false}
          value={item}
          fullWidth
          layoutStyle={{ flex: 1 }}
          {...props.textFieldStyle}
          {...(props.multiline && { multiline: props.multiline })}
          {...(props.rows && { rows: props.rows })}
          rightIcon={<RemoveCircleOutline />}
          rightIconButtonHandler={() => {
            props.onRemoveHandler(idx);
          }}
        />
      </div>
    );
  });
  return (
    <div style={props.layoutStyle}>
      <Typo
        style={{ marginTop: '20px', marginBottom: '6px' }}
        typography="Detail_1"
        fontWeight="500"
        color={Color.label_type1}>
        {label}
      </Typo>
      {listElements}
      {label === 'referenceVideoUrls' ? (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Typo typography="Detail_1" fontWeight="500" color={Color.label_type1}>
              {`Reference Video Add`}
            </Typo>
            <IconButton
              style={{ width: '36px', height: '36px' }}
              onClick={() => {
                props.onAddHandler({
                  url: newElement,
                  description: newElement2,
                });
                setNewElement('');
                setNewElement2('');
              }}>
              <AddCircleOutline />
            </IconButton>
          </div>
          <CustomTextField
            showModifiedStatus={false}
            value={newElement}
            label="URL"
            onChange={evt => {
              setNewElement(evt.target.value);
            }}
            layoutStyle={{ flex: 1 }}
            {...(props.multiline && { multiline: props.multiline })}
            {...(props.rows && { rows: props.rows })}
            {...props.textFieldStyle}
            fullWidth
          />
          <CustomTextField
            showModifiedStatus={false}
            value={newElement2}
            label="Description"
            onChange={evt => {
              setNewElement2(evt.target.value);
            }}
            layoutStyle={{ flex: 1 }}
            {...(props.multiline && { multiline: props.multiline })}
            {...(props.rows && { rows: props.rows })}
            {...props.textFieldStyle}
            fullWidth
          />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <CustomTextField
            showModifiedStatus={false}
            value={newElement}
            onChange={evt => {
              setNewElement(evt.target.value);
            }}
            layoutStyle={{ flex: 1 }}
            {...(props.multiline && { multiline: props.multiline })}
            {...(props.rows && { rows: props.rows })}
            {...props.textFieldStyle}
            rightIcon={<AddCircleOutline />}
            rightIconButtonHandler={() => {
              props.onAddHandler(newElement);
              setNewElement('');
            }}
            fullWidth
          />
        </div>
      )}
    </div>
  );
};

export default ModifiableList;
