import React, { Component } from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

import { fetchData } from '../../app/api';
import Card from './card';
import './cards.css';

class DeliveryCards extends Component {
  state = {
    propositions: [],
    campaigns: [],
    options: {},
    total: 0,
    filter: 0,
  };
  async componentDidMount() {
    const { campaign, user } = this.props;
    const query = {
      orderBy: 'createdAt',
      direction: 'desc',
    };
    let forWho = null;
    if (campaign !== undefined) {
      query.campaignId = campaign;
      forWho = 'campaign';
    }
    if (user !== undefined) {
      query.uid = user;
      forWho = 'user';
    }
    query.include = ['user'];

    try {
      let datas = await fetchData('get', '/proposition/console', query);
      datas.propositions = datas.propositions.filter(proposition => proposition.status >= 10 && proposition.delivery);
      datas.total = datas.propositions.length;
      this.setState({ ...datas, forWho });

      let options = await fetchData('get', '/company/option', { type: 'courier' });
      console.log(options);
      this.setState({ options: options });
    } catch (e) {
      console.error(e);
      alert('제안내역 조회 실패');
    }
  }
  render() {
    const { campaignName } = this.props;
    const { propositions, total, forWho, campaigns, filter, options } = this.state;
    let deliveredCnt = 0,
      requestCnt = 0,
      responseCnt = 0,
      cancelCnt = 0,
      doneCnt = 0;
    let cards = propositions.map(proposition => {
      let type = 0;
      if (proposition.deliveryHistory.length > 0) {
        type = proposition.deliveryHistory[proposition.deliveryHistory.length - 1].state;
        if (type === 1) {
          //발송완료
          deliveredCnt++;
        } else if (type === -2) {
          //정보수정요청
          requestCnt++;
        } else if (type === -1) {
          //정보수정완료
          responseCnt++;
        } else if (type === 10) {
          //이용취소
          cancelCnt++;
        } else {
          //신청완료
          doneCnt++;
        }
      }
      if (
        (filter === 1 && type === 0) ||
        (filter === 2 && type === 1) ||
        (filter === 3 && type === -2) ||
        (filter === 4 && type === -1) ||
        (filter === 5 && type === 10) ||
        filter === 0
      ) {
        return (
          <Card
            key={proposition.id || proposition.rKey}
            proposition={proposition}
            forWho={forWho}
            companies={campaigns}
            options={options}
          />
        );
      } else {
        return null;
      }
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{campaignName === undefined ? '유저 배송 정보' : `${campaignName}   배송 정보`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="proposition-cards">
            <div>
              <Tabs value={filter} onChange={this.handleFilterChange}>
                <Tab label={'전체(' + total + ')'} style={{ minWidth: 140 }} />
                <Tab label={'신청완료(' + doneCnt + ')'} style={{ minWidth: 140 }} />
                <Tab label={'발송완료(' + deliveredCnt + ')'} style={{ minWidth: 140 }} />
                <Tab label={'정보수정요청(' + requestCnt + ')'} style={{ minWidth: 140 }} />
                <Tab label={'정보수정완료(' + responseCnt + ')'} style={{ minWidth: 140 }} />
                <Tab label={'이용취소(' + cancelCnt + ')'} style={{ minWidth: 140 }} />
              </Tabs>
            </div>
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  handleFilterChange = (event, filter) => {
    this.setState({ filter });
  };
}

export default DeliveryCards;
