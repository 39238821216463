import axios from 'axios';

/**
 * Contracts 요청
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @returns {Report[]} Contract 목로
 */

export const getReport = query => {
  const payloadString = Object.entries(query)
    .map(e => e.join('='))
    .join('&');

  let baseURL = `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/report-issues`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const updateReportIssue = (issueId, completed) => {
  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/report-issues/${issueId}`, {
    completed,
  });
};
