import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class NumberEditor extends React.Component {
  state = {};
  render() {
    return (
      <div className="row">
        <label>{this.props.field.name}</label>
        <TextField
          fullWidth
          type="number"
          value={this.props.field.value ? this.props.field.value : 0}
          onChange={evt => this.props.handleChange(parseInt(evt.target.value))}
        />
      </div>
    );
  }
}
