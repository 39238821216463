import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import * as api from '../api';

class InfoPush extends PureComponent {
  render() {
    const { company, pushState, handleSave } = this.props;
    if (company) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>푸시</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <label>적립/배송완료</label>
                <TextField
                  placeholder={'Title'}
                  value={((pushState[0] || {}).notification || {}).title || ''}
                  onChange={evt => this.handleChange(evt.target.value, 0, 'title')}
                  fullWidth
                />
                <TextField
                  placeholder={'Body'}
                  value={((pushState[0] || {}).notification || {}).body || ''}
                  onChange={evt => this.handleChange(evt.target.value, 0, 'body')}
                  fullWidth
                  multiline
                />
                <label>이용완료</label>
                <TextField
                  placeholder={'Title'}
                  value={((pushState[1] || {}).notification || {}).title || ''}
                  onChange={evt => this.handleChange(evt.target.value, 1, 'title')}
                  fullWidth
                />
                <TextField
                  placeholder={'Body'}
                  value={((pushState[1] || {}).notification || {}).body || ''}
                  onChange={evt => this.handleChange(evt.target.value, 1, 'body')}
                  fullWidth
                  multiline
                />
              </section>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button color="secondary" onClick={() => handleSave()}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    const { company, onPushChange } = this.props;
    const tempPush = [];

    tempPush.push(await api.getPush('2.1.' + company));
    tempPush.push(await api.getPush('2.5.' + company));

    onPushChange(tempPush);
  }
  handleChange = (value, idx, type) => {
    const { pushState, onPushChange } = this.props;
    const tempPush = [...pushState];

    if (tempPush[idx]) {
      tempPush[idx]['notification'][type] = value;
    } else {
      tempPush[idx] = { notification: {} };
      tempPush[idx]['notification'][type] = value;
    }
    onPushChange(tempPush);
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoPush);
