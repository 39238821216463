import React from 'react';

const styles = {
  container: {
    cursor: 'pointer',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    marginBottom: '10px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  text: {
    fontSize: '14px',
    color: '#555',
  },
  secondaryText: {
    fontSize: '14px',
    color: '#888',
  },
};

const DeliveryItem = ({ item, onPressHandler, containerStyle }) => {
  return (
    <div onClick={onPressHandler} style={{ ...styles.container, ...containerStyle }}>
      <div style={styles.title}>{item.label}</div>
      <div style={styles.text}>
        {item.firstName} {item.lastName} · {item.countryCode} {item.phoneNumber}
      </div>
      <div style={styles.secondaryText}>
        {item.streetAddress1}, {item.streetAddress2}
      </div>
      <div style={styles.secondaryText}>
        {item.city}, {item.state} {item.zipCode}
      </div>
    </div>
  );
};

export default DeliveryItem;
