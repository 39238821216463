import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import Typo from '../../../components/Typo';
import ImageGallery from '../../../components/ImageList';

import momentLib from 'moment-timezone';
import { Color } from '../../../constants/color';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const InquiryQnALists = ({ inquiries }) => {
  const [expandedIdList, setExpandedIdList] = useState([]);

  if (!inquiries) return null;

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typo typography={'Headline_3'} fontWeight={600}>
          1:1문의 내역
        </Typo>
      </ExpansionPanelSummary>

      {inquiries.map(inquiry => {
        return (
          <ExpansionPanel
            defaultExpanded={false}
            onChange={() => {
              if (expandedIdList.includes(inquiry.id)) {
                setExpandedIdList(prev => prev.filter(id => id !== inquiry.id));
              } else {
                setExpandedIdList(prev => [...prev, inquiry.id]);
              }
            }}
            style={{ boxShadow: 'none' }}>
            <ExpansionPanelSummary
              style={{ margin: '20px 0 20px 0', padding: '0 16px 0 16px' }}
              expandIcon={<ExpandMoreIcon />}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '4px',
                }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ display: 'flex', gap: '8px' }}>
                    <Typo typography={'Body1'}>
                      {momentLib.tz(inquiry.createdAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')}
                    </Typo>

                    <div
                      style={{
                        display: 'flex',
                        color:
                          inquiry.contentImages && inquiry.contentImages.length > 0
                            ? Color.photoState_icon_active
                            : Color.photoState_icon_inactive,
                      }}>
                      <Typo typography={'Body1'}>사진</Typo>
                      <InsertPhotoIcon />
                    </div>

                    <a
                      href={`/inquiry?id=${inquiry.id}`}
                      target="_blank"
                      style={{
                        textDecoration: 'none',
                        color: Color.text_secondary,
                      }}>
                      <div
                        style={{
                          display: 'flex',
                        }}>
                        <Typo typography={'Body1'} style={{}}>
                          상세보기
                        </Typo>
                        <OpenInNewIcon />
                      </div>
                    </a>
                  </div>

                  {inquiry.isAnswered ? (
                    <Typo typography={'Body1'} color={Color.statustext_complete}>
                      답변 완료
                    </Typo>
                  ) : (
                    <Typo typography={'Body1'} color={Color.statustext_pending}>
                      답변 대기중
                    </Typo>
                  )}
                </div>

                {!expandedIdList.includes(inquiry.id) ? (
                  <Typo
                    typography={'Body1'}
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}>
                    {inquiry.contentText}
                  </Typo>
                ) : (
                  <Typo typography={'Body1'}>{inquiry.contentText}</Typo>
                )}
              </div>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails style={{ padding: '0 16px 0 16px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  gap: '4px',
                }}>
                <ImageGallery images={inquiry.contentImages || []} style={{ marginBottom: '24px' }} />

                {inquiry.App_Inquiry_Answer.length > 0 && (
                  <Typo typography={'Headline_3'} fontWeight={600}>
                    답변 내용
                  </Typo>
                )}
                {inquiry.App_Inquiry_Answer.map(inquiryList => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: '4px',
                        marginBottom: '20px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Typo typography={'Body1'}>
                          {momentLib.tz(inquiryList.createdAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')}
                        </Typo>

                        {inquiryList.isRead ? (
                          <Typo typography={'Body1'} color={Color.statustext_complete}>
                            {momentLib.tz(inquiryList.readAt, 'Asia/Seoul').format('YYYY-MM-DD HH:mm')} 읽음
                          </Typo>
                        ) : (
                          <Typo typography={'Body1'} color={Color.statustext_pending}>
                            안읽음
                          </Typo>
                        )}
                      </div>
                      <Typo typography={'Body1'}>{inquiryList.contentText}</Typo>
                      <ImageGallery images={inquiryList.contentImages || []} />
                    </div>
                  );
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </ExpansionPanel>
  );
};

export default InquiryQnALists;
