import Switch from '@material-ui/core/Switch';
import { Edit } from '@material-ui/icons';
import React, { useRef, useState } from 'react';
import Typo from './Typo';
import { Color } from '../constants/color';
import { makeStyles, Tooltip } from '@material-ui/core';

const CustomSwitch = prop => {
  const {
    label,
    checked,
    onChange,
    layoutStyle = {},
    labelStyle = {},
    showModifiedStatus = true,
    tooltip = '',
    ...props
  } = prop;
  const originalValue = useRef(checked || false);
  const [modified, setModified] = useState(false);

  const onChangeHandler = event => {
    if (originalValue.current === event.target.checked) {
      setModified(false);
    } else {
      setModified(true);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const useStyles = makeStyles({
    switch: {
      '& .MuiSwitch-switchBase': {
        color: Color.toggle_handle_inactive,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: Color.toggle_handle_active,
      },
      '& .MuiSwitch-colorSecondary.Mui-disabled': {
        color: Color.toggle_handle_inactive,
        // backgroundColor: Color.toggle_background_inactive,
      },
    },
  });

  const classes = useStyles();

  return (
    <div style={{ ...layoutStyle, marginTop: '20px', marginBottom: '6px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <Typo typography="Detail_1" fontWeight="500" color={Color.label_type1}>
          {label}
        </Typo>
        {showModifiedStatus && modified && (
          <span
            style={{
              marginLeft: 10,
              fontSize: 9,
              fontFamily: 'Pretendard-SemiBold',
              color: '#8BC34A',
            }}>
            <span>수정됨</span>
            <Edit fontSize="inherit" />
          </span>
        )}
      </div>

      <Tooltip title={tooltip} arrow disableHoverListener={!tooltip}>
        <Switch checked={checked} onChange={onChangeHandler} className={classes.switch} {...props} />
      </Tooltip>
    </div>
  );
};

export default CustomSwitch;
