import React, { useState } from 'react';
import AdsCarousel from '../../AdsCarousel';
import { Button } from '@material-ui/core';
import { addAdsToExistingGroup, deleteAdsFromGroup } from '../../../api/ads';

const ModifyAdsGroup = ({ ads, groupId, selectedAdIds, isModifying, setIsModifying }) => {
  const [deleteAds, setDeleteAds] = useState([]);

  const handleAdd = async () => {
    const result = await addAdsToExistingGroup(groupId, selectedAdIds);

    if (result.data.success) {
      alert('현재 그룹에 추가되었습니다.');
      window.location.reload();
    }
  };

  const handleDelete = async () => {
    const result = await deleteAdsFromGroup(deleteAds);

    if (result.data.success) {
      alert('현재 그룹에서 제거되었습니다.');
      window.location.reload();
    }
  };

  const handleGroupSelect = adId => {
    if (deleteAds.includes(adId)) {
      setDeleteAds(deleteAds.filter(id => id !== adId));
    } else {
      setDeleteAds([...deleteAds, adId]);
    }
  };

  return (
    <>
      <div style={styles.carouselContainer}>
        <AdsCarousel ads={ads} showCheckbox={true} checkList={deleteAds} onChange={handleGroupSelect} />

        <div style={styles.buttonContainer}>
          <Button
            style={styles.notSelectedButton}
            onClick={() => {
              setIsModifying(!isModifying);
            }}>
            편집 취소
          </Button>
        </div>
      </div>

      <div style={{ ...styles.buttonContainer, padding: 8 }}>
        <Button style={{ height: '40px' }} onClick={handleAdd} disabled={selectedAdIds.length === 0}>
          현재 그룹에 추가
        </Button>
        <Button disabled={deleteAds.length === 0} style={{ height: '40px' }} onClick={handleDelete}>
          그룹에서 제거
        </Button>
      </div>
    </>
  );
};

const styles = {
  buttonContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  carouselContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    padding: 16,
    border: '1px solid #e0e0e0',
    borderRadius: 8,
  },
  selectedButton: {
    borderRadius: 16,
    backgroundColor: '#1976d2',
    color: '#fff',
  },

  notSelectedButton: {
    borderRadius: 16,
    border: '1px solid #e0e0e0',
    color: '#757575',
  },
};

export default ModifyAdsGroup;
