import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import * as api from './api';
import ActionCreators from '../action';
import { SearchInput, SortableTableHead } from '../../components';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Typography from '@material-ui/core/Typography';

const header = [
  { sortable: false, id: 'type', label: '구분', filter: ['전체', '매장', '제품-표준형', '제품-실속형'] },
  { sortable: false, id: 'name', label: '가맹점명' },
  { sortable: false, id: 'payStep', label: '결제 단계', filter: ['전체', '유료', '유료전환', '무료'] },
  { sortable: false, id: 'currentState', label: '현재차수 결제상태', filter: ['전체', '결제요청', '결제완료'] },
  {
    sortable: false,
    id: 'scheduledState',
    label: '예정차수 결제상태',
    filter: ['전체', '결제예정', '결제완료', '결제중지', '중지 후 재시작'],
  },
  { sortable: false, id: 'paidDate', label: '결제 시작일' },
  { sortable: false, id: 'scheduledDate', label: '결제 예정일' },
  { sortable: false, id: 'contractCount', label: '이용자 수' },
  { sortable: false, id: 'extend', label: '연장 여부', filter: ['전체', 'true', 'false'] },
  { sortable: false, id: 'feedback', label: '피드백', filter: ['전체', 'true', 'false'] },
  { sortable: false, id: 'edit', label: '정보' },
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  paycards: [],
  total: 0,
};

class CompanyPaycardsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, 'id', 'desc', { [selectItem]: findText });
  };

  handleFilter = sel => {
    let options = { $and: [] };
    sel.forEach((item, idx) => {
      if (item.length > 0) {
        let expr = { $or: [] };
        item.forEach(subitem => {
          if (subitem != '전체') {
            let subexpr = {};
            subexpr[header[idx].id] = subitem;
            expr.$or.push(subexpr);
          }
        });
        if (expr.$or.length > 0) {
          options.$and.push(expr);
        }
      }
    });
    this.fetchDatas(0, 'id', 'desc', { expr: JSON.stringify(options) });
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach(key => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { paycards, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
    };

    api.filterCompanyPayCardsFilter(filter).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, paycards, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = paycards.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.type}</TableCell>
          <TableCell>
            <Link to={'/company3?id=' + item.id} target="_blank">
              {item.name}
            </Link>
          </TableCell>
          <TableCell style={{ color: 'grey' }}>
            <Typography color={item.payStep === '유료전환' ? 'secondary' : ''}>{item.payStep}</Typography>
          </TableCell>
          <TableCell style={{ color: 'grey' }}>
            <Typography color={item.currentState === '결제요청' ? 'secondary' : ''}>{item.currentState}</Typography>
          </TableCell>
          <TableCell>{item.scheduledState} </TableCell>
          <TableCell>{item.paidDate} </TableCell>
          <TableCell>{item.scheduledDate}</TableCell>
          <TableCell>{item.contractCount}</TableCell>
          <TableCell>{item.extend !== undefined ? <Checkbox checked={item.extend} disabled /> : ''}</TableCell>
          <TableCell>
            {item.feedback !== undefined ? (
              <Checkbox
                checked={item.feedback}
                onChange={event => {
                  api.updateCompanyPayCard(item.id, item.channel, {
                    feedback: event.target.checked,
                    id: item.feedbackOrder,
                  });
                  paycards[index].feedback = event.target.checked;
                  this.setState({ paycards: paycards });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            <Link to={'/paycard?id=' + item.id + '&channel=' + item.channel} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} handleFilter={this.handleFilter} />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyPaycardsFilter);
