import React, { useEffect } from 'react';
import { useState } from 'react';
import CustomInput from './CustomInput';

const AddModal = ({ onCloseHandler, type }) => {
  const [target, setTarget] = useState('bitly');
  const [url, setUrl] = useState('');
  const [id, setId] = useState('');
  const [useNaver, setUseNaver] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.keyCode === 27) {
        onCloseHandler();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const generatedURL = async () => {
    setIsLoading(true);

    try {
      let URL = 'https://hashble.com/';

      if (target === 'naver') {
        URL += 'naver';
      } else if (target === 'bitly') {
        URL += 'bitly';
      }

      const result = await fetch(`${URL}`, {
        method: 'POST',
        body: JSON.stringify({
          password: 'phozphoz1!',
          longUrl: url,
          ...(target === 'hashble' && {
            [`${type === 'members' ? 'contractId' : 'propositionId'}`]: id,
            useNaver,
          }),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const parse = await result.json();

      console.log(parse);
    } catch (error) {
      alert(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      onClick={event => {
        if (!!event.target.getAttribute('data-overlay')) {
          onCloseHandler();
        }
      }}
      data-overlay
      style={{
        background: 'rgba(255, 255, 255, 0.7)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(7.4px)',
        WebkitBackdropFilter: 'blur(7.4px)',
        border: '1px solid rgba(255, 255, 255, 0.53)',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {/* Modal */}
      <div
        style={{
          zIndex: 2,
          border: '1px solid #e8ebf2',
          backgroundColor: '#fff',
          boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
          borderRadius: '0.8rem',
          padding: '0.8rem',
          maxWidth: '60%',
        }}>
        {/* Contents */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
          <div style={{ display: 'flex' }}>
            {Object.entries({
              // naver: '네이버 단축 URL',
              bitly: 'bitly 단축 URL',
              hashble: 'hashble 단축 URL',
            }).map(([k, v]) => (
              <div
                style={{
                  cursor: 'pointer',
                  padding: '4px 8px',
                  color: target === k ? '#2a5bd7' : '#273144',
                  fontWeight: 500,
                  borderBottom: `1px solid ${target === k ? '#2a5bd7' : '#fff'}`,
                }}
                onClick={() => {
                  setTarget(k);
                }}>
                {v}
              </div>
            ))}
          </div>
          <CustomInput
            label="longURL"
            description="생성할 longURL"
            onChange={e => {
              setUrl(e.target.value);
            }}
            inputStyle={{ width: '100%' }}
            value={url}
          />
          {target === 'hashble' && (
            <>
              <CustomInput
                label={`${type === 'members' ? 'Contract' : 'Proposition'}Id`}
                inputStyle={{ width: '100%' }}
                value={id}
                onChange={e => {
                  setId(e.target.value);
                }}
              />
              <div>
                <CustomInput
                  label="useNaver"
                  description="check(naver) uncheck(bitly)"
                  inputStyle={{ width: 24, height: 24 }}
                  type="checkbox"
                  checked={useNaver}
                  onChange={e => {
                    setUseNaver(e.target.checked);
                  }}
                />
              </div>
            </>
          )}
        </div>
        {/* Footer */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 8,
            marginTop: '48px',
          }}>
          <button
            onClick={onCloseHandler}
            style={{
              color: '#273144',
              border: '1px solid #dbe0eb',
              padding: '8px 16px',
              backgroundColor: '#fff',
            }}>
            Cancel
          </button>
          <button
            disabled={isLoading}
            onClick={generatedURL}
            style={{
              position: 'relative',
              color: '#fff',
              border: '1px solid #2a5bd7',
              padding: '8px 16px',
              backgroundColor: '#2a5bd7',
            }}>
            {isLoading && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  border: '1px solid #2a5bd7',
                  backgroundColor: '#2a5bd7',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                {[...new Array(3)].map(_ => (
                  <div
                    key={_}
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 8,
                      backgroundColor: '#fff',
                    }}
                  />
                ))}
              </div>
            )}
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddModal;
