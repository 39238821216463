import axios from 'axios';

/**
 * Ad 목록 요청
 * @param {number} query.page ?검색하고 싶은 Page 없으면 모든 Row
 * @param {number} query.pageSize ?가져올 Row 개수
 * @param {number} query.adId ?adId
 * @param {string} query.adsType ?adsType (STANDARD | EXPRESS)
 * @param {string} query.platform ?(INSTAGRAM | TIKTOK)
 * @param {string} query.productName ?제품 이름
 * @param {string} query.companyId ?companyId
 * @returns {Ads[]} Ads[]
 */

export const getAdsAll = (query = {}) => {
  const payloadString = Object.entries(query)
    .map(e => e.join('='))
    .join('&');

  let baseURL = process.env.REACT_APP_COSDUCK_BASE_URL + '/console/ads';

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const getAdsWithDetails = adId => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ad/${adId}`);
};

export const updateAd = (id, body) => {
  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ad/${id}`, {
    ...body,
  });
};

export const getAdsGroupByAdId = id => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ad/${id}/group`);
};

export const getAdsGroupByCompanyId = id => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ads/groups?companyId=${id}`);
};

export const addAdsGroup = (adIds, availableCount) => {
  return axios.post(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ads/groups`, {
    adIds,
    groupName: 'ad Group',
    availableCount,
  });
};

export const mergeAdsGroup = groupIds => {
  return axios.post(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ads/group/merge`, { adsGroupIds: groupIds });
};

export const addAdsToExistingGroup = (groupId, adIds) => {
  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ads/group/${groupId}`, { adIds });
};

export const deleteAdsFromGroup = adIds => {
  return axios.delete(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/ads/groups`, {
    data: { adIds },
  });
};
