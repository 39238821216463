import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import { addHandbook, deleteHandbook, getHandbooks } from '../../api/handbook';

import Guide from './components/Guide';
import HandbookTable from './components/HandbookTable';

const Handbook = props => {
  const [contractHandbooks, setContractHandbooks] = useState([]);
  const [adHandbooks, setAdHandbooks] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getHandbooks();

      const { contract, ad } = result.data.data.handbooks.reduce(
        (acc, cur) => {
          if (cur.category === 'AD') {
            acc.ad.push(cur);
          } else {
            acc.contract.push(cur);
          }

          return acc;
        },
        { contract: [], ad: [] }
      );
      setContractHandbooks(contract);
      setAdHandbooks(ad);
    };

    fetch();
  }, []);

  // const removeContractHandbookRow = (targetIndex) => {
  //   const clone = [...contractHandbooks].filter((_, i) => i !== targetIndex);
  //   setContractHandbooks(clone);
  // };

  // const addContractHandbookRow = () => {
  //   const clone = [...contractHandbooks];
  //   clone.push({ ...inputs });

  //   setContractHandbooks(clone);

  //   setInputs({
  //     header: '',
  //     body: '',
  //   });
  // };

  const onAddHandler = async (category, inputs) => {
    const result = await addHandbook({
      category,
      ...inputs,
    });

    const newHandbook = result.data.data.handbook;

    if (category === 'CONTRACT') {
      const clone = [...contractHandbooks];
      clone.push(newHandbook);
      setContractHandbooks(clone);
    } else {
      const clone = [...adHandbooks];
      clone.push(newHandbook);
      setAdHandbooks(clone);
    }
  };

  const onRemoveHandler = async (category, id) => {
    await deleteHandbook(id);
    if (category === 'CONTRACT') {
      const clone = [...contractHandbooks].filter(handbook => handbook.id !== id);
      setContractHandbooks(clone);
    } else {
      const clone = [...adHandbooks].filter(handbook => handbook.id !== id);
      setAdHandbooks(clone);
    }
  };

  return (
    <div style={{ display: 'flex', gap: 24, margin: '0 24px' }}>
      <div>
        <Guide />
      </div>
      <div style={{ flex: 1 }}>
        <HandbookTable
          title={'이용내역'}
          list={contractHandbooks}
          onAddHandler={onAddHandler.bind(null, 'CONTRACT')}
          onRemoveHandler={onRemoveHandler.bind(null, 'CONTRACT')}
        />

        <HandbookTable
          title={'광고'}
          list={adHandbooks}
          onAddHandler={onAddHandler.bind(null, 'AD')}
          onRemoveHandler={onRemoveHandler.bind(null, 'AD')}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Handbook);
