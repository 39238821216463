import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import InfoBasic from './components/InfoBasic';
import InfoBenefitAndLimit from './components/InfoBenefitAndLimit';
import AdInfo from './components/AdInfo';
import Memo from './components/Memo';
import AdsEtc from './components/AdsEtc';
import ContractList from './components/ContractList';
import { useEffect } from 'react';
import { getAdsWithDetails, updateAd } from '../../api/ads';
import MergeModal from './components/MergeModal';
import { useRef } from 'react';
import HandBook from '../../components/HandBook';
import AdsGroup from './components/AdsGroup';

const Ad = props => {
  const [adsWithDetails, setAdsWithDetails] = useState(null);
  const [diffList, setDiffList] = useState([]);
  const baseUpdatedAt = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await getAdsWithDetails(props.location.query.id);
      setAdsWithDetails(result.data.data);
      baseUpdatedAt.current = result.data.data.ad.updatedAt;
    };

    fetch();
  }, []);

  if (!adsWithDetails) {
    return null;
  }

  const findDifferences = (obj1, obj2, path = '') => {
    let differences = [];

    const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

    for (let key of keys) {
      const fullPath = path ? `${path}.${key}` : key;

      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
        differences = differences.concat(findDifferences(obj1[key], obj2[key], fullPath));
      } else if (obj1[key] !== obj2[key]) {
        differences.push({
          path: fullPath,
          value1: obj1[key],
          value2: obj2[key],
        });
      }
    }

    return differences;
  };

  const compareWithDatabase = current => {
    const currentAdsWithDetails = current;

    const differences = findDifferences({ ...adsWithDetails }, currentAdsWithDetails);

    const findUpdatedAt = differences.find(log => log.path === 'ad.updatedAt');

    const exceptUpdatedAt = differences.filter(log => log.path !== 'ad.updatedAt');

    setDiffList(!!findUpdatedAt ? exceptUpdatedAt : []);
    baseUpdatedAt.current = current.ad.updatedAt;
  };

  const onSaveHandler = async () => {
    try {
      const { id: id1, createdAt: c1, updatedAt: u1, ...ad } = adsWithDetails.ad;
      const { id, adId, createdAt, updatedAt, ...channel } = adsWithDetails.channel;

      const result = await updateAd(props.location.query.id, {
        ad,
        channel,
        baseUpdatedAt: baseUpdatedAt.current,
      });

      if (!result.data.success) {
        console.log(result.data);
        compareWithDatabase(result.data.data.current);
      } else {
        alert('저장되었습니다.');
        window.location.reload();
      }
    } catch (err) {
      console.log(err.response.data);
    }
  };

  return (
    <>
      <MergeModal
        diffList={diffList}
        setDiffList={setDiffList}
        adsWithDetails={adsWithDetails}
        setAdsWithDetails={setAdsWithDetails}
      />
      <HandBook category="AD" />
      <div>
        <div className="container">
          <div className="contents">
            <div className="editor-container">
              <div className="editor">
                <InfoBasic adsWithDetails={adsWithDetails} setAdsWithDetails={setAdsWithDetails} />
              </div>
              <InfoBenefitAndLimit adsWithDetails={adsWithDetails} setAdsWithDetails={setAdsWithDetails} />

              <AdInfo adsWithDetails={adsWithDetails} setAdsWithDetails={setAdsWithDetails} />

              <AdsGroup adsWithDetails={adsWithDetails} />

              <AdsEtc adsWithDetails={adsWithDetails} setAdsWithDetails={setAdsWithDetails} />

              <Memo
                adsWithDetails={adsWithDetails}
                setAdsWithDetails={setAdsWithDetails}
                onSaveHandler={onSaveHandler}
              />
            </div>
            <div className="additional-container">
              <ContractList adId={adsWithDetails.ad.id} title={adsWithDetails.ad.title} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
