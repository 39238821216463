import * as api from '../api';

export function getSchema() {
  return api
    .fetchData('get', '/user/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function filterUsers(query) {
  return api
    .fetchData('get', '/user/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getUser(id) {
  return api
    .fetchData('get', '/user/console', { uid: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateUser(id, json) {
  return api
    .fetchData('put', '/user/console', { uid: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function deleteUser(id) {
  return api
    .fetchData('delete', '/user/console', { uid: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function sendPush(json) {
  return api
    .fetchData('post', '/utils/sendpush', null, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getPushs(id) {
  return api
    .fetchData('get', '/user/push', { uid: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function sendSms(json) {
  return api
    .fetchData('post', '/utils/sendsms', null, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function sendEmail(json) {
  return api
    .fetchData('post', '/utils/sendmail', null, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateVistorCount() {
  return api
    .fetchData('put', '/user/visitorcount')
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function setPostLevel(uid, token, level) {
  return api
    .fetchData('post', '/user/postlevel', null, { uid, token, level })
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function resetPostLevel(uid) {
  return api
    .fetchData('delete', '/user/postlevel', { uid })
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateChartAdd(uid) {
  return api
    .fetchData('put', '/user/chart/add', { uid })
    .then(() => {
      return Promise.resolve();
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getLog(id) {
  let query = {
    bool: {
      must: [
        {
          terms: {
            baseUrl: ['user'],
          },
        },
        {
          multi_match: {
            query: id,
            type: 'phrase',
            fields: ['mergeParams.uid', 'mergeParams.id'],
          },
        },
      ],
    },
  };
  let params = new URLSearchParams({
    query: JSON.stringify(query).replace(/\\n/g, ' '),
  });
  return api
    .fetchData('get', '/log/auto?' + params.toString())
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getMessages(phone) {
  return api
    .fetchData('get', '/utils/sendsms', { to: phone })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
