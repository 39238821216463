import { UnleashClient } from 'unleash-proxy-client';

const UNLEASH_API_ENDPOINT = 'https://eu.app.unleash-hosted.com/eugg0008/api/frontend';
const UNLEASH_APP_NAME = 'supermembers-console';
const UNLEASH_API_TOKEN =
  process.env.NODE_ENV === 'production'
    ? 'newpaymentserver:production.538c33a21474642cdc8eea7c1847c42a218536759a955712ed4c2abb'
    : 'newpaymentserver:development.913524866f8a3c227603634fdf52c80d8e5c026f1d3e90e619f9ca50';
const UNLEASH_REFRESH_INTERVAL = process.env.NODE_ENV === 'development' ? 1 : 10;

const unleash = new UnleashClient({
  url: UNLEASH_API_ENDPOINT,
  clientKey: UNLEASH_API_TOKEN,
  appName: UNLEASH_APP_NAME,
  refreshInterval: UNLEASH_REFRESH_INTERVAL,
});

export const getUnleashValue = key => {
  try {
    return unleash.isEnabled(key);
  } catch (error) {
    return false;
  }
};

let isUnleashStarted = false;
const UNLEASH_INIT_TIMEOUT = 3000;
export const startUnleash = () => {
  if (isUnleashStarted) {
    console.log('Unleash SDK is already started.');
    return Promise.resolve();
  }
  isUnleashStarted = true;

  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('Unleash start timeout'));
    }, UNLEASH_INIT_TIMEOUT);

    unleash.start();

    unleash.on('ready', () => {
      clearTimeout(timer);
      resolve();
    });

    unleash.on('error', error => {
      clearTimeout(timer);
      reject(error);
    });
  });
};

export default unleash;
