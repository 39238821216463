import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';
import momentLib from 'moment-timezone';
import Button from '@material-ui/core/Button/Button';

const header = [
  { sortable: false, id: 'phone', label: '번호' },
  { sortable: false, id: 'user', label: '유저' },
  { sortable: false, id: 'ads', label: '가맹점' },
  { sortable: false, id: 'companies', label: '업체' },
  { sortable: false, id: 'subject', label: '제목' },
  { sortable: false, id: 'content', label: '내용' },
  { sortable: false, id: 'type', label: '매체' },
  { sortable: false, id: 'date', label: '발송일' },
];
const styles = theme => ({
  transparent: {
    backgroundColor: 'transparent',
    color: 'transparent',
  },
  pageItemNone: {
    display: 'none',
  },
});

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  messages: [],
  schema: {},
  startKey: null,
  nextKey: null,
  templates: [],
};

class List extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
  }
  componentDidMount() {
    this.fetchDatas(null, null, null, null);
    api.getTemplates().then(templates => {
      this.setState({ templates: templates });
    });
  }
  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };
  resetState = () => {
    let state = {};
    Object.keys(state).forEach(key => {
      state[key] = undefined;
    });

    this.setState({ ..._.omit(initialState, ['templates']) });
  };
  handleMessageFilter = templateId => {
    this.resetState();
    this.fetchDatas(null, null, null, { templateId: templateId });
  };
  handleChangePage(event) {
    this.fetchDatas(
      event.selected,
      this.state.orderBy,
      this.state.direction,
      _.omit(this.state, Object.keys(initialState))
    );
  }
  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const page = pageNum >= 0 ? pageNum : this.state.page;
    let filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      statusCode: '4000',
      ...options,
    };

    if (pageNum && this.state.nextKey && !this.state.search) {
      filter.startKey = this.state.nextKey;
    }
    api.filterMessages(_.omitBy(filter, _.isUndefined)).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }
  render() {
    const { rowsPerPage, orderBy, direction, messages, total, templates, nextKey } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);
    const { classes } = this.props;
    let bodys = messages.length
      ? messages.map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell>{item.to}</TableCell>
              <TableCell>
                <Link to={'/user?uid=' + (item.user || {}).uid} target="_blank">
                  {(item.user || {}).displayName || ''}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={'/company3?id=' + (item.ads || {}).id} target="_blank">
                  {(item.ads || {}).name}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={'/company-user?id=' + (item.companies || {}).id} target="_blank">
                  {(item.companies || {}).name}
                </Link>
              </TableCell>
              <TableCell>{(item.subject || '').slice(0, 20)}</TableCell>
              <TableCell>{(item.text || '').slice(0, 50)}</TableCell>
              <TableCell>{item.type}</TableCell>
              <TableCell>{momentLib(item.dateCreated).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
              {/*<TableCell>*/}
              {/*  <Link to={'/message?uid=' + item.uid} target="_blank">*/}
              {/*    보기*/}
              {/*  </Link>*/}
              {/*</TableCell>*/}
            </TableRow>
          );
        })
      : '';
    return (
      <div className="table-container">
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
          handleMessageFilter={this.handleMessageFilter}
          templates={templates}
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer" style={{ justifyContent: 'center' }}>
          <Button
            color="secondary"
            onClick={() => {
              this.handleChangePage({ selected: this.state.page });
            }}
            disabled={!nextKey}>
            더보기
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(List));
