import * as api from '../api';

export function getSchema() {
  return api
    .fetchData('get', '/report/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function filterReports(query) {
  return api
    .fetchData('get', '/report', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getReport(id) {
  return api
    .fetchData('get', '/report', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateReport(id, json) {
  return api
    .fetchData('put', '/report', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
