import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const Exposure = ({ contract }) => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelSummary>
        <h3>노출 내역</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div className="row">
            <div style={{ flex: 1 }}>
              <label>{contract.type === 'insta' ? '인스타그램 팔로워' : '블로그 순위'}</label>
              {contract.type === 'insta' ? (
                <TextField fullWidth value={contract.follower} disabled />
              ) : (
                <TextField fullWidth value={contract.rank} disabled />
              )}
            </div>
            <div style={{ flex: 2 }} />
          </div>
          {contract.type === 'insta' && (
            <div className="row">
              <div style={{ flex: 1 }}>
                <label>좋아요</label>
                <TextField fullWidth value={contract.like} disabled />
              </div>
              <div style={{ flex: 1 }}>
                <label>댓글</label>
                <TextField fullWidth value={contract.reply} disabled />
              </div>
              <div style={{ flex: 1 }}>
                <label>영상재생</label>
                <TextField fullWidth value={contract.play} disabled />
              </div>
            </div>
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Exposure;
