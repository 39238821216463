export const FONT_FAMILY_MAP = {
  400: 'Pretendard-Regular',
  500: 'Pretendard-Medium',
  600: 'Pretendard-SemiBold',
};

export const TYPO_MAP = {
  Headline1: {
    fontSize: '24px',
    lineHeight: '32px',
  },
  Headline2: {
    fontSize: '20px',
    lineHeight: '24px',
  },
  Headline3: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  Subline1: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  Subline2: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  Body1: {
    fontSize: '16px',
    lineHeight: '24px',
  },
  Body2: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  Detail1: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  Detail2: {
    fontSize: '10px',
    lineHeight: '12px',
  },
};
