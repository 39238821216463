import React from 'react';
import { Color } from '../constants/color';
import { FONT_FAMILY_MAP, TYPO_MAP } from '../constants/typo';

const Typo = ({
  typography = 'Body2',
  children,
  color = 'inherit',
  style,
  weight, // NOTE : cosduck의 Typo와 fit을 맞추기 위해 weight도 사용할 수 있도록 추가
  fontWeight = '400',
}) => {
  return (
    <div
      style={{
        ...style,
        ...TYPO_MAP[typography],
        fontWeight: weight || fontWeight,
        color,
        fontFamily: FONT_FAMILY_MAP[weight || fontWeight],
        whiteSpace: 'pre-wrap',
      }}>
      {children}
    </div>
  );
};

export default Typo;
