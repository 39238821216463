import React from 'react';
import Typo from '../../../../components/Typo';

const AdCompactList = ({ ads }) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 8,
    }}>
    {ads.map((ad, index) => (
      <a href={`/cosduck/ad?id=${ad.adId}`} style={{ textDecoration: 'none' }}>
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '190px',
            padding: 8,
            border: '1px solid #eee',
            borderRadius: 8,
            gap: 8,
          }}>
          <Typo
            style={{
              fontSize: 12,
              color: '#fff',
              width: '40px',
              padding: 4,
              backgroundColor: '#000',
              borderRadius: 4,
              textAlign: 'center',
            }}>
            {ad.adId}
          </Typo>
          <Typo
            style={{
              color: '#000',
              fontSize: 12,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {ad.title}
          </Typo>
        </div>
      </a>
    ))}
  </div>
);

export default AdCompactList;
