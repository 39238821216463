import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import { fetchData } from '../../app/api';
import { withStyles } from '@material-ui/core/styles/index';

const styles = {
  selectMenu: {
    height: 34,
  },
};

class CompanyEditor extends React.Component {
  state = {
    companies: [],
  };

  componentDidMount() {
    fetchData('get', '/company3')
      .then(result => {
        let companies = {};
        for (let i = 0; i < result.companies.length; i++) {
          companies[String(result.companies[i].id)] = result.companies[i].name;
        }
        this.setState({ companies: companies });
      })
      .catch(err => {
        console.log(err);
        alert('가맹점 목록 조회 실패');
      });
  }

  render() {
    const { field, classes } = this.props;
    const { companies } = this.state;
    const keys = Object.keys(companies);
    if (keys.length > 0) {
      let menus = keys.map(key => {
        return (
          <MenuItem value={key} key={key}>
            {key + ' : ' + companies[key]}
          </MenuItem>
        );
      });
      return (
        <div className="row">
          <FormControl fullWidth>
            <label>{field.name}</label>
            <Select
              classes={{ selectMenu: classes.selectMenu }}
              value={field.value === null ? '' : String(field.value)}
              onChange={evt => this.props.handleChange(evt.target.value)}
              input={<Input name="age" id="company-input" fullWidth />}>
              <MenuItem value="">
                <em>가맹점 선택</em>
              </MenuItem>
              {menus}
            </Select>
          </FormControl>
        </div>
      );
    } else {
      return <div>가맹점 목록 로딩중</div>;
    }
  }
}

export default withStyles(styles)(CompanyEditor);
