import React from 'react';
import { ImageList, ImageListItem } from '@material-ui/core';
import IconButton from './IconButton';
import { Cancel } from '@material-ui/icons';
import { Color } from '../constants/color';

const ImageGallery = ({
  cols = 3,
  rowHeight = 200,
  gap = 8,
  style = {},
  images,
  onDeleteImage,
  showDeleteButton = false,
}) => {
  return (
    <ImageList cols={cols} rowHeight={rowHeight} gap={gap} style={style}>
      {images.map((image, index) => (
        <ImageListItem style={{ position: 'relative' }} key={index}>
          <img
            src={image}
            alt={`contentImage-${index}`}
            onClick={() => window.open(image, '_blank')}
            style={{ cursor: 'pointer' }}
          />
          {showDeleteButton && (
            <IconButton
              style={{ position: 'absolute', top: 5, right: 5 }}
              onClick={() => onDeleteImage(index)}
              color={Color.button_icon_icon_delete}>
              <Cancel size={20} color={Color.button_icon_icon_default} />
            </IconButton>
          )}
        </ImageListItem>
      ))}
    </ImageList>
  );
};

export default ImageGallery;
