import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import * as api from './api';
import * as company3Api from '../company3/api';
import ActionCreators from '../action';
import { SearchInput, SortableTableHead } from '../../components';
import _ from 'lodash';

import momentLib from 'moment-timezone';
import { saveAs } from 'file-saver';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const header = [
  { sortable: true, id: 'id', label: 'ID' },
  { sortable: true, id: 'name', label: '업체명' },
  { sortable: false, id: 'last_login_matching', label: '카테고리' },
  { sortable: true, id: 'contacts', label: '담당자 연락처' },
  { sortable: true, id: 'charger', label: '담당자 명' },
  { sortable: true, id: 'cardCnt', label: '등록 카드 수' },
  { sortable: true, id: 'companyCnt', label: '광고 상품 수' },
  { sortable: true, id: 'recommended', label: '추천인' },
  { sortable: true, id: 'route', label: '방문경로' },
  // { sortable: true, id: "payCnt", label: "결제 내역 수" },
  // { sortable: true, id: "payRequestCnt", label: "결제 요청건" },
  { sortable: true, id: 'nextPaidAt', label: '다음 결제 예정일' },
  { sortable: true, id: 'edit', label: '정보' },
];

const initialState = {
  page: 1,
  rowsPerPage: 100,
  orderBy: 'id',
  direction: 'desc',
  companyUsers: [],
  total: 0,
};

class CompanyUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initialState,
      routes: {},
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  async componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
    const routes = await company3Api.getOptions('type=root');
    this.setState({ routes });
  }

  handleSearch = (selectItem, findText) => {
    this.resetState();
    this.fetchDatas(0, 'id', 'desc', { [selectItem]: findText });
  };

  handleDataInRange = async (startDate, endDate) => {
    if (startDate && endDate) {
      this.props.activateLoading();

      await api.getCompanyUserSettlements().then(result => {
        let settlements = result.settlements.filter(settlement => {
          return (
            settlement.status === 7 &&
            momentLib(settlement.settlementDate).tz('Asia/Seoul').isBetween(startDate, endDate, null, '[]')
          );
        });
        let grouped = _.groupBy(settlements, 'cid');
        let data =
          'id, 업체명, 해당 기간 총 정산금액(수수료 포함), 해당 기간 총 정산금액(수수료 제외), 해당 기간 총 수수료\n';
        Object.keys(grouped).map(cid => {
          let lists = grouped[cid];
          let total = _.sumBy(lists, 'price');
          let stotal = _.sumBy(lists, 'settlementPrice');
          data = data + cid + ',' + lists[0].P_MNAME + ',' + total + ',' + stotal + ',' + (total - stotal) + '\n';
          return null;
        });
        console.log(data);
        let blob = new Blob([data], {
          type: 'text/plain',
          charset: 'utf-8',
        });

        saveAs(blob, ['test' + '.' + 'csv']);
      });

      this.props.deactivateLoading();
    }
  };

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach(key => {
      if (key !== 'routes') state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, _.omit(this.state, ['routes']));
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { companyUsers, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
    };

    api.filterCompanyUsers(filter).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, companyUsers, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = companyUsers.map((item, index) => {
      let categoryText = '';
      let categoryColor = '';

      if (!!item.last_login && !!item.last_login_matching) {
        categoryText = '전체';
        categoryColor = '';
      } else if (!!item.last_login && !item.last_login_matching) {
        categoryText = '멤버스';
        categoryColor = '#1BB000';
      } else if (!item.last_login && !!item.last_login_matching) {
        categoryText = '차트';
        categoryColor = '#FF0055';
      }

      return (
        <TableRow key={index}>
          <TableCell>
            <MoveSiteSell id={item.id}></MoveSiteSell>
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell style={{ color: categoryColor }}>{categoryText}</TableCell>
          <TableCell>{item.contacts}</TableCell>
          <TableCell>{item.charger}</TableCell>
          <TableCell>{item.cardCnt}</TableCell>
          <TableCell>{item.companyCnt}</TableCell>
          {/*
          <TableCell>{item.payCnt}</TableCell> */}
          {/* <TableCell>{item.payRequestCnt}</TableCell> */}
          <TableCell>{item.recommended}</TableCell>
          <TableCell>{item.route === 'None' ? '' : this.state.routes[item.route]}</TableCell>
          <TableCell>{item.nextPaidAt}</TableCell>
          <TableCell>
            <Link to={'/company-user?id=' + item.id} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput
          header={header}
          handleSearch={this.handleSearch}
          handleDataInRange={this.handleDataInRange}
          handleDataInRangeLabel="VAT"
        />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div></div>
        </div>
      </div>
    );
  }
}

class MoveSiteSell extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      token: null,
    };
  }

  render() {
    const { id } = this.props;

    return (
      <div>
        <a
          href="#"
          onClick={async event => {
            let target = event.currentTarget;
            let data = await api.getCompanyUserToken(id);
            this.setState({
              anchorEl: target,
              token: data.token,
            });
          }}>
          {id}
        </a>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={() => {
            this.setState({
              anchorEl: null,
            });
          }}>
          <MenuItem
            selected={false}
            onClick={() => {
              window.open('https://supermembers.co.kr/signin?token=' + this.state.token);
            }}>
            supermembers
          </MenuItem>
          <MenuItem
            selected={false}
            onClick={() => {
              window.open('https://www.superchart.com/signin?token=' + this.state.token);
            }}>
            superchart
          </MenuItem>
        </Menu>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyUsers);
