import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  label: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  select: {
    marginTop: 14,
  },
  selectMenu: {
    height: 34,
  },
};

class EnumEditor extends React.Component {
  state = {
    selected: this.props.field.value,
  };

  handleChange = event => {
    if (event.target.value) {
      this.setState({ selected: event.target.value });
    }
    this.props.handleChange(event.target.value);
  };

  render() {
    const { field, classes } = this.props;
    let f = ['', ...field.enum];
    let fields = f.map((item, index) => {
      return (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      );
    });

    return field.name === 'level' ? (
      <div className="row" style={{ position: 'relative' }}>
        <label>{field.name}</label>
        <div>
          <Select
            classes={{ selectMenu: classes.selectMenu }}
            className={classes.select}
            value={this.state.selected}
            onChange={this.handleChange}>
            {fields}
          </Select>
          <Button onClick={this.handleChange} style={{ color: '#fc03f0' }}>
            차트에 추가
          </Button>
        </div>
      </div>
    ) : (
      <div className="row" style={{ position: 'relative' }}>
        <label>{field.name}</label>
        <Select
          classes={{ selectMenu: classes.selectMenu }}
          className={classes.select}
          value={this.state.selected}
          onChange={this.handleChange}>
          {fields}
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(EnumEditor);
