import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router';

import TextField from '@material-ui/core/TextField';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNew from '@material-ui/icons/OpenInNew';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';

import Switch from '@material-ui/core/Switch/Switch';
import Select from '@material-ui/core/Select/Select';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';

import momentLib from 'moment-timezone';
import _ from 'lodash';
import * as api from '../api';
import MenuItem from '../../company3/editor/ads-info';

class InfoBasic extends React.Component {
  state = {
    company: {},
    payRequestCards: [],
    payRequest: {},
    newMemo: '',
  };
  render() {
    const { channel, cards, paycards } = this.props;
    const { company, newMemo, price } = this.state;

    let memoElements = '';
    if (!_.isEmpty(company[channel])) {
      const memo = company[channel].payInfo_memo || [];
      memoElements = memo.map((item, idx) => {
        return (
          <div className="row" key={idx}>
            <TextField
              value={item}
              onChange={evt => this.handleChange('payInfo', 'memo', evt.target.value, idx)}
              fullWidth
            />
            <IconButton onClick={() => this.handleDelete(idx)}>
              <Remove />
            </IconButton>
          </div>
        );
      });
    }

    let dday = 0;
    if (!_.isEmpty(paycards)) {
      let date = momentLib.tz('Asia/Seoul').format('YYYY-MM-DD');
      dday = momentLib(date).diff(momentLib(paycards[0].adsStart), 'day');
      let paycard = null;
      if (dday < -7 && paycards.length > 1) {
        paycard = paycards[1];
      } else {
        paycard = paycards[0];
      }
      dday = momentLib(date).diff(momentLib(paycard.adsStart), 'day');
    }

    let payRequests = '';
    if (this.state.payRequestCards.length > 0) {
      payRequests = this.state.payRequestCards.map((payRequest, idx) => {
        return (
          <option key={idx} value={payRequest}>
            {payRequest.order + '차'}
          </option>
        );
      });
    }

    if (Object.keys(company).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>결제정보</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>광고 상품</label>
                      <Select
                        value={company[channel].type || ''}
                        onChange={evt => this.handleChange(null, 'type', evt.target.value)}>
                        <option key={0} value={''}>
                          {''}
                        </option>
                        <option key={1} value={'S'}>
                          {'표준형'}
                        </option>
                        <option key={2} value={'N'}>
                          {'실속형'}
                        </option>
                      </Select>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>게시 상태</label>
                      <TextField fullWidth value={company.verified} disabled />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제 요청건</label>
                      <TextField fullWidth value={0} disabled />
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제 수단</label>
                      <Select
                        value={company[channel].payInfo_payMethod || ''}
                        onChange={evt => this.handleChange('payInfo', 'payMethod', evt.target.value)}>
                        <option key={0} value={''}>
                          {''}
                        </option>
                        <option key={1} value={'card'}>
                          {'card'}
                        </option>
                        <option key={2} value={'cash'}>
                          {'cash'}
                        </option>
                        <option key={3} value={'free'}>
                          {'free'}
                        </option>
                      </Select>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제 금액</label>
                      <TextField
                        fullWidth
                        value={company[channel].payInfo_amount}
                        onChange={evt => this.handleChange('payInfo', 'amount', parseInt(evt.target.value))}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>결제 카드</label>
                      <Select
                        value={company[channel].payInfo_card}
                        onChange={evt => this.handleChange('payInfo', 'card', evt.target.value)}>
                        <option key={-1} value={null}>
                          없음
                        </option>
                        {cards.map((card, idx) => {
                          return (
                            <option key={idx} value={card.id}>
                              {card.card_name + card.last4}
                            </option>
                          );
                        })}
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>미결제건 결제</label>
                      <Select
                        value={this.state.payRequestCards.length > 0 ? this.state.payRequestCards[0] : ''}
                        onChange={evt => {
                          this.setState({ payRequest: evt.target.value });
                        }}>
                        {payRequests}
                      </Select>
                      {this.state.payRequest.id ? (
                        <Button color="secondary" onClick={this.handlePay}>
                          결제
                        </Button>
                      ) : (
                        ''
                      )}
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>할부 금액</label>
                      <TextField
                        fullWidth
                        value={company[channel].payInfo_installmentAmount}
                        onChange={evt =>
                          this.handleChange(
                            'payInfo',
                            'installmentAmount',
                            evt.target.value ? Number(evt.target.value) : null
                          )
                        }
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}></div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>Plan</label>
                      <Select
                        value={company[channel].payInfo_plan || ''}
                        onChange={evt => this.handleChange('payInfo', 'plan', evt.target.value)}>
                        <option key={1} value={1}>
                          {1}
                        </option>
                        <option key={3} value={3}>
                          {3}
                        </option>
                        <option key={6} value={6}>
                          {6}
                        </option>
                        <option key={12} value={12}>
                          {12}
                        </option>
                      </Select>
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>Pre Pay Count</label>
                      <TextField
                        fullWidth
                        value={company[channel].payInfo_prePayCnt || 0}
                        onChange={evt => this.handleChange('payInfo', 'prePayCnt', parseInt(evt.target.value))}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>D-day</label>
                      <TextField fullWidth value={dday} disabled />
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}></div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>광고 시작일</label>
                      <TextField fullWidth value={company[channel].payInfo_startPaidAt} disabled />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>광고 종료일</label>
                      <TextField fullWidth value={company[channel].payInfo_endPaidAt} disabled />
                    </div>
                  </div>

                  {company.type === 'A' ? (
                    <div className="row">
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <label>프리미엄 체험단 신청 기한일</label>
                        <TextField
                          fullWidth
                          value={company.periodApply}
                          onChange={evt => this.handleAdsChange('periodApply', parseInt(evt.target.value))}
                        />
                      </div>
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <label>프리미엄 체험단 선정 기한일</label>
                        <TextField
                          fullWidth
                          value={company.periodAccept}
                          onChange={evt => this.handleAdsChange('periodAccept', parseInt(evt.target.value))}
                        />
                      </div>
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <label>프리미엄 체험단 리뷰등록 기한일</label>
                        <TextField
                          fullWidth
                          value={company.periodReview}
                          onChange={evt => this.handleAdsChange('periodReview', parseInt(evt.target.value))}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div>
                      <label>업체</label>
                      <Link to={'/company-user?id=' + company.user_id} target="_blank">
                        <IconButton>
                          <OpenInNew />
                        </IconButton>
                      </Link>
                    </div>
                    <div>
                      <label>가맹점</label>
                      <Link to={'/company3?id=' + company.id} target="_blank">
                        <IconButton>
                          <OpenInNew />
                        </IconButton>
                      </Link>
                    </div>
                  </div>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{ marginTop: 0, marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>인원 제한</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>최소인원 보장</label>
                      <Switch
                        checked={company[channel].payInfo_checkBlogger}
                        disabled={true}
                        onChange={(evt, checked) => this.handleChange('payInfo', 'checkBlogger', checked)}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>최대인원 제한</label>
                      <Switch checked={true} disabled />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>연장 60일 제한 해제</label>
                      <Switch
                        checked={company[channel].payInfo_checkPeriod}
                        onChange={(evt, checked) => this.handleChange('payInfo', 'checkPeriod', checked)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>최소인원</label>
                      <TextField
                        type={'number'}
                        fullWidth
                        value={company[channel].payInfo_checkBloggerCnt}
                        onChange={evt => this.handleChange('payInfo', 'checkBloggerCnt', parseInt(evt.target.value))}
                      />
                    </div>
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>최대인원</label>
                      <TextField
                        type={'number'}
                        fullWidth
                        value={company[channel].number}
                        onChange={evt => {
                          this.handleChange(null, 'number', parseInt(evt.target.value));
                          this.handleChange(
                            'payInfo',
                            'amount',
                            (price.naver.product.N * parseInt(evt.target.value)) / 10
                          );
                        }}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{ marginTop: 0, marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>광고 변경 요청</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <div className="row">
                    <div style={{ flex: 1, minWidth: 0 }}>
                      <label>상품 변경요청</label>
                      <Switch checked={company[channel + 'Next']} disabled />
                    </div>
                  </div>
                  {company[channel + 'Next'] ? (
                    <div className="row">
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <label>광고 상품</label>
                        <Select
                          fullWidth
                          value={company[channel + 'Next'].type}
                          onChange={evt => {
                            company[channel + 'Next'].type = evt.target.value;
                            this.props.handleChange(channel + 'Next', company[channel + 'Next']);
                          }}>
                          <option key={0} value={'N'}>
                            {'제품-실속형'}
                          </option>
                          <option key={1} value={'S'}>
                            {'제품-표준형'}
                          </option>
                        </Select>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {company[channel + 'Next'] ? (
                    <div className="row">
                      <div style={{ flex: 1, minWidth: 0 }}>
                        <label>최대인원</label>
                        <TextField
                          type={'number'}
                          fullWidth
                          value={company[channel + 'Next'].number}
                          onChange={evt => {
                            company[channel + 'Next'].number = parseInt(evt.target.value);
                            this.props.handleChange(channel + 'Next', company[channel + 'Next']);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </section>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel defaultExpanded style={{ marginTop: 0 }}>
            <ExpansionPanelSummary>
              <h3>메모</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  {memoElements}
                  <div className="row">
                    <TextField
                      value={newMemo}
                      onChange={evt => this.setState({ newMemo: evt.target.value })}
                      fullWidth
                    />
                    <IconButton onClick={() => this.handleAdd()}>
                      <Add />
                    </IconButton>
                  </div>
                </section>
                <div className="row" style={{ justifyContent: 'flex-end' }}>
                  <Button color="secondary" onClick={this.handleSave}>
                    저장
                  </Button>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }
  componentDidMount() {
    this.setState({ company: this.props.company });

    let payRequestCards = this.props.paycards.filter(paycard => {
      return paycard.payState == 2;
    });
    if (payRequestCards.length > 0) {
      this.setState({ payRequestCards: payRequestCards });
      this.setState({ payRequest: payRequestCards[0] });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({ company: newProps.company });

    let payRequestCards = newProps.paycards.filter(paycard => {
      return paycard.payState == 2;
    });
    if (payRequestCards.length > 0) {
      this.setState({ payRequestCards: payRequestCards });
      this.setState({ payRequest: payRequestCards[0] });
    }
  }

  handleSave = () => {
    const { channel } = this.props;

    if (this.state.company) {
      console.log(this.state.company);
      if (
        this.state.company[channel].payInfo_installmentAmount &&
        this.state.company[channel].payInfo_installmentAmount < 165000
      ) {
        alert('할부 최소 금액 오류');
        return;
      }
      api.updateCompany(this.state.company.id, this.state.company).then(() => {
        let paycards = this.props.paycards.filter(paycard => {
          return paycard.payState == 0;
        });
        if (paycards.length > 0) {
          let scheduledPaycard = paycards[0];
          scheduledPaycard.amount = this.state.company[scheduledPaycard.channel].payInfo_amount;
          scheduledPaycard.checkBlogger = this.state.company[scheduledPaycard.channel].payInfo_checkBlogger;
          scheduledPaycard.checkBloggerCnt = this.state.company[scheduledPaycard.channel].payInfo_checkBloggerCnt;
          scheduledPaycard.dayOfMonth = this.state.company[scheduledPaycard.channel].payInfo_dayOfMonth;
          scheduledPaycard.extened = this.state.company[scheduledPaycard.channel].payInfo_extended;
          scheduledPaycard.payMethod = this.state.company[scheduledPaycard.channel].payInfo_payMethod;
          scheduledPaycard.checkPeriod = this.state.company[scheduledPaycard.channel].payInfo_checkPeriod;
          scheduledPaycard.installmentAmount = this.state.company[scheduledPaycard.channel].payInfo_installmentAmount;
          scheduledPaycard.plan = this.state.company[scheduledPaycard.channel].payInfo_plan;
          scheduledPaycard.prePayCnt = this.state.company[scheduledPaycard.channel].payInfo_prePayCnt;
          api.updateCompanyPayCard(this.state.company.id, scheduledPaycard.channel, scheduledPaycard).then(() => {
            window.setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        } else {
          window.location.reload();
        }
      });
    }
  };

  handleChange = (child, name, value, idx) => {
    const { channel } = this.props;

    if (this.state.company) {
      if (channel) {
        if (child) {
          if (!this.state.company[channel]) {
            this.state.company[channel] = {};
          }
          if (idx === undefined) {
            this.state.company[channel][child + '_' + name] = value;
          } else {
            this.state.company[channel][child + '_' + name][idx] = value;
          }
        } else {
          this.state.company[channel][name] = value;
        }
      } else {
        this.state.company[name] = value;
      }
      this.setState({ company: this.state.company });
      this.forceUpdate();
    }
  };

  handleAdsChange = (name, value) => {
    console.log('@@ handleAdsChange', name, value);

    if (this.state.company) {
      this.state.company[name] = value;
      this.setState({ company: this.state.company });
      this.forceUpdate();
    }
  };

  handleDelete = idx => {
    const { channel } = this.props;
    const { company } = this.state;

    const memo = company[channel].payInfo_memo || [];

    memo.splice(idx, 1);

    this.handleChange('payInfo', 'memo', memo);
  };

  handleAdd = () => {
    const { channel } = this.props;
    const { company, newMemo } = this.state;

    const memo = company[channel].payInfo_memo || [];

    if (newMemo.length === 0) {
      return;
    }
    memo.push(newMemo);
    this.setState({ newMemo: '' });

    this.handleChange('payInfo', 'memo', memo);
  };

  handlePay = () => {
    if (this.state.payRequest.id) {
      api.createCompanyPayCardPay(this.props.company.id, this.props.channel, this.state.payRequest.id).then(() => {
        window.location.reload();
      });
    }
  };

  async componentDidMount() {
    const price = await api.getOptions('type=price');
    this.setState({ price });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(InfoBasic);
