import * as api from '../api';

export function getSchedule() {
  return api
    .fetchData('get', '/pay/schedule', null)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
