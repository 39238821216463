import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { signIn } from '../api';
import './signin.css';

class AuthScreen extends React.Component {
  state = {
    email: '',
    password: '',
  };
  componentDidUpdate() {
    if (this.props.user !== null) this.props.push('/');
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  tryLogin = () => {
    if (this.state.email.indexOf('@supermembers.co.kr') > 0) {
      signIn(this.state.email, this.state.password)
        .then(() => {
          this.props.login();
        })
        .catch(err => {
          alert(err);
        });
    } else {
      alert('접근이 허용되지 않습니다.');
    }
  };
  render() {
    if (this.props.user !== null) {
      return (
        <div style={{ width: '100%', height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h3>로그인 완료</h3>
          <h3>로딩중...</h3>
        </div>
      );
    } else {
      return (
        <div>
          <div className="signin-box">
            <TextField
              id="email"
              label="이메일"
              value={this.state.email}
              onChange={this.handleChange('email')}
              margin="normal"
              fullWidth
            />
            <TextField
              id="password"
              type="password"
              label="비밀번호"
              value={this.state.password}
              onChange={this.handleChange('password')}
              margin="normal"
              fullWidth
            />
            <Button color="secondary" style={{ marginTop: 40, width: '100%' }} onClick={this.tryLogin}>
              로그인
            </Button>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AuthScreen);
