import React, { PureComponent } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Radio from '@material-ui/core/Radio';

import * as api from '../api';

class InfoCard extends PureComponent {
  render() {
    const { companyUser, cards } = this.props;

    const items = cards.map((card, idx) => {
      return (
        <div
          key={idx}
          style={{ display: 'flex', justifyContent: 'space-between', padding: 12, borderBottom: '1px solid #dbdbdb' }}>
          <div>
            <Radio checked={card.main} value={idx} onChange={this.handleChangeMain} style={{ padding: 0 }} />
            <span>{card.main ? '주결제수단' : ''}</span>
          </div>
          <div>
            <span>{card.card_name}</span>
          </div>
          <div>
            <span>{card.customer_name}</span>
          </div>
          <div>
            <span>{card.last4}</span>
          </div>
          <div>
            <span>{card.status}</span>
          </div>
          <div>
            <IconButton
              style={{ padding: 0 }}
              onClick={() => {
                if (window.confirm('정말 삭제 하시겠습니까')) {
                  api.deleteCompanyUserCard(companyUser.id, card.id);
                  window.location.reload();
                }
              }}>
              <Delete />
            </IconButton>
          </div>
        </div>
      );
    });
    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>{`${companyUser.name}님의 결제수단`}</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>{items}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  handleChangeMain = event => {
    this.props.cards.map((card, idx) => {
      card.main = idx === Number(event.target.value);
      api.updateCompanyUserCard(this.props.companyUser.id, card);
      window.location.reload();
    });
  };
  async componentDidMount() {}
}

export default InfoCard;
