import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';

import InfoBasic from './info-basic';
import InfoLimit from './info-limit';
import AdsBasic from './ads-basic';
import AdsInfo from './ads-info';
import AdsEtc from './ads-etc';
import InfoMemo from './info-memo';
import InfoAdsGroup from './info-adsGroup';
import QuestionAnswer from './question-answer';
import InfoInsta from './info-insta';
import InfoPush from './info-push';
import AdsHistory from './ads-history';

import {
  Editor,
  ContractCards,
  PayCard,
  PlaceKeywordCard,
  KeywordShareCard,
  InfoLog,
  InfoMessage,
} from '../../../components';

import * as api from '../api';
import _ from 'lodash';

class Company3 extends React.Component {
  state = {
    id: this.props.location.query.id,
    company: {},
    adsGroup: undefined,
    snackOpen: false,
    push: [],
  };
  render() {
    const { company, adsGroup, push } = this.state;

    if (company && Object.keys(company).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container contents-editor">
            <InfoBasic company={company} handleChange={this.handleChange} handlePropChange={this.handlePropChange} />
            <InfoLimit
              company={company}
              handleChange={this.handleChange}
              handlePropChange={this.handlePropChange}
              updateCompanyData={this.updateCompanyData}
            />
            <AdsBasic company={company} handleChange={this.handleChange} handlePropChange={this.handlePropChange} />
            <AdsInfo
              company={company}
              handleChange={this.handleChange}
              handlePropChange={this.handlePropChange}
              onRef={ref => (this.adsInfo = ref)}
            />
            <AdsEtc company={company} handleChange={this.handleChange} handlePropChange={this.handlePropChange} />
            {adsGroup !== undefined ? (
              <InfoAdsGroup
                company={company}
                adsGroup={adsGroup}
                handleChange={this.handleChange}
                handlePropChange={this.handlePropChange}
                handleDelete={this.handleDelete}
                handleSave={this.handleSave}
              />
            ) : null}
            <InfoMemo
              company={company}
              handleChange={this.handleChange}
              handlePropChange={this.handlePropChange}
              handleDelete={this.handleDelete}
              handleSave={this.handleSave}
            />
            {company.category.firstName === '매장' ? <PlaceKeywordCard company={company.id} /> : ''}
            <KeywordShareCard company={company.id} category={company.category} />
            <InfoPush
              company={company.id}
              pushState={push}
              onPushChange={this.onPushChange}
              handleSave={this.handlePushSave}
            />
            <InfoMessage type={'company3'} phone={company.contacts} />
            <InfoInsta company={company} />
            <AdsHistory company={company.id} />
            <InfoLog type={'company3'} id={company.id} />
          </div>
          <div className="additional-container">
            <ContractCards company={company.id} companyName={company.name} />
            <QuestionAnswer company={company} />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={4000}
              onClose={() => this.setState({ snackOpen: false })}
              message={<span>업데이트 성공</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => this.setState({ snackOpen: false })}>
                  <i className="material-icons">close</i>
                </IconButton>,
              ]}
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.props.activateLoading();
    api.getCompany(this.state.id).then(result => {
      const company = result.companies[0];
      if (company.requirement && !Array.isArray(company.requirement)) {
        company.requirement = [company.requirement];
      }
      this.setState({ company: company });

      if (company.adsGroupId) {
        api
          .getAdsGroup(company.adsGroupId)
          .then(result => {
            if (result.data !== undefined) {
              const adsGroup = result.data;
              this.setState({ adsGroup });
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.props.deactivateLoading();
          });
      } else {
        this.setState({ adsGroup: null });
        this.props.deactivateLoading();
      }
    });
  }
  handleChange = (name, value) => {
    if (name === 'adsGroup') {
      const adIds = value;
      this.setState({ adsGroup: { ...this.state.adsGroup, adIds } });
    } else {
      this.setState({ company: { ...this.state.company, [name]: value } });
    }
  };
  updateCompanyData = newCompanyData => {
    this.setState({ company: { ...this.state.company, ...newCompanyData } });
  };
  handlePropChange = (name, key, value) => {
    this.setState(prevState => {
      let company = {
        ...prevState.company,
      };
      if (!company[name]) {
        company[name] = {};
      }
      company[name][key] = value;

      return { company };
    });
  };
  onPushChange = newPush => {
    this.setState({ push: newPush });
    this.forceUpdate();
  };
  handleDelete = () => {
    if (this.state.company.naver) {
      alert('삭제할 수 없는 가맹점입니다.');
    } else if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      const { id, company } = this.state;
      api
        .deleteCompany(id)
        .then(() => {
          this.setState({ snackOpen: true });
          this.props.deactivateLoading();
        })
        .catch(err => {
          this.props.deactivateLoading();
          if (err.response && err.response.data) {
            alert(err.response.data);
          } else {
            alert('삭제에 실패했습니다. 다시 확인해주세요.');
          }
        });
    }
  };
  handlePushSave = () => {
    const { company } = this.state;
    const companyId = company.id;
    this.state.push.forEach((push, idx) => {
      if (push && (push.notification || {}).body && (push.notification || {}).body.length > 0) {
        api.updatePush(idx === 0 ? '2.1.' + companyId : '2.5.' + companyId, push);
      } else {
        api.deletePush(idx === 0 ? '2.1.' + companyId : '2.5.' + companyId);
      }
    });
  };

  // -- validate function --
  isValidEmptyStringArray = arr => {
    const emptyStringRegex = /\s/;
    return arr.some(item => emptyStringRegex.test(item));
  };

  isValidNumberStringArray = arr => {
    const numberStringRegex = /^\d+$/;
    return arr.every(item => numberStringRegex.test(item));
  };

  isValidUUIDv4StringArray = arr => {
    if (arr.length !== 1) return false;
    const uuidv4Regex = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidv4Regex.test(arr[0]);
  };

  isInvalidAdIdArr = arr => {
    if (arr.length === 0) return null;
    else if (this.isValidEmptyStringArray(arr)) {
      return '광고그룹 입력값에 공백문자가 있습니다.';
    } else if (this.isValidNumberStringArray(arr) || this.isValidUUIDv4StringArray(arr)) {
      return null;
    } else return '광고그룹 입력값은 1)하나의 adsGroupId 2)하나 이상의 광고ID 중 하나여야 합니다.';
  };
  // -----------------------

  handleSave = async () => {
    const currentAdId = String(this.state.company.id);

    this.props.activateLoading();

    if (!!this.state.company.adsGroupId) {
      if (_.isEmpty(this.state.adsGroup.adIds)) {
        const result = await api.deleteAdsGroup(this.state.company.adsGroupId);
        if (!result.success) {
          const errMessage = result.err.message;
          window.alert(errMessage);
          this.props.deactivateLoading();
          return;
        }
      } else {
        const err = this.isInvalidAdIdArr(this.state.adsGroup.adIds);
        if (err) {
          window.alert(err);
          this.props.deactivateLoading();
          return;
        }

        const result = await api.upsertAdsGroup(this.state.adsGroup);
        if (!result.success) {
          const errMessage = result.err.message;
          window.alert(errMessage);
          this.props.deactivateLoading();
          return;
        }
      }
    } else {
      if (!this.state.adsGroup || _.isEmpty(this.state.adsGroup.adIds)) {
        // DO NOTHING
      } else {
        const err = this.isInvalidAdIdArr(this.state.adsGroup.adIds);
        if (err) {
          window.alert(err);
          this.props.deactivateLoading();
          return;
        }

        if (this.isValidUUIDv4StringArray(this.state.adsGroup.adIds)) {
          const adsGroupId = this.state.adsGroup.adIds[0];
          const result = await api.addAdsToAdsGroup(adsGroupId, Number(currentAdId));
          if (!result.success) {
            const errMessage = result.err.message;
            window.alert(errMessage);
            this.props.deactivateLoading();
            return;
          }
        } else {
          if (!this.state.adsGroup.adIds.includes(currentAdId)) {
            window.alert('신규 광고그룹 생성시에는 광고ID 리스트에 현재 광고ID가 반드시 포함되어야 합니다.');
            this.props.deactivateLoading();
            return;
          }

          const companyId = this.state.company.companyId;
          const adsGroupData = {
            companyId,
            adIds: this.state.adsGroup.adIds,
          };
          const result = await api.upsertAdsGroup(adsGroupData);
          if (!result.success) {
            const errMessage = result.err.message;
            window.alert(errMessage);
            this.props.deactivateLoading();
            return;
          }
        }
      }
    }

    await this.adsInfo.handleAddKeyword();
    await this.adsInfo.handleAddHashtag();

    const { id, company, push } = this.state;

    const data = await api.getCompanyUser(company.companyId);
    if (data.total > 0) {
      let companyUser = data.users[0];
      company.email = companyUser.email;
    }
    company.user_id = company.companyId;

    if ((company.naver || {}).black) {
      company.naver.diamond = company.naver.black;
    }

    if ((company.naver || {}).blackFullPrice) {
      company.naver.diamondFullPrice = company.naver.blackFullPrice;
    }

    if ((company.naver || {}).blackPayment) {
      company.naver.diamondPayment = company.naver.blackPayment;
    }

    if ((company.naver || {}).blackPrice) {
      company.naver.diamondPrice = company.naver.blackPrice;
    }
    if (company.naver) {
      company.naver = _.omit(company.naver, [
        'payInfo_dayOfMonth',
        'payInfo_checkBlogger',
        'payInfo_lastPaidAt',
        'payInfo_startPaidAt',
        'payInfo_checkBloggerCnt',
        'payInfo_endPaidAt',
        'payInfo_amount',
        'payInfo_payMethod',
        'payInfo_extended',
        'payInfo_card',
        'payInfo_plan',
        'payInfo_memo',
        'payInfo_nextPaidAt',
        'payInfo_prePayCnt',
        'payInfo_installmentAmount',
        'payInfo_installmentNumber',
        'payInfo_checkPeriod',
      ]);
    }
    const defaultPromise = [api.updateCompany(id, company)];

    if (
      (company.naver || {}).available === 0 &&
      company.category.firstName === '제품' &&
      company.deliveryMethod === 'A'
    ) {
      const pushChange = () => {
        const itemName =
          company.name.split(']').length > 1
            ? company.name.split(']')[1].split('/')[0].trim()
            : company.name.split('/')[0];
        const tempPush = [...push];

        [0, 1].forEach(idx => {
          if (!tempPush[idx]) {
            tempPush[idx] = { notification: {} };
          }
          tempPush[idx]['notification']['title'] = `${itemName} ${
            idx === 0 ? '제품 발송 완료 안내' : '제품 신청 완료 안내'
          }`;
          tempPush[idx]['notification']['body'] = `${itemName} ${
            idx === 0
              ? '제품 배송이 시작되었습니다.'
              : '제품 신청이 정상적으로 완료되었습니다.제품 배송이 시작되면 별도의 앱 내 푸시로 안내해드립니다. 감사합니다.'
          }`;
        });
        this.setState({ push: tempPush });
      };
      pushChange();
      defaultPromise.push(this.handlePushSave());
    }

    Promise.all(defaultPromise)
      .then(() => {
        this.setState({ snackOpen: true });
        window.location.reload();
        this.props.deactivateLoading();
      })
      .catch(err => {
        this.props.deactivateLoading();
        if (err.response && err.response.data) {
          alert(err.response.data);
        } else {
          alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        }
      });
  };
  handleCancel = () => {
    this.props.push('/companies');
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Company3);
