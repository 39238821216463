import React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import BigCalendar from 'react-big-calendar';
import Moment from 'moment';
import './calendar.css';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as api from './api';
import * as companyApi from '../company3/api';
import * as contractApi from '../contract/api';
import momentLib from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class Schedule extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.context = context;
    this.state = {
      totals: {},
      events: [],
    };
    BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment));

    this.fetchDatas = this.fetchDatas.bind(this);
  }

  componentDidMount() {
    this.fetchDatas();
  }

  fetchDatas() {
    this.props.activateLoading();

    let totals = {};
    let events = [];
    const range = moment.rangeFromInterval('day', -6, new Date());
    const dates = Array.from(range.by('day'));
    // Promise.all([...Array(momentLib(new Date()).endOf('month').date()).keys()].map((i) => {
    Promise.all(
      dates.map(date => {
        return contractApi.filterContracts({
          date0: momentLib(date).tz('Asia/Seoul').format('YYYY-MM-DD'),
        });
      })
    ).then(contracts => {
      contracts.forEach((contract, i) => {
        totals[momentLib(dates[i]).tz('Asia/Seoul').format('DD')] = 'Total ' + contract.total;
      });
      this.setState({ totals: totals });
    });
    companyApi
      .filterCompanies()
      .then(data => {
        data.companies.forEach(company => {
          ['naver', 'insta'].map(channel => {
            if (company[channel]) {
              let date = new Date(company[channel].startDate);
              date.setHours(9, 0, 0);
              events.push({
                title: `[${company.id}]`,
                key: company.id,
                allDay: true,
                start: date,
                end: date,
                channel: channel,
              });
            }
          });
        });

        this.setState({ events: events });
        return Promise.all(
          events.map(event => {
            if ((event || {}).key) {
              return companyApi.filterCompanies({ id: event.key });
            } else {
              return Promise.resolve(null);
            }
          })
        );
      })
      .then(results => {
        results.forEach((result, idx) => {
          if (((result || {}).companies || [])[0]) {
            if (result.companies[0].name) {
              let nameArray = result.companies[0].name.split(']');
              events[idx].title =
                events[idx].title +
                nameArray[nameArray.length - 1] +
                '/' +
                events[idx].channel +
                '/' +
                results[idx].companies[0].contractCount.naver +
                '/' +
                results[idx].companies[0].contractCount.insta;
            }
          }
        });
        this.setState({ events: events });
        this.props.deactivateLoading();
      });
  }

  handleSelectSlot({ start, end }) {
    // this.state.events.push({start: start, end: end, title: "Test"});
    // this.setState({});
  }

  handleSelectEvent(evtObj) {
    const companyId = evtObj.title.split(/[[\]]+/)[1];
    window.open(`/company3?id=${companyId}`);
  }
  EventCommon(props) {
    return <strong>{props.event.title}</strong>;
  }
  EventMonth(props) {
    if (moment(new Date()).add(7, 'd').isSame(moment(props.event.start), 'day')) {
      return <div className="red">{props.event.title}</div>;
    } else if (props.event.title.indexOf('Total ') > -1) {
      return <div className="yellow">{props.event.title}</div>;
    } else {
      return <div className="dot">{props.event.title}</div>;
    }
  }

  DateHeaderMonth(props) {
    if (this.state.totals[props.label]) {
      return (
        <div>
          <span className="left">{this.state.totals[props.label]}</span>
          <span>{props.label}</span>
        </div>
      );
    } else {
      return <div className="dot">{props.label}</div>;
    }
  }

  EventAgenda(props) {
    return <em>{props.event.title}</em>;
  }

  render() {
    return (
      <div className={'schedule'}>
        <BigCalendar
          selectable
          popup
          events={this.state.events}
          // onSelectSlot={this.handleSelectSlot.bind(this)}
          onSelectEvent={this.handleSelectEvent.bind(this)}
          defaultDate={new Date()}
          components={{
            event: this.EventCommon,
            month: {
              dateHeader: this.DateHeaderMonth.bind(this),
              event: this.EventMonth,
            },
            agenda: {
              event: this.EventAgenda,
            },
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
