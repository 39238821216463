import React, { useEffect, useRef, useState } from 'react';
import AdInformation from '../AdInformation';
import Typo from '../../../../components/Typo';

const AdsCarousel = ({ ads, numberOfDisplay = 3, showCheckbox = false, checkList = [], onChange }) => {
  const currentIndex = useRef(0);
  const [displayAds, setDisplayAds] = useState([]);

  const handleNext = () => {
    currentIndex.current = currentIndex.current === ads.length - 1 ? 0 : currentIndex.current + 1;

    const nextDisplayAds = [...displayAds];
    nextDisplayAds.shift();
    nextDisplayAds.push(ads[currentIndex.current + numberOfDisplay]);

    setDisplayAds(nextDisplayAds);
  };

  const handlePrev = () => {
    currentIndex.current = currentIndex.current === 0 ? 0 : currentIndex.current - 1;

    const nextDisplayAds = [...displayAds];
    nextDisplayAds.pop();
    nextDisplayAds.unshift(ads[currentIndex.current]);

    setDisplayAds(nextDisplayAds);
  };

  useEffect(() => {
    setDisplayAds(ads.slice(0, numberOfDisplay));
  }, [ads]);

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
        alignItems: 'center',
        gap: 8,
        width: 48 + numberOfDisplay * 200 + (numberOfDisplay - 1) * 8,
        height: 200,
        paddingLeft: 24,
        paddingRight: 24,
        overflow: 'hidden',
      }}>
      <button
        onClick={handlePrev}
        disabled={currentIndex.current === 0}
        style={{ ...styles.button, position: 'absolute', left: 0 }}>
        <Typo>&lt;</Typo>
      </button>

      {displayAds.length > 0 ? (
        displayAds.map(ad => {
          return (
            <AdInformation
              ad={ad}
              showCheckbox={showCheckbox}
              checkList={checkList.includes(ad.adId)}
              onChange={onChange}
            />
          );
        })
      ) : (
        <Typo style={{ width: '100%', textAlign: 'center', color: '#9e9e9e' }}>그룹이 존재하지 않습니다.</Typo>
      )}

      <button
        onClick={handleNext}
        disabled={currentIndex.current >= ads.length - 1 - numberOfDisplay}
        style={{ ...styles.button, position: 'absolute', right: 0 }}>
        <Typo>&gt;</Typo>
      </button>
    </div>
  );
};

const styles = {
  button: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    zIndex: 1,
  },
};

export default AdsCarousel;
