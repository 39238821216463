import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const styles = {
  label: {
    top: 0,
    left: 0,
    position: 'absolute',
    transform: 'translate(0, 1.5px) scale(0.75)',
    transformOrigin: 'top left',
  },
  switch: {
    marginTop: 8,
    marginLeft: -14,
  },
};

class BooleanEditor extends React.Component {
  render() {
    const { field, classes } = this.props;
    return (
      <div className="row" style={{ position: 'relative' }}>
        {field.name === 'verified' ? <label>광고상태</label> : <label>{field.name}</label>}
        <Switch
          className={classes.switch}
          checked={field.value === null ? false : field.value}
          onChange={(evt, checked) => this.props.handleChange(checked)}
        />
      </div>
    );
  }
}

export default withStyles(styles)(BooleanEditor);
