import React from 'react';

const TagWithPrice = props => {
  const mainColor = '#0080FF';
  return (
    <div>
      <span
        style={{
          marginLeft: 2,
          marginRight: 4,
          color: props.isLocked ? '#808080' : mainColor,
          fontWeight: 500,
        }}>
        {props.type === 'point' ? 'Point' : 'Cashback'}
      </span>
      <span
        style={{
          color: props.isLocked ? '#808080' : mainColor,
        }}>
        ${props.price}
      </span>
    </div>
  );
};

export default TagWithPrice;
