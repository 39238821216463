import * as api from '../api';

export function filterCompanyPoint(query) {
  return api
    .fetchData('get', '/company/user/point', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getCompanyPoint(id) {
  return api
    .fetchData('get', '/company/user/point', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateCompanyPoint(id, json) {
  return api
    .fetchData('put', '/company/user/point', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function deleteCompanyPoint(id) {
  return api
    .fetchData('delete', '/company/user/point', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function filterPropositions(query) {
  return api
    .fetchData('get', '/proposition/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getCompanyUser(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}
export function filterCompanyCharge(query) {
  console.log(query);
  return api
    .fetchData('get', '/company/user/charge', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function filterCompanyPropositions(query) {
  return api
    .fetchData('get', '/company/proposition', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
