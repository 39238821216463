import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import OpenInNew from '@material-ui/icons/OpenInNew';

import * as api from '../api';
import { ListItemIcon } from '../../../components/keyword-share-card/card';
import { Link } from 'react-router';
import momentLib from 'moment-timezone';
import { getCompanyUser } from '../api';

class InfoBasic extends React.Component {
  state = {
    categories: [],
  };
  render() {
    const { company } = this.props;
    const { categories } = this.state;

    const sTypeLabel = company.category.firstName === '매장' ? '표준 체험단' : '선착순 체험단';
    const aTypeLabel = company.category.firstName === '매장' ? '프리미엄 체험단' : '선정형 체험단';

    let firstItems = <MenuItem value={company.category.first}>{company.category.firstName}</MenuItem>;
    let secondItems = <MenuItem value={company.category.second}>{company.category.secondName}</MenuItem>;
    let thirdItems = <MenuItem value={company.category.third}>{company.category.thirdName}</MenuItem>;
    if (Object.keys(categories).length > 0) {
      firstItems = Object.keys(categories).map(item => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });
      const second = categories[company.category.firstName].sub;
      secondItems = Object.keys(second).map(item => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });
      const third = company.category.secondName
        ? categories[company.category.firstName].sub[company.category.secondName].sub
        : {};
      thirdItems = Object.keys(third).map(item => {
        return (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        );
      });
    }

    if (Object.keys(company).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>가맹점 정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <label>가맹점명</label>
                <TextField
                  fullWidth
                  value={company.name}
                  onChange={evt => this.props.handleChange('name', evt.target.value)}
                />
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>판매자명</label>
                    <TextField
                      fullWidth
                      value={company.companyName}
                      onChange={evt => this.props.handleChange('companyName', evt.target.value)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>회원문의 연락처</label>
                    <TextField
                      fullWidth
                      value={company.csNumber}
                      onChange={evt => this.props.handleChange('csNumber', evt.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>광고 승인 상태</label>
                    <Switch
                      checked={company.verified}
                      onChange={(evt, checked) => this.props.handleChange('verified', checked)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>자동 선정 해제</label>
                    <Switch
                      checked={company.disableAutoSelection}
                      onChange={(evt, checked) => this.props.handleChange('disableAutoSelection', checked)}
                    />
                  </div>
                </div>
                <div className="row">
                  {company.naver ? (
                    <div style={{ flex: 1 }}>
                      <label>마지막 결제일</label>
                      <TextField
                        value={momentLib((company.naver || {}).payInfo_lastPaidAt)
                          .tz('Asia/Seoul')
                          .format('YYYY-MM-DD')}
                        disabled
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div style={{ flex: 1 }}>
                    <label>앱 광고 순위(숫자가 클수록 상위에 노출됩니다)</label>
                    <Select
                      fullWidth
                      value={company.priority}
                      onChange={evt => this.props.handleChange('priority', evt.target.value)}>
                      {Array.from({ length: 30 }, (_, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {' '}
                          {index + 1}{' '}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  {company.naver ? (
                    <div style={{ flex: 1 }}>
                      <label>네이버 상태</label>
                      <Select
                        fullWidth
                        value={(company.naver || {}).available}
                        onChange={evt => this.props.handlePropChange('naver', 'available', evt.target.value)}>
                        <MenuItem value={0}>게시중</MenuItem>
                        <MenuItem value={1}>일시중지</MenuItem>
                        <MenuItem value={2}>광고종료</MenuItem>
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  {company.insta ? (
                    <div style={{ flex: 1 }}>
                      <label>인스타 상태</label>
                      <Select
                        fullWidth
                        value={(company.insta || {}).available}
                        onChange={evt => this.props.handlePropChange('insta', 'available', evt.target.value)}>
                        <MenuItem value={0}>게시중</MenuItem>
                        <MenuItem value={1}>일시중지</MenuItem>
                        <MenuItem value={2}>광고종료</MenuItem>
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}
                  {company.naver ? (
                    <div style={{ flex: 1 }}>
                      <label>네이버 예정일</label>
                      <TextField
                        value={momentLib((company.naver || {}).adsStart)
                          .tz('Asia/Seoul')
                          .format('YYYY-MM-DD')}
                        disabled
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {company.insta ? (
                    <div style={{ flex: 1 }}>
                      <label>인스타 예정일</label>
                      <TextField
                        value={momentLib((company.insta || {}).adsStart)
                          .tz('Asia/Seoul')
                          .format('YYYY-MM-DD')}
                        disabled
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row">
                  {company.naver ? (
                    <div style={{ flex: 1 }}>
                      <label>네이버 중지요청</label>
                      <Switch
                        checked={(company.naver || {}).stopRequest}
                        onChange={(evt, checked) => this.props.handlePropChange('naver', 'stopRequest', checked)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {company.insta ? (
                    <div style={{ flex: 1 }}>
                      <label>인스타 중지요청</label>
                      <Switch
                        checked={(company.insta || {}).stopRequest}
                        onChange={(evt, checked) => this.props.handlePropChange('insta', 'stopRequest', checked)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>체험단 종류</label>
                    <Select
                      fullWidth
                      value={company.type || 'S'}
                      onChange={evt => {
                        this.props.handleChange('type', evt.target.value);
                      }}>
                      <MenuItem value={'S'}>{sTypeLabel}</MenuItem>
                      <MenuItem value={'A'}>{aTypeLabel}</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>대분류</label>
                    <Select
                      fullWidth
                      value={company.category.firstName}
                      onChange={evt => {
                        this.props.handlePropChange('category', 'first', categories[evt.target.value].id);
                        this.props.handlePropChange('category', 'firstName', evt.target.value);
                        this.props.handlePropChange('category', 'second', '');
                        this.props.handlePropChange('category', 'secondName', '');
                        this.props.handlePropChange('category', 'third', '');
                        this.props.handlePropChange('category', 'thirdName', '');
                      }}>
                      {firstItems}
                    </Select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>중분류</label>
                    <Select
                      fullWidth
                      value={company.category.secondName}
                      onChange={evt => {
                        this.props.handlePropChange(
                          'category',
                          'second',
                          categories[company.category.firstName].sub[evt.target.value].id
                        );
                        this.props.handlePropChange('category', 'secondName', evt.target.value);
                        this.props.handlePropChange('category', 'third', '');
                        this.props.handlePropChange('category', 'thirdName', '');
                      }}>
                      {secondItems}
                    </Select>
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>소분류</label>
                    <Select
                      fullWidth
                      value={company.category.thirdName}
                      onChange={evt => {
                        this.props.handlePropChange(
                          'category',
                          'third',
                          categories[company.category.firstName].sub[company.category.secondName].sub[evt.target.value]
                            .id
                        );
                        this.props.handlePropChange('category', 'thirdName', evt.target.value);
                      }}>
                      {thirdItems}
                    </Select>
                  </div>
                </div>
                <div className="row">
                  {company.naver ? (
                    <div>
                      <label>결제 상태 - 네이버</label>
                      <Link to={'/paycard?id=' + company.id + '&channel=naver'} target="_blank">
                        <IconButton>
                          <OpenInNew />
                        </IconButton>
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                  {company.insta ? (
                    <div>
                      <label>결제 상태 - 인스타</label>
                      <Link to={'/paycard?id=' + company.id + '&channel=insta'} target="_blank">
                        <IconButton>
                          <OpenInNew />
                        </IconButton>
                      </Link>
                    </div>
                  ) : (
                    ''
                  )}
                  <div>
                    <label>업체</label>
                    <Link to={'/company-user?id=' + company.user_id} target="_blank">
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </Link>
                  </div>
                  <div>
                    <label>업체변경</label>
                    <TextField
                      type="number"
                      value={company.companyId}
                      onChange={async evt => {
                        this.props.handleChange('companyId', Number(evt.target.value));
                        let result = await api.getCompanyUser(Number(evt.target.value));
                        if (result.users.length > 0) {
                          const companyUser = result.users[0];
                          this.props.handleChange('user_id', companyUser.id);
                          this.props.handleChange('email', companyUser.email);
                        }
                      }}
                    />
                  </div>
                </div>
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  async componentDidMount() {
    const categories = await api.getOptions('type=category');
    this.setState({ categories });
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(InfoBasic);
