import React from 'react';
import * as api from '../../company3/api';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Switch from '@material-ui/core/Switch';
import momentLib from 'moment-timezone';
import Button from '@material-ui/core/Button/Button';
import { Link } from 'react-router';
import IconButton from '@material-ui/core/IconButton/IconButton';
import OpenInNew from '@material-ui/icons/OpenInNew';

class DetailCharge extends React.Component {
  state = {
    companyPoint: {},
    routes: {},
  };

  render() {
    let { companyPoint, routes } = this.state;

    if (Object.keys(companyPoint).length > 0) {
      return (
        <div>
          <ExpansionPanel defaultExpanded style={{ marginBottom: 0 }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <h3>충전금액 충전</h3>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div style={{ width: '100%' }}>
                <section>
                  <label>포인트 분류</label>
                  <TextField fullWidth value={'충전'} />
                  <label>chargeId</label>
                  <TextField fullWidth value={companyPoint.taggableId} />
                  <label>결제 상태</label>
                  <TextField
                    fullWidth
                    value={
                      {
                        0: '결제대기',
                        1: '결제완료',
                        2: '승인 전',
                        3: '승인 중',
                        20: '결제 취소',
                        '-20': '결제 취소 실패',
                        '-30': '결제 취소 진행중',
                        '-1': '결제 실패(오류)',
                        '-2': '승인 실패',
                        99: '환불',
                      }[companyPoint.charge.status]
                    }
                  />
                  <label>결제 수단</label>
                  <TextField fullWidth value={companyPoint.charge.method} />
                  <label>충전 포인트</label>
                  <TextField fullWidth value={companyPoint.charge.amount} />
                  <label>결제 카드</label>
                  <TextField fullWidth value={companyPoint.charge.card} />
                  <label>주문 ID</label>
                  <TextField fullWidth value={companyPoint.charge.order} />
                  <label>영수증 ID</label>
                  <TextField fullWidth value={companyPoint.charge.receiptId} />
                </section>
                <div className="row">
                  <div>
                    <label>영수증 링크</label>
                    <a href={companyPoint.charge.receiptUrl} target="_blank" rel="noopener noreferrer">
                      <IconButton>
                        <OpenInNew />
                      </IconButton>
                    </a>
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      );
    } else {
      return <div />;
    }
  }

  async componentDidMount() {
    const routes = await api.getOptions('type=root');
    this.setState({ routes });
    this.setState({ companyPoint: this.props.companyPoint });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ companyPoint: newProps.companyPoint });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(DetailCharge);
