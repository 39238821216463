import React from 'react';

const Tag = props => {
  return (
    <span
      style={{
        padding: '4px 8px 4px 8px',
        border: '1px solid #0080FF',
        color: '#0080FF',
        boxSizing: 'border-box',
      }}>
      {props.label}
    </span>
  );
};

const ShortFormGuide = props => {
  return (
    <div
      style={{
        margin: '20px 16px',
      }}>
      <h3>Short-Form Video Guide</h3>
      <div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
            marginTop: 20,
          }}>
          Video Length Requirement
        </p>
        <p>Minimum video duration of 15 seconds. </p>
      </div>
      <div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
            marginTop: 20,
          }}>
          Hashtags (Required)
        </p>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
          {(props.requiredHashTags || []).map(hash => (
            <Tag label={hash} />
          ))}
        </div>
      </div>

      <div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
            marginTop: 20,
          }}>
          Hashtags (Optional)
        </p>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
          {(props.optionalHashTags || []).map(hash => (
            <Tag label={hash} />
          ))}
        </div>
      </div>

      <div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
            marginTop: 20,
          }}>
          Official Account
        </p>
        <p>{props.officialAccount}</p>
      </div>

      <div>
        <p
          style={{
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
            marginTop: 20,
          }}>
          Required Tasks
        </p>
        <ol style={{ paddingLeft: 16 }}>
          {(props.tasks || []).map(task => (
            <li>{task}</li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ShortFormGuide;
