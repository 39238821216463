import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

const CustomDialog = ({ open, setOpen, title, content, handleConfirm, ...props }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ minHeight: '80px' }}>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleClose();
          }}
          color="secondary">
          취소
        </Button>
        <Button
          onClick={() => {
            handleConfirm();
            handleClose();
          }}
          color="secondary"
          autoFocus>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
