import React from 'react';

const ProductInfo = props => {
  return (
    <div
      style={{
        margin: '20px 16px',
      }}>
      <h3
        style={{
          fontSize: '16px',
          fontWeight: 500,
          margin: 0,
          color: '#333',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        {props.title}
      </h3>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <span style={{ fontSize: '14px', fontWeight: 500, color: '#0080FF' }}>{props.salePrice}</span>
        <span
          style={{
            fontSize: '14px',
            textDecoration: 'line-through',
            color: '#999',
            marginLeft: '8px',
          }}>
          {props.ogPrice}
        </span>
      </div>
      <p style={{ fontSize: '14px', color: '#777' }}>{props.abstract}</p>
    </div>
  );
};

export default ProductInfo;
