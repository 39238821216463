import React, { useRef, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { IconButton, makeStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Typo from './Typo';
import { Color } from '../constants/color';
import { FONT_FAMILY_MAP, TYPO_MAP } from '../constants/typo';

const CustomTextField = props => {
  const {
    value,
    label,
    layoutStyle,
    rightIcon,
    onChange,
    rightIconButtonHandler,
    showModifiedStatus = true,
    ...rest
  } = props;

  const [modified, setModified] = useState(false);
  const originalValue = useRef(value ? value : '');

  const onChangeHandler = event => {
    if (originalValue.current !== event.target.value) {
      setModified(true);
    } else {
      setModified(false);
    }

    if (onChange) {
      onChange(event);
    }
  };

  const useStyles = makeStyles({
    textField: {
      '& .MuiInputBase-input::placeholder': {
        color: '#4770bd',
        opacity: 0.2,
      },
      '& .MuiInputBase-input': {
        fontFamily: FONT_FAMILY_MAP[400],
        ...TYPO_MAP['Body_1'],
        fontWeight: 400,
      },
    },
  });

  const classes = useStyles();

  return (
    <div
      style={{
        ...layoutStyle,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: '20px',
          marginBottom: '6px',
          fontFamily: 'Pretendard-Regular',
        }}>
        <Typo typography="Detail_1" fontWeight="500" color={Color.label_type1}>
          {label}
        </Typo>

        {showModifiedStatus && modified && (
          <span
            style={{
              marginLeft: 10,
              fontSize: 9,
              fontFamily: 'Pretendard-SemiBold',
              color: '#8BC34A',
            }}>
            <span>수정됨</span>
            <Edit fontSize="inherit" />
          </span>
        )}
      </div>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <TextField
          value={value}
          style={{
            width: '100%',
          }}
          placeholder={originalValue.current}
          onChange={onChangeHandler}
          className={classes.textField}
          {...rest}
        />

        {rightIcon && (
          <IconButton style={{ width: '36px', height: '36px' }} onClick={rightIconButtonHandler}>
            {rightIcon}
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default CustomTextField;
