import * as api from '../api';

export function getSchema() {
  return api
    .fetchData('get', '/company/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function filterCompanies(query) {
  return api
    .fetchData('get', '/company/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getCompany(id) {
  if (id) {
    return api
      .fetchData('get', '/company/console', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}

export function updateCompany(id, json) {
  return api
    .fetchData('put', '/company/console', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function deleteCardInfo(id) {
  return api
    .fetchData('delete', '/pay/subscribe', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
