import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

// import TextField from '@material-ui/core/TextField';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import Switch from '@material-ui/core/Switch';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ImageEditor from '../../../components/image-editor';
import AdsMenu from './ads-etc-menu';
import AdsQuestion from './ads-etc-question';

class AdsEtc extends PureComponent {
  render() {
    const { company } = this.props;
    if (Object.keys(company).length > 0) {
      return (
        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>기타 상세정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <div>
                <ImageEditor
                  label="광고 이미지"
                  images={company.thumbnails ? company.thumbnails : []}
                  main={company.thumbnail}
                  handleChange={arr => this.props.handleChange('thumbnails', arr)}
                  handleMainChange={url => this.props.handleChange('thumbnail', url)}
                />
              </div>
              <div>
                <AdsMenu
                  menu={company.menu}
                  handleChange={this.props.handleChange}
                  handlePropChange={this.props.handlePropChange}
                />
                <AdsQuestion
                  question={company.question}
                  handleChange={this.props.handleChange}
                  handlePropChange={this.props.handlePropChange}
                />
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(AdsEtc);
