import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import * as api from '../api';

class EditorBasic extends React.Component {
  state = {
    proposition: {},
    user: {},
    point: {},
  };
  render() {
    const { proposition } = this.state;

    if (Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <label>회원명</label>
                <TextField fullWidth value={proposition.User.displayName} />
                <div className="row">
                  <div style={{ flex: 1 }}>
                    <label>채널</label>
                    <TextField fullWidth value={proposition.User.type} />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>등급</label>
                    <Select fullWidth value={proposition.User.level}>
                      <MenuItem value={undefined}>미지정</MenuItem>
                      <MenuItem value={'black'}>black</MenuItem>
                      <MenuItem value={'red'}>red</MenuItem>
                      <MenuItem value={'yellow'}>yellow</MenuItem>
                      <MenuItem value={'level'}>white</MenuItem>
                    </Select>
                  </div>
                </div>
                <label>유저 적립 포인트</label>
                <TextField fullWidth value={proposition.settlementAmount || 0} />
                <label>포인트 상태</label>
                <TextField
                  fullWidth
                  value={
                    {
                      0: '캠페인진행중',
                      1: '적립대기',
                      2: '적립보류',
                      5: '취소',
                      10: '적립완료',
                      101: '인출대기',
                      110: '인출완료',
                    }[proposition.pointStatus]
                  }
                />
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    this.setState({ proposition: this.props.proposition });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ proposition: newProps.proposition });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorBasic);
