import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

const Guide = () => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + '/asset/worry_emoji.png'} width="244px" style={{ marginBottom: 16 }} />
          </div>

          <div style={{ marginBottom: 16 }}>
            <h2>핸드북이란?</h2>
            <p style={{ fontSize: 16, fontWeight: 400 }}>중요한 정보와 지침 또는 사용법을 정리해놓은 것입니다.</p>
          </div>

          <div>
            <h2>왜 작성해야할까요? (기대효과)</h2>
            <ul style={{ fontSize: 16, fontWeight: 400 }}>
              <li>일관된 정보 제공</li>
              <li>새로운 구성원의 온보딩 지원</li>
            </ul>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Guide;
