import React from 'react';
import momentLib from 'moment-timezone';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import InputMoment from 'input-moment';
import 'input-moment/dist/input-moment.css';

export default class DateEditor extends React.Component {
  constructor(props) {
    super();
    let initDate = props.field.value;
    this.state = {
      moment:
        initDate !== null && initDate !== undefined && initDate.split('-')[0] !== '1970' ? momentLib(initDate) : null,
      editable: false,
    };
    this.dateFormat = props.field.format === 'date-time' ? 'YYYY-MM-DDTHH:mm:ss.SSSZ' : 'YYYY-MM-DD';
  }
  handleChange = moment => {
    this.setState({ moment });
    this.props.handleChange(moment.format(this.dateFormat));
  };
  render() {
    const { editable, moment } = this.state;
    const description = this.props.description;
    return (
      <div>
        <label>{this.props.field.name}</label>
        {description && (
          <p
            style={{
              marginTop: '4px',
              marginBottom: '4px',
              fontSize: '10px',
            }}>
            {description}
          </p>
        )}
        <div className="flex-row">
          <FormControl fullWidth>
            <Input
              fullWidth
              type="string"
              name={this.props.field.name}
              style={{ cursor: 'pointer' }}
              value={moment !== null ? moment.tz('Asia/Seoul').format(this.dateFormat) : ''}
              onClick={() => this.setState({ editable: !editable })}
              endAdornment={
                <InputAdornment position="end">
                  <i className="material-icons">{editable ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                </InputAdornment>
              }
            />
          </FormControl>
          <Button onClick={() => this.handleChange(momentLib(new Date()))}>Now</Button>
        </div>
        {editable ? (
          <InputMoment
            moment={moment !== null ? moment : momentLib(new Date())}
            onChange={this.handleChange}
            minStep={5} // default
            hourStep={1} // default
            prevMonthIcon="ion-ios-arrow-left" // default
            nextMonthIcon="ion-ios-arrow-right" // default
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}
