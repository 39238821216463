import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import CustomTextField from '../../../components/CustomTextField';
import CustomButton from '../../../components/CustomButton';
import { createAnswer } from '../../../services/supermembers/inquiry';
import Typo from '../../../components/Typo';
import ImageGallery from '../../../components/ImageList';
import CustomDialog from '../../../components/CustomDialog';
import useUploadFiles from '../../../hooks/useUploadFiles';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

const PostInquiryAnswer = ({ id, ...props }) => {
  const [open, setOpen] = useState(false); // Post, Modify
  const [answer, setAnswer] = useState(''); // Post, Modify
  const [files, setFiles] = useState([]); // Post, Modify
  const [previewImages, setPreviewImages] = useState([]); // Post, Modify

  const { uploadFiles } = useUploadFiles();

  const fileInputRef = useRef(null);

  const addPhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const newFiles = event.target.files;
    const newImages = Array.from(newFiles).map(file => URL.createObjectURL(file));

    setFiles([...files, ...newFiles]);
    setPreviewImages([...previewImages, ...newImages]);
  };

  const onDeleteImage = index => {
    setPreviewImages(previewImages.filter((_, imageIdx) => imageIdx !== index));
    setFiles(files.filter((_, fileIdx) => fileIdx !== index));
  };

  const submitAnswer = async () => {
    if (answer.length === 0) {
      alert('답변을 입력해주세요.');
      return;
    }

    try {
      props.activateLoading();
      const uploadedFileURLs = await uploadFiles(id, files);

      const response = await createAnswer(id, {
        contentText: answer,
        contentImages: uploadedFileURLs,
      });

      if (response) {
        alert('답변이 등록되었습니다.');
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    } finally {
      props.deactivateLoading();
    }
  };

  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelSummary>
        <Typo typography={'Body1'} fontWeight={600}>
          답변 등록
        </Typo>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <CustomTextField
            value={answer}
            onChange={event => {
              setAnswer(event.target.value);
            }}
            multiline
            showModifiedStatus={false}
          />

          <ImageGallery images={previewImages} onDeleteImage={onDeleteImage} showDeleteButton={true} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomButton onClick={addPhoto}>사진 첨부</CustomButton>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <CustomButton type="primary" onClick={() => setOpen(true)}>
              등록
            </CustomButton>

            <CustomDialog
              title="답변 등록 확인"
              content="답변을 등록하시겠습니까?"
              open={open}
              setOpen={setOpen}
              handleConfirm={submitAnswer}
            />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(null, mapDispatchToProps)(PostInquiryAnswer);
