import React, { Component } from 'react';

import momentLib from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

class InfoMessage extends Component {
  state = {
    messages: [],
  };

  render() {
    const { classes } = this.props;
    const { messages } = this.state;

    if (messages.length > 0) {
      let items = messages.map((message, idx) => {
        message.date = momentLib(message.date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm');

        return (
          <ExpansionPanel style={{ minWidth: '100%' }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <h4>
                  <span
                    style={{
                      color: '#a0a0a0',
                    }}>{`${momentLib(message.dateCreated).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}`}</span>
                  <span style={{ color: '#1ec800' }}>{message.text}</span>
                </h4>
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ display: 'block' }}>
              <div>
                <div>
                  <h4>내용</h4>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>{message.text}</div>
                </div>
                <div>
                  <h4>매체</h4>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>{message.type}</div>
                </div>
                <div
                  style={{
                    padding: 12,
                    borderBottom: '1px solid #dbdbdb',
                  }}>
                  <div>
                    {Object.entries(message)
                      .map(([key, value]) => `${key}: ${value}`)
                      .join(', ')}
                  </div>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      });
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>메시지 내역</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block', paddingLeft: 0, paddingRight: 0 }}>
            {items}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }

  formatParams = params => {
    if (params === null || params === undefined) {
      return '';
    }
    return Object.entries(params)
      .map(([key, value]) => {
        if (typeof value === 'object') {
          return `${key}: ${this.formatParams(value)}`;
        } else {
          return `${key}: ${value}`;
        }
      })
      .join(', ');
  };

  async componentDidMount() {
    let api = require(`../../app/${this.props.type}/api`);

    if (this.props.phone) {
      const messages = await api.getMessages(this.props.phone.replace(/\D/g, ''));
      console.log(messages);
      this.setState({ messages: messages.messages });
    } else {
      console.log('EMPTY this.props.phone', this.props.phone);
    }
  }
}

export default withStyles(styles)(InfoMessage);
