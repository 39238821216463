import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import momentLib from 'moment-timezone';

import { fetchData } from '../../app/api';

String.prototype.hashCode = function () {
  var hash = 0,
    i = 0,
    len = this.length;
  while (i < len) {
    hash = ((hash << 5) - hash + this.charCodeAt(i++)) << 0;
  }
  return hash + 2147483647 + 1;
};

class NaverHistoryCard extends React.PureComponent {
  state = {
    naverHistory: [],
    naverHistory1: [],
    naverHistory2: [],
    blogauto: [],
    nextDate1: '',
    nextDate2: '',
    history: {
      days: 0,
      reasons: [],
    },
  };
  render() {
    const { naverHistory, naverHistory1, naverHistory2, blogauto, nextDate1, nextDate2, history } = this.state;
    const elements1 = naverHistory
      ? naverHistory1.map((item, idx) => {
          const date = momentLib(item.schedule || item.updatedAt)
            .tz('Asia/Seoul')
            .format('YY/MM/DD HH:mm');
          return (
            <TableRow key={date}>
              <TableCell>
                <span className={item.level ? item.level : 'nolevel'}>{date}</span>
              </TableCell>
              <TableCell>
                <span className={item.prelevel ? item.prelevel : 'nolevel'}>{item.prelevel || ''}</span>
              </TableCell>
              <TableCell>{item.visitorCount}</TableCell>
              <TableCell>
                {idx === naverHistory.length - 1
                  ? item.blogAuto || blogauto.length > 0
                    ? '포함'
                    : '없음'
                  : item.blogAuto
                    ? '포함'
                    : '없음'}
              </TableCell>
            </TableRow>
          );
        })
      : '';
    const elements2 = naverHistory
      ? naverHistory2.map((item, idx) => {
          const date = momentLib(item.schedule || item.updatedAt)
            .tz('Asia/Seoul')
            .format('YY/MM/DD HH:mm');
          return (
            <TableRow key={date}>
              <TableCell>
                <span>{date}</span>
              </TableCell>
              <TableCell colSpan={4}>{item.reasons}</TableCell>
            </TableRow>
          );
        })
      : '';
    return (
      <div style={{ marginBottom: '14px' }}>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>
              블로거 가심사{' '}
              {(blogauto.length > 0 ? '(자동화 검색결과 있음)' : '(자동화 검색결과 없음)') +
                '(예정일:' +
                nextDate1 +
                ')'}
            </h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section style={{ marginBottom: '0px' }}>
                <div className="table-container">
                  <Table style={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>심사 일자</TableCell>
                        <TableCell>등급</TableCell>
                        <TableCell>일방문자 평균</TableCell>
                        <TableCell>자동화 검색 결과</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{elements1}</TableBody>
                  </Table>
                </div>
              </section>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button onClick={this.handleUpdate}>업데이트</Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>블로거 재심사 {'(예정일:' + nextDate2 + ')'}</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section style={{ marginBottom: '0px' }}>
                <div className="table-container">
                  <Table style={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>다음심사일</TableCell>
                        <TableCell>사유</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{elements2}</TableBody>
                  </Table>
                </div>
              </section>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <label>다음 재심사 일정</label>
                  <Select
                    fullWidth
                    value={history.days}
                    onChange={evt => {
                      history.days = evt.target.value;
                      this.setState({ history: history });
                      this.forceUpdate();
                    }}>
                    <MenuItem value={0}></MenuItem>
                    <MenuItem value={14}>
                      2주 후({momentLib(new Date()).tz('Asia/Seoul').add(14, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={30}>
                      1개월 후({momentLib(new Date()).tz('Asia/Seoul').add(30, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={60}>
                      2개월 후({momentLib(new Date()).tz('Asia/Seoul').add(60, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={90}>
                      3개월 후({momentLib(new Date()).tz('Asia/Seoul').add(90, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={120}>
                      4개월 후({momentLib(new Date()).tz('Asia/Seoul').add(120, 'day').format('YY/MM/DD')})
                    </MenuItem>
                  </Select>
                </div>
                <div style={{ flex: 1 }}>
                  <label>사유</label>
                  <Select
                    multiple
                    fullWidth
                    renderValue={selected => selected.join(', ')}
                    value={history.reasons}
                    onChange={evt => {
                      history.reasons = evt.target.value;
                      this.setState({ history: history });
                      this.forceUpdate();
                    }}>
                    <MenuItem value="내츄럴">내츄럴</MenuItem>
                    <MenuItem value="어뷰징">어뷰징</MenuItem>
                    <MenuItem value="상향 체크">상향 체크</MenuItem>
                    <MenuItem value="하향 체크">하향 체크</MenuItem>
                    <MenuItem value="후기 적음">후기 적음</MenuItem>
                    <MenuItem value="키워드 부적합">키워드 부적합</MenuItem>
                    <MenuItem value="노출 좋음">노출 좋음</MenuItem>
                    <MenuItem value="노출 불량">노출 불량</MenuItem>
                    <MenuItem value="퀄 좋음">퀄 좋음</MenuItem>
                    <MenuItem value="퀄 불량">퀄 불량</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button onClick={this.handleDelete}>최근 심사일 상세 삭제</Button>
                <Button color="secondary" onClick={this.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
  componentDidMount() {
    fetchData('get', '/user/console', { uid: this.props.uid })
      .then(datas => {
        let blogId =
          datas.users[0].email && datas.users[0].email.indexOf('@') > 0
            ? datas.users[0].email.split('@')[0]
            : datas.users[0].email;

        let nextDate1 = '';
        let nextDate2 = '';
        let reasons = (datas.users[0].naverHistory || []).filter(history => history.days !== undefined);
        if (reasons.length > 0) {
          nextDate2 = momentLib(reasons[reasons.length - 1].schedule)
            .tz('Asia/Seoul')
            .format('YYYY-MM-DD');
        }

        let hasCode = blogId.hashCode() % 7;
        let dateCode = Math.round(new Date().getTime() / (1000 * 60 * 60 * 24)) % 7;
        let nextDays = (7 + dateCode - hasCode) % 7;
        nextDays = nextDays === 0 ? 7 : nextDays;
        nextDate1 = momentLib().tz('Asia/Seoul').add(nextDays, 'day').format('YYYY-MM-DD');

        this.setState({
          naverHistory: datas.users[0].naverHistory,
          naverHistory1: (datas.users[0].naverHistory || []).filter(history => {
            return !history.days;
          }),
          naverHistory2: (datas.users[0].naverHistory || []).filter(history => {
            return history.days;
          }),
          uid: datas.users[0].uid,
          nextDate1: nextDate1,
          nextDate2: nextDate2,
        });

        return fetchData('get', '/user/blogauto', { blogId: blogId });
      })
      .then(result => {
        this.setState({
          blogauto: result,
        });
      });
  }
  handleUpdate = () => {
    const { uid } = this.state;
    fetchData('put', '/user/visitorcount', { uid: uid }).then(() => {
      fetchData('get', '/user/console', { uid: uid }).then(datas => {
        if (datas.users) {
          this.setState({
            naverHistory: datas.users[0].naverHistory || [],
            naverHistory1: datas.users[0].naverHistory.filter(history => {
              return !history.days;
            }),
            naverHistory2: datas.users[0].naverHistory.filter(history => {
              return history.days;
            }),
            uid: datas.users[0].uid,
          });
          this.forceUpdate();
        }
      });
    });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      const { uid } = this.state;
      fetchData('delete', '/user/visitorcount', { uid: this.props.uid }).then(() => {
        fetchData('get', '/user/console', { uid: this.props.uid }).then(datas => {
          if (datas.users) {
            this.setState({
              naverHistory: datas.users[0].naverHistory || [],
              naverHistory1: datas.users[0].naverHistory.filter(history => {
                return !history.days;
              }),
              naverHistory2: datas.users[0].naverHistory.filter(history => {
                return history.days;
              }),
              uid: datas.users[0].uid,
            });
            this.forceUpdate();
          }
        });
      });
    }
  };
  handleSave = () => {
    const { history } = this.state;
    if (history.days > 0) {
      history.schedule = momentLib(new Date()).tz('Asia/Seoul').add(history.days, 'day').format('YYYY-MM-DD');
      history.schedule = new Date(history.schedule);
    }
    fetchData('post', '/user/visitorcount', { uid: this.props.uid, ...history }).then(() => {
      fetchData('get', '/user/console', { uid: this.props.uid }).then(datas => {
        if (datas.users) {
          this.setState({
            naverHistory: datas.users[0].naverHistory,
            naverHistory1: datas.users[0].naverHistory.filter(history => {
              return !history.days;
            }),
            naverHistory2: datas.users[0].naverHistory.filter(history => {
              return history.days;
            }),
            uid: datas.users[0].uid,
          });
          this.forceUpdate();
        }
      });
    });
  };
}

export default NaverHistoryCard;
