import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { Button, Tooltip } from '@material-ui/core';
import { cancelContracts } from '../../../api/contract';
import CustomDialog from '../../../../../components/CustomDialog';

const Tools = props => {
  const { contract } = props;
  const [open, setOpen] = useState(false);

  const handleConfirm = async () => {
    const result = await cancelContracts([contract.id]);

    if (result.data.success) {
      alert('이용내역이 취소되었습니다.');
      window.location.reload();
    }
  };
  return (
    <ExpansionPanel expanded={true} style={{ boxShadow: 'none' }}>
      <ExpansionPanelDetails>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ width: '100%' }}>
            <div style={{ display: 'flex', gap: 16 }}>
              <div>
                <Tooltip title="이미 취소된 이용내역은 취소가 불가능합니다." arrow placement="top">
                  <span>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setOpen(true);
                      }}
                      disabled={contract.currentContractStatus === 'CANCELLED'}>
                      이용내역 취소
                    </Button>
                  </span>
                </Tooltip>
                <CustomDialog
                  title="이용내역 취소 확인"
                  content="이용내역을 취소하시겠습니까?"
                  open={open}
                  setOpen={setOpen}
                  handleConfirm={handleConfirm}
                />
              </div>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Tools;
