import React, { Component } from 'react';
import { Link } from 'react-router';

import momentLib from 'moment-timezone';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import Pageview from '@material-ui/icons/Pageview';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';

import { getUser, fetchData } from '../../app/api';
import './cards.css';
import { Button } from '@material-ui/core';

import _ from 'lodash';

class CardEdit extends Component {
  state = {
    ...this.props.contract,
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  handleSave = () => {
    const { close, handleSaved } = this.props;
    let contract = this.state;

    if (contract.link && contract.currentState < 1) {
      contract.currentState = 1;
      contract.date1 = new Date();
    }
    if (contract.mark && contract.currentState < 2) contract.currentState = 2;
    contract.date2 = new Date();

    fetchData('put', '/contract/console', { id: contract.id }, contract)
      .then(result => {
        handleSaved(contract);
        alert('저장 완료');
        close();
      })
      .catch(err => {
        alert('저장 실패');
      });
  };
  render() {
    const { close, collapsed } = this.props;
    const { link, mark } = this.state;
    return (
      <div className={collapsed ? 'forms collapsed' : 'forms'}>
        <TextField
          label={'링크'}
          name="link"
          fullWidth
          style={{ marginRight: 20 }}
          value={link ? link : ''}
          onChange={this.handleChange}
        />
        <TextField label={'평가'} name="mark" fullWidth value={mark ? mark : ''} onChange={this.handleChange} />
        <div className="buttons">
          <IconButton onClick={this.handleSave}>
            <Save />
          </IconButton>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </div>
      </div>
    );
  }
}
class Card extends Component {
  state = {
    email: '',
    editable: false,
    contract: this.props.contract,
  };
  componentDidMount() {
    const { contract } = this.state;
    if (contract.userEmail === undefined) {
      fetchData('get', '/user/console', { uid: contract.user }).then(result => {
        if (result.users.length > 0) {
          this.setState({ email: result.users[0].email, outerId: result.users[0].outerId });
        } else {
          getUser(contract.user).then(user => {
            this.setState({ email: user.email, outerId: user.outerId });
          });
        }
      });
    } else {
      this.setState({ email: contract.userEmail, outerId: contract.outerId || contract.userOuterId });
    }
  }
  openLink = () => {
    const { contract, outerId, email } = this.state;
    if (contract.type === 'naver' || contract.type === undefined) {
      if (_.isNil((this.props.user || {})['outerId' || 'email'] || outerId || email)) {
        alert('유저정보 조회 실패\n탈퇴한 회원일 수 있습니다.');
        return;
      }
      window.open(
        `http://blog.naver.com/${String((this.props.user || {})['outerId' || 'email'] || outerId || email).split('@')[0]}/${contract.link}`
      );
    } else {
      window.open(contract.link);
    }
  };

  getStateName(contract) {
    const stateNameMap = {
      '-3': '선정완료',
      '-2': '선정대기',
      '-1': '이용취소',
      0: {
        매장: '이용완료',
        제품: '신청완료',
        0: '구매완료',
      },
      1: '리뷰등록',
      2: '평가완료',
    };

    if (stateNameMap[contract.currentState] && typeof stateNameMap[contract.currentState] === 'object') {
      if (contract.rewardStatus === null) {
        return stateNameMap[contract.currentState][contract.first];
      }

      if (stateNameMap[contract.currentState][contract.rewardStatus]) {
        return stateNameMap[contract.currentState][contract.rewardStatus];
      }
    }

    return stateNameMap[contract.currentState];
  }

  render() {
    const { forWho, companies } = this.props;
    const { editable, contract, email } = this.state;

    return (
      <div className={'card ' + contract.level}>
        <div className="infos">
          <div className="date">
            {momentLib(!!contract.appliedAt ? contract.appliedAt : contract.date0)
              .tz('Asia/Seoul')
              .format('YY/MM/DD HH:mm')}
            <div className="state">
              {contract.currentState === 2 ? `${contract.mark}/5` : this.getStateName(contract)}
              {`${contract.receiptOnly ? '(영수증)' : ''}`}
              {!!contract.extended ? '(연장)' : ''}
            </div>
          </div>
          <div className="info">{contract.level}</div>
          {forWho === 'company' ? (
            <div className="info">
              {/*<h4 className={contract.level}>{contract.level}</h4>*/}
              <Link to={'/user?uid=' + contract.user} target="_blank">
                {contract.username}
              </Link>
              {contract.type === 'insta' ? (
                contract.userOuterId ? (
                  <a href={'https://instagram.com/' + contract.userOuterId} target="_blank">
                    {' (' + contract.userOuterId + ')'}
                  </a>
                ) : (
                  ''
                )
              ) : contract.userEmail ? (
                <a href={'https://blog.naver.com/' + (contract.userEmail || '').split('@')[0]} target="_blank">
                  {' (' + contract.userEmail + ')'}
                </a>
              ) : (
                ''
              )}
            </div>
          ) : (
            <div className="info">
              <Link to={'/company3?id=' + contract.company} target="_blank">
                {contract.companyName}
              </Link>
            </div>
          )}
          <div className="buttons">
            <IconButton onClick={() => this.setState({ editable: !editable })}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => window.open(`/contract?id=${contract.id || contract.rKey}`)}>
              <Pageview />
            </IconButton>
            {contract.receiptReview ? (
              <Button
                color="default"
                disabled={!contract.receiptReview}
                onClick={() => window.open(contract.receiptReview)}>
                영수증
              </Button>
            ) : (
              ''
            )}
            {contract.purchaseThumbnail ? (
              <Button
                color="default"
                disabled={!contract.purchaseThumbnail}
                onClick={() => window.open(contract.purchaseThumbnail)}>
                구매인증
              </Button>
            ) : (
              ''
            )}
            <Button color="default" disabled={!contract.link} onClick={this.openLink}>
              {contract.type === 'insta' ? '인스타' : '블로그'}
            </Button>
          </div>
        </div>
        <CardEdit
          collapsed={!editable}
          contract={contract}
          handleSaved={contract => this.setState({ contract: contract })}
          close={() => this.setState({ editable: false })}
        />
      </div>
    );
  }
}

export default Card;
