import axios from 'axios';

export const getProductReviewConfigs = (query = {}) => {
  const payloadString = Object.entries(query)
    .map(e => e.join('='))
    .join('&');

  let baseURL = `${process.env.REACT_APP_COSDUCK_BASE_URL}/console/product-review`;

  if (payloadString) {
    baseURL += `?${payloadString}`;
  }

  return axios.get(baseURL);
};

export const getProductReviewConfig = id => {
  return axios.get(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/product-review/${id}`);
};

export const updateProductReviewConfig = (id, body) => {
  return axios.patch(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/product-review/${id}`, {
    ...body,
  });
};
export const deleteProductReviewConfig = id => {
  return axios.delete(`${process.env.REACT_APP_COSDUCK_BASE_URL}/console/product-review/${id}`);
};
