import React, { Component } from 'react';
import { Link } from 'react-router';

import momentLib from 'moment-timezone';
import TextField from '@material-ui/core/TextField';

import IconButton from '@material-ui/core/IconButton';
import Save from '@material-ui/icons/Save';
import Close from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';

import { getUser, fetchData } from '../../app/api';
import './cards.css';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Select from '@material-ui/core/Select';

class CardEdit extends Component {
  state = {
    ...this.props.proposition,
  };
  handleChange = evt => {
    this.setState({ [evt.target.name]: evt.target.value });
  };
  handleSave = () => {
    const { close, handleSaved } = this.props;
    let proposition = this.state;

    if (proposition.link && proposition.currentState < 1) {
      proposition.currentState = 1;
      proposition.date1 = new Date();
    }
    if (proposition.mark && proposition.currentState < 2) proposition.currentState = 2;
    proposition.date2 = new Date();

    fetchData('put', '/proposition/console', { id: proposition.id }, proposition)
      .then(result => {
        handleSaved(proposition);
        alert('저장 완료');
        close();
      })
      .catch(err => {
        alert('저장 실패');
      });
  };
  render() {
    const { close, collapsed } = this.props;
    const { link, mark } = this.state;
    return (
      <div className={collapsed ? 'forms collapsed' : 'forms'}>
        <TextField
          label={'링크'}
          name="link"
          fullWidth
          style={{ marginRight: 20 }}
          value={link ? link : ''}
          onChange={this.handleChange}
        />
        <TextField label={'평가'} name="mark" fullWidth value={mark ? mark : ''} onChange={this.handleChange} />
        <div className="buttons">
          <IconButton onClick={this.handleSave}>
            <Save />
          </IconButton>
          <IconButton onClick={close}>
            <Close />
          </IconButton>
        </div>
      </div>
    );
  }
}
class Card extends Component {
  state = {
    email: '',
    editable: false,
    proposition: this.props.proposition,
    manuscript: this.props.manuscript,
    openWaybill: false,
    openRequest: false,
  };
  componentDidMount() {
    const { proposition, manuscript } = this.state;
    if (proposition.userEmail === undefined) {
      if (proposition.user) {
        this.setState({ email: proposition.user.email, username: proposition.user.displayName });
      } else {
        getUser(proposition.uid).then(user => {
          this.setState({ email: user.email, username: user.displayName });
        });
      }
    } else {
      this.setState({ email: proposition.userEmail });
    }
  }
  openLink = () => {
    const { proposition, email } = this.state;
    if (proposition.type === 'naver' || proposition.type === undefined) {
      if (email === undefined || email === null) {
        alert('유저정보 조회 실패\n탈퇴한 회원일 수 있습니다.');
        return;
      }
      window.open(`http://blog.naver.com/${email.split('@')[0]}/${proposition.link}`);
    } else {
      window.open(proposition.link);
    }
  };
  render() {
    const { forWho, companies } = this.props;
    const { proposition, email, username, openWaybill, openRequest } = this.state;

    let deliveryInfo = [];
    if (proposition.deliveryHistory.length > 0) {
      deliveryInfo = proposition.deliveryHistory[proposition.deliveryHistory.length - 1];
    }
    let delivery = Object.keys(proposition.delivery).map(key => {
      if (key === 'name') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>실명</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'contact') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>연락처</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'email') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>이메일</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'id') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>자사몰 ID</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'address') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>배송주소</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'addressContract') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>연락처</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'addressName') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>수신자명</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'request') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>요청사항</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'additionalInfo') {
        if (!_.isEmpty(JSON.parse(proposition.delivery[key]))) {
          return (
            <div>
              <div style={{ width: 80, display: 'inline-block' }}>추가질문</div>
              <div style={{ display: 'inline-block' }}>
                {Object.keys(JSON.parse(proposition.delivery[key])).map(key2 => {
                  return (
                    <div>
                      <div>{key2}</div>
                      <div>{JSON.parse(proposition.delivery[key])[key2]}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }
      } else if (key === 'courier') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>배송사</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      } else if (key === 'waybill') {
        return (
          <div>
            <span style={{ width: 80, display: 'inline-block' }}>운송장</span>
            <span>{proposition.delivery[key]}</span>
          </div>
        );
      }
    });

    return (
      <div className={'card ' + proposition.level}>
        <div className="infos">
          <div className="date" style={{ display: 'block' }}>
            <div>{momentLib(deliveryInfo.updatedAt).tz('Asia/Seoul').format('YY/MM/DD')}</div>
            <div>{momentLib(deliveryInfo.updatedAt).tz('Asia/Seoul').format('HH:mm')}</div>
          </div>
          {forWho === 'campaign' ? (
            <div className="info" style={{ display: 'block' }}>
              {/*<h4 className={proposition.level}>{proposition.level}</h4>*/}
              <div style={{ display: 'block' }}>
                <Link to={'/user?uid=' + proposition.uid} target="_blank">
                  {username}
                </Link>
              </div>
              <div>
                {email ? (
                  <a href={'https://blog.naver.com/' + (email || '').split('@')[0]} target="_blank">
                    {' (' + email.split('@')[0] + ')'}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            <div className="info" style={{ display: 'block' }}>
              <Link to={'/campaigns?id=' + proposition.campaign} target="_blank">
                {proposition.campaignName}
              </Link>
            </div>
          )}
          <div className="delivery" style={{ display: 'block' }}>
            <div>
              <span style={{ width: 80, display: 'inline-block' }}>상태</span>
              <span>
                {' '}
                {deliveryInfo.state === 1
                  ? '발송완료'
                  : deliveryInfo.state === -2
                    ? '정보수정요청'
                    : deliveryInfo.state === -1
                      ? '정보수정완료'
                      : deliveryInfo.state === 10
                        ? '이용취소'
                        : '신청완료'}
              </span>
            </div>
            <div>
              <div>
                <span style={{ width: 80, display: 'inline-block' }}>수정요청</span>
                <span>{deliveryInfo.message && deliveryInfo.message.length > 0 ? deliveryInfo.message : ''}</span>
              </div>
              <span style={{ width: 80, display: 'inline-block' }}></span>
              <a
                href="#"
                onClick={() => {
                  this.setState({ openRequest: true });
                }}>
                정보수정요청
              </a>
              <Dialog
                onClose={() => {
                  this.setState({ openRequest: false });
                }}
                open={openRequest}>
                <DialogTitle id="form-dialog-title">정보수정요청</DialogTitle>
                <DialogContent>
                  <TextField
                    margin="dense"
                    label="메시지"
                    fullWidth
                    value={deliveryInfo.message}
                    onChange={evt => {
                      proposition.deliveryHistory.push({
                        state: -2,
                        updatedAt: new Date(),
                        message: evt.target.value,
                      });
                      deliveryInfo.message = evt.target.value;
                      this.setState({ proposition: proposition });
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({ openRequest: false });
                    }}>
                    닫기
                  </Button>
                  <Button
                    onClick={() => {
                      fetchData(
                        'put',
                        '/campaign/proposition',
                        { id: proposition.id },
                        { deliveryHistory: proposition.deliveryHistory }
                      );
                      this.setState({ openRequest: false });
                    }}>
                    저장
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            {delivery}
            <div>
              <span style={{ width: 80, display: 'inline-block' }}></span>
              <a
                href="#"
                onClick={() => {
                  this.setState({ openWaybill: true });
                }}>
                운송장 등록/수정
              </a>
              <Dialog
                onClose={() => {
                  this.setState({ openWaybill: false });
                }}
                open={openWaybill}>
                <DialogTitle id="form-dialog-title">운송장</DialogTitle>
                <DialogContent>
                  <Select
                    fullWidth
                    value={proposition.delivery.courier}
                    onChange={evt => (proposition.delivery.courier = evt.target.value)}>
                    {Object.keys(this.props.options).map(key => {
                      return <MenuItem value={key}>{this.props.options[key]}</MenuItem>;
                    })}
                  </Select>
                  <TextField
                    margin="dense"
                    label="운송장"
                    fullWidth
                    value={proposition.delivery.waybill}
                    onChange={evt => {
                      proposition.delivery.waybill = evt.target.value;
                      this.setState({ proposition: proposition });
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      this.setState({ openWaybill: false });
                    }}>
                    닫기
                  </Button>
                  <Button
                    onClick={() => {
                      fetchData(
                        'put',
                        '/campaign/proposition',
                        { id: proposition.id },
                        { delivery: proposition.delivery }
                      );
                      this.setState({ openWaybill: false });
                    }}>
                    저장
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
