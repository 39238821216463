import React, { useEffect } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import DeliveryItem from '../../../components/DeliveryItem';
import { CASHBACK_STATUS, CONTRACT_STATUS, DELIVERY_STATUS, REWARD_STATUS } from '../../constants';

import { InsertPhoto } from '@material-ui/icons';
import CustomModal from '../../../../../components/CustomModal';
import CustomTextField from '../../../../../components/CustomTextField';
import CustomSelect from '../../../../../components/CustomSelect';
import CustomSwitch from '../../../../../components/CustomSwitch';

const ContractInformation = props => {
  useEffect(() => {
    if (props.contract.purchaseImgUrl) {
      const image = new Image();
      image.src = props.contract.purchaseImgUrl; // 미리 로드할 이미지
    }
  }, []);

  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomSelect
              // NOTE : 취소 이외의 status 변경이 필요하다면, 향후에 disabled를 풀고 API를 통해 호출해서 처리할 필요가 있음
              disabled={true}
              layoutStyle={{ flex: 1 }}
              label="currentContractStatus"
              value={props.contract.currentContractStatus}
              menus={CONTRACT_STATUS.map(status => ({
                name: status,
                value: status,
              }))}
              onChange={event => {
                props.onChangeContract('currentContractStatus', event.target.value);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'deadline'}
              value={props.contract.deadline}
              onChange={event => {
                props.onChangeContract('deadline', event.target.value);
              }}
            />
            <CustomSwitch
              label="extended"
              layoutStyle={{ flex: 1 }}
              checked={Boolean(props.contract.extended) || false}
              onChange={event => {
                props.onChangeContract('extended', event.target.checked);
              }}
            />
          </div>

          <div style={{ display: 'flex', gap: 16 }}>
            <CustomSwitch
              label="isReviewed"
              layoutStyle={{ flex: 1 }}
              checked={Boolean(props.contract.isReviewed) || false}
              onChange={event => {
                props.onChangeContract('isReviewed', event.target.checked);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'reviewPostingUrl'}
              value={props.contract.reviewPostingUrl || ''}
              onChange={event => {
                props.onChangeContract('reviewPostingUrl', event.target.value);
              }}
            />
          </div>

          {props.contract.deliveryMethod === 'REIMBURSEMENT' && (
            <div style={{ display: 'flex', gap: 16 }}>
              <CustomSwitch
                label="isPurchased"
                layoutStyle={{ flex: 1 }}
                checked={Boolean(props.contract.isPurchased) || false}
                onChange={() => {
                  props.onChangeContract('isPurchased', !props.contract.isPurchased);
                }}
              />
              <div style={{ display: 'flex', alignItems: 'flex-end', flex: 1 }}>
                <CustomTextField
                  layoutStyle={{ flex: 1 }}
                  label={'purchaseImgUrl'}
                  value={props.contract.purchaseImgUrl}
                  onChange={event => {
                    props.onChangeContract('purchaseImgUrl', event.target.value);
                  }}
                />

                {props.contract.purchaseImgUrl && (
                  <CustomModal
                    title=""
                    content={
                      <img
                        src={props.contract.purchaseImgUrl}
                        alt="purchaseLinkImage"
                        style={{ width: '100%', height: 'auto' }}
                        loading="lazy"
                      />
                    }
                    buttonIcon={<InsertPhoto />}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                  />
                )}
              </div>
            </div>
          )}

          <div style={{ display: 'flex', gap: 16 }}>
            {props.contract.deliveryMethod === 'DIRECT_SHIPPING' && (
              <CustomSelect
                layoutStyle={{ flex: 1 }}
                label="currentDeliveryStatus"
                value={props.contract.currentDeliveryStatus}
                menus={DELIVERY_STATUS.map(status => ({
                  name: status,
                  value: status,
                }))}
                onChange={event => {
                  props.onChangeContract('currentDeliveryStatus', event.target.value);
                }}
              />
            )}

            {props.contract.deliveryMethod === 'REIMBURSEMENT' && (
              <CustomSelect
                // NOTE : 취소 이외의 status 변경이 필요하다면, 향후에 disabled를 풀고 API를 통해 호출해서 처리할 필요가 있음
                layoutStyle={{ flex: 1 }}
                disabled={true}
                label="currentCashbackStatus"
                value={props.contract.currentCashbackStatus}
                menus={CASHBACK_STATUS.map(status => ({
                  name: status,
                  value: status,
                }))}
                onChange={event => {
                  props.onChangeContract('currentCashbackStatus', event.target.value);
                }}
              />
            )}

            {props.contract.rewardAmount > 0 && (
              <CustomSelect
                layoutStyle={{ flex: 1 }}
                label="currentRewardStatus"
                value={props.contract.currentRewardStatus}
                menus={REWARD_STATUS.map(status => ({
                  name: status,
                  value: status,
                }))}
                onChange={event => {
                  props.onChangeContract('currentRewardStatus', event.target.value);
                }}
              />
            )}
          </div>
          {props.contract.deliveryMethod === 'DIRECT_SHIPPING' && props.contract.deliveryAddress && (
            <DeliveryItem item={props.contract.deliveryAddress} />
          )}

          <CustomTextField
            label={'memo'}
            value={props.contract.memo}
            layoutStyle={{ flex: 1 }}
            fullWidth
            multiline
            onChange={event => {
              props.onChangeContract('memo', event.target.value);
            }}
          />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ContractInformation;
