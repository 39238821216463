import React, { useRef } from 'react';

import CustomTextField from '../../../components/CustomTextField';
import CustomSwitch from '../../../components/CustomSwitch';
import CustomButton from '../../../components/CustomButton';

import ImageGallery from '../../../components/ImageList';
import { updateInquiry } from '../../../services/supermembers/inquiry';
import { INQUIRY_MODIFY_ALLOWED_CATEGORY } from '../../../constants';

const Information = ({ inquiry, setInquiry }) => {
  const initialIsAnswered = useRef(inquiry.isAnswered || false);

  const onSaveHandler = async () => {
    const modifiedInquiry = { ...inquiry };

    try {
      const response = await updateInquiry(inquiry.id, modifiedInquiry);

      if (response) {
        window.location.reload();
        alert('문의 수정이 완료되었습니다.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
        }}>
        <div style={{ display: 'flex', gap: 16 }}>
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'ID'}
            value={inquiry.id}
            onChange={event => {
              setInquiry({ ...inquiry, id: event.target.value });
            }}
          />
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'ADID'}
            value={inquiry.adId}
            onChange={event => {
              setInquiry({ ...inquiry, uid: event.target.value });
            }}
          />
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'UID'}
            value={inquiry.uid}
            onChange={event => {
              setInquiry({ ...inquiry, uid: event.target.value });
            }}
          />
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'앱 버전'}
            value={inquiry.appVersion}
            onChange={event => {
              setInquiry({ ...inquiry, appVersion: event.target.value });
            }}
          />
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'기기 종류'}
            value={inquiry.device}
            onChange={event => {
              setInquiry({ ...inquiry, device: event.target.value });
            }}
          />
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'OS'}
            value={inquiry.os}
            onChange={event => {
              setInquiry({ ...inquiry, os: event.target.value });
            }}
          />
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'OS 버전'}
            value={inquiry.osVersion}
            onChange={event => {
              setInquiry({ ...inquiry, osVersion: event.target.value });
            }}
          />
        </div>

        <div style={{ display: 'flex', gap: 16 }}>
          <CustomTextField
            layoutStyle={{ flex: 1 }}
            label={'카테고리'}
            value={inquiry.category}
            onChange={event => {
              setInquiry({ ...inquiry, category: event.target.value });
            }}
          />
          <CustomTextField
            layoutStyle={{ flex: 1 }}
            label={'하위 카테고리'}
            value={inquiry.subCategory}
            onChange={event => {
              setInquiry({ ...inquiry, subCategory: event.target.value });
            }}
          />
        </div>
        <CustomTextField
          multiline={true}
          layoutStyle={{ flex: 1 }}
          label={'내용'}
          value={inquiry.contentText}
          onChange={event => {
            setInquiry({ ...inquiry, contentText: event.target.value });
          }}
        />
        <CustomTextField
          multiline={true}
          layoutStyle={{ flex: 1 }}
          label={'추가 정보'}
          value={inquiry.additionalInfo}
          onChange={event => {
            setInquiry({ ...inquiry, additionalInfo: event.target.value });
          }}
        />

        <div style={{ display: 'flex', gap: 16 }}>
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'응답일자'}
            value={inquiry.answeredAt}
            onChange={event => {
              setInquiry({ ...inquiry, answeredAt: event.target.value });
            }}
          />
          <CustomTextField
            disabled={true}
            layoutStyle={{ flex: 1 }}
            label={'생성일자'}
            value={inquiry.createdAt}
            onChange={event => {
              setInquiry({ ...inquiry, createdAt: event.target.value });
            }}
          />
        </div>

        <ImageGallery images={inquiry.contentImages || []} />

        <CustomSwitch
          layoutStyle={{ flex: 1 }}
          label={'처리여부'}
          checked={inquiry.isAnswered}
          disabled={initialIsAnswered.current || !INQUIRY_MODIFY_ALLOWED_CATEGORY.includes(inquiry.category)}
          tooltip={'한 번 변경 후 다시 변경할 수 없습니다.'}
          onChange={event => {
            setInquiry({ ...inquiry, isAnswered: event.target.checked });
          }}
        />

        <div style={{ textAlign: 'right' }}>
          <CustomButton disabled={initialIsAnswered.current} type="primary" onClick={onSaveHandler}>
            저장
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default Information;
