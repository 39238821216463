import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import Button from '@material-ui/core/Button';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import BackgroundForm from './background-form.js';
import ButtonForm from './button-form.js';
import ImageForm from './image-form.js';
import TextForm from './text-form.js';

class FormDialog extends Component {
  state = {
    type: 'image',
    ...this.props.initialValue,
  };
  handleChange = obj => {
    this.setState({ ...obj });
  };
  handleSave = () => {
    this.props.handleSave(this.state);
    this.setState({ type: 'image', url: '' });
  };
  render() {
    return (
      <Dialog
        className="contents-editor"
        open={this.props.open}
        onClose={this.props.close}
        fullWidth
        fullScreen={this.props.fullScreen}>
        <DialogTitle>컨텐츠 등록</DialogTitle>
        <DialogContent>
          <label>컨텐츠 종류</label>
          <Select
            value={this.state.type}
            onChange={evt => this.handleChange({ [evt.target.name]: evt.target.value })}
            inputProps={{
              name: 'type',
            }}>
            <MenuItem value={''} disabled>
              컨텐츠 종류
            </MenuItem>
            <MenuItem value={'image'}>이미지</MenuItem>
            <MenuItem value={'button'}>버튼</MenuItem>
            <MenuItem value={'text'}>텍스트</MenuItem>
          </Select>
          {this.state.type === 'text' ? (
            <div>
              <TextForm
                initialValue={this.state.text}
                initialStyle={this.state.textStyle}
                multiline={true}
                handleChange={this.handleChange}
              />
              <BackgroundForm initialStyle={this.state.backgroundStyle} handleChange={this.handleChange} />
            </div>
          ) : this.state.type === 'button' ? (
            <div>
              <TextForm
                initialValue={this.state.label}
                initialStyle={this.state.textStyle}
                handleChange={this.handleChange}
              />
              <ButtonForm
                initialStyle={this.state.buttonStyle}
                initialType={this.state.routeType}
                initialName={this.state.routeType === 'app' ? this.state.routeName : this.state.routeURL}
                initialParams={this.state.routeParams}
                handleChange={this.handleChange}
              />
              <BackgroundForm initialStyle={this.state.backgroundStyle} handleChange={this.handleChange} />
            </div>
          ) : (
            <div>
              <ImageForm initialURL={this.state.url} handleChange={this.handleChange} />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.close} variant="contained" color="primary">
            취소
          </Button>
          <Button onClick={this.handleSave} variant="contained" color="secondary" autoFocus>
            등록
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(FormDialog);
