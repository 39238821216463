import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../app/action';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './cards.css';
import { fetchData } from '../../app/api';
import Card from './card';
import { withStyles } from '@material-ui/core/styles/index';
import TextField from '@material-ui/core/TextField';
import momentLib from 'moment-timezone';
import Button from '@material-ui/core/Button';

import ExcelDownload from '../excel-download';
import _ from 'lodash';

const styles = theme => ({
  Select: {
    marginTop: '4px',
    width: '100%',
  },
  save: {
    width: '100%',
    height: '30px',
    borderRadius: '3px',
    cursor: 'pointer',
    backgroundColor: 'rgba(82,82,82,.12)',
  },
  date: {
    width: '30%',
  },
});

const today = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');

class CampaignKeywordCard extends Component {
  state = {
    total: 0,
    campaignKeywords: [],
    date: today,
  };

  componentDidMount() {
    fetchData('get', '/campaign/keyword', { campaignId: this.props.campaign })
      .then(datas => {
        if (datas.campaignKeywords.length === 0) {
          datas.campaignKeywords.push({
            campaignId: this.props.campaign,
          });
        }
        this.setState({ campaignKeywords: datas.campaignKeywords });
      })
      .catch(err => {
        console.error(err);
        alert('가맹점 키워드 조회 실패');
      });
  }

  handleSave = (keyword, confirm) => {
    this.props.activateLoading();
    let promiseFunc = Promise.resolve();
    if (!keyword.id && confirm) {
      if (keyword.name) {
        promiseFunc = fetchData('post', '/campaign/keyword', {
          campaignId: this.props.campaign,
          name: keyword.name,
        });
      }
    } else {
      if (keyword.id) {
        promiseFunc = fetchData('put', '/campaign/keyword', {
          id: keyword.id,
          campaignId: this.props.campaign,
          name: keyword.name,
        });
      }
    }
    promiseFunc
      .then(() => {
        console.log('done');
        window.location.reload();
        this.props.deactivateLoading();
      })
      .catch(err => {
        this.props.deactivateLoading();
        console.error(err);
        alert('저장 실패');
      });
  };

  render() {
    const { classes } = this.props;

    let groupedRanks = {};
    let fields = {};
    let cards = this.state.campaignKeywords.map((keyword, i) => {
      if (keyword.ranks) {
        groupedRanks = _.groupBy(Object.keys(keyword.ranks), date => {
          keyword.ranks[date].rank = _.minBy(keyword.ranks[date], 'rank');
          let dates = date.split('-');
          return dates[0] + dates[1];
        });
        Object.keys(groupedRanks).forEach(month => {
          groupedRanks[month].minDate = _.minBy(groupedRanks[month], date => {
            // let rank = _.minBy(keyword.ranks[date], 'rank');
            return (keyword.ranks[date].rank || {}).rank;
          });

          fields['name'] = 'name';
          fields[month] = month;
          if (groupedRanks[month].minDate) {
            groupedRanks[month] = keyword.ranks[groupedRanks[month].minDate].rank.rank;
            this.state.campaignKeywords[i][month] = groupedRanks[month];
          } else {
            delete groupedRanks[month];
          }
        });
      }

      return (
        <Card
          key={i}
          index={i}
          campaignKeyword={keyword}
          isLast={this.state.campaignKeywords.length - 1 === i}
          date={this.state.date}
          handlePlus={() => {
            this.setState(prevState => ({
              campaignKeywords: [...prevState.campaignKeywords, { campaignId: this.props.campaign }],
            }));
          }}
          handleMinus={index => {
            if (window.confirm('정말 삭제 하시겠습니까')) {
              if (this.state.campaignKeywords[index].id) {
                fetchData('delete', '/campaign/keyword', {
                  id: this.state.campaignKeywords[index].id,
                });
              }
              let keywords = this.state.campaignKeywords.filter((keyword, i) => {
                return i !== index;
              });
              this.setState({ campaignKeywords: keywords });
            }
          }}
          handleChange={(index, keyword) => {
            this.state.campaignKeywords[index] = keyword;
            this.setState({ campaignKeywords: this.state.campaignKeywords });
          }}
          handleSave={(keyword, confirm) => {
            this.handleSave(keyword, confirm);
          }}
        />
      );
    });

    return (
      <ExpansionPanel defaultExpanded={true}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h3>상위 노출 공유</h3>
          <ExcelDownload data={this.state.campaignKeywords} filename={this.props.campaign} fields={fields}>
            Download
          </ExcelDownload>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="keyword-cards">
            <div className="line">
              <TextField
                id="date"
                type="date"
                defaultValue={today}
                className={classes.date}
                onChange={event => {
                  this.setState({ date: event.target.value });
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Button
                color="secondary"
                className={classes.button}
                onClick={() => {
                  Promise.all(
                    this.state.campaignKeywords.map((keyword, i) => {
                      if (keyword.name && !keyword.rank) {
                        this.handleSave(keyword, true);
                      }
                    })
                  )
                    .then(() => {
                      window.location.reload();
                      this.props.deactivateLoading();
                    })
                    .catch(err => {
                      this.props.deactivateLoading();
                      console.error(err);
                      alert('저장 실패');
                    });
                }}>
                일괄 저장
              </Button>
              <Button
                color="secondary"
                className={classes.button}
                onClick={() => {
                  this.state.campaignKeywords.map((keyword, i) => {
                    Promise.all(
                      this.state.campaignKeywords.map((keyword, i) => {
                        if (keyword.name) {
                          this.handleSave(keyword, false);
                        }
                      })
                    );
                  });
                }}>
                전체 재검색
              </Button>
            </div>
            {cards}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(CampaignKeywordCard));
