import React, { useEffect, useState } from 'react';
import Information from './components/Information';
import { getInquiry } from '../../services/supermembers/inquiry';
import { UserCard } from '../../components';
import PostInquiryAnswer from './components/PostInquiryAnswer';
import InquiryAnswerLists from './components/InquiryAnswerLists';

const Inquiry = props => {
  const id = props.location.query.id;
  const [inquiry, setInquiry] = useState(null);

  useEffect(() => {
    const fetchInquiry = async () => {
      try {
        const result = await getInquiry(id);
        console.log('fetchInquiry', result);
        setInquiry(result.inquiry);
      } catch (error) {
        console.error('문의 조회 실패:', error);
      }
    };
    if (id) {
      fetchInquiry();
    }
  }, [id]);

  return (
    <div className="container">
      {inquiry && (
        <div className="contents">
          <div className="editor-container">
            <div className="editor">
              <Information inquiry={inquiry} setInquiry={setInquiry} />
            </div>
          </div>
          <div className="additional-container">
            <UserCard uid={inquiry.uid} />
            <InquiryAnswerLists id={inquiry.id} inquiryAnswers={inquiry.App_Inquiry_Answer} />
            <PostInquiryAnswer id={inquiry.id} />
            <div style={{ width: 1, height: 100 }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Inquiry;
