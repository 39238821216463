import React from 'react';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';
import Clipboard from 'clipboard';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Drawer from '@material-ui/core/Drawer';
import Menu from '@material-ui/icons/Menu';

import './container.css';

import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Switch } from '@material-ui/core';

const routeMenu = {
  supermembers: {
    대시보드: {
      links: ['/'],
    },
    공지사항: {
      links: ['/notices', 'notice'],
    },
    업체: {
      links: ['/company-users', '/company-user'],
    },
    '구독 가맹점': {
      links: [
        '/companies3',
        '/company3',
        '/companies',
        '/company',
        '/company3-manual',
        '/contracts',
        '/contract',
        '/paycards',
        '/paycard',
        '/paycards-filter',
        '/userpays',
        '/userpay',
      ],
      menu: {
        이용내역: ['/contracts', '/contract'],
        가맹점: ['/companies3', '/company3', '/companies', '/company', '/company3-manual'],
        결제: ['/paycards', '/paycard'],
        '결제-필터': ['/paycards-filter'],
        회원결제: ['/userpays', '/userpay'],
        패키지: ['/packages', '/package'],
        프리미엄패키지: ['/premiums', '/premium'],
        충전금: ['/sumempoints', '/sumempoint'],
      },
    },
    '매칭 캠페인': {
      links: [
        '/campaigns',
        '/campaign',
        '/propositions',
        '/proposition',
        '/user-points',
        '/userpoint',
        '/company-points',
      ],
      menu: {
        매칭내역: ['/propositions', '/proposition'],
        캠페인: ['/campaigns', '/campaign'],
        '회원 포인트': ['/user-points', '/userpoint'],
        '업체 충전금액': ['/company-points'],
      },
    },
    구매링크: {
      links: ['/purchase-link'],
    },
    블로거: {
      links: ['/bloggers'],
    },
    블로거: {
      links: ['/bloggers'],
    },
    인스타그래머: {
      links: ['/instagrammers'],
    },
    유튜버: {
      links: ['/youtubers'],
    },
    계정: {
      links: ['/accounts'],
    },
    메시지: {
      links: ['/messages'],
    },
    탈퇴신청: {
      links: ['/drawns', '/drawn'],
    },
    '1:1 문의': {
      links: ['/inquiries', '/inquiry'],
    },
    불편사항: {
      links: ['/reports', '/report'],
    },
    정보수정요청: {
      links: ['/reportinfos'],
    },
    키워드추가요청: {
      links: ['/keyword-requests'],
    },
    기타: {
      links: ['/etc'],
    },
    // 달력: [ '/calendar' ],
    // 버그리포트: [ '/bugs' ],
  },
  cosduck: {
    대시보드: {
      links: ['/'],
    },
    유저: { links: ['/cosduck/users', '/cosduck/user'] },
    업체: { links: ['/cosduck/company-user', '/cosduck/company'] },
    광고: { links: ['/cosduck/ads', 'cosduck/ad'] },
    이용내역: { links: ['/cosduck/contracts', '/cosduck/contract'] },
    이슈: { links: ['/cosduck/report'] },
    커머스: {
      links: ['/cosduck/productReviewConfigs', '/cosduck/productReviewConfig'],
      menu: {
        '제품 리뷰 설정': ['/cosduck/productReviewConfigs', '/cosduck/productReviewConfig'],
      },
    },
    핸드북: { links: ['/cosduck/handbook'] },
  },
};

const pageMap = {
  '/': { name: '대시보드' },
  '/notices': { name: '공지사항' },
  '/notice': { name: '공지사항상세' },
  '/contracts': { name: '이용내역' },
  '/contract': { name: '이용내역상세' },
  '/company-users': { name: '업체' },
  '/company-user': { name: '업체상세' },
  '/companies3': { name: '가맹점' },
  '/company3': { name: '가맹점상세' },
  '/paycards': { name: '결제' },
  '/paycards-filter': { name: '결제-필터' },
  '/paycard': { name: '결제상세' },
  '/userpays': { name: '회원결제' },
  '/userpay': { name: '회원결제상세' },
  '/packages': { name: '패키지' },
  '/package': { name: '패키지상세' },
  '/premiums': { name: '프리미엄패키지' },
  '/premium': { name: '프리미엄패키지상세' },
  '/sumempoints': { name: '멤버스충전금' },
  '/sumempoint': { name: '멤버스충전금상세' },
  '/campaigns': { name: '매칭 캠페인' },
  '/bloggers': { name: '블로거' },
  '/instagrammers': { name: '인스타그래머' },
  '/accounts': { name: '계정' },
  '/account': { name: '계정상세' },
  '/messages': { name: '메시지' },
  '/message': { name: '메시지상세' },
  '/user': { name: '유저상세' },
  '/drawns': { name: '탈퇴신청' },
  '/reports': { name: '불편사항' },
  '/inquiries': { name: '1:1 문의' },
  '/inquiry': { name: '1:1 문의' },
  '/reportinfos': { name: '정보수정요청' },
  '/etc': { name: '기타' },
  // '/bugs': { name: '버그리포트' },
  '/propositions': { name: '매칭 내역' },
  '/user-points': { name: '회원 포인트 리스트' },
  '/company-points': { name: '업체 충전금액' },
  '/keyword-requests': { name: '키워드추가요청' },
  '/cosduck/users': { name: '유저목록' },
  '/cosduck/user': { name: '유저 상세내역' },
  '/cosduck/ads': { name: '광고목록' },
  '/cosduck/ad': { name: '광고 상세내역' },
  '/cosduck/contracts': { name: '이용목록' },
  '/cosduck/contract': { name: '이용 상세내역' },
  '/cosduck/company-user': { name: '업체목록' },
  '/cosduck/company': { name: '업체상세' },
  '/cosduck/handbook': { name: '핸드북' },
  '/cosduck/report': { name: '이슈' },
};
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#f5f5f5',
      main: '#f5f5f5',
      dark: '#c7c7c7',
      contrastText: '#000',
    },
    secondary: {
      light: '#bd1328',
      main: '#bd1328',
      dark: '#7d0010',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Noto Sans KR, sans-serif',
    fontWeight: 300,
  },
});
const styles = {
  paper: {
    backgroundColor: '#525252',
  },
};

const CustomSwitch = withStyles({
  root: {
    height: '32px !important',
    width: '65px !important',
    padding: '0px !important',
    '&$hover': {
      backgroundColor: 'transparent',
    },
  },
  switchBase: {
    padding: '0px !important',
    '&$checked': {
      transform: 'translateX(30px) !important',
      '& + $track': {
        background:
          'linear-gradient(169deg, rgba(234,42,58,1) 1%, rgba(255,164,171,0.7161458333333333) 50%, rgba(234,42,58,0.7945772058823529) 100%) !important',
      },
    },
    '&$hover': {
      backgroundColor: 'transparent',
    },
    transition: '0.3s !important',
  },
  thumb: {
    width: '30px !important',
    height: '30px !important',
    padding: '0px !important',
    backgroundSize: 'contain !important',
    backgroundPosition: 'center !important',
    backgroundColor: 'white !important',
    backgroundImage: props => (props.checked ? 'url(/asset/supermembers.png)' : 'url(/asset/cosduck.png)'),
  },
  track: {
    height: '32px !important',
    width: '60px !important',
    borderRadius: '20px !important',
    background:
      'linear-gradient(204deg, rgba(48,166,255,1) 13%, rgba(124,198,255,0.7721682422969187) 40%, rgba(82,181,255,0.6573223039215687) 58%, rgba(45,141,255,1) 79%) !important', // 기본 트랙 색상
  },
  checked: {},
  focusVisible: {},
})(Switch);

class Container extends React.Component {
  state = {
    menuOpened: false,
    service: localStorage.getItem('service') || 'supermembers',
  };
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyPress);

    this.props.authChanged(user => {
      if (this.props.user === null) this.props.push('/signin');
      this.props.deactivateLoading();
    });
  }
  logout = () => {
    this.props.logout();
    this.props.push('/signin');
  };
  toggleDrawer = () => {
    this.setState({ menuOpened: !this.state.menuOpened });
  };
  setClipboard(comp) {
    if (comp !== null) new Clipboard(comp);
  }

  handleKeyPress = event => {
    if ((event.shiftKey && event.altKey && event.key === 'C') || event.key === 'Ç') {
      const service = this.state.service === 'supermembers' ? 'cosduck' : 'supermembers';

      localStorage.setItem('service', service);
      this.setState({
        service: service,
      });
    }
  };

  render() {
    const { path, user, loading, classes } = this.props;
    let links = Object.keys(routeMenu[this.state.service]).map((menuName, index) => {
      let submenus = '';
      if (
        routeMenu[this.state.service][menuName]
          .menu /* && routeMenu[this.state.service][menuName].links.indexOf(path) >= 0*/
      ) {
        submenus = Object.keys(routeMenu[this.state.service][menuName].menu).map((submenu, index) => {
          return (
            <ul style={{ paddingLeft: '20px', margin: '8px 0px' }}>
              <li
                key={index}
                className={routeMenu[this.state.service][menuName].menu[submenu].indexOf(path) >= 0 ? 'active' : ''}
                style={{ padding: '0px' }}>
                <Link to={routeMenu[this.state.service][menuName].menu[submenu][0]} onClick={this.toggleDrawer}>
                  {submenu}
                </Link>
              </li>
            </ul>
          );
        });
      }

      return (
        <li key={index} className={routeMenu[this.state.service][menuName].links.indexOf(path) >= 0 ? 'active' : ''}>
          <Link to={routeMenu[this.state.service][menuName].links[0]} onClick={this.toggleDrawer}>
            {menuName}
          </Link>
          {submenus}
        </li>
      );
    });
    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className="contents">
          <Drawer open={this.state.menuOpened} onClose={this.toggleDrawer} classes={{ paper: classes.paper }}>
            <div
              className="left-menu"
              style={{
                display: user === null || user.isAnonymous ? 'none' : '',
              }}>
              {this.state.service === 'supermembers' ? (
                <img src={process.env.PUBLIC_URL + '/asset/supermembers_logo.png'} alt="logo" />
              ) : (
                <img src={process.env.PUBLIC_URL + '/asset/cosduck_logo.png'} alt="logo" width="140" />
              )}

              <ul>
                {links}
                <li>
                  <a onClick={this.logout}>로그아웃</a>
                </li>
              </ul>
            </div>
          </Drawer>
          <div className="right-section">
            <header
              style={{
                display: user === null || user.isAnonymous ? 'none' : 'flex',
              }}>
              <IconButton onClick={this.toggleDrawer} style={{ marginRight: 10 }}>
                <Menu />
              </IconButton>
              <div className="header-title">
                {pageMap[path] ? pageMap[path].name : ''}
                {this.props.location.query.id || this.props.location.query.uid ? (
                  <Tooltip title="copy" placement="bottom">
                    <span
                      className="header-id-span"
                      data-clipboard-text={this.props.location.query.id || this.props.location.query.uid}
                      ref={comp => this.setClipboard(comp)}>
                      {this.props.location.query.id || this.props.location.query.uid}
                    </span>
                  </Tooltip>
                ) : (
                  ''
                )}
              </div>

              <div className="header-switch">
                <Tooltip title={<div style={{ fontSize: '10px' }}>⇧ + ⌥ + C</div>} arrow placement="bottom">
                  <div>
                    <CustomSwitch
                      checked={this.state.service === 'supermembers'}
                      onChange={() => {
                        const service = this.state.service === 'supermembers' ? 'cosduck' : 'supermembers';

                        localStorage.setItem('service', service);
                        this.setState({
                          service: service,
                        });
                      }}
                      onKeyDown={this.handleKeyPress}
                    />
                  </div>
                </Tooltip>
              </div>
            </header>
            <div className="container">{user === null && path !== '/signin' ? '' : this.props.children}</div>
          </div>
          <div className={'loading-background' + (loading ? ' active' : '')}>
            <CircularProgress size={40} thickness={5} style={{ color: 'white' }} />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: state.app.loading,
    user: state.auth.user,
    path: state.routing.locationBeforeTransitions.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Container));
