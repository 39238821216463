import React from 'react';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import * as api from '../api';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class EditorAuth extends React.Component {
  state = {
    proposition: {},
    user: {},
    point: {},
  };
  render() {
    const { proposition } = this.state;

    if (Object.keys(proposition).length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>인증 정보</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <label>구분</label>
                <TextField fullWidth value={proposition.authBusiness ? '사업자' : '개인'} />
                <label>이름/법인명</label>
                <TextField fullWidth value={proposition.authName} />
                <label>주민번호/사업자등록번호</label>
                <TextField fullWidth value={proposition.authID} />
                <label>업태/종목</label>
                <TextField fullWidth value={proposition.authCategory} />
                <label>사업장 주소</label>
                <TextField fullWidth value={proposition.authAddr} />
                <label>사업자등록증 사본</label>
                <TextField fullWidth value={proposition.authBusiness} />
                <label>통장사본</label>
                <TextField fullWidth value={proposition.copyBankbook} />
              </section>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    this.setState({ proposition: this.props.proposition });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ proposition: newProps.proposition });
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(EditorAuth);
