import React from 'react';
import TextField from '@material-ui/core/TextField';

export default class StringEditor extends React.Component {
  state = {};
  render() {
    const { field, disabled } = this.props;
    return (
      <div className="row">
        <label>{field.name}</label>
        <TextField
          fullWidth
          multiline={field.multiple ? true : false}
          rows={6}
          value={field.value === null ? '' : field.value}
          disabled={disabled}
          onChange={evt => this.props.handleChange(evt.target.value)}
        />
      </div>
    );
  }
}
