import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';

import InfoBasic from './info-basic-backup';
import InfoPaycard from './info-paycard-backup';
import InfoPay from './info-pay-backup';

import * as api from '../api';

class ClassCompanyPaycard extends React.Component {
  state = {
    id: this.props.location.query.id,
    channel: this.props.location.query.channel,
    company: {},
    paycards: [],
    cards: [],
    snackOpen: false,
  };
  render() {
    const { channel, company, paycards, cards } = this.state;

    if (Object.keys(company).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container contents-editor">
            <InfoBasic
              company={company}
              channel={channel}
              cards={cards}
              paycards={paycards}
              handleChange={this.handleChange}
              handleSave={this.handleSave}
            />
            <InfoPay company={company} channel={channel} paycards={paycards} />
          </div>
          <div className="additional-container contents-editor">
            <InfoPaycard company={company} paycards={paycards} cards={cards} />
            <Snackbar
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={this.state.snackOpen}
              autoHideDuration={4000}
              onClose={() => this.setState({ snackOpen: false })}
              message={<span>업데이트 성공</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => this.setState({ snackOpen: false })}>
                  <i className="material-icons">close</i>
                </IconButton>,
              ]}
            />
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }

  componentDidMount() {
    this.props.activateLoading();
    Promise.all([api.getCompanies3(this.state.id), api.getCompanyPayCards(this.state.id, this.state.channel)])
      .then(results => {
        this.setState({ paycards: results[1].paycards });
        this.setState({ company: results[0].companies[0] });

        return api.getCompanyUserCards(results[0].companies[0].user_id);
      })
      .then(result => {
        this.setState({ cards: result.cards });
        this.props.deactivateLoading();
      });
  }

  handleChange = (name, value) => {
    console.log(name, value);
    this.setState({ company: { ...this.state.company, [name]: value } });
  };
  handleSave = () => {};
  handleCancel = () => {
    this.props.push('/paycards');
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

// default export를 named export로 변경
export default connect(null, mapDispatchToProps)(ClassCompanyPaycard);
