export const primitive = {
  Primary_light: 'rgba(234, 42, 58, 1)',
  Primary_medium: 'rgba(209, 13, 34, 1)',
  Primary_dark: 'rgba(189, 17, 34, 1)',
  Secondary_light: 'rgba(68, 99, 187, 1)',
  Secondary_medium: 'rgba(55, 81, 153, 1)',
  Secondary_dark: 'rgba(44, 64, 119, 1)',
  Tertiary_light: 'rgba(12, 138, 255, 1)',
  Tertiary_medium: 'rgba(0, 101, 253, 1)',
  Tertiary_dark: 'rgba(0, 87, 217, 1)',
  Green_light: 'rgba(90, 219, 160, 1)',
  Green_medium: 'rgba(52, 205, 137, 1)',
  Green_dark: 'rgba(21, 192, 114, 1)',
  Yellow_light: 'rgba(254, 211, 51, 1)',
  Yellow_medium: 'rgba(252, 204, 34, 1)',
  Yellow_dark: 'rgba(238, 187, 0, 1)',
  Grayscale_01: 'rgba(32, 32, 32, 1)',
  Grayscale_02: 'rgba(58, 58, 58, 1)',
  Grayscale_03: 'rgba(128, 128, 128, 1)',
  Grayscale_04: 'rgba(194, 194, 194, 1)',
  Grayscale_05: 'rgba(219, 219, 219, 1)',
  Grayscale_06: 'rgba(245, 245, 245, 1)',
  Grayscale_07: 'rgba(251, 251, 251, 1)',
  Grayscale_08: 'rgba(255, 255, 255, 1)',
  Tint_60: 'rgba(255, 255, 255, 0.6000000238418579)',
  Tint_80: 'rgba(255, 255, 255, 0.800000011920929)',
  Shade_20: 'rgba(58, 58, 58, 0.20000000298023224)',
  Shade_50: 'rgba(58, 58, 58, 0.5)',
  Shade_70: 'rgba(58, 58, 58, 0.699999988079071)',
  Primarypastel_light_lightness90: 'rgba(246, 162, 169, 1)',
};

export const Color = {
  COSDUCK_PRIMARY_COLOR: '#30A7FF',
  textbox_text_default: primitive.Grayscale_02,
  textbox_text_placeholder: primitive.Grayscale_04,
  textbox_border_default: primitive.Grayscale_02,
  textbox_border_focused: primitive.Grayscale_01,
  textbox_border_disabled: primitive.Grayscale_05,
  textbox_text_disabled: primitive.Grayscale_04,
  textbox_background_disabled: primitive.Grayscale_06,
  textbox_icon_default: primitive.Grayscale_03,
  text_default: primitive.Grayscale_02,
  text_secondary: primitive.Grayscale_03,
  text_disabled: primitive.Grayscale_05,
  text_red: primitive.Primary_light,
  text_yellow: primitive.Yellow_light,
  text_green: primitive.Green_dark,
  button_iconwithbg_icon_default: primitive.Grayscale_03,
  button_primary_background_default: primitive.Primary_light,
  button_textbutton_type1_background_hover: primitive.Grayscale_06,
  button_icon_icon_default: primitive.Grayscale_03,
  button_icon_icon_modify: primitive.Tertiary_light,
  button_iconwithbg_icon_hover: primitive.Grayscale_03,
  button_iconwithbg_icon_clicked: primitive.Grayscale_02,
  button_iconwithbg_icon_disabled: primitive.Grayscale_05,
  button_iconwithbg_background_default: primitive.Grayscale_05,
  button_iconwithbg_background_hover: primitive.Grayscale_04,
  button_iconwithbg_background_clicked: primitive.Grayscale_03,
  button_iconwithbg_background_disabled: primitive.Grayscale_06,
  button_textbutton_type1_background_clicked: primitive.Grayscale_07,
  button_textbutton_type1_text_default: primitive.Grayscale_02,
  button_textbutton_type1_text_disabled: primitive.Grayscale_05,
  button_primary_background_hover: primitive.Primary_medium,
  button_primary_background_clicked: primitive.Primary_dark,
  button_primary_background_disabled: primitive.Grayscale_05,
  button_primary_text_default: primitive.Grayscale_08,
  button_textbutton_type2_background_hover: primitive.Grayscale_06,
  button_textbutton_type2_background_clicked: primitive.Grayscale_07,
  button_textbutton_type2_text_default: primitive.Primary_light,
  button_textbutton_type2_text_disabled: primitive.Grayscale_05,
  button_primary_text_hover: primitive.Grayscale_08,
  button_primary_text_clicked: primitive.Grayscale_08,
  button_primary_text_disabled: primitive.Grayscale_08,
  button_secondary_background_default: primitive.Grayscale_06,
  button_secondary_background_hover: primitive.Grayscale_05,
  button_secondary_background_clicked: primitive.Grayscale_04,
  button_secondary_background_disabled: primitive.Grayscale_06,
  button_secondary_text_default: primitive.Grayscale_02,
  button_secondary_text_hover: primitive.Grayscale_02,
  button_secondary_text_clicked: primitive.Grayscale_02,
  button_secondary_text_disabled: primitive.Grayscale_05,
  header_textbutton_default: primitive.Grayscale_03,
  header_textbutton_hover: primitive.Grayscale_02,
  header_textbutton_clicked: primitive.Grayscale_02,
  header_textbuton_disabled: primitive.Grayscale_05,
  header_background: primitive.Grayscale_08,
  background_type1: primitive.Grayscale_06,
  toggle_background_active: primitive.Primarypastel_light_lightness90,
  toggle_background_inactive: primitive.Grayscale_04,
  toggle_handle_active: primitive.Primary_light,
  toggle_handle_inactive: primitive.Grayscale_05,
  label_type1: primitive.Secondary_light,
  statustext_pending: primitive.Secondary_light,
  statustext_complete: primitive.Grayscale_03,
  surface_type1: primitive.Grayscale_08,
  dropdown_icon: primitive.Grayscale_03,
  dropdown_text_placeholder: primitive.Grayscale_04,
  dropdown_background_disabled: primitive.Grayscale_06,
  dropdown_text_default: primitive.Grayscale_02,
  dropdown_border_default: primitive.Grayscale_02,
  dropdown_border_focused: primitive.Grayscale_01,
  dropdown_text_disabled: primitive.Grayscale_04,
  dropdown_border_disabled: primitive.Grayscale_05,
  divider_background: primitive.Grayscale_05,
  tab_text_active: primitive.Grayscale_02,
  tab_text_inactive: primitive.Grayscale_03,
  tab_activebar: primitive.Primary_light,
  tab_text_disabled: primitive.Grayscale_05,
  photoState_icon_active: primitive.Green_dark,
  photoState_icon_inactive: primitive.Grayscale_04,
};
