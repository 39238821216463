export const AdTypeEnum = {
  STANDARD: 'S',
  PREMIUM: 'A',
};

export const AdCategoryEnum = {
  PRODUCT_STANDARD: 'PRODUCT_STANDARD',
  STORE_STANDARD: 'STORE_STANDARD',
  PRODUCT_PREMIUM: 'PRODUCT_PREMIUM',
  STORE_PREMIUM: 'STORE_PREMIUM',
};

export const ChannelPlatformEnum = {
  NAVER: 'naver',
  INSTA: 'insta',
};

export const AdsProgressStepEnum = {
  REVIEWING: 0,
  PENDING_PAYMENT: 1,
  PENDING_ACTIVE: 2,
  ACTIVE: 3,
  PENDING_TERMINATION: 4,
  TERMINATED: 6, // NOTE: STEPPER에서 마지막은 active없이 completed로 표시하기 위해 6로 설정
};

export const AdsProgressStepLabelEnum = {
  REVIEWING: '검토 중',
  PENDING_PAYMENT: '결제 예정',
  PENDING_ACTIVE: '광고 준비 중',
  ACTIVE: '광고 중',
  PENDING_TERMINATION: '종료 예정',
  TERMINATED: '광고 종료',
};

export const PaymentMethodEnum = {
  CARD: 'card',
  CASH: 'cash',
  FREE: 'free',
};

export const PaymentStatusEnum = {
  SCHEDULED: 0,
  COMPLETE: 1,
  REQUESTED: 2,
  STOPPED: 3,
  RESTARTED: 4,
};

export const PaymentPlanEnum = {
  ONE: 1,
  THREE: 3,
  SIX: 6,
  TWELVE: 12,
};

export const PaymentEndReasonEnum = {
  CLOSED_BUSINESS: 1,
  ETC: 2,
  SERVICE_DISSATISFACTION: 3,
  LOW_USAGE: 4,
  LATER: 5,
  NO_CONTACT: 6,
  COST_BURDEN: 7,
  BLOGGER_SCORE_DISSATISFACTION: 8,
  OTHER_SERVICE: 9,
  SUFFICIENT_USE: 10,
  BLOGGER_ATTITUDE_DISSATISFACTION: 11,
};
