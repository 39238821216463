import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CustomTextField from '../../../../../components/CustomTextField';
import { ADS_TYPE, DELIVERY_METHOD, PLATFORM_TYPE } from '../../constants';
import CustomSelect from '../../../../../components/CustomSelect';

const AdsInformation = props => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}>
          <p
            onClick={() => {
              window.open('/cosduck/ad?id=' + props.contract.adId, '_blank');
            }}
            style={{
              fontSize: 20,
              fontWeight: 600,
              cursor: 'pointer',
            }}>
            ({props.contract.adId}) {props.contract.productName}
          </p>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomSelect
              layoutStyle={{ flex: 1 }}
              label="channel"
              value={props.contract.channel}
              menus={PLATFORM_TYPE.map(platform => ({
                name: platform,
                value: platform,
              }))}
              onChange={event => {
                props.onChangeContract('channel', event.target.value);
              }}
            />
            <CustomSelect
              layoutStyle={{ flex: 1 }}
              label="adsType"
              value={props.contract.adsType}
              menus={ADS_TYPE.map(type => ({
                name: type,
                value: type,
              }))}
              onChange={event => {
                props.onChangeContract('adsType', event.target.value);
              }}
            />
            <CustomSelect
              layoutStyle={{ flex: 1 }}
              label="deliveryMethod"
              value={props.contract.deliveryMethod}
              menus={DELIVERY_METHOD.map(dm => ({
                name: dm,
                value: dm,
              }))}
              onChange={event => {
                props.onChangeContract('deliveryMethod', event.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'totalPrice'}
              value={props.contract.totalPrice}
              onChange={event => {
                props.onChangeContract('totalPrice', event.target.value);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'discountRate'}
              value={props.contract.discountRate}
              onChange={event => {
                props.onChangeContract('discountRate', event.target.value);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'finalPrice'}
              value={props.contract.finalPrice}
              onChange={event => {
                props.onChangeContract('finalPrice', event.target.value);
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: 16 }}>
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'cashbackAmount'}
              value={props.contract.cashbackAmount}
              onChange={event => {
                props.onChangeContract('cashbackAmount', event.target.value);
              }}
            />
            <CustomTextField
              layoutStyle={{ flex: 1 }}
              label={'rewardAmount'}
              value={props.contract.rewardAmount}
              onChange={event => {
                props.onChangeContract('rewardAmount', event.target.value);
              }}
            />
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default AdsInformation;
