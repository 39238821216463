import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import CustomTextField from '../../../../components/CustomTextField';
import Button from '@material-ui/core/Button';

import AddressList from './components/AddressList';
import ContractList from './components/ContractList';
import CustomSwitch from '../../../../components/CustomSwitch';
import { getUser, updateUser } from '../../api/user';
import CustomSelect from '../../../../components/CustomSelect';
import { LEVEL } from '../constants';

const levels = LEVEL.map((item, index) => ({
  value: item,
  name: item,
}));

const User = props => {
  const [user, setUser] = useState(null);
  const [tiktokMetadata, setTiktokMetadata] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await getUser(props.location.query.id);
      setUser(result.data.data.user);
      if (result.data.data.tiktokMetadata) {
        setTiktokMetadata(result.data.data.tiktokMetadata);
      }
    };

    fetch();
  }, []);

  if (!user) {
    return null;
  }

  const onSave = async () => {
    const result = await updateUser(props.location.query.id, user);

    if (result.data.success) {
      alert('저장되었습니다.');
      window.location.reload();
    }
  };

  return (
    <>
      <div>
        <div className="container">
          <div className="contents">
            <div className="editor-container">
              <div className="editor">
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'이름'}
                    value={user.firstName}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, firstName: event.target.value });
                    }}
                  />
                  <CustomTextField
                    label={'성'}
                    value={user.lastName}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, lastName: event.target.value });
                    }}
                  />
                </div>
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'이메일'}
                    value={user['email']}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, email: event.target.value });
                    }}
                  />
                  <CustomTextField
                    label={'생년월일'}
                    value={user.birth}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, birth: event.target.value });
                    }}
                  />
                </div>
                <CustomSelect
                  menus={levels}
                  label={'등급'}
                  value={user.level}
                  layoutStyle={{ flex: 1 }}
                  multiline
                  onChange={event => {
                    setUser({ ...user, level: event.target.value });
                  }}
                />

                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'국가 코드'}
                    value={user.countryCode}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, countryCode: event.target.value });
                    }}
                  />
                  <CustomTextField
                    label={'연락처'}
                    value={user.phoneNumber}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({ ...user, phoneNumber: event.target.value });
                    }}
                  />
                </div>

                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'가입일'}
                    value={user.createdAt}
                    layoutStyle={{ flex: 1 }}
                    disabled={true}
                    onChange={event => {
                      setUser({ ...user, createdAt: event.target.value });
                    }}
                  />
                  <CustomTextField label={'token'} value={user.token} layoutStyle={{ flex: 1 }} disabled={true} />
                </div>

                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'TIKTOK'}
                    value={(user.connectAccounts || {}).TIKTOK}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({
                        ...user,
                        connectAccounts: {
                          ...user.connectAccounts,
                          TIKTOK: event.target.value,
                        },
                      });
                    }}
                  />
                  <CustomTextField
                    label={'INSTAGRAM'}
                    value={(user.connectAccounts || {}).INSTAGRAM}
                    layoutStyle={{ flex: 1 }}
                    disabled={true}
                    onChange={event => {
                      setUser({
                        ...user,
                        connectAccounts: {
                          ...user.connectAccounts,
                          INSTAGRAM: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'PAYPAL'}
                    value={(user.payoutAccounts || {}).PAYPAL}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({
                        ...user,
                        payoutAccounts: {
                          ...user.payoutAccounts,
                          PAYPAL: event.target.value,
                        },
                      });
                    }}
                  />
                  <CustomTextField
                    label={'VENMO'}
                    value={(user.payoutAccounts || {}).VENMO}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({
                        ...user,
                        payoutAccounts: {
                          ...user.payoutAccounts,
                          VENMO: event.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div style={{ display: 'flex', gap: 16 }}>
                  <CustomTextField
                    label={'최근 결제수단'}
                    value={user.lastPayoutMethod}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({
                        ...user,
                        lastPayoutMethod: event.target.value,
                      });
                    }}
                  />
                  <CustomTextField
                    label={'최근 배송지'}
                    value={user.lastDeliveryAddressId}
                    layoutStyle={{ flex: 1 }}
                    onChange={event => {
                      setUser({
                        ...user,
                        lastDeliveryAddressId: event.target.value,
                      });
                    }}
                  />
                </div>

                <CustomTextField
                  label={'메모'}
                  value={user.memo}
                  layoutStyle={{ flex: 1 }}
                  fullWidth
                  multiline
                  onChange={event => {
                    setUser({ ...user, memo: event.target.value });
                  }}
                />

                <div style={{ display: 'flex', gap: 16, margin: '16px 0' }}>
                  <CustomTextField
                    label={'TIKTOK-지역'}
                    value={(tiktokMetadata || {}).region || '데이터 없음'}
                    layoutStyle={{ flex: 1 }}
                    disabled
                  />
                  <CustomTextField
                    label={'TIKTOK-팔로워 수'}
                    value={(tiktokMetadata || {}).followerCount || '데이터 없음'}
                    layoutStyle={{ flex: 1 }}
                    disabled
                  />
                  <CustomTextField
                    label={'TIKTOK-평균 조회 수'}
                    value={(tiktokMetadata || {}).avgPlayCount || '데이터 없음'}
                    layoutStyle={{ flex: 1 }}
                    disabled
                  />
                </div>

                <CustomSwitch
                  label="비활성화"
                  checked={Boolean(user.isAccountDeactivated) || false}
                  onChange={event => {
                    setUser({
                      ...user,
                      isAccountDeactivated: event.target.checked,
                    });
                  }}
                />

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '32px',
                  }}>
                  <Button style={{ marginLeft: 12 }} variant="contained" disabled={true}>
                    회원 탈퇴
                  </Button>
                  <Button
                    style={{ marginLeft: 12 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      onSave();
                    }}>
                    저장
                  </Button>
                </div>
              </div>
              <AddressList lastDeliveryAddressId={user.lastDeliveryAddressId} id={props.location.query.id} />
            </div>
            <div className="additional-container">
              <ContractList uid={props.location.query.id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
