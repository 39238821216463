import React, { useState, useCallback, useEffect, useRef } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ReactPaginate from 'react-paginate';
import { SearchInput, SortableTableHead } from '../../../../components';
import momentLib from 'moment-timezone';
import { getUsers, updateUsersLevel } from '../../api/user';
import { Button, Checkbox, Chip } from '@material-ui/core';
import { LEVEL } from '../constants';
import useCheckList from '../../hooks/useCheckList';

const ROWS_PER_PAGE = 20;

const initialState = {
  page: 1,
  rowsPerPage: ROWS_PER_PAGE,
  orderBy: '',
  direction: 'desc',
  users: [],
  total: 0,
};

const Users = props => {
  const searchOptions = useRef({
    selectItem: '',
    findText: '',
  });

  const [state, setState] = useState(initialState);
  const [level, setLevel] = useState('EGG');

  const { checkList, setCheckList, isAllChecked, setIsAllChecked } = useCheckList();

  const header = [
    {
      sortable: false,
      id: 'checkbox',
      label: '',
      component: <Checkbox checked={isAllChecked} onChange={event => onChangeHandler(event)} />,
    },
    {
      sortable: false,
      id: 'createdAt',
      label: '가입일',
    },
    { sortable: false, id: 'name', label: '이름' },
    { sortable: false, id: 'email', label: '이메일' },
    { sortable: false, id: 'phoneNumber', label: '틱톡 ID' },
    { sortable: false, id: 'region', label: '국가' },
    { sortable: true, id: 'followerCount', label: '팔로워' },
    { sortable: true, id: 'avgPlayCount', label: '평균 조회수' },
    { sortable: false, id: 'level', label: '등급' },
    { sortable: false, id: 'memo', label: '메모' },
    { sortable: false, id: 'edit', label: '정보' },
  ];

  const excludedHeaders = ['checkbox'];

  const fetch = async (page, orderBy, order) => {
    const result = await getUsers({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),

      ...(orderBy === 'followerCount' &&
        !!order && {
          filterTiktokFollowers: order,
        }),
      ...(orderBy === 'avgPlayCount' &&
        !!order && {
          filterTiktokAvgPlayCount: order,
        }),
    });

    setState({
      ...state,
      users: [...result.data.data.users],
      page: page,
      total: result.data.data.totalRows,
      orderBy,
      direction: order,
    });

    setCheckList(
      result.data.data.users.map(item => {
        const { user, tiktokMetadata } = item;
        return { id: user.id, checked: false };
      })
    );

    setIsAllChecked(false);
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleSearch = (selectItem, findText) => {
    searchOptions.current = {
      selectItem,
      findText,
    };

    fetch(1, state.orderBy, state.direction);
  };

  const onChangeHandler = (event, user) => {
    const check = event.target.checked;

    if (user) {
      setCheckList(checkList => checkList.map(item => (item.id == user.id ? { ...item, checked: check } : item)));
    } else {
      setIsAllChecked(check);

      setCheckList(checkList =>
        checkList.map(item => {
          return { ...item, checked: check };
        })
      );
    }
  };

  const onClickHandler = async () => {
    const selectedUsers = checkList.reduce((acc, cur) => {
      if (cur.checked) acc.push(cur.id);
      return acc;
    }, []);

    if (selectedUsers.length === 0) {
      return;
    }

    const result = await updateUsersLevel(selectedUsers, level);

    if (result.data.success) {
      alert('저장되었습니다.');
      window.location.reload();
    }
  };

  const body = state.users.map((userInfo, index) => {
    const { user, tiktokMetadata } = userInfo;

    return (
      <TableRow key={index}>
        <TableCell>
          <Checkbox
            checked={(checkList[index] && !!checkList[index].checked) || false}
            onChange={event => onChangeHandler(event, user)}
          />
        </TableCell>
        <TableCell>{momentLib(user.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
        <TableCell>
          {user.firstName} {user.lastName}
        </TableCell>
        <TableCell clasName="lg">{user.email}</TableCell>
        <TableCell>{user.tiktokAccount}</TableCell>
        <TableCell>{tiktokMetadata.region || '-'}</TableCell>
        <TableCell>{tiktokMetadata.followerCount || '-'}</TableCell>
        <TableCell>{tiktokMetadata.avgPlayCount || '-'}</TableCell>
        <TableCell>{user.level || ''}</TableCell>
        <TableCell>{user.memo || ''}</TableCell>
        <TableCell>
          <Link to={'/cosduck/user?id=' + user.id} target="_blank">
            편집
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const pageCount = parseInt(state.total / state.rowsPerPage, 10) + (state.total % state.rowsPerPage === 0 ? 0 : 1);

  const handleChangePage = event => {
    fetch(event.selected + 1, state.orderBy, state.direction);
  };

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '8px',
        }}>
        <SearchInput
          header={header.filter(item => !excludedHeaders.includes(item.id))}
          handleSearch={handleSearch}
          customComponents={[
            <div
              style={{
                display: 'flex',
                flex: 1,
                margin: 8,
                marginLeft: 20,
                gap: 8,
              }}>
              {LEVEL.map(item => {
                return (
                  <Chip
                    label={item}
                    variant="outlined"
                    value={item}
                    style={{
                      backgroundColor: level === item ? '#555555' : '',
                      color: level === item ? 'white' : '',
                    }}
                    onClick={event => {
                      console.log(event.target);
                      setLevel(item);
                    }}
                  />
                );
              })}

              <Button key="changeButton" variant="contained" color="secondary" onClick={onClickHandler}>
                등급 변경
              </Button>
            </div>,
          ]}
        />
      </div>

      <Table>
        <SortableTableHead
          order={state.direction}
          orderBy={state.orderBy}
          columns={header}
          handleSort={(orderBy, order) => {
            fetch(state.page, orderBy, order);
          }}
        />
        <TableBody>{body}</TableBody>
      </Table>
      <div className="table-footer">
        <div />
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={handleChangePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
