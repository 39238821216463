import React from 'react';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import momentLib from 'moment-timezone';
import cheerio from 'cheerio';

import { fetchData, updateDirectly } from '../../app/api';

String.prototype.hashCode = function () {
  var hash = 0,
    i = 0,
    len = this.length;
  while (i < len) {
    hash = ((hash << 5) - hash + this.charCodeAt(i++)) << 0;
  }
  return hash + 2147483647 + 1;
};

class InstaHistoryCard extends React.PureComponent {
  state = {
    instaHistory: [],
    instaHistory1: [],
    instaHistory2: [],
    history: {
      days: 0,
      reasons: [],
    },
  };
  static getDerivedStateFromProps(props) {
    const propsHistory = props.user.instaHistory || [];
    return {
      instaHistory: propsHistory,
      instaHistory1: propsHistory.filter(history => {
        return !history.days;
      }),
      instaHistory2: propsHistory.filter(history => {
        return history.days;
      }),
    };
  }
  render() {
    const {
      user: { outerId },
    } = this.props;
    const { instaHistory, instaHistory1, instaHistory2, history } = this.state;

    let nextDate1 = '';
    let nextDate2 = '';
    let reasons = instaHistory.filter(history => history.days !== undefined);
    if (reasons.length > 0) {
      nextDate2 = momentLib(reasons[reasons.length - 1].schedule)
        .tz('Asia/Seoul')
        .format('YYYY-MM-DD');
    }

    let hasCode = outerId.hashCode() % 14;
    let dateCode = Math.round(new Date().getTime() / (1000 * 60 * 60 * 24)) % 14;
    let nextDays = (14 + dateCode - hasCode) % 14;
    nextDays = nextDays === 0 ? 14 : nextDays;
    nextDate1 = momentLib().tz('Asia/Seoul').add(nextDays, 'day').format('YYYY-MM-DD');

    const elements1 = instaHistory
      ? instaHistory1.map(item => {
          const date = (item.schedule || item.updatedAt || new Date()).toDate
            ? (item.schedule || item.updatedAt).toDate()
            : item.schedule || item.updatedAt || new Date();
          const dateText = momentLib(date).tz('Asia/Seoul').format('YY/MM/DD HH:mm');
          item.percentage = item.percentage || 0;
          return (
            <TableRow key={dateText}>
              <TableCell>
                <span className={item.level ? item.level : 'nolevel'}>{dateText}</span>
              </TableCell>
              <TableCell>
                <span className={item.prelevel ? item.prelevel : 'nolevel'}>{item.prelevel || ''}</span>
              </TableCell>
              <TableCell>{item.follower}</TableCell>
              <TableCell>{item.ER ? item.ER.toFixed(1) : '-'}</TableCell>
            </TableRow>
          );
        })
      : '';
    const elements2 = instaHistory
      ? instaHistory2.map(item => {
          const date = (item.schedule || item.updatedAt).toDate
            ? (item.schedule || item.updatedAt).toDate()
            : item.schedule || item.updatedAt;
          const dateText = momentLib(date).tz('Asia/Seoul').format('YY/MM/DD HH:mm');
          item.percentage = item.percentage || 0;
          return (
            <TableRow key={dateText}>
              <TableCell>
                <span>{dateText}</span>
              </TableCell>
              <TableCell colSpan={5}>{item.reasons}</TableCell>
            </TableRow>
          );
        })
      : '';
    return (
      <div>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>인스타 가심사{'(예정일:' + nextDate1 + ')'}</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section style={{ marginBottom: '0px' }}>
                <div className="table-container">
                  <Table style={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>심사 일자</TableCell>
                        <TableCell>등급</TableCell>
                        <TableCell>TF 총 팔로워</TableCell>
                        <TableCell>ER 평균 반응수</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{elements1}</TableBody>
                  </Table>
                </div>
              </section>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button onClick={this.handleUpdate}>업데이트</Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel defaultExpanded={true}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <h3>인스타 재심사{'(예정일:' + nextDate2 + ')'}</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section style={{ marginBottom: '0px' }}>
                <div className="table-container">
                  <Table style={{ minWidth: 500 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>다음 심사일</TableCell>
                        <TableCell>사유</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{elements2}</TableBody>
                  </Table>
                </div>
              </section>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <label>다음 재심사 일정</label>
                  <Select
                    fullWidth
                    value={history.days}
                    onChange={evt => {
                      history.days = evt.target.value;
                      this.setState({ history: history });
                      this.forceUpdate();
                    }}>
                    <MenuItem value={0}></MenuItem>
                    <MenuItem value={14}>
                      2주 후({momentLib(new Date()).tz('Asia/Seoul').add(14, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={30}>
                      1개월 후({momentLib(new Date()).tz('Asia/Seoul').add(30, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={60}>
                      2개월 후({momentLib(new Date()).tz('Asia/Seoul').add(60, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={90}>
                      3개월 후({momentLib(new Date()).tz('Asia/Seoul').add(90, 'day').format('YY/MM/DD')})
                    </MenuItem>
                    <MenuItem value={120}>
                      4개월 후({momentLib(new Date()).tz('Asia/Seoul').add(120, 'day').format('YY/MM/DD')})
                    </MenuItem>
                  </Select>
                </div>
                <div style={{ flex: 1 }}>
                  <label>사유</label>
                  <Select
                    multiple
                    fullWidth
                    renderValue={selected => selected.join(', ')}
                    value={history.reasons}
                    onChange={evt => {
                      history.reasons = evt.target.value;
                      this.setState({ history: history });
                      this.forceUpdate();
                    }}>
                    <MenuItem value="내츄럴">내츄럴</MenuItem>
                    <MenuItem value="어뷰징">어뷰징</MenuItem>
                    <MenuItem value="상향 체크">상향 체크</MenuItem>
                    <MenuItem value="하향 체크">하향 체크</MenuItem>
                    <MenuItem value="후기 적음">후기 적음</MenuItem>
                    <MenuItem value="키워드 부적합">키워드 부적합</MenuItem>
                    <MenuItem value="노출 좋음">노출 좋음</MenuItem>
                    <MenuItem value="노출 불량">노출 불량</MenuItem>
                    <MenuItem value="퀄 좋음">퀄 좋음</MenuItem>
                    <MenuItem value="퀄 불량">퀄 불량</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button onClick={this.handleDelete}>최근 심사일 상세 삭제</Button>
                <Button color="secondary" onClick={this.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
  handleUpdate = () => {
    const {
      user: { outerId, uid, level },
    } = this.props;
    const { instaHistory } = this.state;
    fetch(`https://www.instagram.com/${outerId}`)
      .then(result => result.text())
      .then(body => {
        const $ = cheerio.load(body);
        const data = $('script:contains("_sharedData")').html();
        const result = {
          ER: 0,
          follower: 0,
          updatedAt: new Date(),
        };
        if (data && data.length > 0) {
          const parsed = JSON.parse(/window\._sharedData = (.+);/g.exec(data)[1]).entry_data.ProfilePage[0].graphql
            .user;

          result.follower = parsed.edge_followed_by.count;
          parsed.edge_owner_to_timeline_media.edges.forEach(edge => {
            result.ER += edge.node.edge_liked_by.count + edge.node.edge_media_to_comment.count;
          });
          result.ER /= parsed.edge_owner_to_timeline_media.edges.length;

          result.level = 'nolevel';
          if (result.follower >= 10000 && result.ER >= 300) {
            result.level = 'black';
          } else if (result.follower >= 1000 && result.ER >= 50) {
            result.level = 'red';
          } else if (result.follower >= 100) {
            result.level = 'yellow';
          }
          result.prelevel = level;
        }
        instaHistory.push(result);
        updateDirectly('user', uid, {
          instaHistory,
        }).catch(err => {
          console.log(err);
          alert('업데이트 실패');
        });
      })
      .catch(err => {
        console.log(err);
        alert('업데이트 데이터 조회 실패');
      });
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      const {
        user: { uid },
      } = this.props;
      fetchData('delete', '/user/visitorcount', { uid: uid }).catch(err => {
        console.log(err);
        alert('삭제 실패');
      });
    }
  };
  handleSave = () => {
    const {
      user: { uid },
    } = this.props;
    const { history } = this.state;
    if (history.days > 0) {
      history.schedule = momentLib(new Date()).tz('Asia/Seoul').add(history.days, 'day').format('YYYY-MM-DD');
      history.schedule = new Date(history.schedule);
    }
    fetchData('post', '/user/visitorcount', { uid: uid, ...history }).catch(err => {
      console.log(err);
      alert('저장 실패');
    });
  };
}

export default InstaHistoryCard;
