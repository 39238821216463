import * as types from './types';

export function activateLoading() {
  return {
    type: types.ACTIVATE_LOADING,
  };
}

export function deactivateLoading() {
  return {
    type: types.DEACTIVATE_LOADING,
  };
}
