import * as types from './types';

const initialAuthState = {
  user: null,
};

export default function auth(state = initialAuthState, action) {
  switch (action.type) {
    case types.SIGN_IN:
      return { ...state, user: action.user };
    case types.SIGN_OUT:
      return { ...state, user: null };
    default:
      return state;
  }
}
