import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ActionCreators from '../../app/action';
import { findDOMNode } from 'react-dom';

import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import { withStyles } from '@material-ui/core/styles';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import VirtualizedSelect from 'react-virtualized-select';
import momentLib from 'moment-timezone';
import { Popover } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Photo from '@material-ui/icons//Photo';
import Pageview from '@material-ui/icons//Pageview';
import OpenInNew from '@material-ui/icons//OpenInNew';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '30px',
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  category: {
    position: 'relative',
  },
  button: {
    width: 40,
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconButton: {
    width: 32,
    height: 32,
  },
  '@global': {
    '.Select': {
      width: '30%',
    },
    '.Select-control': {
      alignItems: 'center',
      height: 'auto',
      background: 'transparent',
    },
    '.Select-menu-outer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      position: 'absolute',
      left: 0,
    },
  },
});

const today = momentLib(new Date()).tz('Asia/Seoul').format('YYYY-MM-DD');

class Card extends React.Component {
  state = {
    single: null,
    multi: null,
    keywords1: [],
    keywords2: [],
    keyword: {
      info: [{}, {}],
    },
    open: false,
    anchorEl: null,
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'center',
    positionTop: 0, // Just so the popover can be spotted more easily
    positionLeft: 0, // Same as above
    anchorReference: 'anchorEl',
  };

  componentDidMount() {
    this.setState({
      keywords1: this.props.keywords1,
      keywords2: this.props.keywords2,
      keyword: this.props.keyword,
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      keywords1: newProps.keywords1,
      keywords2: newProps.keywords2,
      keyword: newProps.keyword,
    });
  }

  render() {
    const { classes, index, isLast, handlePlus, handleMinus, handleSave } = this.props;

    const {
      open,
      anchorEl,
      anchorOriginVertical,
      anchorOriginHorizontal,
      transformOriginVertical,
      transformOriginHorizontal,
      positionTop,
      positionLeft,
      anchorReference,
      keyword,
    } = this.state;

    const buttons = isLast ? (
      <IconButton className={classes.iconButton} onClick={handlePlus}>
        <Add />
      </IconButton>
    ) : (
      <IconButton className={classes.iconButton} onClick={() => handleMinus(index)}>
        <Remove />
      </IconButton>
    );

    return (
      <div className={classes.root}>
        <VirtualizedSelect
          clearable={true}
          disabled={false}
          labelKey="name"
          multi={false}
          onChange={keyword1Selected => {
            this.setState(prevState => {
              let keyword = prevState.keyword;
              keyword.info[0] = keyword1Selected;
              this.props.handleChange(index, keyword);
              return { keyword: keyword };
            });
          }}
          options={this.props.keywords1}
          searchable={true}
          value={keyword.info ? keyword.info[0] : ''}
          filterOptions={(options, filterString) => {
            const exactMatch = (options || []).filter(
              option => String(option.name).replace(/ /gi, '') === filterString.replace(/ /gi, '')
            );
            const partialMatches = (options || []).filter(
              option => String(option.name).includes(filterString) && option.name !== filterString
            );

            return [...exactMatch, ...partialMatches];
          }}
        />
        <VirtualizedSelect
          clearable={true}
          disabled={false}
          labelKey="name"
          multi={false}
          onChange={keyword2Selected => {
            this.setState(prevState => {
              let keyword = prevState.keyword;
              keyword.info[1] = keyword2Selected;
              this.props.handleChange(index, keyword);
              return { keyword: keyword };
            });
          }}
          options={this.props.keywords2}
          searchable={true}
          value={keyword.info ? keyword.info[1] : ''}
          filterOptions={(options, filterString) => {
            const exactMatch = (options || []).filter(
              option => String(option.name).replace(/ /gi, '') === filterString.replace(/ /gi, '')
            );
            const partialMatches = (options || []).filter(
              option => String(option.name).includes(filterString) && option.name !== filterString
            );

            return [...exactMatch, ...partialMatches];
          }}
        />
        <span className={classes.category}>
          {'[' +
            ((((keyword || {}).info || [])[0] || {}).level || 'A') +
            ((((keyword || {}).info || [])[1] || {}).level || 'A') +
            '] ' +
            (((keyword.ranks || {})[this.props.date || today] || {})[0]
              ? keyword.ranks[this.props.date || today][0].rank
              : '무') +
            '순위'}
        </span>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            if (keyword.info[0].name || keyword.info[1].name) {
              handleSave(keyword, true);
            } else {
              alert('키워드를 선택해 주세요.');
            }
          }}>
          저장
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          onClick={() => {
            if (keyword.info[0].name || keyword.info[1].name) {
              handleSave(keyword, false);
            } else {
              alert('키워드를 선택해 주세요.');
            }
          }}>
          재검색
        </Button>
        <Button
          color="secondary"
          className={classes.button}
          ref={node => (this.button = node)}
          onClick={() => {
            if ((keyword.ranks || {})[this.props.date || today]) {
              this.setState({
                open: true,
                anchorEl: findDOMNode(this.button),
              });
            } else {
              alert('내역이 없습니다.');
            }
          }}>
          내역
        </Button>
        {buttons}
        {open ? (
          <Popover
            open={open}
            anchorEl={anchorEl}
            anchorReference={anchorReference}
            anchorPosition={{ top: positionTop, left: positionLeft }}
            onClose={() => {
              this.setState({
                open: false,
              });
            }}
            anchorOrigin={{
              vertical: anchorOriginVertical,
              horizontal: anchorOriginHorizontal,
            }}
            transformOrigin={{
              vertical: transformOriginVertical,
              horizontal: transformOriginHorizontal,
            }}>
            <List component="nav">
              {(keyword.ranks || {})[this.props.date || today]
                ? keyword.ranks[this.props.date || today].map((rank, i) => {
                    return (
                      <ListItem key={i} button>
                        <ListItemText secondary={rank.level + ' ' + rank.username} />
                        <ListItemText secondary={'순위: ' + rank.rank} />
                        <ListItemIcon>
                          <Photo
                            onClick={() => {
                              if (rank.screenshot) {
                                window.open(rank.screenshot);
                              } else {
                                window.open(
                                  'https://search.naver.com/search.naver?date_from=&date_option=0&date_to=&dup_remove=1&nso=&post_blogurl=&post_blogurl_without=&&srchby=all&st=sim&where=post&query=' +
                                    keyword.name +
                                    '&start=' +
                                    (parseInt((rank.rank - 1) / 10) * 10 + 1)
                                );
                              }
                            }}
                          />
                        </ListItemIcon>
                        <ListItemIcon
                          onClick={() => {
                            window.open(`/contract?id=${rank.contractId}`);
                          }}>
                          <Pageview />
                        </ListItemIcon>
                        <ListItemIcon
                          onClick={() => {
                            if (rank.useremail && rank.link) {
                              window.open(`http://blog.naver.com/${(rank.useremail || '').split('@')[0]}/${rank.link}`);
                            } else {
                              alert('이메일 또는 링크 정보가 없습니다.');
                            }
                          }}>
                          <OpenInNew />
                        </ListItemIcon>
                      </ListItem>
                    );
                  })
                : ''}
            </List>
          </Popover>
        ) : null}
      </div>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(Card));
