import * as api from '../api';

export function getSchema() {
  return api
    .fetchData('get', '/proposition/schema')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function filterPropositions(query) {
  console.log(query);
  return api
    .fetchData('get', '/proposition/console', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getProposition(id) {
  return api
    .fetchData('get', '/proposition/console', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateProposition(id, json) {
  return api
    .fetchData('put', '/proposition/console', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function deleteProposition(id) {
  return api
    .fetchData('delete', '/proposition/console', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getUserPointDetail(id) {
  return api
    .fetchData('get', '/proposition/detail/console', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
