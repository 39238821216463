import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import EditorBasic from './editor-basic';
import EditorEtc from './editor-etc';
import EditorMemo from './editor-memo';
import EditorDelivery from './editor-delivery';
import EditorDeliveryHistory from './editor-delivery-history';
import Exposure from './exposure';
import UserCard from '../../../components/user-card';
import * as api from '../api';
import { InfoLog } from '../../../components';
import { uploadFile } from '../../../app/api';

class Contract extends React.Component {
  state = {
    id: this.props.location.query.id,
    schema: {},
    contract: {},
    snackOpen: false,
    filePurchaseThumbnail: null,
    fileReceiptReview: null,
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getSchema().then(schema => {
      api.getContract(this.state.id).then(result => {
        schema = schema.properties;
        let contract = result.contracts[0];
        let keys = Object.keys(schema);
        for (let i = 0; i < keys.length; i++) {
          schema[keys[i]].name = keys[i];
          schema[keys[i]].value = contract[keys[i]] === undefined ? null : contract[keys[i]];
        }
        this.setState({ schema, contract });
      });
      this.props.deactivateLoading();
    });
  }
  handleChange = (name, value) => {
    const contract = { ...this.state.contract };
    if (name === 'extended' && value && !contract[name]) {
      contract['deadline'] += 5;
    } else if (name === 'extended' && !value && contract[name]) {
      contract['deadline'] -= 5;
    }
    contract[name] = value;
    this.setState({ contract });
  };
  handleCancel = () => {
    this.props.push('/contracts');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteContract(this.state.id)
        .then(result => {
          alert('삭제 완료');
          this.props.push('/contracts');
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleSave = () => {
    this.props.activateLoading();
    const { id } = this.state;
    api
      .updateContract(id, this.state.contract)
      .then(() => {
        this.props.deactivateLoading();
        alert('저장 성공');
      })
      .catch(err => {
        console.log(err);
        this.props.deactivateLoading();
        alert('업데이트에 실패했습니다. 다시 확인해주세요.');
      });
  };

  handleLoad = (event, type) => {
    if (type === 'purchaseThumbnail') {
      this.setState({ filePurchaseThumbnail: event.target.files[0] });
    } else {
      this.setState({ fileReceiptReview: event.target.files[0] });
    }
  };
  handleUpload = async type => {
    const { id } = this.state;
    if (type === 'purchaseThumbnail') {
      uploadFile(
        `/contract/store-review/history/contract${this.state.id}_${this.state.contract.company}_${this.state.filePurchaseThumbnail.name}`,
        this.state.filePurchaseThumbnail,
        progressObj => {
          this.setState({ progress: progressObj.progress });
        }
      )
        .then(downloadURL => {
          api.updateContract(id, { purchaseThumbnail: downloadURL }).then(() => {
            this.handleChange('purchaseThumbnail', downloadURL);
          });
        })
        .catch(err => {
          console.log(err);
          alert('파일 업로드에 실패했습니다.');
        });
    } else {
      uploadFile(
        `/contract/store-review/contract${this.state.id}_${this.state.contract.company}_${this.state.fileReceiptReview.name}`,
        this.state.fileReceiptReview,
        progressObj => {
          this.setState({ progress: progressObj.progress });
        }
      )
        .then(downloadURL => {
          api.updateContract(id, { receiptReview: downloadURL }).then(() => {
            this.handleChange('receiptReview', downloadURL);
          });
        })
        .catch(err => {
          console.log(err);
          alert('파일 업로드에 실패했습니다.');
        });
    }
  };

  render() {
    const { schema, contract } = this.state;
    if (Object.keys(schema).length > 0) {
      return (
        <div className="contents">
          <div className="editor-container contents-editor">
            <EditorBasic contract={contract} handleChange={this.handleChange} />
            <EditorEtc
              contract={contract}
              handleChange={this.handleChange}
              handleLoad={this.handleLoad}
              handleUpload={this.handleUpload}
            />
            <EditorMemo
              contract={contract}
              handleChange={this.handleChange}
              handleDelete={this.handleDelete}
              handleSave={this.handleSave}
            />
            <InfoLog type={'contract'} id={contract.id} />
          </div>
          <div className="additional-container">
            <UserCard uid={contract.user} />
            {contract.delivery && (
              <div className="contents-editor">
                <EditorDelivery contract={contract} handleChange={this.handleChange} />
                <EditorDeliveryHistory
                  contract={contract}
                  handleChange={this.handleChange}
                  handleSave={this.handleSave}
                />
              </div>
            )}
            <div className="contents-editor">
              <Exposure contract={contract} />
            </div>
          </div>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>업데이트 성공</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => this.setState({ snackOpen: false })}>
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return <div />;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Contract);
