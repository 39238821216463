import React from 'react';
import { styled } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import Alert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { createTheme } from '@material-ui/core/styles';

export const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
  },
});

export const StyledPaper = styled(Paper)({
  padding: '20px',
  marginBottom: '20px',
});

export const DetailPaper = styled(Paper)({
  padding: '24px',
  marginBottom: '20px',
  border: '1px solid #e0e0e0',
  boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
  '&:hover': {
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
});

export const StyledBackdrop = styled(Backdrop)({
  zIndex: 9999,
  color: '#fff',
});

export const StyledAlert = styled(Alert)({
  fontSize: '16px',
  '& .MuiAlert-message': {
    padding: '10px 0',
  },
  '& .MuiAlert-icon': {
    fontSize: '24px',
  },
});

export const FormSection = styled('div')({
  marginBottom: '32px',
});

export const FormLabel = styled('label')({
  display: 'block',
  marginBottom: '8px',
  fontWeight: 500,
  color: '#666',
});

export const FormLabelWithTooltip = styled(({ title, children, ...props }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
    <FormLabel {...props}>{children}</FormLabel>
    <Tooltip title={title} arrow placement="top">
      <HelpOutline style={{ fontSize: '16px', color: '#999', cursor: 'help' }} />
    </Tooltip>
  </div>
))({});

// NOTE: Step Components

export const COLOR = {
  STEP_COMPLETED: 'rgba(25, 118, 210, 0.5)',
  STEP_ACTIVE: '#1976d2',
  STEP_ERROR: '#bd1328',
  FIELD_COMPLETED: '#4CAF50',
  FIELD_NOT_COMPLETED: '#e91e3c',
};

export const localTheme = createTheme({
  palette: {
    secondary: {
      light: '#bd1328',
      main: '#bd1328',
      dark: '#7d0010',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: COLOR.STEP_COMPLETED + ' !important',
        },
        '&$active': {
          color: COLOR.STEP_ACTIVE + ' !important',
        },
        '&$error': {
          color: COLOR.STEP_ERROR + ' !important',
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {},
      active: {
        '& $line': {
          backgroundImage: `linear-gradient(95deg, ${COLOR.STEP_COMPLETED} 0%, ${COLOR.STEP_ACTIVE} 100%)`,
        },
      },
      completed: {
        '& $line': {
          backgroundImage: `linear-gradient(95deg, ${COLOR.STEP_COMPLETED} 0%, ${COLOR.STEP_COMPLETED} 100%)`,
        },
      },
      line: {
        height: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
      },
    },
  },
});

export const StepContent = styled('div')({
  padding: '24px',
  backgroundColor: '#f5f5f5',
  borderRadius: '4px',
  marginTop: '16px',
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  marginTop: '16px',
});

export const ChecklistContainer = styled('div')(({ complete }) => ({}));

export const ChecklistHeader = styled('div')(({ complete }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '4px 0 4px 0',
  '& svg': {
    color: complete ? COLOR.FIELD_COMPLETED : COLOR.FIELD_NOT_COMPLETED,
  },
  color: complete ? COLOR.FIELD_COMPLETED : COLOR.FIELD_NOT_COMPLETED,
}));

export const CheckList = styled('ul')({
  marginTop: '4px',
  marginBottom: '4px',
  listStyleType: 'none',
  padding: '0px 0px 0px 24px',
});

export const ChecklistItemContainer = styled('div')(({ complete }) => ({
  // marginTop: "4px",
  // marginBottom: "4px",
  color: complete ? COLOR.FIELD_COMPLETED : COLOR.FIELD_NOT_COMPLETED,
}));

export const CheckListItem = styled('li')(({ complete }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '3px',
  marginBottom: '3px',
  fontSize: '0.9em',
  color: complete ? COLOR.FIELD_COMPLETED : COLOR.FIELD_NOT_COMPLETED,
  // fontWeight: complete ? "" : "bold",
}));

export const CheckListItemDot = styled('span')(({ complete }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  backgroundColor: complete ? COLOR.FIELD_COMPLETED : COLOR.FIELD_NOT_COMPLETED,
  marginRight: '6px',
  display: 'inline-block',
}));

export const ErrorList = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  margin: '2px 0 2px 32px',
  padding: 0,
  listStyle: 'disc',
  color: COLOR.FIELD_NOT_COMPLETED,
});

export const ErrorListItem = styled('li')({
  fontSize: '0.9em',
  color: '#666',
});

export const Divider = styled('hr')({
  border: 'none',
  height: '1px',
  backgroundColor: '#e0e0e0',
  margin: '20px 0',
});
