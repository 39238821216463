import React from 'react';
import { bindActionCreators } from 'redux';
import momentLib from 'moment-timezone';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';
import _ from 'lodash';

const header = [
  // {sortable:false, id:'rKey', label:'ID'},
  { sortable: true, id: 'category', label: '변동일시' },
  { sortable: true, id: 'company', label: '업체' },
  { sortable: true, id: 'name', label: '내용' },
  { sortable: true, id: 'number', label: '변동' },
  { sortable: true, id: 'balance', label: '모집가능인원' },
  { sortable: true, id: 'period', label: '남은기간' },
  { sortable: true, id: 'total', label: '누적충전인원' },
  { sortable: false, id: 'edit', label: '상세' },
];

const initialState = {
  page: 1,
  rowsPerPage: 50,
  orderBy: 'id',
  direction: 'desc',
  premiums: [],
  total: 0,
};

class Premiums extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
    this.resetState = this.resetState.bind(this);
  }
  render() {
    const { rowsPerPage, orderBy, direction, premiums, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = premiums.map((item, index) => {
      return (
        <TableRow key={index} style={item.adId ? { backgroundColor: '#ffd0d0' } : { backgroundColor: '#d0d0ff' }}>
          <TableCell>{momentLib(item.updatedAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
          <TableCell>
            <Link to={'/company-user?id=' + item.companyId} target="_blank">
              {item.cname}
            </Link>
          </TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.adId ? -item.number : item.number}명</TableCell>
          <TableCell>{item.balance}명</TableCell>
          <TableCell>{item.remaining_days}일</TableCell>
          <TableCell>{item.total}명</TableCell>
          <TableCell>
            <Link to={'/premium?id=' + (item.id || item.rKey)} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} handlePrivate={this.handlePrivate} />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => {
              this.setState({ orderBy: orderBy, direction: direction });
              this.fetchDatas(null, orderBy, direction, null);
            }}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div />
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div />
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.resetState();
    this.fetchDatas(null, null, null, null);
  }

  resetState = () => {
    let state = {};
    Object.keys(this.state).forEach(key => {
      state[key] = undefined;
    });

    this.setState({ ...state, ...initialState });
  };

  handleChangePage(event) {
    this.resetState();
    this.fetchDatas(event.selected, this.state.orderBy, this.state.direction, this.state);
  }

  async fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const { premiums, total, rowsPerPage, schema, ...rest } = _.pickBy(options, _.identity) || {};

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      ...rest,
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      group: 'companyId',
    };

    let results = await api.filterPremium(filter);
    results.premiums = await Promise.all(
      results.packages.map(async premium => {
        let result = await api.getCompanyUser(premium.companyId);
        const company = result.users[0];
        premium.cname = company.name;

        return premium;
      })
    );

    this.setState(Object.assign(filter, results));
    this.props.deactivateLoading();
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Premiums);
