import React, { useRef } from 'react';
import Card from './card';
import NewCard from './new-card';
import './card.css';

const ImageEditor = ({ images, main, handleMainChange, handleChange, label }) => {
  const handleAdd = newImageUrl => {
    const newArr = [...images, newImageUrl];
    handleChange(newArr);
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const handleDelete = idx => {
    const newArr = [...images];
    if (newArr.length > 1) {
      newArr.splice(idx, 1);
      handleChange(newArr);
    }
  };

  const handlePrev = idx => {
    const newArr = [...images];
    if (idx > 0) {
      const url = newArr[idx];
      newArr[idx] = newArr[idx - 1];
      newArr[idx - 1] = url;
      handleChange(newArr);
    }
  };

  const handleNext = idx => {
    const newArr = [...images];
    if (idx < newArr.length - 1) {
      const url = newArr[idx];
      newArr[idx] = newArr[idx + 1];
      newArr[idx + 1] = url;
      handleChange(newArr);
    }
  };

  const handleRotate = idx => {
    let url = require('url');
    const newArr = [...images];
    let parsedUrl = url.parse(newArr[idx], true); // true returns a URL object
    let query = parsedUrl.query;
    query.r = ((Number(query.r) || 0) + 90) % 360;
    let search = Object.keys(query)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
      .join('&');
    newArr[idx] = `${parsedUrl.protocol}//${parsedUrl.host}${parsedUrl.pathname}?${search}`;
    handleChange(newArr);
  };

  const dragStart = idx => {
    dragItem.current = idx;
  };

  const dragEnter = idx => {
    dragOverItem.current = idx;
  };

  const drop = () => {
    const copyListItems = [...images];
    const dragItemConotent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemConotent);
    dragItem.current = null;
    dragOverItem.current = null;
    handleChange(copyListItems);
  };

  const cards = images.map((item, idx) => (
    <Card
      key={item}
      image={item}
      main={main}
      handlePrev={() => handlePrev(idx)}
      handleNext={() => handleNext(idx)}
      onDragStart={() => dragStart(idx)}
      onDragEnter={() => dragEnter(idx)}
      onDragOver={e => e.preventDefault()}
      onDragEnd={drop}
      isMain={main === item}
      handleRotateRight={() => handleRotate(idx)}
      handleMain={() => handleMainChange(item)}
      handleDelete={() => handleDelete(idx)}
    />
  ));

  return (
    <div>
      <label>{label}</label>
      <div className="card-list" style={{ display: 'flex', flexWrap: 'wrap' }}>
        {cards}
        <NewCard handleAdd={handleAdd} />
      </div>
    </div>
  );
};

export default ImageEditor;
