import * as api from '../api';

export function filterSumemPoint(query) {
  return api
    .fetchData('get', '/company/user/sumempoint', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getSumemPoint(id) {
  return api
    .fetchData('get', '/company/user/sumempoint', { id: id })
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function createSumemPoint(json) {
  return api
    .fetchData('post', '/company/user/sumempoint', json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function updateSumemPoint(id, json) {
  return api
    .fetchData('put', '/company/user/sumempoint', { id: id }, json)
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function deleteSumemPoint(id) {
  return api
    .fetchData('delete', '/company/user/sumempoint', { id: id })
    .then(result => {
      return Promise.resolve(result);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
export function getCompanyUser(id) {
  if (id) {
    return api
      .fetchData('get', '/company/user', { id: id })
      .then(datas => {
        return Promise.resolve(datas);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  } else {
    return Promise.reject('');
  }
}
export function filterCompanyCharge(query) {
  console.log(query);
  return api
    .fetchData('get', '/company/user/charge', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
