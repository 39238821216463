import React, { PureComponent } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/EditOutlined';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import momentLib from 'moment-timezone';

import * as api from '../api';

class Editor extends PureComponent {
  state = {
    selected: null,
    manuscript: {},
  };
  render() {
    const { proposition, manuscripts } = this.props;
    const { selected, manuscript } = this.state;
    const manuscriptElements =
      manuscripts.length > 0
        ? manuscripts.map((item, idx) => {
            let status = '';
            let manuStatus = '';
            if (item && item.status === 3) {
              status = '등록';
            } else if (item && (item.status === 1 || item.status === 2)) {
              if (item.step === 0) {
                status = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    초본
                  </a>
                );
              } else {
                status = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    {item.step}차 수정본
                  </a>
                );
              }
              if (item.status === 1) {
                manuStatus = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    수정요청
                  </a>
                );
              } else {
                manuStatus = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    업로드요청
                  </a>
                );
              }
            } else if (item) {
              if (item.step) {
                status = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    {item.step}차 수정본
                  </a>
                );
              } else {
                status = (
                  <a href="#" onClick={() => window.open(`${item.manuscript}`)}>
                    초본 등록
                  </a>
                );
              }
            }

            return (
              <TableRow key={idx}>
                <TableCell style={{ textAlign: 'center' }}>
                  {momentLib(item.manuRegisteredAt).tz('Asia/Seoul').format('YY/MM/DD HH:mm')}
                </TableCell>
                <TableCell>{item.step}</TableCell>
                <TableCell>{item.request}</TableCell>
                <TableCell>{manuStatus}</TableCell>
                <TableCell>{status}</TableCell>
                <TableCell>
                  <IconButton onClick={() => this.handleSelect(idx)}>
                    <Edit />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        : '';
    if (manuscripts && manuscripts.length > 0) {
      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>원고 내역</h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div style={{ width: '100%' }}>
              <section>
                <div className="table-container">
                  <Table style={{ minWidth: '100px' }}>
                    <TableBody>{manuscriptElements}</TableBody>
                  </Table>
                </div>
              </section>
              {selected > -1 && Object.keys(manuscript).length > 0 ? (
                <section>
                  <div className="row">
                    <div style={{ flex: 1 }}>
                      <label>원고상태</label>
                      <Select
                        fullWidth
                        value={manuscript.status}
                        onChange={evt => this.handleChange('status', evt.target.value)}>
                        <MenuItem value={0}>수정완료</MenuItem>
                        <MenuItem value={1}>수정요청</MenuItem>
                        <MenuItem value={2}>업로드요청</MenuItem>
                        <MenuItem value={3}>업로드완료</MenuItem>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div style={{ flex: 1 }}>
                      <label>요청사항</label>
                      <TextField
                        fullWidth
                        value={manuscript.request}
                        onChange={evt => this.handleChange('request', evt.target.value)}
                      />
                    </div>
                  </div>
                </section>
              ) : null}
              <div className="row" style={{ justifyContent: 'flex-end' }}>
                <Button color="secondary" onClick={this.handleSave}>
                  저장
                </Button>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  handleChange = (key, value) => {
    const { manuscript } = this.state;
    manuscript[key] = value;
    this.setState({ manuscript: manuscript });
    this.forceUpdate();
  };
  handleSave = async () => {
    const { manuscript } = this.state;
    await api.updateManuscript(manuscript.id, manuscript);
    window.location.reload();
  };
  handleSelect = idx => {
    const { manuscripts } = this.props;
    this.setState({ selected: idx, manuscript: manuscripts[idx] });
  };
}
export default Editor;
