import * as api from '../api';

export function filterMessages(query) {
  return api
    .fetchData('get', '/utils/sendsms', query)
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}

export function getTemplates() {
  return api
    .fetchData('get', '/utils/alimtalk/template')
    .then(datas => {
      return Promise.resolve(datas);
    })
    .catch(err => {
      return Promise.reject(err);
    });
}
