import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../../action';
import { connect } from 'react-redux';
import ContractCard from '../../../components/ContractCard';
import { LOGO_IMAGE } from '../../constants';

import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router';
import { getContracts } from '../../../api/contract';

const dummy_contract = [
  {
    id: '1415eb44-538a-11ef-9830-42010a400002',
    adId: 4,
    adsOrder: 1,
    adsType: 'STANDARD',
    uid: 'F3dOlNSVgLQ07mlj9pqVZVDB8Re2',
    userLevel: 'lv1',
    channel: 'INSTAGRAM',
    productName: 'test-standard-shipping-haspoint',
    extended: 0,
    deadline: 14,
    category: null,
    thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/gigs/zDyjn1506401650.jpg?w=652',
    cancelReason: null,
    cancelReasonDetail: null,
    currentContractStatus: 'WAITING_FOR_SELECTION',
    statusHistory: [
      {
        status: 'WAITING_FOR_SELECTION',
        updatedAt: '2024-08-06T00:23:16.972Z',
      },
    ],
    currentDeliveryStatus: 'PENDING',
    deliveryHistory: [{ status: 'PENDING', updatedAt: '2024-08-06T00:23:16.972Z' }],
    deliveryMethod: 'DIRECT_SHIPPING',
    deliveryAddress: {
      city: '도중 테스트',
      label: '도중 테스트',
      state: '도중 테스트',
      zipCode: '도중 테스트',
      lastName: 'Change2',
      firstName: 'Jinsoo',
      countryCode: '+82',
      phoneNumber: '1098210990',
      streetAddress1: '도중 테스트',
      streetAddress2: '도중 테스트',
    },
    currentCashbackStatus: 'PENDING',
    cashbackHistory: [{ status: 'PENDING', updatedAt: '2024-08-06T00:23:16.972Z' }],
    cashbackAmount: 0.0,
    currentRewardStatus: 'PENDING',
    rewardHistory: [{ status: 'PENDING', updatedAt: '2024-08-06T00:23:16.972Z' }],
    rewardAmount: 20.0,
    isReviewed: 0,
    reviewPostingUrl: null,
    isPurchased: 0,
    purchaseImgUrl: null,
    upcomingPurchaseLink: null,
    totalPrice: 30.0,
    discountRate: 50,
    finalPrice: 15.0,
    questionAnswers: [
      { answer: 'Do', question: 'question 1' },
      { answer: 'Asd', question: 'question 2' },
    ],
    memo: null,
    createdAt: '2024-08-06 00:23:17',
    updatedAt: '2024-08-08 15:23:07',
  },
  {
    id: '19338517-5552-11ef-9830-42010a400002',
    adId: 10,
    adsOrder: 1,
    adsType: 'EXPRESS',
    uid: 'pZGGqckBpqOTTab9MX1hr1Rj98n2',
    userLevel: 'lv1',
    channel: 'INSTAGRAM',
    productName: 'test-express-reimbursement-haspoint',
    extended: 0,
    deadline: 14,
    category: null,
    thumbnail: 'https://d2v80xjmx68n4w.cloudfront.net/gigs/zDyjn1506401650.jpg?w=652',
    cancelReason: 'Not enough benefit',
    cancelReasonDetail: null,
    currentContractStatus: 'CANCELLED',
    statusHistory: [
      { status: 'SELECTED', updatedAt: '2024-08-08T06:47:36.098Z' },
      { status: 'CANCELLED', updatedAt: '2024-08-08 15:47:48' },
    ],
    currentDeliveryStatus: 'PENDING',
    deliveryHistory: [{ status: 'PENDING', updatedAt: '2024-08-08T06:47:36.098Z' }],
    deliveryMethod: 'REIMBURSEMENT',
    deliveryAddress: 'null',
    currentCashbackStatus: 'PENDING',
    cashbackHistory: [{ status: 'PENDING', updatedAt: '2024-08-08T06:47:36.098Z' }],
    cashbackAmount: 15.0,
    currentRewardStatus: 'PENDING',
    rewardHistory: [{ status: 'PENDING', updatedAt: '2024-08-08T06:47:36.098Z' }],
    rewardAmount: 20.0,
    isReviewed: 0,
    reviewPostingUrl: null,
    isPurchased: 0,
    purchaseImgUrl: null,
    upcomingPurchaseLink: null,
    totalPrice: 30.0,
    discountRate: 50,
    finalPrice: 15.0,
    questionAnswers: [
      { answer: '3', question: 'question 1' },
      { answer: '4', question: 'question 2' },
    ],
    memo: null,
    createdAt: '2024-08-08 06:47:36',
    updatedAt: '2024-08-08 15:47:49',
  },
];

const ContractList = props => {
  const { adId, title } = props;
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const result = await getContracts({ adId: adId });
      setContracts(result.data.data.contracts);
    };

    fetch();
  }, []);

  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <h3>{title} 이용내역</h3>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails style={{ display: 'block' }}>
        {contracts.map(contractInfo => (
          <div
            style={{
              marginTop: 16,
              paddingBottom: 16,
              borderBottom: '1px solid #4b4b4b',
            }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 8,
              }}>
              <img
                style={{
                  width: 24,
                  height: 24,
                  marginRight: 4,
                }}
                src={contractInfo.contract.channel === 'TIKTOK' ? LOGO_IMAGE.TIKTOK : LOGO_IMAGE.INSTAGRAM}
              />
              <div>
                <span style={{ fontSize: 14, fontWeight: 500, marginRight: 4 }}>
                  [{contractInfo.contract.userLevel}]
                </span>
                <span style={{ fontSize: 14, fontWeight: 500, marginRight: 8 }}>
                  {contractInfo.user.firstName} {contractInfo.user.lastName}
                </span>
                <span style={{ fontSize: 12 }}>{contractInfo.user.email}</span>
                <p style={{ fontSize: 10 }}>{contractInfo.user.id}</p>
              </div>
            </div>
            {/* <Link to={'/cosduck/user?id=' + contract.uid} target='_blank'>
              [{contract.userLevel}]{contract.uid}
            </Link> */}

            <ContractCard contract={contractInfo.contract} />
          </div>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);
