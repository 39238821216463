import React, { useState } from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CompanyCard from '../../../components/CompanyCard';
import ProductDetail from './Preview/ProductDetail';

import { Button, Radio } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

const Preview = props => {
  const frameStyle = {
    width: '375px',
    border: '1px solid',
    height: '586px',
    paddingTop: '16px',
  };

  const [openPreview, setOpenPreview] = useState(false);
  const [userLevel, setUserLevel] = useState(null);

  const adsWithDetails = props.adsWithDetails;

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpenPreview(true);
        }}>
        미리보기
      </Button>

      {openPreview && (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}>
          <div
            style={{
              backgroundColor: '#fff',
              padding: 24,
              // maxHeight: '80%',
              overflowY: 'scroll',
              // width: '60%',
              borderRadius: 16,
            }}>
            <div style={{ textAlign: 'right' }}>
              <Close
                style={{ color: '#000', width: 36, height: 36 }}
                onClick={() => {
                  setOpenPreview(false);
                }}
              />
            </div>
            <div>
              <div>
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginBottom: 16,
                  }}>
                  Preview
                </p>
                <div
                  style={{
                    marginBottom: 8,
                    display: 'flex',
                    gap: 16,
                  }}>
                  <label>
                    <input
                      type="radio"
                      value=""
                      checked={!userLevel}
                      onChange={event => {
                        setUserLevel(null);
                      }}
                    />
                    비회원
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="EGG"
                      checked={userLevel === 'EGG'}
                      onChange={event => {
                        setUserLevel(event.target.value);
                      }}
                    />
                    EGG
                  </label>

                  <label>
                    <input
                      type="radio"
                      value="DUCK1"
                      checked={userLevel === 'DUCK1'}
                      onChange={event => {
                        setUserLevel(event.target.value);
                      }}
                    />
                    DUCK1
                  </label>
                </div>
                <div style={{ display: 'flex', gap: 32 }}>
                  <div style={frameStyle}>
                    <CompanyCard adsWithDetails={adsWithDetails} userLevel={userLevel} />
                  </div>
                  <div
                    style={{
                      ...frameStyle,
                      overflowY: 'scroll',
                    }}>
                    <ProductDetail adsWithDetails={adsWithDetails} userLevel={userLevel} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Preview;
