import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../action';
import { connect } from 'react-redux';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

import { Editor } from '../../components';
import * as api from './api';

class Notice extends React.Component {
  state = {
    id: this.props.location.query.id,
    schema: {},
    notice: {},
    snackOpen: false,
    snackText: '',
  };
  componentDidMount() {
    this.props.activateLoading();
    api.getSchema().then(schemaResult => {
      const schema = schemaResult.properties;
      const keys = Object.keys(schema);
      if (this.state.id) {
        api.getNotice(this.state.id).then(result => {
          const notice = result.notices[0];
          for (let i = 0; i < keys.length; i++) {
            schema[keys[i]].name = keys[i];
            schema[keys[i]].value =
              notice[keys[i]] === undefined ? (schema.type === 'boolean' ? false : null) : notice[keys[i]];
          }
          this.setState({ schema: schema, notice: notice });
          this.props.deactivateLoading();
        });
      } else {
        for (let i = 0; i < keys.length; i++) {
          schema[keys[i]].name = keys[i];
          if (keys[i] === 'for') {
            schema[keys[i]].value = 'user';
          }
          if (schema[keys[i]].type === 'boolean') {
            schema[keys[i]].value = false;
          }
          if (schema[keys[i]].type === 'object') {
            schema[keys[i]].value = {};
            Object.keys(schema[keys[i]].properties).map(key => {
              schema[keys[i]].value[key] = false;
            });
          }
        }
        this.setState({ schema: schema });
        this.props.deactivateLoading();
      }
    });
  }
  handleSave = json => {
    this.props.activateLoading();
    const { id } = this.state;
    const keys = Object.keys(json);
    let requestBody = {};
    for (let i = 0; i < keys.length; i++) {
      requestBody[keys[i]] = json[keys[i]].value;
    }
    if (id) {
      api
        .updateNotice(id, requestBody)
        .then(() => {
          this.setState({ schema: json, snackOpen: true, snackText: '업데이트 성공' });
          this.props.deactivateLoading();
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('업데이트에 실패했습니다. 다시 확인해주세요.');
        });
    } else {
      api
        .createNotice(requestBody)
        .then(() => {
          this.setState({ schema: json, snackOpen: true, snackText: '신규 생성 성공' });
          this.props.push('/notices');
          this.props.deactivateLoading();
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('생성에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  handleCancel = () => {
    this.props.push('/notices');
  };
  handleDelete = () => {
    if (window.confirm('정말 삭제 하시겠습니까')) {
      this.props.activateLoading();
      api
        .deleteNotice(this.state.id)
        .then(() => {
          this.setState({ snackOpen: true, snackText: '삭제 성공' });
          this.props.deactivateLoading();
        })
        .catch(err => {
          console.log(err);
          this.props.deactivateLoading();
          alert('삭제에 실패했습니다. 다시 확인해주세요.');
        });
    }
  };
  render() {
    const { schema, snackOpen, snackText } = this.state;
    if (Object.keys(schema).length > 0) {
      return (
        <div className="editor-container">
          <Editor
            schema={schema}
            handleSave={this.handleSave}
            handleCancel={this.handleCancel}
            handleDelete={this.handleDelete}
            handleDeleteText={'삭제'}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={snackOpen}
            autoHideDuration={4000}
            onClose={() => this.setState({ snackOpen: false })}
            message={<span>{snackText}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => this.setState({ snackOpen: false })}>
                <Close />
              </IconButton>,
            ]}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(Notice);
