import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../action';
import { Link } from 'react-router';
import { SearchInput, SortableTableHead } from '../../../../components';
import momentLib from 'moment-timezone';
import { getProductReviewConfigs } from '../../api/productReviewConfig';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { LEVEL } from '../constants';
import ReactPaginate from 'react-paginate';
import { ROWS_PER_PAGE } from '../../../../constants';

const initialState = {
  page: 1,
  rowsPerPage: ROWS_PER_PAGE,
  orderBy: '',
  direction: 'desc',
  configs: [],
  total: 0,
};
const ProductReviewConfigs = () => {
  const searchOptions = useRef({
    selectItem: '',
    findText: '',
  });

  const [state, setState] = useState(initialState);
  const [level, setLevel] = useState('EGG');

  const header = [
    {
      sortable: false,
      id: 'createdAt',
      label: '생성일',
    },
    { sortable: false, id: 'searchKeyword', label: '이름' },
    { sortable: false, id: 'sourceUrl', label: '원본 URL' },
    { sortable: false, id: 'target', label: '타겟' },
    { sortable: false, id: 'targetId', label: '타겟 ID' },
  ];

  const excludedHeaders = ['checkbox'];

  const fetch = async (page, orderBy, order) => {
    console.log({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),
    });
    const result = await getProductReviewConfigs({
      pageSize: ROWS_PER_PAGE,
      page: page,
      ...(!!searchOptions.current.findText && {
        [searchOptions.current.selectItem]: searchOptions.current.findText,
      }),
    });
    console.log(result.data.data);

    setState({
      ...state,
      configs: [...result.data.data.configs],
      page: page,
      total: result.data.data.totalRows,
      orderBy,
      direction: order,
    });
  };

  useEffect(() => {
    fetch(1);
  }, []);

  const resetState = useCallback(() => {
    setState(initialState);
  }, []);

  const handleSearch = (selectItem, findText) => {
    searchOptions.current = {
      selectItem,
      findText,
    };

    fetch(1, state.orderBy, state.direction);
  };

  const body = state.configs.map((config, index) => {
    return (
      <TableRow key={index}>
        <TableCell>{momentLib(config.createdAt).tz('Asia/Seoul').format('YY-MM-DD HH:mm')}</TableCell>
        <TableCell>{config.name}</TableCell>
        <TableCell className="lg">
          <a href={`${config.sourceUrl}`} target="_blank">
            {config.sourceUrl}
          </a>
        </TableCell>
        <TableCell>{config.target}</TableCell>
        <TableCell>{config.targetId}</TableCell>
        <TableCell>
          <Link to={'/cosduck/productReviewConfig?id=' + config.id} target="_blank">
            편집
          </Link>
        </TableCell>
      </TableRow>
    );
  });

  const pageCount = parseInt(state.total / state.rowsPerPage, 10) + (state.total % state.rowsPerPage === 0 ? 0 : 1);

  const handleChangePage = event => {
    fetch(event.selected + 1, state.orderBy, state.direction);
  };

  return (
    <div className="table-container">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '8px',
        }}>
        <SearchInput header={header.filter(item => !excludedHeaders.includes(item.id))} handleSearch={handleSearch} />
      </div>

      <Table>
        <SortableTableHead
          order={state.direction}
          orderBy={state.orderBy}
          columns={header}
          handleSort={(orderBy, order) => {
            fetch(state.page, orderBy, order);
          }}
        />
        <TableBody>{body}</TableBody>
      </Table>
      <div className="table-footer">
        <div />
        <ReactPaginate
          previousLabel={'< 이전'}
          nextLabel={'다음 >'}
          breakLabel={<span>...</span>}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={10}
          onPageChange={handleChangePage}
          containerClassName={'pagination'}
          subContainerClassName={'pages pagination'}
          activeClassName={'active'}
          initialPage={0}
          forcePage={state.page - 1}
        />
        <div />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductReviewConfigs);
