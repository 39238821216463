import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CircularProgress from '@material-ui/core/CircularProgress';

import _ from 'lodash';
import { mapSeries } from 'p-iteration';

import * as api from '../api';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import AWS from 'aws-sdk';
import GeneratedQna from './GeneratedQna';

class LoadQNA extends React.Component {
  state = {
    progress: -1,
    file: null,
  };
  render() {
    const { progress } = this.state;

    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary>
          <h3>QNA 업데이트</h3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div style={{ width: '100%' }}>
            <section>
              <div className="row">
                <div style={{ flex: 1 }}>
                  <input accept=".json" id="json-button-file" onChange={this.handleLoad} type="file" />
                </div>
                <div style={{ flex: 1 }}>
                  {progress == -1 ? (
                    <IconButton onClick={this.handleUpload}>
                      <CloudUploadIcon />
                    </IconButton>
                  ) : (
                    <CircularProgress variant="static" value={progress} color="secondary" />
                  )}
                </div>
                <div style={{ flex: 1 }}>{progress > -1 ? progress + '%' : ''}</div>
              </div>
            </section>
          </div>
        </ExpansionPanelDetails>
        <GeneratedQna />
      </ExpansionPanel>
    );
  }
  async componentDidMount() {}

  handleLoad = event => {
    this.setState({ file: event.target.files[0] });
  };

  handleUpload = async () => {
    if (this.state.file) {
      AWS.config.update({
        region: 'ap-northeast-2',
        accessKeyId: 'AKIAJOTDRH27VFET7VIA',
        secretAccessKey: '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',
      });

      const s3 = new AWS.S3();
      const params = {
        Bucket: 'supermembers.upload',
        Key: 'etc/' + this.state.file.name,
        Body: this.state.file,
        ACL: 'public-read',
      };

      try {
        await s3.upload(params).promise();
        alert('업로드 성공!');
      } catch (error) {
        alert('업로드 중 오류 발생:', error.message);
      }
    }
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(LoadQNA);
