import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';

import * as api from './api';
import ActionCreators from '../action';
import { SortableTableHead, SearchInput } from '../../components';

const header = [
  { sortable: true, id: 'updated', label: '가입일' },
  { sortable: true, id: 'id', label: 'ID' },
  { sortable: true, id: 'name', label: '가맹점명' },
  { sortable: true, id: 'payMethod', label: '결제수단' },
  { sortable: true, id: 'verified', label: '진행상태' },
  { sortable: true, id: 'lastPaidAt', label: '최근결제일' },
  { sortable: true, id: 'nextPaidAt', label: '다음결제일' },
  { sortable: true, id: 'contractCount', label: '이용자수' },
  { sortable: true, id: 'keywordCount', label: '검색어수' },
  { sortable: true, id: 'checkBlogger', label: '카운트필수' },
  { sortable: true, id: 'edit', label: '정보' },
];

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rowsPerPage: 100,
      orderBy: 'id',
      direction: 'asc',
      companies: [],
      total: 0,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.fetchDatas = this.fetchDatas.bind(this);
  }

  componentDidMount() {
    this.fetchDatas(null, null, null, null);
  }

  handleSearch = (selectItem, findText) => {
    this.fetchDatas(0, this.state.orderBy, this.state.direction, { [selectItem]: findText });
  };

  handleChangePage(event) {
    this.fetchDatas(event.selected, null, null, null);
  }

  fetchDatas(pageNum, orderBy, direction, options) {
    this.props.activateLoading();

    const page = pageNum >= 0 ? pageNum : this.state.page;
    const filter = {
      page: page + 1,
      limit: this.state.rowsPerPage,
      orderBy: orderBy || this.state.orderBy,
      direction: direction || this.state.direction,
      ...options,
    };
    api.filterCompanies(filter).then(results => {
      this.setState(Object.assign(filter, results));
      this.props.deactivateLoading();
    });
  }

  render() {
    const { rowsPerPage, orderBy, direction, companies, total } = this.state;
    const pageCount = parseInt(total / rowsPerPage, 10) + (total % rowsPerPage === 0 ? 0 : 1);

    let bodys = companies.map((item, index) => {
      return (
        <TableRow key={index}>
          <TableCell>{item.updated}</TableCell>
          <TableCell>{item.id}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.payMethod}</TableCell>
          <TableCell>{item.verified ? '진행' : '종료'}</TableCell>
          <TableCell>{item.lastPaidAt}</TableCell>
          <TableCell>{item.nextPaidAt}</TableCell>
          <TableCell>{item.contractCount}</TableCell>
          <TableCell>{item.keywordCount}</TableCell>
          <TableCell>
            {item.checkBlogger !== undefined ? (
              <Checkbox
                checked={item.checkBlogger}
                disabled={true}
                onChange={event => {
                  api.updateCompany(item.id, { checkBlogger: event.target.checked });
                  companies[index].checkBlogger = event.target.checked;
                  this.setState({ companies: companies });
                }}
              />
            ) : (
              ''
            )}
          </TableCell>
          <TableCell>
            <Link to={'/company?id=' + item.id} target="_blank">
              편집
            </Link>
          </TableCell>
        </TableRow>
      );
    });
    return (
      <div className="table-container">
        <SearchInput header={header} handleSearch={this.handleSearch} />
        <Table>
          <SortableTableHead
            orderBy={orderBy}
            order={direction}
            columns={header}
            handleSort={(orderBy, direction) => this.fetchDatas(null, orderBy, direction, null)}
          />
          <TableBody>{bodys}</TableBody>
        </Table>
        <div className="table-footer">
          <div></div>
          <ReactPaginate
            previousLabel={'< 이전'}
            nextLabel={'다음 >'}
            breakLabel={<span>...</span>}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={10}
            onPageChange={this.handleChangePage}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            initialPage={0}
            forcePage={this.state.page - 1}
          />
          <div></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
