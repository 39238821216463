import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Button from '@material-ui/core/Button';
import { CASHBACK_STATUS } from '../../constants';

const CashbackHistoryTable = props => {
  return (
    <ExpansionPanel expanded={true}>
      <ExpansionPanelDetails>
        <div style={{ width: '100%' }}>
          <p
            style={{
              fontSize: 16,
              fontWeight: 600,
            }}>
            cashbackHistory
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>status</TableCell>
                <TableCell>updatedAt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.cashbackHistory.map(history => (
                <TableRow>
                  <TableCell>{history.status}</TableCell>
                  <TableCell>{history.updatedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div
            style={{
              gap: 16,
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: 32,
            }}>
            {CASHBACK_STATUS.map(status => (
              <Button
                // NOTE : 취소 이외의 status 변경이 필요하다면, 향후에 disabled를 풀고 API를 통해 호출해서 처리할 필요가 있음
                disabled={true}
                variant="contained"
                color="primary"
                onClick={() => {
                  props.onPushHistory('cashbackHistory', status);
                }}>
                [{status}] Push
              </Button>
            ))}
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default CashbackHistoryTable;
