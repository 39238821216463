import React from 'react';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../action';
import { connect } from 'react-redux';
import * as api from '../api';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import _ from 'lodash';
import Remove from '@material-ui/icons/RemoveCircleOutline';
import Add from '@material-ui/icons/AddCircleOutline';
import Select from '@material-ui/core/Select/Select';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing(1),
  },
});

class CampaignChannel extends React.Component {
  state = {
    filter: 'naver',
    newRequiredKeyword: '',
    newKeyword: '',
    newMission: '',
  };
  render() {
    const { campaign, classes } = this.props;
    const { filter, newMission } = this.state;

    if (Object.keys(campaign).length > 0) {
      let details = ['naver', 'insta', 'youtube'].map(channel => {
        if (campaign[channel] && channel === filter) {
          const requiredKeywords = _.isEmpty(campaign[channel].requiredKeywords)
            ? ''
            : campaign[channel].requiredKeywords.map((item, idx) => {
                return (
                  <Chip
                    key={item + idx}
                    label={item}
                    className={classes.chip}
                    onDelete={() => this.handleDeleteRequiredKeyword(channel, idx)}
                  />
                );
              });

          const keywords = _.isEmpty(campaign[channel].keywords)
            ? ''
            : campaign[channel].keywords.map((item, idx) => {
                return (
                  <Chip
                    key={item + idx}
                    label={item}
                    className={classes.chip}
                    onDelete={() => this.handleDeleteKeyword(channel, idx)}
                  />
                );
              });

          let missionElements = '';
          const mission = (campaign[channel] || {}).mission || [];
          missionElements = mission.map((item, idx) => {
            return (
              <div className="row" key={idx}>
                <TextField
                  value={item}
                  onChange={evt => this.props.handlePropChange(channel, 'mission', evt.target.value, idx)}
                  fullWidth
                />
                <IconButton onClick={() => this.handleDelete(idx)}>
                  <Remove />
                </IconButton>
              </div>
            );
          });

          return (
            <div key={channel} style={{ width: '100%' }}>
              <div>
                <label>회원 제공 혜택</label>
                <TextField
                  fullWidth
                  value={(campaign[channel] || {}).reward}
                  onChange={evt => this.props.handlePropChange(channel, 'reward', evt.target.value)}
                />
              </div>
              <div>
                <label>정가</label>
                <TextField
                  fullWidth
                  value={(campaign[channel] || {}).rewardPrice}
                  onChange={evt => this.props.handlePropChange(channel, 'rewardPrice', evt.target.value)}
                />
              </div>
              <div>
                <label>필수 키워드</label>
                <div className="row">
                  <TextField
                    fullWidth
                    value={
                      this.state.newRequiredKeyword.length > 0 ? JSON.parse(this.state.newRequiredKeyword)[channel] : ''
                    }
                    onChange={evt => {
                      let newRequiredKeyword =
                        this.state.newRequiredKeyword.length > 0 ? JSON.parse(this.state.newRequiredKeyword) : {};
                      newRequiredKeyword[channel] = evt.target.value;
                      this.setState({ newRequiredKeyword: JSON.stringify(newRequiredKeyword) });
                    }}
                  />
                  <IconButton onClick={() => this.handleAddRequiredKeyword(channel)}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div className={classes.root}>{requiredKeywords}</div>
              </div>
              <div>
                <label>선택 키워드</label>
                <div className="row">
                  <TextField
                    fullWidth
                    value={this.state['newKeyword' + channel]}
                    onChange={evt => {
                      let newKeyword = this.state.newKeyword.length > 0 ? JSON.parse(this.state.newKeyword) : {};
                      newKeyword[channel] = evt.target.value;
                      this.setState({ newKeyword: JSON.stringify(newKeyword) });
                    }}
                  />
                  <IconButton onClick={() => this.handleAddKeyword(channel)}>
                    <AddIcon />
                  </IconButton>
                </div>
                <div className={classes.root}>{keywords}</div>
              </div>
              <div>
                <label>필수 미션</label>
                {missionElements}
                <div className="row">
                  <TextField
                    value={newMission}
                    onChange={evt => this.setState({ newMission: evt.target.value })}
                    fullWidth
                  />
                  <IconButton onClick={() => this.handleAddMission(channel)}>
                    <Add />
                  </IconButton>
                </div>
              </div>
              <div>
                <label>구매링크</label>
                <TextField
                  fullWidth
                  value={(campaign[channel] || {}).purchaseLink}
                  onChange={evt => this.props.handlePropChange(channel, 'purchaseLink', evt.target.value)}
                />
              </div>
              <div className="row">
                <div style={{ flex: 1, minWidth: 0 }}>
                  <label>촬영 장비/스마트폰사용여부</label>
                  <Select
                    fullWidth
                    value={(campaign[channel] || {}).device}
                    onChange={evt => {
                      this.props.handlePropChange(channel, 'device', evt.target.value);
                    }}>
                    <option key={0} value={'N'}>
                      {'무관'}
                    </option>
                    <option key={1} value={'X'}>
                      {'불가'}
                    </option>
                  </Select>
                </div>
                <div style={{ flex: 1, minWidth: 0 }}>
                  <label>사진 분량/영상분량/노출분량</label>
                  <TextField
                    fullWidth
                    value={(campaign[channel] || {}).photo}
                    onChange={evt => this.props.handlePropChange(channel, 'photo', evt.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div style={{ flex: 0.5, minWidth: 0 }}>
                  <label>영상 포함/미디어형태/화질</label>
                  <Select
                    fullWidth
                    value={(campaign[channel] || {}).video}
                    onChange={evt => {
                      this.props.handlePropChange(channel, 'video', evt.target.value);
                    }}>
                    <option key={0} value={'N'}>
                      {'무관'}
                    </option>
                    <option key={1} value={'O'}>
                      {'필수'}
                    </option>
                    <option key={2} value={'P'}>
                      {'사진'}
                    </option>
                    <option key={3} value={'V'}>
                      {'영상'}
                    </option>
                    <option key={4} value={'VP'}>
                      {'영상+사진'}
                    </option>
                    <option key={5} value={'1080'}>
                      {'1080p이상'}
                    </option>
                    <option key={6} value={'4k'}>
                      {'4k'}
                    </option>
                  </Select>
                </div>
                <div style={{ flex: 0.5, minWidth: 0 }}>
                  <label>얼굴 포함 여부</label>
                  <Select
                    fullWidth
                    value={(campaign[channel] || {}).face}
                    onChange={evt => {
                      this.props.handlePropChange(channel, 'face', evt.target.value);
                    }}>
                    <option key={0} value={'N'}>
                      {'무관'}
                    </option>
                    <option key={1} value={'O'}>
                      {'필수'}
                    </option>
                  </Select>
                </div>
              </div>
              <div className="row">
                <div style={{ flex: 0.5, minWidth: 0 }}>
                  <label>광고형태</label>
                  <Select
                    fullWidth
                    value={(campaign[channel] || {}).adsType}
                    onChange={evt => {
                      this.props.handlePropChange(channel, 'adsType', evt.target.value);
                    }}>
                    <option key={0} value={'B'}>
                      {'브랜디드 컨텐츠'}
                    </option>
                    <option key={1} value={'P'}>
                      {'PPL'}
                    </option>
                  </Select>
                </div>
                <div style={{ flex: 0.5, minWidth: 0 }}>
                  <label>단독영상여부</label>
                  <Select
                    fullWidth
                    value={(campaign[channel] || {}).exclusive}
                    onChange={evt => {
                      this.props.handlePropChange(channel, 'exclusive', evt.target.value);
                    }}>
                    <option key={0} value={'X'}>
                      {'다른 브랜드 광고 포함 불가'}
                    </option>
                    <option key={1} value={'O'}>
                      {'다른 브랜드 광고 포함 허용'}
                    </option>
                  </Select>
                </div>
              </div>
            </div>
          );
        } else {
          return <div key={channel} />;
        }
      });

      return (
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary>
            <h3>
              채널별 제공혜택 및 편집조건 <span style={{ fontWeight: 300 }}>정보</span>
            </h3>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'block' }}>
            <div>
              <Tabs
                value={filter}
                onChange={(event, filter) => {
                  this.setState({ filter });
                }}>
                <Tab value={'naver'} label={'네이버'} />
                <Tab value={'insta'} label={'인스타'} />
                <Tab value={'youtube'} label={'유튜브'} />
              </Tabs>
            </div>
            {details}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else {
      return <div />;
    }
  }
  async componentDidMount() {
    this.props.onRef(this);
    const categories = await api.getOptions('type=category');
    this.setState({ categories });
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  handleAddRequiredKeyword = async channel => {
    let { newRequiredKeyword } = this.state;
    newRequiredKeyword = newRequiredKeyword.length > 0 ? JSON.parse(newRequiredKeyword) : {};
    if (newRequiredKeyword[channel] && newRequiredKeyword[channel].length > 0) {
      const newArr = this.props.campaign[channel].requiredKeywords
        ? [...this.props.campaign[channel].requiredKeywords]
        : [];
      newRequiredKeyword[channel].split(',').forEach(keyword => {
        newArr.push(keyword.trim());
      });
      await this.props.handlePropChange(channel, 'requiredKeywords', newArr);
      // this.setState({ newKeyword: '' });
      return new Promise(resolve => {
        let newRequiredKeyword = {};
        newRequiredKeyword[channel] = '';
        this.setState({ newRequiredKeyword: JSON.stringify(newRequiredKeyword) }, () => resolve());
      });
    }
  };
  handleDeleteRequiredKeyword = (channel, idx) => {
    const newArr = this.props.campaign[channel].requiredKeywords
      ? [...this.props.campaign[channel].requiredKeywords]
      : [];
    if (newArr.length > 0) {
      newArr.splice(idx, 1);
      this.props.handlePropChange(channel, 'requiredKeywords', newArr);
    }
  };

  handleAddKeyword = async channel => {
    let { newKeyword } = this.state;
    newKeyword = newKeyword.length > 0 ? JSON.parse(newKeyword) : {};
    if (newKeyword[channel] && newKeyword[channel].length > 0) {
      const newArr = this.props.campaign[channel].keywords ? [...this.props.campaign[channel].keywords] : [];
      newKeyword[channel].split(',').forEach(keyword => {
        newArr.push(keyword.trim());
      });
      await this.props.handlePropChange(channel, 'keywords', newArr);
      // this.setState({ newKeyword: '' });
      return new Promise(resolve => {
        let newKeyword = {};
        newKeyword[channel] = '';
        this.setState({ newKeyword: JSON.stringify(newKeyword) }, () => resolve());
      });
    }
  };
  handleDeleteKeyword = (channel, idx) => {
    const newArr = this.props.campaign[channel].keywords ? [...this.props.campaign[channel].keywords] : [];
    if (newArr.length > 0) {
      newArr.splice(idx, 1);
      this.props.handlePropChange(channel, 'keywords', newArr);
    }
  };
  handleAddMission = channel => {
    const { campaign } = this.props;
    const { newMission } = this.state;

    const mission = (campaign[channel] || {}).mission || [];

    if (newMission.length === 0) {
      return;
    }
    mission.push(newMission);
    this.setState({ newMission: '' });

    this.props.handlePropChange(channel, 'mission', mission);
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}
export default connect(null, mapDispatchToProps)(withStyles(styles)(CampaignChannel));
