import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router';

import OpenInNew from '@material-ui/icons/OpenInNew';
import Add from '@material-ui/icons/AddCircleOutline';
import Remove from '@material-ui/icons/RemoveCircleOutline';

import Switch from '@material-ui/core/Switch/Switch';
import Select from '@material-ui/core/Select/Select';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import _ from 'lodash';
import * as apiV2 from '../../api-v2';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import { AdCategoryEnum } from '../../paycard.enum';
import momentLib from 'moment-timezone';

import { StyledTextField, StyledPaper, FormSection, FormLabel, FormLabelWithTooltip } from './StyledComponents';

const PaymentAdsDetail = ({
  ad,
  channel,
  paymentCards,
  onUpdate,
  adCategory,
  setLoading,
  setOpenSnackbar,
  setSnackbarMessage,
  onExpose, // 새로운 prop 추가
}) => {
  const [formData, setFormData] = useState({ ad: {}, channel: {} });
  const [initialValues, setInitialValues] = useState({ ad: {}, channel: {} });
  const [changes, setChanges] = useState({ ad: {}, channel: {} });
  const [newMemo, setNewMemo] = useState('');
  // ---
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openUpdateWarningDialog, setOpenUpdateWarningDialog] = useState(false);
  const [updateWarningData, setUpdateWarningData] = useState(null);

  useEffect(() => {
    setFormData(JSON.parse(JSON.stringify({ ad, channel })));
    setInitialValues(JSON.parse(JSON.stringify({ ad, channel })));
    setChanges({ ad: {}, channel: {} });
  }, [ad, channel]);

  const getChangedFieldLabels = () => {
    const labels = [];

    for (const key in changes) {
      for (const fieldKey in changes[key]) {
        let labelName = '';
        if (fieldKey === 'payInfo_amount') labelName = '결제 금액';
        else if (fieldKey === 'payInfo_plan') labelName = '결제 플랜';
        else if (fieldKey === 'payInfo_payMethod') labelName = '결제 수단';
        else if (fieldKey === 'payInfo_card') labelName = '결제 카드';
        else if (fieldKey === 'payInfo_checkBloggerCnt') labelName = '최소 보장인원';
        else if (fieldKey === 'number') labelName = '모집인원';
        else if (fieldKey === 'payInfo_checkPeriod') labelName = '연장 60일 제한 해제';
        else if (fieldKey === 'payInfo_memo') labelName = '메모';
        else if (fieldKey === 'periodApply') labelName = '프리미엄 체험단 신청 기한일';
        else if (fieldKey === 'periodAccept') labelName = '프리미엄 체험단 선정 기한일';
        else if (fieldKey === 'periodReview') labelName = '프리미엄 체험단 리뷰등록 기한일';

        const original =
          initialValues[key][fieldKey] === null
            ? initialValues[key][fieldKey]
            : fieldKey === 'payInfo_memo'
              ? `[ ${initialValues[key][fieldKey].join(', ')} ]`
              : fieldKey === 'payInfo_card'
                ? `${paymentCards[initialValues[key][fieldKey]].cardName}${
                    paymentCards[initialValues[key][fieldKey]].last4
                  }`
                : initialValues[key][fieldKey];

        const changed =
          changes[key][fieldKey] === null
            ? changes[key][fieldKey]
            : fieldKey === 'payInfo_memo'
              ? `[ ${changes[key][fieldKey].join(', ')} ]`
              : fieldKey === 'payInfo_card'
                ? `${paymentCards[changes[key][fieldKey]].cardName}${paymentCards[changes[key][fieldKey]].last4}`
                : changes[key][fieldKey];

        if (labelName) {
          labels.push(
            `<span style="font-weight:bold">${labelName}:</span> <span style="color: #9B111E">${original}</span> → <span style="color: #006400">${changed}</span>`
          );
        }
      }
    }

    return labels.join('\n');
  };

  const handleSave = () => {
    if (Object.keys(changes.ad).length === 0 && Object.keys(changes.channel).length === 0) return;
    setOpenDialog(true);
  };

  const handleConfirmSave = async () => {
    setOpenDialog(false);
    setLoading(true);

    try {
      const adId = ad.id;

      const result = await apiV2.updateAdsChannel({
        adId,
        ...(Object.keys(changes.ad).length > 0 && {
          adsBaseUpdatedAt: initialValues.ad.updatedAt,
          adsChanges: changes.ad,
        }),
        ...(Object.keys(changes.channel).length > 0 && {
          channelsBaseUpdatedAt: initialValues.channel.updatedAt,
          channelsChanges: changes.channel,
        }),
      });

      if (result.success) {
        console.log('Successfully updated channel information');
        onUpdate({
          ...(!!result.data.ad && { ad: result.data.ad }),
          ...(!!result.data.channel && { channel: result.data.channel }),
        });
        setSnackbarMessage('광고 정보가 성공적으로 저장되었습니다.');
        setOpenSnackbar(true);
      } else {
        if (result.error.code === 'CHANNELS_UPDATED_AT_NOT_MATCH' || result.error.code === 'ADS_UPDATED_AT_NOT_MATCH') {
          const conflictData =
            result.error.code === 'CHANNELS_UPDATED_AT_NOT_MATCH'
              ? {
                  originalUpdatedAt: initialValues.channel.updatedAt,
                  newUpdatedAt: result.data.channel.updatedAt,
                }
              : {
                  originalUpdatedAt: initialValues.ad.updatedAt,
                  newUpdatedAt: result.data.ad.updatedAt,
                };

          setUpdateWarningData(conflictData);
          setOpenUpdateWarningDialog(true);
        } else {
          console.log('@@ error', result);
          alert('업데이트 중 오류가 발생했습니다. 개발팀에 문의해주세요.');
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNewAdsPayment = useCallback(() => {
    // NOTE: setChanges 없이 그냥 changes를 참조하면 함수 생성 당시의 changes만 참조함
    setChanges(currentChanges => {
      if (Object.keys(currentChanges.ad).length > 0 || Object.keys(currentChanges.channel).length > 0) {
        alert('광고 정보 변경사항이 있습니다. 변경사항을 먼저 저장해주세요.');
        return currentChanges;
      }
      setOpenCreateDialog(true);
      return currentChanges;
    });
  }, []);

  useEffect(() => {
    // 컴포넌트가 마운트될 때 메서드들을 부모에게 전달
    if (onExpose) {
      onExpose(prev => ({
        ...prev,
        handleCreateNewAdsPayment,
      }));
    }
  }, [handleCreateNewAdsPayment, onExpose]);

  const handleConfirmCreateNewAdsPayment = async () => {
    setOpenCreateDialog(false);
    setLoading(true);

    try {
      const adId = ad.id;

      const result = await apiV2.createAdsPayment({
        adId,
      });

      if (result.success) {
        console.log('Successfully created new ads payment');
        onUpdate({
          ...(!!result.data.adPayments && {
            adPayments: result.data.adPayments,
          }),
        });
        setSnackbarMessage('신규 차수가 성공적으로 생성되었습니다.');
        setOpenSnackbar(true);
      } else {
        console.log('@@ error', result);
        alert('업데이트 중 오류가 발생했습니다. 개발팀에 문의해주세요.');
      }
    } catch (err) {
      console.log('@@ error', err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (key, fieldName, fieldValue, idx) => {
    setFormData(prev => {
      const updated = { ...prev };
      if (idx !== undefined) {
        // Handle array updates
        const arr = [...(updated[key][fieldName] || [])];
        arr[idx] = fieldValue;
        updated[key][fieldName] = arr;
      } else {
        // Handle regular field updates
        updated[key][fieldName] = fieldValue;
      }

      // Check if value is different from initial and update changes
      const initial = initialValues[key][fieldName];
      if (idx !== undefined) {
        const initialArr = initial || [];
        if (!_.isEqual(updated[key][fieldName], initialArr)) {
          setChanges(prev => ({
            ...prev,
            [key]: {
              ...prev[key],
              [fieldName]: updated[key][fieldName],
            },
          }));
        } else {
          setChanges(prev => {
            const newChanges = {
              ...prev,
              [key]: { ...prev[key] },
            };
            delete newChanges[key][fieldName];
            return newChanges;
          });
        }
      } else {
        if (!_.isEqual(fieldValue, initial)) {
          setChanges(prev => ({
            ...prev,
            [key]: {
              ...prev[key],
              [fieldName]: fieldValue,
            },
          }));
        } else {
          setChanges(prev => {
            const newChanges = {
              ...prev,
              [key]: { ...prev[key] },
            };
            delete newChanges[key][fieldName];
            return newChanges;
          });
        }
      }
      return updated;
    });
  };

  const handleReset = () => {
    if (Object.keys(changes.ad).length === 0 && Object.keys(changes.channel).length === 0) return;

    if (window.confirm('변경사항을 모두 초기화하시겠습니까?')) {
      setFormData(JSON.parse(JSON.stringify(initialValues)));
      setChanges({ ad: {}, channel: {} });
    }
  };

  const handleMemoAdd = () => {
    if (newMemo.length === 0) return;

    setFormData(prev => {
      const memo = [...(prev.channel.payInfo_memo || []), newMemo];
      if (_.isEqual(memo, initialValues.channel.payInfo_memo)) {
        setChanges(prev => {
          const newChanges = {
            ...prev,
            channel: { ...prev.channel },
          };
          delete newChanges.channel.payInfo_memo;
          return newChanges;
        });
      } else {
        setChanges(prev => {
          return {
            ...prev,
            channel: {
              ...prev.channel,
              payInfo_memo: memo,
            },
          };
        });
      }
      return {
        ...prev,
        channel: {
          ...prev.channel,
          payInfo_memo: memo,
        },
      };
    });
    setNewMemo('');
  };

  const handleMemoDelete = idx => {
    setFormData(prev => {
      const memo = [...(prev.channel.payInfo_memo || [])];
      memo.splice(idx, 1);
      if (_.isEqual(memo, initialValues.channel.payInfo_memo)) {
        setChanges(prev => {
          const newChanges = {
            ...prev,
            channel: { ...prev.channel },
          };
          delete newChanges.channel.payInfo_memo;
          return newChanges;
        });
      } else {
        setChanges(prev => {
          return {
            ...prev,
            channel: {
              ...prev.channel,
              payInfo_memo: memo,
            },
          };
        });
      }
      return {
        ...prev,
        channel: {
          ...prev.channel,
          payInfo_memo: memo,
        },
      };
    });
  };

  const memoElements = !_.isEmpty(formData.channel)
    ? (formData.channel.payInfo_memo || []).map((item, idx) => (
        <Grid item xs={12} key={idx}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <StyledTextField
              variant="outlined"
              value={item}
              onChange={evt => handleChange('channel', 'payInfo_memo', evt.target.value, idx)}
              fullWidth
            />
            <IconButton onClick={() => handleMemoDelete(idx)}>
              <Remove />
            </IconButton>
          </div>
        </Grid>
      ))
    : '';

  if (!!formData.ad && !!formData.channel && adCategory) {
    return (
      <div>
        <StyledPaper>
          <FormSection>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <h2>광고 상태</h2>
              {(Object.keys(changes.ad).length > 0 || Object.keys(changes.channel).length > 0) && (
                <span
                  style={{
                    color: '#e91e3c',
                    fontSize: '0.8rem',
                    fontWeight: 'bold',
                    border: '1px solid #e91e3c',
                    padding: '4px 6px',
                    borderRadius: '4px',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                  }}>
                  저장하지 않은 변경내역이 있습니다
                </span>
              )}
            </div>
            <Divider style={{ margin: '16px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="광고상품 종류 (매장, 제품)">상품 종류</FormLabelWithTooltip>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  value={
                    adCategory === AdCategoryEnum.PRODUCT_PREMIUM
                      ? '제품-프리미엄'
                      : adCategory === AdCategoryEnum.STORE_PREMIUM
                        ? '매장-프리미엄'
                        : adCategory === AdCategoryEnum.PRODUCT_STANDARD
                          ? '제품-표준형'
                          : adCategory === AdCategoryEnum.STORE_STANDARD
                            ? '매장-표준형'
                            : ''
                  }
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 verified 필드. 0: 검수중, 1: 검수완료">
                  검수 상태
                </FormLabelWithTooltip>
                <StyledTextField variant="outlined" fullWidth value={!!ad.verified ? '검수완료' : '검수중'} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 available 필드. 0: 게시중, 1: 일시중지, 2: 광고종료">
                  게시 상태
                </FormLabelWithTooltip>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  value={
                    channel.available === 0
                      ? '게시중'
                      : channel.available === 1
                        ? '일시중지'
                        : channel.available === 2
                          ? '광고종료'
                          : ''
                  }
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 currentAdsOrder 필드. 가장 최근 진행한 차수정보를 표시합니다.">
                  현재 차수
                </FormLabelWithTooltip>
                <StyledTextField variant="outlined" fullWidth value={channel.currentAdsOrder} disabled />
              </Grid>

              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 startDate 필드">
                  현재 차수 광고 시작일
                </FormLabelWithTooltip>
                <StyledTextField variant="outlined" fullWidth value={channel.startDate} disabled />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_endPaidAt 필드">
                  현재 차수 광고 종료일
                </FormLabelWithTooltip>
                <StyledTextField variant="outlined" fullWidth value={channel.payInfo_endPaidAt} disabled />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormLabel>업체 정보(company) 바로가기</FormLabel>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px',
                    textDecoration: 'underline',
                    textUnderlineOffset: 4,
                    textDecorationColor: '#999',
                  }}>
                  <Link to={'/company-user?id=' + ad.companyId} target="_blank">
                    <IconButton>
                      <OpenInNew />
                    </IconButton>
                  </Link>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabel>가맹점 정보(ads) 바로가기</FormLabel>
                <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                  <Link to={'/company3?id=' + ad.id} target="_blank">
                    <IconButton>
                      <OpenInNew />
                    </IconButton>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </FormSection>

          <FormSection>
            <h2>광고 결제 정보</h2>
            <Divider style={{ margin: '16px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_amount 필드">결제 금액</FormLabelWithTooltip>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  value={formData.channel.payInfo_amount}
                  onChange={evt => handleChange('channel', 'payInfo_amount', parseInt(evt.target.value))}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_plan 필드">결제 플랜</FormLabelWithTooltip>
                <Select
                  value={formData.channel.payInfo_plan}
                  onChange={evt => {
                    const planValue = evt.target.value;
                    handleChange('channel', 'payInfo_plan', planValue);

                    let amount;
                    switch (planValue) {
                      case 1:
                        amount = 165000;
                        break;
                      case 3:
                        amount = 396000;
                        break;
                      case 6:
                        amount = 891000;
                        break;
                      case 12:
                        amount = 1584000;
                        break;
                      default:
                        amount = 0;
                    }
                    handleChange('channel', 'payInfo_amount', amount);
                  }}
                  variant="outlined"
                  fullWidth>
                  <option key={1} value={1}>
                    {'1개월'}
                  </option>
                  <option key={3} value={3}>
                    {'3개월'}
                  </option>
                  <option key={6} value={6}>
                    {'6개월'}
                  </option>
                  <option key={12} value={12}>
                    {'12개월'}
                  </option>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_payMethod 필드">결제 수단</FormLabelWithTooltip>
                <Select
                  value={formData.channel.payInfo_payMethod}
                  onChange={evt => handleChange('channel', 'payInfo_payMethod', evt.target.value)}
                  variant="outlined"
                  fullWidth>
                  <option key={0} value={''}>
                    {''}
                  </option>
                  <option key={1} value={'card'}>
                    {'카드'}
                  </option>
                  <option key={2} value={'cash'}>
                    {'현금'}
                  </option>
                  <option key={3} value={'free'}>
                    {'무상지원'}
                  </option>
                </Select>
              </Grid>
              <Grid item xs={12} md={3}>
                {formData.channel.payInfo_payMethod === 'card' && (
                  <>
                    <FormLabelWithTooltip title="AuthUsers_Card 테이블 데이터">결제 카드</FormLabelWithTooltip>
                    <Select
                      value={formData.channel.payInfo_card}
                      onChange={evt => {
                        handleChange('channel', 'payInfo_card', evt.target.value === '' ? null : evt.target.value);
                      }}
                      variant="outlined"
                      fullWidth
                      disabled={formData.channel.payInfo_payMethod !== 'card'}>
                      <option value="">없음</option>
                      {paymentCards.map((card, idx) => (
                        <option key={idx} value={card.id}>
                          {card.cardName + card.last4}
                        </option>
                      ))}
                    </Select>
                  </>
                )}
              </Grid>
            </Grid>
          </FormSection>

          <FormSection>
            <h2>광고 관리</h2>
            <Divider style={{ margin: '16px 0' }} />
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_checkBloggerCnt 필드">
                  최소 보장인원
                </FormLabelWithTooltip>

                <StyledTextField
                  type={'number'}
                  variant="outlined"
                  fullWidth
                  value={formData.channel.payInfo_checkBloggerCnt}
                  onChange={evt => {
                    handleChange('channel', 'payInfo_checkBloggerCnt', parseInt(evt.target.value));
                    if (!adCategory.startsWith('STORE')) {
                      handleChange('channel', 'number', parseInt(evt.target.value));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 number 필드. 매장의 경우 모집인원 제한이 없기에 빈 값으로 둡니다.">
                  모집인원
                </FormLabelWithTooltip>
                <StyledTextField
                  type={'number'}
                  variant="outlined"
                  fullWidth
                  disabled={adCategory.startsWith('STORE')}
                  value={formData.channel.number}
                  onChange={evt => {
                    if (!adCategory.startsWith('STORE')) {
                      handleChange('channel', 'number', parseInt(evt.target.value));
                      handleChange('channel', 'payInfo_checkBloggerCnt', parseInt(evt.target.value));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormLabelWithTooltip title="Channels 테이블의 payInfo_checkPeriod 필드">
                  연장 60일 제한 해제
                </FormLabelWithTooltip>
                <Switch
                  color="secondary"
                  checked={formData.channel.payInfo_checkPeriod}
                  onChange={(evt, checked) => handleChange('channel', 'payInfo_checkPeriod', checked)}
                />
              </Grid>
              {formData.ad.type === 'A' && (
                <>
                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단 신청이 가능한 마감일을 설정합니다">
                      프리미엄 체험단 신청 기한일
                    </FormLabelWithTooltip>
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      value={formData.ad.periodApply}
                      onChange={evt => handleChange('ad', 'periodApply', parseInt(evt.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단 선정이 완료되어야 하는 기한일을 설정합니다">
                      프리미엄 체험단 선정 기한일
                    </FormLabelWithTooltip>
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      value={formData.ad.periodAccept}
                      onChange={evt => handleChange('ad', 'periodAccept', parseInt(evt.target.value))}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormLabelWithTooltip title="프리미엄 체험단의 리뷰 등록이 완료되어야 하는 기한일을 설정합니다">
                      프리미엄 체험단 리뷰등록 기한일
                    </FormLabelWithTooltip>
                    <StyledTextField
                      variant="outlined"
                      fullWidth
                      value={formData.ad.periodReview}
                      onChange={evt => handleChange('ad', 'periodReview', parseInt(evt.target.value))}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </FormSection>

          <FormSection>
            <h2>메모</h2>
            <Divider style={{ margin: '16px 0' }} />
            <Grid container spacing={2}>
              {memoElements}
              <Grid item xs={12}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    value={newMemo}
                    onChange={evt => setNewMemo(evt.target.value)}
                    placeholder="새 메모 추가"
                  />
                  <Tooltip title="+버튼을 눌러야 메모가 추가됩니다" open={newMemo.length > 0} arrow placement="top">
                    <IconButton color="secondary" onClick={handleMemoAdd}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </FormSection>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '24px',
              gap: '12px',
            }}>
            <Button
              variant="outlined"
              color="default"
              onClick={handleReset}
              size="large"
              disabled={Object.keys(changes.ad).length === 0 && Object.keys(changes.channel).length === 0}>
              변경사항 초기화
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              size="large"
              disabled={Object.keys(changes.ad).length === 0 && Object.keys(changes.channel).length === 0}>
              광고 정보 저장
            </Button>
          </div>
        </StyledPaper>
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>{'확인'}</DialogTitle>
          <DialogContent>
            <div
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{
                __html: `다음 필드가 변경되었습니다:\n\n${getChangedFieldLabels()}\n\n정말 변경하시겠습니까?`,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>취소</Button>
            <Button onClick={handleConfirmSave} color="secondary" variant="contained">
              변경하기
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
          <DialogTitle>{'신규 차수 생성'}</DialogTitle>
          <DialogContent>
            <div style={{ whiteSpace: 'pre-line' }}>현재 입력된 광고 정보로 신규 차수를 생성하시겠습니까?</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCreateDialog(false)}>취소</Button>
            <Button onClick={handleConfirmCreateNewAdsPayment} color="secondary" variant="contained">
              생성하기
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openUpdateWarningDialog}
          onClose={() => setOpenUpdateWarningDialog(false)}
          maxWidth="sm"
          fullWidth>
          <DialogTitle>데이터 변경 감지</DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: '20px' }}>광고 데이터 원본이 다른 사용자에 의해 변경되었습니다.</div>
            {updateWarningData && (
              <div
                style={{
                  display: 'grid',
                  gap: '8px',
                  backgroundColor: '#f5f5f5',
                  padding: '16px',
                  borderRadius: '4px',
                }}>
                <div>
                  <strong>수정 시작 시점: </strong>
                  {momentLib(updateWarningData.originalUpdatedAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                  <strong>최신 데이터 시점: </strong>
                  {momentLib(updateWarningData.newUpdatedAt).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')}
                </div>
              </div>
            )}
            <div style={{ marginTop: '20px' }}>최신 데이터를 받아오기 위해 페이지를 새로고침하시겠습니까?</div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpdateWarningDialog(false)}>취소</Button>
            <Button onClick={() => window.location.reload()} color="secondary" variant="contained">
              새로고침
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default PaymentAdsDetail;
